// eslint-disable-next-line
import React from 'react';
import DataSheetBase from './DataSheetBase.js';

export default class DataSheet_QNav extends DataSheetBase {

  constructor(id, updateCb) {
    super(id, updateCb);
    this.requestedKeyPath = "";  // this value can be specified in the React Studio data sheet UI
  }

  makeDefaultItems() {
    // eslint-disable-next-line no-unused-vars
    let key = 1;
    // eslint-disable-next-line no-unused-vars
    let item;
    
    item = {};
    this.items.push(item);
    item['QuestionGroup'] = "0";
    item['QGText'] = "Questions 1-5";
    item.key = key++;
    
    item = {};
    this.items.push(item);
    item['QuestionGroup'] = "1";
    item['QGText'] = "Questions 6-10";
    item.key = key++;
    
    item = {};
    this.items.push(item);
    item['QuestionGroup'] = "2";
    item['QGText'] = "Questions 11-15";
    item.key = key++;
    
    item = {};
    this.items.push(item);
    item['QuestionGroup'] = "3";
    item['QGText'] = "Questions 16-20";
    item.key = key++;
    
    item = {};
    this.items.push(item);
    item['QuestionGroup'] = "4";
    item['QGText'] = "Questions 21-25";
    item.key = key++;
    
    item = {};
    this.items.push(item);
    item['QuestionGroup'] = "5";
    item['QGText'] = "Questions 26-30";
    item.key = key++;
    
    item = {};
    this.items.push(item);
    item['QuestionGroup'] = "6";
    item['QGText'] = "Questions 31-35";
    item.key = key++;
  }

}
