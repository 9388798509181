import React, { Component } from 'react';
import './App.css';
import ScreenContext from './ScreenContext';
import Logo from './Logo';
import btn_icon_343547 from './images/btn_icon_343547.png';
import btn_icon_360777 from './images/btn_icon_360777.png';
import btn_icon_465608 from './images/btn_icon_465608.png';
import ContentArea from './ContentArea';

export default class HomeScreen extends Component {

  static contextType = ScreenContext;


  constructor(props) {
    super(props);
    
    this.state = {
      buttonlabelconnect: (<div>Connect</div>),
      buttonlabelconnect_plainText: "Connect",
      buttonlabelengage: (<div>Engage</div>),
      buttonlabelengage_plainText: "Engage",
      buttonlabelactivate: (<div>Activate</div>),
      buttonlabelactivate_plainText: "Activate",
    };
  }

  componentDidMount() {
  }

  componentWillUnmount() {
  }

  componentDidUpdate(prevProps, prevState) {
  }

  onClick_elButtonconnect = async () => {
    // Go to screen 'Connect'
    this.context.appActions.goToScreen('connect', this.context.baseScreenId, { transitionId: 'fadeIn' });
  
  }
  
  
  onClick_elButtonLabelconnect = async () => {
    // Go to screen 'Connect'
    this.context.appActions.goToScreen('connect', this.context.baseScreenId, { transitionId: 'fadeIn' });
  
  }
  
  
  onClick_elButtonengage = async () => {
    // Go to screen 'Engage'
    this.context.appActions.goToScreen('engage', this.context.baseScreenId, { transitionId: 'fadeIn' });
  
  }
  
  
  onClick_elButtonLabelengage = async () => {
    // Go to screen 'Engage'
    this.context.appActions.goToScreen('engage', this.context.baseScreenId, { transitionId: 'fadeIn' });
  
  }
  
  
  onClick_elButtonactivate = async () => {
    // Go to screen 'Activate'
    this.context.appActions.goToScreen('activate', this.context.baseScreenId, { transitionId: 'fadeIn' });
  
  }
  
  
  render() {
    let layoutFlowStyle = {};
    let baseStyle = {};
    if (this.context.transitionId && this.context.transitionId.length > 0 && this.context.atTopOfScreenStack && this.context.transitionForward) {
      baseStyle.animation = '0.25s ease-in-out '+this.context.transitionId;
    }
    if ( !this.context.atTopOfScreenStack) {
      layoutFlowStyle.height = '100vh';
      layoutFlowStyle.overflow = 'hidden';
    }
    
    const style_elBackground = {
      width: '100%',
      height: '100%',
     };
    const style_elBackground_outer = {
      boxSizing: 'border-box',
      backgroundColor: '#fdfdfd',
     };
    
    const style_elButtonconnect = {
      display: 'block',
      textTransform: 'uppercase',
      backgroundImage: 'url('+btn_icon_343547+')',
      backgroundRepeat: 'no-repeat',
      backgroundSize: '75.784%',
      backgroundPosition: '50% 0%',
      color: '(null)',
      textAlign: 'center',
      backgroundColor: 'transparent',
      cursor: 'pointer',
      pointerEvents: 'auto',
     };
    const style_elButtonLabelconnect = {
      color: '#1f1f1f',
      textAlign: 'center',
      cursor: 'pointer',
      pointerEvents: 'auto',
     };
    
    const style_elButtonengage = {
      display: 'block',
      textTransform: 'uppercase',
      backgroundImage: 'url('+btn_icon_360777+')',
      backgroundRepeat: 'no-repeat',
      backgroundSize: '75.784%',
      backgroundPosition: '50% 0%',
      color: '(null)',
      textAlign: 'center',
      backgroundColor: 'transparent',
      cursor: 'pointer',
      pointerEvents: 'auto',
     };
    const style_elButtonLabelengage = {
      color: '#1f1f1f',
      textAlign: 'center',
      cursor: 'pointer',
      pointerEvents: 'auto',
     };
    
    const style_elButtonactivate = {
      display: 'block',
      textTransform: 'uppercase',
      backgroundImage: 'url('+btn_icon_465608+')',
      backgroundRepeat: 'no-repeat',
      backgroundSize: '75.784%',
      backgroundPosition: '50% 0%',
      color: '(null)',
      textAlign: 'center',
      backgroundColor: 'transparent',
      cursor: 'pointer',
      pointerEvents: 'auto',
     };
    const style_elButtonLabelactivate = {
      color: '#1f1f1f',
      textAlign: 'center',
     };
    
    return (
      <div className="AppScreen HomeScreen" style={baseStyle}>
        <div className="background">
          <div className="containerMinHeight elBackground" style={style_elBackground_outer}>
            <div className="appBg" style={style_elBackground} />
          </div>
        </div>
        
        <div className="layoutFlow" style={layoutFlowStyle}>
          <div className="hasNestedComps elLogo">
            <div>
              <Logo ref={(el)=> this._elLogo = el} />
            </div>
          </div>
          
          <div className="elButtonconnect">
            <button className="actionFont" style={style_elButtonconnect} onClick={this.onClick_elButtonconnect}  />
          </div>
          
          <div className="elButtonLabelconnect">
            <div className="baseFont" style={style_elButtonLabelconnect} onClick={this.onClick_elButtonLabelconnect} >
              <div>{this.state.buttonlabelconnect}</div>
            </div>
          </div>
          
          <div className="elButtonengage">
            <button className="actionFont" style={style_elButtonengage} onClick={this.onClick_elButtonengage}  />
          </div>
          
          <div className="elButtonLabelengage">
            <div className="baseFont" style={style_elButtonLabelengage} onClick={this.onClick_elButtonLabelengage} >
              <div>{this.state.buttonlabelengage}</div>
            </div>
          </div>
          
          <div className="elButtonactivate">
            <button className="actionFont" style={style_elButtonactivate} onClick={this.onClick_elButtonactivate}  />
          </div>
          
          <div className="elButtonLabelactivate">
            <div className="baseFont" style={style_elButtonLabelactivate}>
              <div>{this.state.buttonlabelactivate}</div>
            </div>
          </div>
          
          <div className="hasNestedComps elCompcontentArea">
            <div>
              <ContentArea visualStateIndex={(this.context.appActions.dataSlots ? this.context.appActions.dataSlots['ds_attendence_area'] : '')} ref={(el)=> this._elCompcontentArea = el} />
            </div>
          </div>
          
          <div className="elSpacer">
            <div />
          </div>
          
          <div className="elSpacer2">
            <div />
          </div>
        </div>
        
      </div>
    )
  }
  
}
