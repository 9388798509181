// eslint-disable-next-line
import React from 'react';
import DataSheetBase from './DataSheetBase.js';

export default class DataSheet_q26to30 extends DataSheetBase {

  constructor(id, updateCb) {
    super(id, updateCb);
    this.requestedKeyPath = "";  // this value can be specified in the React Studio data sheet UI
  }

  makeDefaultItems() {
    // eslint-disable-next-line no-unused-vars
    let key = 1;
    // eslint-disable-next-line no-unused-vars
    let item;
    
    item = {};
    this.items.push(item);
    item['support'] = "69";
    item['question'] = "Our community college is important to our area. But in terms of receiving more financial support, there are other things that are just more important.";
    item.key = key++;
    
    item = {};
    this.items.push(item);
    item['support'] = "77.6";
    item['question'] = "Following more than a year of COVID-19, it is more important than ever to support the career education and training programs at our community college.";
    item.key = key++;
    
    item = {};
    this.items.push(item);
    item['support'] = "76.4";
    item['question'] = "Unless we keep the college's programs and facilities up-to-date, many area workers will not be able to get the training they need to prepare for the good paying jobs of today and tomorrow, and that will hurt the local economy.";
    item.key = key++;
    
    item = {};
    this.items.push(item);
    item['support'] = "76.5";
    item['question'] = "The community college's career education programs help create an educated workforce. That leads to an improved economy, and in the long term, that lowers local taxes. Supporting the community just makes financial sense.";
    item.key = key++;
    
    item = {};
    this.items.push(item);
    item['support'] = "56.5";
    item['question'] = "I just don't trust local leadership with additional public dollars.";
    item.key = key++;
  }

}
