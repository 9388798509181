// eslint-disable-next-line
import React from 'react';
import DataSheetBase from './DataSheetBase.js';

export default class DataSheet_ms extends DataSheetBase {

  constructor(id, updateCb) {
    super(id, updateCb);
    this.requestedKeyPath = "";  // this value can be specified in the React Studio data sheet UI
  }

  makeDefaultItems() {
    // eslint-disable-next-line no-unused-vars
    let key = 1;
    // eslint-disable-next-line no-unused-vars
    let item;
    
    item = {};
    this.items.push(item);
    item['name'] = "SOUTH MIDDLE";
    item['type'] = "ms";
    item['district'] = "Parkway C-2 School District";
    item.key = key++;
    
    item = {};
    this.items.push(item);
    item['name'] = "SOUTHWEST MIDDLE";
    item['type'] = "ms";
    item['district'] = "Parkway C-2 School District";
    item.key = key++;
    
    item = {};
    this.items.push(item);
    item['name'] = "CENTRAL MIDDLE";
    item['type'] = "ms";
    item['district'] = "Parkway C-2 School District";
    item.key = key++;
    
    item = {};
    this.items.push(item);
    item['name'] = "WEST MIDDLE";
    item['type'] = "ms";
    item['district'] = "Parkway C-2 School District";
    item.key = key++;
    
    item = {};
    this.items.push(item);
    item['name'] = "NORTHEAST MIDDLE";
    item['type'] = "ms";
    item['district'] = "Parkway C-2 School District";
    item.key = key++;
  }

}
