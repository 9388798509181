import React, { Component } from 'react';
import './App.css';
import ScreenContext from './ScreenContext';

export default class Logo extends Component {

  static contextType = ScreenContext;

  // This component doesn't use any properties

  constructor(props) {
    super(props);
    
    this.state = {
      logotext: (<div>DC</div>),
      logotext_plainText: "DC",
    };
  }

  componentDidMount() {
  }

  componentWillUnmount() {
  }

  componentDidUpdate(prevProps, prevState) {
  }

  render() {
    
    const style_elLogocircle = {
      background: 'rgba(0, 0, 0, 1.000)',
      borderRadius: '50%',
     };
    const style_elLogotext = {
      fontSize: 20.0,
      color: '#feffff',
      textAlign: 'center',
     };
    
    return (
      <div className="Logo">
        <div className="foreground">
          <div className="elLogocircle" style={style_elLogocircle} />
          <div className="font-robotoLight  elLogotext" style={style_elLogotext}>
            <div>{this.state.logotext}</div>
          </div>
        </div>
      </div>
    )
  }
  
}
