import React, { Component } from 'react';
import './App.css';
import ScreenContext from './ScreenContext';
import { ResponsiveContainer, BarChart, Bar, XAxis, YAxis, Text, Tooltip} from 'recharts';

export default class ResultsByQuestion extends Component {

  static contextType = ScreenContext;

  // Properties used by this component:
  // visualStateIndex

  constructor(props) {
    super(props);
    
    this.state = {
    };
  }

  componentDidMount() {
  }

  componentWillUnmount() {
  }

  componentDidUpdate(prevProps, prevState) {
  }

  // --- Functions for component state index 0 (1 of 8) --- 
  
  renderState0() {
    
    return (
      <div className="ResultsByQuestion appBg">
      </div>
    )
  }
  
  // --- Functions for component state index 1 (2 of 8) --- 
  
  renderState1() {
    
    
    const sheet_state1_elBarchartresultsGroup1 = this.context.appActions.getDataSheet('allQuestions').items; 
    const slot_state1_elBarchartresultsGroup1 = (this.context.appActions.dataSlots ? this.context.appActions.dataSlots['ds_district'] : ''); 
    const slot_state1_elBarchartresultsGroup12 = (this.context.appActions.dataSlots ? this.context.appActions.dataSlots['ds_filter'] : ''); 
    
    sheet_state1_elBarchartresultsGroup1.forEach(function(list){
      list.support = Number(list.support);
    });
    
    let filteredChart = [];
    let filterStr = slot_state1_elBarchartresultsGroup1; 
    let filterStr2 = slot_state1_elBarchartresultsGroup12; 
    
    console.log(sheet_state1_elBarchartresultsGroup1);
    sheet_state1_elBarchartresultsGroup1.forEach(item => {
      if (item.district == filterStr && item.questionGroup == filterStr2) {
        filteredChart.push(item);
      }
    });
    
    const style_state1_elBarchartresultsGroup1 = {
      pointerEvents: 'auto',
     };
    
    return (
      <div className="ResultsByQuestion appBg">
        <div className="foreground">
          <div className="containerMinHeight state1_elBarchartresultsGroup1" style={style_state1_elBarchartresultsGroup1}>
            
            <ResponsiveContainer width="100%" height="100%"> 
                <BarChart data={filteredChart}>
                    <Tooltip labelFormatter={() => 'Percent:'} formatter={(myValue) => [new Intl.NumberFormat('en', {style: 'percent'}).format(myValue/100), ]} />
                    <XAxis dataKey="question" axisLine={true} tickLine={true} interval={0} height={75} tick={ { width: 200, fontSize: 10 }} />
                    <YAxis axisLine = {true} tickLine={true} tickFormatter={(tickItem) => {return String(tickItem).concat("%");}} />
                    <Bar dataKey="support" fill="rgba(146.40787661075592, 146.41141802072525, 146.40951812267303, 1)" 
            
            />
                </BarChart>
            </ResponsiveContainer>
          </div>
        </div>
      </div>
    )
  }
  
  // --- Functions for component state index 2 (3 of 8) --- 
  
  renderState2() {
    
    
    const sheet_state2_elBarchartresultsGroup2 = this.context.appActions.getDataSheet('allQuestions').items; 
    const slot_state2_elBarchartresultsGroup2 = (this.context.appActions.dataSlots ? this.context.appActions.dataSlots['ds_district'] : ''); 
    const slot_state2_elBarchartresultsGroup22 = (this.context.appActions.dataSlots ? this.context.appActions.dataSlots['ds_filter'] : ''); 
    
    sheet_state2_elBarchartresultsGroup2.forEach(function(list){
      list.support = Number(list.support);
    });
    
    let filteredChart = [];
    let filterStr = slot_state2_elBarchartresultsGroup2; 
    let filterStr2 = slot_state2_elBarchartresultsGroup22; 
    
    console.log(sheet_state2_elBarchartresultsGroup2);
    sheet_state2_elBarchartresultsGroup2.forEach(item => {
      if (item.district == filterStr && item.questionGroup == filterStr2) {
        filteredChart.push(item);
      }
    });
    
    const style_state2_elBarchartresultsGroup2 = {
      pointerEvents: 'auto',
     };
    
    return (
      <div className="ResultsByQuestion appBg">
        <div className="foreground">
          <div className="containerMinHeight state2_elBarchartresultsGroup2" style={style_state2_elBarchartresultsGroup2}>
            
            <ResponsiveContainer width="100%" height="100%"> 
                <BarChart data={filteredChart}>
                    <Tooltip labelFormatter={() => 'Percent:'} formatter={(myValue) => [new Intl.NumberFormat('en', {style: 'percent'}).format(myValue/100), ]} />
                    <XAxis dataKey="question" axisLine={true} tickLine={true} interval={0} height={75} tick={ { width: 200, fontSize: 10 }} />
                    <YAxis axisLine = {true} tickLine={true} tickFormatter={(tickItem) => {return String(tickItem).concat("%");}} />
                    <Bar dataKey="support" fill="rgba(146.40787661075592, 146.41141802072525, 146.40951812267303, 1)" 
            
            />
                </BarChart>
            </ResponsiveContainer>
          </div>
        </div>
      </div>
    )
  }
  
  // --- Functions for component state index 3 (4 of 8) --- 
  
  renderState3() {
    
    
    const sheet_state3_elBarchartresultsGroup3 = this.context.appActions.getDataSheet('allQuestions').items; 
    const slot_state3_elBarchartresultsGroup3 = (this.context.appActions.dataSlots ? this.context.appActions.dataSlots['ds_district'] : ''); 
    const slot_state3_elBarchartresultsGroup32 = (this.context.appActions.dataSlots ? this.context.appActions.dataSlots['ds_filter'] : ''); 
    
    sheet_state3_elBarchartresultsGroup3.forEach(function(list){
      list.support = Number(list.support);
    });
    
    let filteredChart = [];
    let filterStr = slot_state3_elBarchartresultsGroup3; 
    let filterStr2 = slot_state3_elBarchartresultsGroup32; 
    
    console.log(sheet_state3_elBarchartresultsGroup3);
    sheet_state3_elBarchartresultsGroup3.forEach(item => {
      if (item.district == filterStr && item.questionGroup == filterStr2) {
        filteredChart.push(item);
      }
    });
    
    const style_state3_elBarchartresultsGroup3 = {
      pointerEvents: 'auto',
     };
    
    return (
      <div className="ResultsByQuestion appBg">
        <div className="foreground">
          <div className="containerMinHeight state3_elBarchartresultsGroup3" style={style_state3_elBarchartresultsGroup3}>
            
            <ResponsiveContainer width="100%" height="100%"> 
                <BarChart data={filteredChart}>
                    <Tooltip labelFormatter={() => 'Percent:'} formatter={(myValue) => [new Intl.NumberFormat('en', {style: 'percent'}).format(myValue/100), ]} />
                    <XAxis dataKey="question" axisLine={true} tickLine={true} interval={0} height={75} tick={ { width: 200, fontSize: 10 }} />
                    <YAxis axisLine = {true} tickLine={true} tickFormatter={(tickItem) => {return String(tickItem).concat("%");}} />
                    <Bar dataKey="support" fill="rgba(146.40787661075592, 146.41141802072525, 146.40951812267303, 1)" 
            
            />
                </BarChart>
            </ResponsiveContainer>
          </div>
        </div>
      </div>
    )
  }
  
  // --- Functions for component state index 4 (5 of 8) --- 
  
  renderState4() {
    
    
    const sheet_state4_elBarchartresultsGroup4 = this.context.appActions.getDataSheet('allQuestions').items; 
    const slot_state4_elBarchartresultsGroup4 = (this.context.appActions.dataSlots ? this.context.appActions.dataSlots['ds_district'] : ''); 
    const slot_state4_elBarchartresultsGroup42 = (this.context.appActions.dataSlots ? this.context.appActions.dataSlots['ds_filter'] : ''); 
    
    sheet_state4_elBarchartresultsGroup4.forEach(function(list){
      list.support = Number(list.support);
    });
    
    let filteredChart = [];
    let filterStr = slot_state4_elBarchartresultsGroup4; 
    let filterStr2 = slot_state4_elBarchartresultsGroup42; 
    
    console.log(sheet_state4_elBarchartresultsGroup4);
    sheet_state4_elBarchartresultsGroup4.forEach(item => {
      if (item.district == filterStr && item.questionGroup == filterStr2) {
        filteredChart.push(item);
      }
    });
    
    const style_state4_elBarchartresultsGroup4 = {
      pointerEvents: 'auto',
     };
    
    return (
      <div className="ResultsByQuestion appBg">
        <div className="foreground">
          <div className="containerMinHeight state4_elBarchartresultsGroup4" style={style_state4_elBarchartresultsGroup4}>
            
            <ResponsiveContainer width="100%" height="100%"> 
                <BarChart data={filteredChart}>
                    <Tooltip labelFormatter={() => 'Percent:'} formatter={(myValue) => [new Intl.NumberFormat('en', {style: 'percent'}).format(myValue/100), ]} />
                    <XAxis dataKey="question" axisLine={true} tickLine={true} interval={0} height={75} tick={ { width: 200, fontSize: 10 }} />
                    <YAxis axisLine = {true} tickLine={true} tickFormatter={(tickItem) => {return String(tickItem).concat("%");}} />
                    <Bar dataKey="support" fill="rgba(146.40787661075592, 146.41141802072525, 146.40951812267303, 1)" 
            
            />
                </BarChart>
            </ResponsiveContainer>
          </div>
        </div>
      </div>
    )
  }
  
  // --- Functions for component state index 5 (6 of 8) --- 
  
  renderState5() {
    
    
    const sheet_state5_elBarchartresultsGroup5 = this.context.appActions.getDataSheet('allQuestions').items; 
    const slot_state5_elBarchartresultsGroup5 = (this.context.appActions.dataSlots ? this.context.appActions.dataSlots['ds_district'] : ''); 
    const slot_state5_elBarchartresultsGroup52 = (this.context.appActions.dataSlots ? this.context.appActions.dataSlots['ds_filter'] : ''); 
    
    sheet_state5_elBarchartresultsGroup5.forEach(function(list){
      list.support = Number(list.support);
    });
    
    let filteredChart = [];
    let filterStr = slot_state5_elBarchartresultsGroup5; 
    let filterStr2 = slot_state5_elBarchartresultsGroup52; 
    
    console.log(sheet_state5_elBarchartresultsGroup5);
    sheet_state5_elBarchartresultsGroup5.forEach(item => {
      if (item.district == filterStr && item.questionGroup == filterStr2) {
        filteredChart.push(item);
      }
    });
    
    const style_state5_elBarchartresultsGroup5 = {
      pointerEvents: 'auto',
     };
    
    return (
      <div className="ResultsByQuestion appBg">
        <div className="foreground">
          <div className="containerMinHeight state5_elBarchartresultsGroup5" style={style_state5_elBarchartresultsGroup5}>
            
            <ResponsiveContainer width="100%" height="100%"> 
                <BarChart data={filteredChart}>
                    <Tooltip labelFormatter={() => 'Percent:'} formatter={(myValue) => [new Intl.NumberFormat('en', {style: 'percent'}).format(myValue/100), ]} />
                    <XAxis dataKey="question" axisLine={true} tickLine={true} interval={0} height={75} tick={ { width: 200, fontSize: 10 }} />
                    <YAxis axisLine = {true} tickLine={true} tickFormatter={(tickItem) => {return String(tickItem).concat("%");}} />
                    <Bar dataKey="support" fill="rgba(146.40787661075592, 146.41141802072525, 146.40951812267303, 1)" 
            
            />
                </BarChart>
            </ResponsiveContainer>
          </div>
        </div>
      </div>
    )
  }
  
  // --- Functions for component state index 6 (7 of 8) --- 
  
  renderState6() {
    
    
    const sheet_state6_elBarchartresultsGroup6 = this.context.appActions.getDataSheet('allQuestions').items; 
    const slot_state6_elBarchartresultsGroup6 = (this.context.appActions.dataSlots ? this.context.appActions.dataSlots['ds_district'] : ''); 
    const slot_state6_elBarchartresultsGroup62 = (this.context.appActions.dataSlots ? this.context.appActions.dataSlots['ds_filter'] : ''); 
    
    sheet_state6_elBarchartresultsGroup6.forEach(function(list){
      list.support = Number(list.support);
    });
    
    let filteredChart = [];
    let filterStr = slot_state6_elBarchartresultsGroup6; 
    let filterStr2 = slot_state6_elBarchartresultsGroup62; 
    
    console.log(sheet_state6_elBarchartresultsGroup6);
    sheet_state6_elBarchartresultsGroup6.forEach(item => {
      if (item.district == filterStr && item.questionGroup == filterStr2) {
        filteredChart.push(item);
      }
    });
    
    const style_state6_elBarchartresultsGroup6 = {
      pointerEvents: 'auto',
     };
    
    return (
      <div className="ResultsByQuestion appBg">
        <div className="foreground">
          <div className="containerMinHeight state6_elBarchartresultsGroup6" style={style_state6_elBarchartresultsGroup6}>
            
            <ResponsiveContainer width="100%" height="100%"> 
                <BarChart data={filteredChart}>
                    <Tooltip labelFormatter={() => 'Percent:'} formatter={(myValue) => [new Intl.NumberFormat('en', {style: 'percent'}).format(myValue/100), ]} />
                    <XAxis dataKey="question" axisLine={true} tickLine={true} interval={0} height={75} tick={ { width: 200, fontSize: 10 }} />
                    <YAxis axisLine = {true} tickLine={true} tickFormatter={(tickItem) => {return String(tickItem).concat("%");}} />
                    <Bar dataKey="support" fill="rgba(146.40787661075592, 146.41141802072525, 146.40951812267303, 1)" 
            
            />
                </BarChart>
            </ResponsiveContainer>
          </div>
        </div>
      </div>
    )
  }
  
  // --- Functions for component state index 7 (8 of 8) --- 
  
  renderState7() {
    
    
    const sheet_state7_elBarchartresultsGroup7 = this.context.appActions.getDataSheet('allQuestions').items; 
    const slot_state7_elBarchartresultsGroup7 = (this.context.appActions.dataSlots ? this.context.appActions.dataSlots['ds_district'] : ''); 
    const slot_state7_elBarchartresultsGroup72 = (this.context.appActions.dataSlots ? this.context.appActions.dataSlots['ds_filter'] : ''); 
    
    sheet_state7_elBarchartresultsGroup7.forEach(function(list){
      list.support = Number(list.support);
    });
    
    let filteredChart = [];
    let filterStr = slot_state7_elBarchartresultsGroup7; 
    let filterStr2 = slot_state7_elBarchartresultsGroup72; 
    
    console.log(sheet_state7_elBarchartresultsGroup7);
    sheet_state7_elBarchartresultsGroup7.forEach(item => {
      if (item.district == filterStr && item.questionGroup == filterStr2) {
        filteredChart.push(item);
      }
    });
    
    const style_state7_elBarchartresultsGroup7 = {
      pointerEvents: 'auto',
     };
    
    return (
      <div className="ResultsByQuestion appBg">
        <div className="foreground">
          <div className="containerMinHeight state7_elBarchartresultsGroup7" style={style_state7_elBarchartresultsGroup7}>
            
            <ResponsiveContainer width="100%" height="100%"> 
                <BarChart data={filteredChart}>
                    <Tooltip labelFormatter={() => 'Percent:'} formatter={(myValue) => [new Intl.NumberFormat('en', {style: 'percent'}).format(myValue/100), ]} />
                    <XAxis dataKey="question" axisLine={true} tickLine={true} interval={0} height={75} tick={ { width: 200, fontSize: 10 }} />
                    <YAxis axisLine = {true} tickLine={true} tickFormatter={(tickItem) => {return String(tickItem).concat("%");}} />
                    <Bar dataKey="support" fill="rgba(146.40787661075592, 146.41141802072525, 146.40951812267303, 1)" 
            
            />
                </BarChart>
            </ResponsiveContainer>
          </div>
        </div>
      </div>
    )
  }
  
  
  render() {
    switch (parseInt((this.state && this.state.visualStateIndexOverride !== undefined) ? this.state.visualStateIndexOverride : this.props.visualStateIndex, 10)) {
      default:
      case 0:
        return this.renderState0();
      case 1:
        return this.renderState1();
      case 2:
        return this.renderState2();
      case 3:
        return this.renderState3();
      case 4:
        return this.renderState4();
      case 5:
        return this.renderState5();
      case 6:
        return this.renderState6();
      case 7:
        return this.renderState7();
    }
  }
  
}
