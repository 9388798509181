// eslint-disable-next-line
import React from 'react';
import DataSheetBase from './DataSheetBase.js';

export default class DataSheet_districts extends DataSheetBase {

  constructor(id, updateCb) {
    super(id, updateCb);
    this.requestedKeyPath = "";  // this value can be specified in the React Studio data sheet UI
  }

  makeDefaultItems() {
    // eslint-disable-next-line no-unused-vars
    let key = 1;
    // eslint-disable-next-line no-unused-vars
    let item;
    
    item = {};
    this.items.push(item);
    item['district'] = "all";
    item.key = key++;
    
    item = {};
    this.items.push(item);
    item['district'] = "Afton 101";
    item.key = key++;
    
    item = {};
    this.items.push(item);
    item['district'] = "Bayless";
    item.key = key++;
    
    item = {};
    this.items.push(item);
    item['district'] = "Brentwood";
    item.key = key++;
    
    item = {};
    this.items.push(item);
    item['district'] = "Clayton";
    item.key = key++;
    
    item = {};
    this.items.push(item);
    item['district'] = "Dallas County R-I";
    item.key = key++;
    
    item = {};
    this.items.push(item);
    item['district'] = "Ferguson-Florissant R-II";
    item.key = key++;
    
    item = {};
    this.items.push(item);
    item['district'] = "Hancock Place";
    item.key = key++;
    
    item = {};
    this.items.push(item);
    item['district'] = "Hazelwood";
    item.key = key++;
    
    item = {};
    this.items.push(item);
    item['district'] = "Jennings";
    item.key = key++;
    
    item = {};
    this.items.push(item);
    item['district'] = "Kirkwood R-VII";
    item.key = key++;
    
    item = {};
    this.items.push(item);
    item['district'] = "Ladue";
    item.key = key++;
    
    item = {};
    this.items.push(item);
    item['district'] = "Lindbergh";
    item.key = key++;
    
    item = {};
    this.items.push(item);
    item['district'] = "Maplewood-Richmond Heights";
    item.key = key++;
    
    item = {};
    this.items.push(item);
    item['district'] = "Mehlville R-IX";
    item.key = key++;
    
    item = {};
    this.items.push(item);
    item['district'] = "Parkway C-2";
    item.key = key++;
    
    item = {};
    this.items.push(item);
    item['district'] = "Pattonville R-III";
    item.key = key++;
    
    item = {};
    this.items.push(item);
    item['district'] = "Ritenour";
    item.key = key++;
    
    item = {};
    this.items.push(item);
    item['district'] = "Rockwood R-VI";
    item.key = key++;
    
    item = {};
    this.items.push(item);
    item['district'] = "St. Louis City";
    item.key = key++;
  }

}
