// eslint-disable-next-line
import React from 'react';
import DataSheetBase from './DataSheetBase.js';

export default class DataSheet_localizationSheet extends DataSheetBase {

  constructor(id, updateCb) {
    super(id, updateCb);
    this.requestedKeyPath = "";  // this value can be specified in the React Studio data sheet UI
  }

  makeDefaultItems() {
    // eslint-disable-next-line no-unused-vars
    let key = 1;
    // eslint-disable-next-line no-unused-vars
    let item;
    
    item = {};
    this.items.push(item);
    item['key'] = "start_button_858958";
    item['en'] = "New button";
    
    item = {};
    this.items.push(item);
    item['key'] = "compheader_iconbutton_438921";
    item['en'] = "Icon button";
    
    item = {};
    this.items.push(item);
    item['key'] = "compheader_text_482344";
    item['en'] = "MCM";
    
    item = {};
    this.items.push(item);
    item['key'] = "menumain_iconbutton_693874";
    item['en'] = "Home";
    
    item = {};
    this.items.push(item);
    item['key'] = "compsurvey_text_668590";
    item['en'] = "Survey";
    
    item = {};
    this.items.push(item);
    item['key'] = "compsurvey_text_65865";
    item['en'] = "Survey";
    
    item = {};
    this.items.push(item);
    item['key'] = "logo_text_784141";
    item['en'] = "MCM";
    
    item = {};
    this.items.push(item);
    item['key'] = "start_iconbutton_769804";
    item['en'] = "Home";
    
    item = {};
    this.items.push(item);
    item['key'] = "start_text_113467";
    item['en'] = "HOME";
    
    item = {};
    this.items.push(item);
    item['key'] = "home_text_684529";
    item['en'] = "Attendance Area";
    
    item = {};
    this.items.push(item);
    item['key'] = "home_textcopy_1034277";
    item['en'] = "School Name";
    
    item = {};
    this.items.push(item);
    item['key'] = "home_iconbutton_838022";
    item['en'] = "Icon button";
    
    item = {};
    this.items.push(item);
    item['key'] = "home_iconbuttoncopy_800197";
    item['en'] = "Icon button";
    
    item = {};
    this.items.push(item);
    item['key'] = "home_iconbuttoncopy2_922570";
    item['en'] = "Icon button";
    
    item = {};
    this.items.push(item);
    item['key'] = "start_iconbuttoncopy_343547";
    item['en'] = "Survey";
    
    item = {};
    this.items.push(item);
    item['key'] = "start_iconbuttoncopy2_360777";
    item['en'] = "Profile";
    
    item = {};
    this.items.push(item);
    item['key'] = "start_iconbuttoncopy3_465608";
    item['en'] = "Custom";
    
    item = {};
    this.items.push(item);
    item['key'] = "home_checkbox_684436";
    item['en'] = " ";
    
    item = {};
    this.items.push(item);
    item['key'] = "comp1_text_593564";
    item['en'] = "HOME";
    
    item = {};
    this.items.push(item);
    item['key'] = "contentarea_iconbuttoncopy2_676555";
    item['en'] = "Icon button";
    
    item = {};
    this.items.push(item);
    item['key'] = "contentarea_iconbuttoncopy_399429";
    item['en'] = "Icon button";
    
    item = {};
    this.items.push(item);
    item['key'] = "contentarea_iconbutton_851318";
    item['en'] = "Icon button";
    
    item = {};
    this.items.push(item);
    item['key'] = "contentarea_textcopy_592955";
    item['en'] = "School Name";
    
    item = {};
    this.items.push(item);
    item['key'] = "contentarea_textcopy2_202258";
    item['en'] = "Attendance Area";
    
    item = {};
    this.items.push(item);
    item['key'] = "start_button_652547";
    item['en'] = "New button";
    
    item = {};
    this.items.push(item);
    item['key'] = "start_text_875523";
    item['en'] = "Survey";
    
    item = {};
    this.items.push(item);
    item['key'] = "start_textcopy_698486";
    item['en'] = "Survey";
    
    item = {};
    this.items.push(item);
    item['key'] = "start_textcopy2_497589";
    item['en'] = "Survey";
    
    item = {};
    this.items.push(item);
    item['key'] = "start_text_472709";
    item['en'] = "New text. Double-click to edit";
    
    item = {};
    this.items.push(item);
    item['key'] = "contentarea_text_40980";
    item['en'] = "SURVEY ANALYTICS";
    
    item = {};
    this.items.push(item);
    item['key'] = "contentarea_textcopy_883410";
    item['en'] = "PROFILE ANALYTICS";
    
    item = {};
    this.items.push(item);
    item['key'] = "contentarea_textcopy2_309443";
    item['en'] = "CUSTOM ANALYTICS";
    
    item = {};
    this.items.push(item);
    item['key'] = "personicxdistribution_text_875925";
    item['en'] = "Distribution - Cluster group";
    
    item = {};
    this.items.push(item);
    item['key'] = "screen6_text_850248";
    item['en'] = "New text. Double-click to edit";
    
    item = {};
    this.items.push(item);
    item['key'] = "screen6_text_729536";
    item['en'] = "New text. Double-click to edit";
    
    item = {};
    this.items.push(item);
    item['key'] = "screen6_text2_296040";
    item['en'] = "New text. Double-click to edit";
    
    item = {};
    this.items.push(item);
    item['key'] = "listitem1_text2_479876";
    item['en'] = "New text. Double-click to edit";
    
    item = {};
    this.items.push(item);
    item['key'] = "listitem1_text_449589";
    item['en'] = "New text. Double-click to edit";
    
    item = {};
    this.items.push(item);
    item['key'] = "pickquestion_text_254032";
    item['en'] = "New text. Double-click to edit";
    
    item = {};
    this.items.push(item);
    item['key'] = "pickquestion_button_714046";
    item['en'] = "New button";
    
    item = {};
    this.items.push(item);
    item['key'] = "pickquestion_iconbutton_432962";
    item['en'] = "Icon button";
    
    item = {};
    this.items.push(item);
    item['key'] = "pickquestion_button_153620";
    item['en'] = "New button";
    
    item = {};
    this.items.push(item);
    item['key'] = "questionsglobal_button_850162";
    item['en'] = "Questions 1-5";
    
    item = {};
    this.items.push(item);
    item['key'] = "questionsglobal_buttoncopy_557311";
    item['en'] = "Questions 6-10";
    
    item = {};
    this.items.push(item);
    item['key'] = "questionsglobal_buttoncopy2_392508";
    item['en'] = "Questions 11-15";
    
    item = {};
    this.items.push(item);
    item['key'] = "questionsglobal_buttoncopy3_802981";
    item['en'] = "Questions 16-20";
    
    item = {};
    this.items.push(item);
    item['key'] = "questionsglobal_buttoncopy4_823821";
    item['en'] = "Questions 21-25";
    
    item = {};
    this.items.push(item);
    item['key'] = "questionsglobal_buttoncopy5_202266";
    item['en'] = "Questions 26-30";
    
    item = {};
    this.items.push(item);
    item['key'] = "questionsglobal_buttoncopy6_323220";
    item['en'] = "Questions 31-35";
    
    item = {};
    this.items.push(item);
    item['key'] = "questionsglobal_buttoncopy7_245630";
    item['en'] = "New button";
    
    item = {};
    this.items.push(item);
    item['key'] = "component1_text_537907";
    item['en'] = "New text. Double-click to edit";
    
    item = {};
    this.items.push(item);
    item['key'] = "component1_button_340325";
    item['en'] = "Questions 1-5";
    
    item = {};
    this.items.push(item);
    item['key'] = "component1_buttoncopy_965842";
    item['en'] = "Questions 6-10";
    
    item = {};
    this.items.push(item);
    item['key'] = "component1_buttoncopy2_843996";
    item['en'] = "Questions 11-15";
    
    item = {};
    this.items.push(item);
    item['key'] = "component1_buttoncopy3_573201";
    item['en'] = "Questions 16-20";
    
    item = {};
    this.items.push(item);
    item['key'] = "component1_buttoncopy4_1046163";
    item['en'] = "Questions 21-25";
    
    item = {};
    this.items.push(item);
    item['key'] = "component1_buttoncopy5_610654";
    item['en'] = "Questions 26-30";
    
    item = {};
    this.items.push(item);
    item['key'] = "component1_buttoncopy6_862921";
    item['en'] = "Questions 31-35";
    
    item = {};
    this.items.push(item);
    item['key'] = "screen8_buttonsurvey_658073";
    item['en'] = "Survey";
    
    item = {};
    this.items.push(item);
    item['key'] = "screen8_buttonlabelsurvey_315273";
    item['en'] = "Connect";
    
    item = {};
    this.items.push(item);
    item['key'] = "screen8_buttonprofile_364076";
    item['en'] = "Profile";
    
    item = {};
    this.items.push(item);
    item['key'] = "screen8_buttonlabelprofile_660865";
    item['en'] = "Engage";
    
    item = {};
    this.items.push(item);
    item['key'] = "screen8_buttoncustom_946414";
    item['en'] = "Custom";
    
    item = {};
    this.items.push(item);
    item['key'] = "screen8_buttonlabelcustom_72806";
    item['en'] = "Activate";
    
    item = {};
    this.items.push(item);
    item['key'] = "questions_text_814326";
    item['en'] = "Hover mouse over column to see exact % of support\nClick on Bar to see details of questions";
    
    item = {};
    this.items.push(item);
    item['key'] = "questions_text_578013";
    item['en'] = "New text. Double-click to edit";
    
    item = {};
    this.items.push(item);
    item['key'] = "listitem1_text_759719";
    item['en'] = "New text. Double-click to edit";
    
    item = {};
    this.items.push(item);
    item['key'] = "questionscomponent_text2_867140";
    item['en'] = "New text. Double-click to edit";
    
    item = {};
    this.items.push(item);
    item['key'] = "listitem1_text2_969493";
    item['en'] = "New text. Double-click to edit";
    
    item = {};
    this.items.push(item);
    item['key'] = "questions_text_502097";
    item['en'] = "New text. Double-click to edit";
    
    item = {};
    this.items.push(item);
    item['key'] = "questions_text_695201";
    item['en'] = "Click on Question to see breakdown of support";
    
    item = {};
    this.items.push(item);
    item['key'] = "engage_buttonsurvey_786769";
    item['en'] = "Survey";
    
    item = {};
    this.items.push(item);
    item['key'] = "engage_buttonlabelsurvey_170620";
    item['en'] = "Connect";
    
    item = {};
    this.items.push(item);
    item['key'] = "engage_buttonprofile_638429";
    item['en'] = "Profile";
    
    item = {};
    this.items.push(item);
    item['key'] = "engage_buttonlabelprofile_594735";
    item['en'] = "Engage";
    
    item = {};
    this.items.push(item);
    item['key'] = "engage_buttoncustom_962741";
    item['en'] = "Custom";
    
    item = {};
    this.items.push(item);
    item['key'] = "engage_buttonlabelcustom_64382";
    item['en'] = "Activate";
    
    item = {};
    this.items.push(item);
    item['key'] = "engage_buttonsurvey_910808";
    item['en'] = "Survey";
    
    item = {};
    this.items.push(item);
    item['key'] = "engage_buttonlabelsurvey_339944";
    item['en'] = "Connect";
    
    item = {};
    this.items.push(item);
    item['key'] = "engage_buttonprofile_267812";
    item['en'] = "Profile";
    
    item = {};
    this.items.push(item);
    item['key'] = "engage_buttonlabelprofile_872962";
    item['en'] = "Emgage";
    
    item = {};
    this.items.push(item);
    item['key'] = "engage_buttoncustom_340446";
    item['en'] = "Custom";
    
    item = {};
    this.items.push(item);
    item['key'] = "engage_buttonlabelcustom_811848";
    item['en'] = "Activate";
    
    item = {};
    this.items.push(item);
    item['key'] = "questions_buttonsurvey_997433";
    item['en'] = "Survey";
    
    item = {};
    this.items.push(item);
    item['key'] = "questions_buttonlabelsurvey_112221";
    item['en'] = "Connect";
    
    item = {};
    this.items.push(item);
    item['key'] = "questions_buttonprofile_922064";
    item['en'] = "Profile";
    
    item = {};
    this.items.push(item);
    item['key'] = "questions_buttonlabelprofile_433046";
    item['en'] = "Emgage";
    
    item = {};
    this.items.push(item);
    item['key'] = "questions_buttoncustom_1019496";
    item['en'] = "Custom";
    
    item = {};
    this.items.push(item);
    item['key'] = "questions_buttonlabelcustom_888426";
    item['en'] = "Activate";
    
    item = {};
    this.items.push(item);
    item['key'] = "questions_text_737673";
    item['en'] = "Click on Question to see breakdown of support";
    
    item = {};
    this.items.push(item);
    item['key'] = "questions_text2_273699";
    item['en'] = "Select a school district";
    
    item = {};
    this.items.push(item);
    item['key'] = "questions_button_450138";
    item['en'] = "Refresh Charts";
    
    item = {};
    this.items.push(item);
    item['key'] = "questions_button_444324";
    item['en'] = "Refresh Charts";
    
    item = {};
    this.items.push(item);
    item['key'] = "connect_text_60959";
    item['en'] = "New text. Double-click to edit";
    
    item = {};
    this.items.push(item);
    item['key'] = "connect_text_273046";
    item['en'] = "Pick a School  District from the pull-down menu to filter the results";
    
    item = {};
    this.items.push(item);
    item['key'] = "connect2_text_435343";
    item['en'] = "Pick a School  District from the pull-down menu to filter the results";
    
    item = {};
    this.items.push(item);
    item['key'] = "connect2_buttonconnect_914563";
    item['en'] = "Survey";
    
    item = {};
    this.items.push(item);
    item['key'] = "connect2_buttonlabelconnect_744725";
    item['en'] = "Connect";
    
    item = {};
    this.items.push(item);
    item['key'] = "connect2_buttonengage_223693";
    item['en'] = "Profile";
    
    item = {};
    this.items.push(item);
    item['key'] = "connect2_buttonlabelengage_818067";
    item['en'] = "Engage";
    
    item = {};
    this.items.push(item);
    item['key'] = "connect2_buttonactivate_915655";
    item['en'] = "Custom";
    
    item = {};
    this.items.push(item);
    item['key'] = "connect2_buttonlabelactivate_318750";
    item['en'] = "Activate";
    
    item = {};
    this.items.push(item);
    item['key'] = "connect2_labelresultsbyquestion_272124";
    item['en'] = "Click on Question to see breakdown of support";
    
    item = {};
    this.items.push(item);
    item['key'] = "activate_text2_178286";
    item['en'] = "New text. Double-click to edit";
    
    item = {};
    this.items.push(item);
    item['key'] = "activate_text3_889378";
    item['en'] = "Voter Support";
    
    let storedItems = localStorage.getItem(this.id);
    if (storedItems != null) {
      this.items = JSON.parse(storedItems);
    }
  }

  addItem(item, options) {
    super.addItem(item, options);
    
    localStorage.setItem(this.id, JSON.stringify(this.items));
  }

  removeItem(item, options) {
    super.removeItem(item, options);
    
    localStorage.setItem(this.id, JSON.stringify(this.items));
  }

  replaceItemByRowIndex(idx, newItem, options) {
    super.replaceItemByRowIndex(idx, newItem, options);
    
    localStorage.setItem(this.id, JSON.stringify(this.items));
  }

  getStringsByLanguage = () => {
    let stringsByLang = {};
    for (let row of this.items) {
      const locKey = row.key;
      for (let key in row) {
        if (key === 'key')
          continue;
        let langObj = stringsByLang[key] || {};
        langObj[locKey] = row[key];
        stringsByLang[key] = langObj;
      }
    }
    return stringsByLang;
  }

}
