import React, { Component } from 'react';
import './App.css';
import ScreenContext from './ScreenContext';
import Logo from './Logo';
import btn_icon_786769 from './images/btn_icon_786769.png';
import btn_icon_638429 from './images/btn_icon_638429.png';
import btn_icon_962741 from './images/btn_icon_962741.png';
import { ResponsiveContainer, BarChart, Bar, XAxis, YAxis, Text, Tooltip} from 'recharts';

// UI framework component imports
import Select from 'muicss/lib/react/select';
import Option from 'muicss/lib/react/option';

export default class EngageScreen extends Component {

  static contextType = ScreenContext;


  constructor(props) {
    super(props);
    
    this.state = {
    };
  }

  componentDidMount() {
  }

  componentWillUnmount() {
  }

  componentDidUpdate(prevProps, prevState) {
  }

  onClick_elLogo = async () => {
    // Go to screen 'Home'
    this.context.appActions.goToScreen('home', this.context.baseScreenId, { transitionId: 'fadeIn' });
  
  }
  
  
  
  getValue_elPicker = () => {
    return this.state.picker !== undefined ? this.state.picker : ((this.context.appActions.dataSlots ? this.context.appActions.dataSlots['ds_district'] : ''));
  }
  
  pickerValueChanged_elPicker = (event) => {
    this.setState({picker: event.target.value});
    
    this.context.appActions.updateDataSlot("ds_district", event.target.value);
  }
  
  onClick_elButtonconnect = async () => {
    // Go to screen 'Connect'
    this.context.appActions.goToScreen('connect', this.context.baseScreenId, { transitionId: 'fadeIn' });
  
  }
  
  
  onClick_elButtonLabelconnect = async () => {
    // Go to screen 'Connect'
    this.context.appActions.goToScreen('connect', this.context.baseScreenId, { transitionId: 'fadeIn' });
  
  }
  
  
  onClick_elButtonengage = async () => {
  
  }
  
  
  onClick_elButtonactivate = async () => {
  
  }
  
  
  render() {
    let layoutFlowStyle = {};
    let baseStyle = {};
    if (this.context.transitionId && this.context.transitionId.length > 0 && this.context.atTopOfScreenStack && this.context.transitionForward) {
      baseStyle.animation = '0.25s ease-in-out '+this.context.transitionId;
    }
    if ( !this.context.atTopOfScreenStack) {
      layoutFlowStyle.height = '100vh';
      layoutFlowStyle.overflow = 'hidden';
    }
    
    const style_elLogo = {
      cursor: 'pointer',
      pointerEvents: 'auto',
     };
    
    let selection_picker = this.getValue_elPicker();
    // Source datasheet and selected data column for picker element 'picker'
    const dataSource_picker = this.context.appActions.getDataSheet('districts');
    const valueColumnName_picker = 'district';
    const labelColumnName_picker = 'district';
    
    const style_elPicker = {
      pointerEvents: 'auto',
     };
    
    const style_elButtonconnect = {
      display: 'block',
      textTransform: 'uppercase',
      backgroundImage: 'url('+btn_icon_786769+')',
      backgroundRepeat: 'no-repeat',
      backgroundSize: '75.784%',
      backgroundPosition: '50% 0%',
      color: '(null)',
      textAlign: 'center',
      backgroundColor: 'transparent',
      cursor: 'pointer',
      pointerEvents: 'auto',
     };
    const style_elButtonLabelconnect = {
      color: '#1f1f1f',
      textAlign: 'center',
      cursor: 'pointer',
      pointerEvents: 'auto',
     };
    
    const style_elButtonengage = {
      display: 'block',
      textTransform: 'uppercase',
      backgroundImage: 'url('+btn_icon_638429+')',
      backgroundRepeat: 'no-repeat',
      backgroundSize: '75.784%',
      backgroundPosition: '50% 0%',
      color: '(null)',
      textAlign: 'center',
      backgroundColor: 'transparent',
      cursor: 'pointer',
      pointerEvents: 'auto',
     };
    const style_elButtonLabelengage = {
      color: '#1f1f1f',
      textAlign: 'center',
     };
    
    const style_elButtonactivate = {
      display: 'block',
      textTransform: 'uppercase',
      backgroundImage: 'url('+btn_icon_962741+')',
      backgroundRepeat: 'no-repeat',
      backgroundSize: '75.784%',
      backgroundPosition: '50% 0%',
      color: '(null)',
      textAlign: 'center',
      backgroundColor: 'transparent',
      cursor: 'pointer',
      pointerEvents: 'auto',
     };
    const style_elButtonLabelactivate = {
      color: '#1f1f1f',
      textAlign: 'center',
     };
    
    
    const sheet_elAngledBarchartpopulationByPersonix = this.context.appActions.getDataSheet('personicxDist').items; 
    sheet_elAngledBarchartpopulationByPersonix.forEach(function(list){
      list.support = Number(list.support);
    });
    
    const CustomizedAxisTick: FunctionComponent<any> = (props: any) => {
      const { x, y, payload } = props;
    
      return (
        <g transform={ `translate(${x},${y})` }>
          <text
            x={0}
            y={0}
            dy={16}
            textAnchor="end"
            fill="#666"
            transform="rotate(-45)"
          >
            {payload.value}
          </text>
        </g>
      );
    };
    
    const style_elAngledBarchartpopulationByPersonix = {
      pointerEvents: 'auto',
     };
    
    return (
      <div className="AppScreen EngageScreen" style={baseStyle}>
        <div className="layoutFlow" style={layoutFlowStyle}>
          <div className="hasNestedComps elLogo">
            <div style={style_elLogo} onClick={this.onClick_elLogo} >
              <Logo ref={(el)=> this._elLogo = el} />
            </div>
          </div>
          
          <div className="elPicker">
            <Select className="baseFont" style={style_elPicker}  onChange={this.pickerValueChanged_elPicker} value={selection_picker} >
              {dataSource_picker.items.every(item => {
                return item[valueColumnName_picker] !== selection_picker;
              }) ? <Option value=''/> : null}
              {dataSource_picker.items.map(item => {
                const colValue = item[valueColumnName_picker];
                const labelColValue = item[labelColumnName_picker];
                return <Option key={item.key} value={colValue} label={labelColValue} />
              })}
            </Select>
          </div>
          
          <div className="elButtonconnect">
            <button className="actionFont" style={style_elButtonconnect} onClick={this.onClick_elButtonconnect}  />
          </div>
          
          <div className="elButtonLabelconnect">
            <div className="baseFont" style={style_elButtonLabelconnect} onClick={this.onClick_elButtonLabelconnect} >
              <div>{this.context.locStrings.engage_buttonlabelsurvey_170620}</div>
            </div>
          </div>
          
          <div className="elButtonengage">
            <button className="actionFont" style={style_elButtonengage} onClick={this.onClick_elButtonengage}  />
          </div>
          
          <div className="elButtonLabelengage">
            <div className="baseFont" style={style_elButtonLabelengage}>
              <div>{this.context.locStrings.engage_buttonlabelprofile_594735}</div>
            </div>
          </div>
          
          <div className="elButtonactivate">
            <button className="actionFont" style={style_elButtonactivate} onClick={this.onClick_elButtonactivate}  />
          </div>
          
          <div className="elButtonLabelactivate">
            <div className="baseFont" style={style_elButtonLabelactivate}>
              <div>{this.context.locStrings.engage_buttonlabelcustom_64382}</div>
            </div>
          </div>
          
          <div className="elAngledBarchartpopulationByPersonix">
            <div style={style_elAngledBarchartpopulationByPersonix}>
              
              <ResponsiveContainer width="100%" height="100%"> 
                  <BarChart data={sheet_elAngledBarchartpopulationByPersonix}>
                      <Tooltip labelFormatter={() => 'Percent:'} formatter={(myValue) => [new Intl.NumberFormat('en', {style: 'percent'}).format(myValue/100), ]} />
                      <XAxis dataKey="category" axisLine={true} tickLine={true} interval={0} height={175} tick={<CustomizedAxisTick />} />
                      <YAxis axisLine = {true} tickLine={true} tickFormatter={(tickItem) => {return String(tickItem).concat("%");}} />
                      <Bar dataKey="support" fill="rgba(146.40787661075592, 146.41141802072525, 146.40951812267303, 1)" 
              
              />
                  </BarChart>
              </ResponsiveContainer>
            </div>
          </div>
        </div>
        
      </div>
    )
  }
  
}
