// eslint-disable-next-line
import React from 'react';
import DataSheetBase from './DataSheetBase.js';

export default class DataSheet_hs extends DataSheetBase {

  constructor(id, updateCb) {
    super(id, updateCb);
    this.requestedKeyPath = "";  // this value can be specified in the React Studio data sheet UI
  }

  makeDefaultItems() {
    // eslint-disable-next-line no-unused-vars
    let key = 1;
    // eslint-disable-next-line no-unused-vars
    let item;
    
    item = {};
    this.items.push(item);
    item['name'] = "SOUTH HIGH";
    item['type'] = "hs";
    item['district'] = "Parkway C-2 School District";
    item.key = key++;
    
    item = {};
    this.items.push(item);
    item['name'] = "CENTRAL HIGH";
    item['type'] = "hs";
    item['district'] = "Parkway C-2 School District";
    item.key = key++;
    
    item = {};
    this.items.push(item);
    item['name'] = "WEST HIGH";
    item['type'] = "hs";
    item['district'] = "Parkway C-2 School District";
    item.key = key++;
    
    item = {};
    this.items.push(item);
    item['name'] = "NORTH HIGH";
    item['type'] = "hs";
    item['district'] = "Parkway C-2 School District";
    item.key = key++;
    
    item = {};
    this.items.push(item);
    item['name'] = "KIRKWOOD SR. HIGH";
    item['type'] = "hs";
    item['district'] = "Parkway C-2 School District";
    item.key = key++;
  }

}
