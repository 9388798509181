// eslint-disable-next-line
import React from 'react';
import DataSheetBase from './DataSheetBase.js';

export default class DataSheet_attendence extends DataSheetBase {

  constructor(id, updateCb) {
    super(id, updateCb);
    this.requestedKeyPath = "";  // this value can be specified in the React Studio data sheet UI
  }

  makeDefaultItems() {
    // eslint-disable-next-line no-unused-vars
    let key = 1;
    // eslint-disable-next-line no-unused-vars
    let item;
    
    item = {};
    this.items.push(item);
    item['area'] = "District";
    item['area_type'] = "";
    item['state_switch'] = "0";
    item.key = key++;
    
    item = {};
    this.items.push(item);
    item['state_switch'] = "1";
    item['area_type'] = "hs";
    item['area'] = "High School";
    item.key = key++;
    
    item = {};
    this.items.push(item);
    item['state_switch'] = "2";
    item['area_type'] = "ms";
    item['area'] = "Middle School";
    item.key = key++;
    
    item = {};
    this.items.push(item);
    item['state_switch'] = "3";
    item['area_type'] = "es";
    item['area'] = "Elementary School";
    item.key = key++;
  }

}
