// eslint-disable-next-line
import React from 'react';
import DataSheetBase from './DataSheetBase.js';

export default class DataSheet_q31to35 extends DataSheetBase {

  constructor(id, updateCb) {
    super(id, updateCb);
    this.requestedKeyPath = "";  // this value can be specified in the React Studio data sheet UI
  }

  makeDefaultItems() {
    // eslint-disable-next-line no-unused-vars
    let key = 1;
    // eslint-disable-next-line no-unused-vars
    let item;
    
    item = {};
    this.items.push(item);
    item['support'] = "77.2";
    item['question'] = "Right now there are thousands of good-paying jobs in our area that remain unfilled because businesses can't find employees with the necessary skills. This proposal helps train local residents for these jobs and keep them in our area.";
    item.key = key++;
    
    item = {};
    this.items.push(item);
    item['support'] = "73.6";
    item['question'] = "Many businesses can't grow, or move to our area, because of the shortage of a well-trained workforce. Our economic future depends on supporting job training and retraining programs at our community college.";
    item.key = key++;
    
    item = {};
    this.items.push(item);
    item['support'] = "73.9";
    item['question'] = "This proposal will enable the college to repurpose out-of-date and underutilized spaces to meet the needs of today's students and for future generations.";
    item.key = key++;
    
    item = {};
    this.items.push(item);
    item['support'] = "63.1";
    item['question'] = "As asked earlier, assume that your local community college is seeking an 8-cent increase in property taxes to support improved facilities and programs. If you were voting today, is that something you would . . .";
    item.key = key++;
  }

}
