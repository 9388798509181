// eslint-disable-next-line
import React from 'react';
import DataSheetBase from './DataSheetBase.js';

export default class DataSheet_categoryGraph extends DataSheetBase {

  constructor(id, updateCb) {
    super(id, updateCb);
    this.requestedKeyPath = "";  // this value can be specified in the React Studio data sheet UI
  }

  makeDefaultItems() {
    // eslint-disable-next-line no-unused-vars
    let key = 1;
    // eslint-disable-next-line no-unused-vars
    let item;
    
    item = {};
    this.items.push(item);
    item['support'] = "59.7";
    item['district'] = "Afton 101";
    item['questionGroup'] = "1";
    item['category'] = "Questions 1-5";
    item['index'] = "1";
    item.key = key++;
    
    item = {};
    this.items.push(item);
    item['support'] = "59.3";
    item['district'] = "Bayless";
    item['questionGroup'] = "1";
    item['category'] = "Questions 1-5";
    item['index'] = "1";
    item.key = key++;
    
    item = {};
    this.items.push(item);
    item['support'] = "59.8";
    item['district'] = "Brentwood";
    item['questionGroup'] = "1";
    item['category'] = "Questions 1-5";
    item['index'] = "1";
    item.key = key++;
    
    item = {};
    this.items.push(item);
    item['support'] = "58.1";
    item['district'] = "Clayton";
    item['questionGroup'] = "1";
    item['category'] = "Questions 1-5";
    item['index'] = "1";
    item.key = key++;
    
    item = {};
    this.items.push(item);
    item['support'] = "60.0";
    item['district'] = "Dallas County R-I";
    item['questionGroup'] = "1";
    item['category'] = "Questions 1-5";
    item['index'] = "1";
    item.key = key++;
    
    item = {};
    this.items.push(item);
    item['support'] = "59.2";
    item['district'] = "Ferguson-Florissant R-II";
    item['questionGroup'] = "1";
    item['category'] = "Questions 1-5";
    item['index'] = "1";
    item.key = key++;
    
    item = {};
    this.items.push(item);
    item['support'] = "58.7";
    item['district'] = "Hancock Place";
    item['questionGroup'] = "1";
    item['category'] = "Questions 1-5";
    item['index'] = "1";
    item.key = key++;
    
    item = {};
    this.items.push(item);
    item['support'] = "59.4";
    item['district'] = "Hazelwood";
    item['questionGroup'] = "1";
    item['category'] = "Questions 1-5";
    item['index'] = "1";
    item.key = key++;
    
    item = {};
    this.items.push(item);
    item['support'] = "58.5";
    item['district'] = "Jennings";
    item['questionGroup'] = "1";
    item['category'] = "Questions 1-5";
    item['index'] = "1";
    item.key = key++;
    
    item = {};
    this.items.push(item);
    item['support'] = "58.3";
    item['district'] = "Kirkwood R-VII";
    item['questionGroup'] = "1";
    item['category'] = "Questions 1-5";
    item['index'] = "1";
    item.key = key++;
    
    item = {};
    this.items.push(item);
    item['support'] = "57.4";
    item['district'] = "Ladue";
    item['questionGroup'] = "1";
    item['category'] = "Questions 1-5";
    item['index'] = "1";
    item.key = key++;
    
    item = {};
    this.items.push(item);
    item['support'] = "58.9";
    item['district'] = "Lindbergh";
    item['questionGroup'] = "1";
    item['category'] = "Questions 1-5";
    item['index'] = "1";
    item.key = key++;
    
    item = {};
    this.items.push(item);
    item['support'] = "60.1";
    item['district'] = "Maplewood-Richmond Heights";
    item['questionGroup'] = "1";
    item['category'] = "Questions 1-5";
    item['index'] = "1";
    item.key = key++;
    
    item = {};
    this.items.push(item);
    item['support'] = "58.9";
    item['district'] = "Mehlville R-IX";
    item['questionGroup'] = "1";
    item['category'] = "Questions 1-5";
    item['index'] = "1";
    item.key = key++;
    
    item = {};
    this.items.push(item);
    item['support'] = "58.4";
    item['district'] = "Parkway C-2";
    item['questionGroup'] = "1";
    item['category'] = "Questions 1-5";
    item['index'] = "1";
    item.key = key++;
    
    item = {};
    this.items.push(item);
    item['support'] = "62.0";
    item['district'] = "Pattonville R-III";
    item['questionGroup'] = "1";
    item['category'] = "Questions 1-5";
    item['index'] = "1";
    item.key = key++;
    
    item = {};
    this.items.push(item);
    item['support'] = "59.5";
    item['district'] = "Ritenour";
    item['questionGroup'] = "1";
    item['category'] = "Questions 1-5";
    item['index'] = "1";
    item.key = key++;
    
    item = {};
    this.items.push(item);
    item['support'] = "57.8";
    item['district'] = "Rockwood R-VI";
    item['questionGroup'] = "1";
    item['category'] = "Questions 1-5";
    item['index'] = "1";
    item.key = key++;
    
    item = {};
    this.items.push(item);
    item['support'] = "59.8";
    item['district'] = "St. Louis City";
    item['questionGroup'] = "1";
    item['category'] = "Questions 1-5";
    item['index'] = "1";
    item.key = key++;
    
    item = {};
    this.items.push(item);
    item['support'] = "58.2";
    item['district'] = "Afton 101";
    item['questionGroup'] = "1";
    item['category'] = "Questions 6-10";
    item['index'] = "2";
    item.key = key++;
    
    item = {};
    this.items.push(item);
    item['support'] = "59.1";
    item['district'] = "Bayless";
    item['questionGroup'] = "1";
    item['category'] = "Questions 6-10";
    item['index'] = "2";
    item.key = key++;
    
    item = {};
    this.items.push(item);
    item['support'] = "57.6";
    item['district'] = "Brentwood";
    item['questionGroup'] = "1";
    item['category'] = "Questions 6-10";
    item['index'] = "2";
    item.key = key++;
    
    item = {};
    this.items.push(item);
    item['support'] = "55.6";
    item['district'] = "Clayton";
    item['questionGroup'] = "1";
    item['category'] = "Questions 6-10";
    item['index'] = "2";
    item.key = key++;
    
    item = {};
    this.items.push(item);
    item['support'] = "56.0";
    item['district'] = "Dallas County R-I";
    item['questionGroup'] = "1";
    item['category'] = "Questions 6-10";
    item['index'] = "2";
    item.key = key++;
    
    item = {};
    this.items.push(item);
    item['support'] = "59.0";
    item['district'] = "Ferguson-Florissant R-II";
    item['questionGroup'] = "1";
    item['category'] = "Questions 6-10";
    item['index'] = "2";
    item.key = key++;
    
    item = {};
    this.items.push(item);
    item['support'] = "59.1";
    item['district'] = "Hancock Place";
    item['questionGroup'] = "1";
    item['category'] = "Questions 6-10";
    item['index'] = "2";
    item.key = key++;
    
    item = {};
    this.items.push(item);
    item['support'] = "58.2";
    item['district'] = "Hazelwood";
    item['questionGroup'] = "1";
    item['category'] = "Questions 6-10";
    item['index'] = "2";
    item.key = key++;
    
    item = {};
    this.items.push(item);
    item['support'] = "59.2";
    item['district'] = "Jennings";
    item['questionGroup'] = "1";
    item['category'] = "Questions 6-10";
    item['index'] = "2";
    item.key = key++;
    
    item = {};
    this.items.push(item);
    item['support'] = "55.2";
    item['district'] = "Kirkwood R-VII";
    item['questionGroup'] = "1";
    item['category'] = "Questions 6-10";
    item['index'] = "2";
    item.key = key++;
    
    item = {};
    this.items.push(item);
    item['support'] = "54.8";
    item['district'] = "Ladue";
    item['questionGroup'] = "1";
    item['category'] = "Questions 6-10";
    item['index'] = "2";
    item.key = key++;
    
    item = {};
    this.items.push(item);
    item['support'] = "56.1";
    item['district'] = "Lindbergh";
    item['questionGroup'] = "1";
    item['category'] = "Questions 6-10";
    item['index'] = "2";
    item.key = key++;
    
    item = {};
    this.items.push(item);
    item['support'] = "59.5";
    item['district'] = "Maplewood-Richmond Heights";
    item['questionGroup'] = "1";
    item['category'] = "Questions 6-10";
    item['index'] = "2";
    item.key = key++;
    
    item = {};
    this.items.push(item);
    item['support'] = "56.8";
    item['district'] = "Mehlville R-IX";
    item['questionGroup'] = "1";
    item['category'] = "Questions 6-10";
    item['index'] = "2";
    item.key = key++;
    
    item = {};
    this.items.push(item);
    item['support'] = "55.5";
    item['district'] = "Parkway C-2";
    item['questionGroup'] = "1";
    item['category'] = "Questions 6-10";
    item['index'] = "2";
    item.key = key++;
    
    item = {};
    this.items.push(item);
    item['support'] = "60.0";
    item['district'] = "Pattonville R-III";
    item['questionGroup'] = "1";
    item['category'] = "Questions 6-10";
    item['index'] = "2";
    item.key = key++;
    
    item = {};
    this.items.push(item);
    item['support'] = "60.1";
    item['district'] = "Ritenour";
    item['questionGroup'] = "1";
    item['category'] = "Questions 6-10";
    item['index'] = "2";
    item.key = key++;
    
    item = {};
    this.items.push(item);
    item['support'] = "54.7";
    item['district'] = "Rockwood R-VI";
    item['questionGroup'] = "1";
    item['category'] = "Questions 6-10";
    item['index'] = "2";
    item.key = key++;
    
    item = {};
    this.items.push(item);
    item['support'] = "59.5";
    item['district'] = "St. Louis City";
    item['questionGroup'] = "1";
    item['category'] = "Questions 6-10";
    item['index'] = "2";
    item.key = key++;
    
    item = {};
    this.items.push(item);
    item['support'] = "74.7";
    item['district'] = "Afton 101";
    item['questionGroup'] = "1";
    item['category'] = "Questions 11-15";
    item['index'] = "3";
    item.key = key++;
    
    item = {};
    this.items.push(item);
    item['support'] = "58.1";
    item['district'] = "Bayless";
    item['questionGroup'] = "1";
    item['category'] = "Questions 11-15";
    item['index'] = "3";
    item.key = key++;
    
    item = {};
    this.items.push(item);
    item['support'] = "56.9";
    item['district'] = "Brentwood";
    item['questionGroup'] = "1";
    item['category'] = "Questions 11-15";
    item['index'] = "3";
    item.key = key++;
    
    item = {};
    this.items.push(item);
    item['support'] = "54.6";
    item['district'] = "Clayton";
    item['questionGroup'] = "1";
    item['category'] = "Questions 11-15";
    item['index'] = "3";
    item.key = key++;
    
    item = {};
    this.items.push(item);
    item['support'] = "56.0";
    item['district'] = "Dallas County R-I";
    item['questionGroup'] = "1";
    item['category'] = "Questions 11-15";
    item['index'] = "3";
    item.key = key++;
    
    item = {};
    this.items.push(item);
    item['support'] = "58.1";
    item['district'] = "Ferguson-Florissant R-II";
    item['questionGroup'] = "1";
    item['category'] = "Questions 11-15";
    item['index'] = "3";
    item.key = key++;
    
    item = {};
    this.items.push(item);
    item['support'] = "57.9";
    item['district'] = "Hancock Place";
    item['questionGroup'] = "1";
    item['category'] = "Questions 11-15";
    item['index'] = "3";
    item.key = key++;
    
    item = {};
    this.items.push(item);
    item['support'] = "57.4";
    item['district'] = "Hazelwood";
    item['questionGroup'] = "1";
    item['category'] = "Questions 11-15";
    item['index'] = "3";
    item.key = key++;
    
    item = {};
    this.items.push(item);
    item['support'] = "58.3";
    item['district'] = "Jennings";
    item['questionGroup'] = "1";
    item['category'] = "Questions 11-15";
    item['index'] = "3";
    item.key = key++;
    
    item = {};
    this.items.push(item);
    item['support'] = "54.4";
    item['district'] = "Kirkwood R-VII";
    item['questionGroup'] = "1";
    item['category'] = "Questions 11-15";
    item['index'] = "3";
    item.key = key++;
    
    item = {};
    this.items.push(item);
    item['support'] = "53.7";
    item['district'] = "Ladue";
    item['questionGroup'] = "1";
    item['category'] = "Questions 11-15";
    item['index'] = "3";
    item.key = key++;
    
    item = {};
    this.items.push(item);
    item['support'] = "55.3";
    item['district'] = "Lindbergh";
    item['questionGroup'] = "1";
    item['category'] = "Questions 11-15";
    item['index'] = "3";
    item.key = key++;
    
    item = {};
    this.items.push(item);
    item['support'] = "58.7";
    item['district'] = "Maplewood-Richmond Heights";
    item['questionGroup'] = "1";
    item['category'] = "Questions 11-15";
    item['index'] = "3";
    item.key = key++;
    
    item = {};
    this.items.push(item);
    item['support'] = "55.8";
    item['district'] = "Mehlville R-IX";
    item['questionGroup'] = "1";
    item['category'] = "Questions 11-15";
    item['index'] = "3";
    item.key = key++;
    
    item = {};
    this.items.push(item);
    item['support'] = "54.6";
    item['district'] = "Parkway C-2";
    item['questionGroup'] = "1";
    item['category'] = "Questions 11-15";
    item['index'] = "3";
    item.key = key++;
    
    item = {};
    this.items.push(item);
    item['support'] = "60.0";
    item['district'] = "Pattonville R-III";
    item['questionGroup'] = "1";
    item['category'] = "Questions 11-15";
    item['index'] = "3";
    item.key = key++;
    
    item = {};
    this.items.push(item);
    item['support'] = "59.1";
    item['district'] = "Ritenour";
    item['questionGroup'] = "1";
    item['category'] = "Questions 11-15";
    item['index'] = "3";
    item.key = key++;
    
    item = {};
    this.items.push(item);
    item['support'] = "53.9";
    item['district'] = "Rockwood R-VI";
    item['questionGroup'] = "1";
    item['category'] = "Questions 11-15";
    item['index'] = "3";
    item.key = key++;
    
    item = {};
    this.items.push(item);
    item['support'] = "58.7";
    item['district'] = "St. Louis City";
    item['questionGroup'] = "1";
    item['category'] = "Questions 11-15";
    item['index'] = "3";
    item.key = key++;
    
    item = {};
    this.items.push(item);
    item['support'] = "78.5";
    item['district'] = "Afton 101";
    item['questionGroup'] = "1";
    item['category'] = "Questions 16-20";
    item['index'] = "4";
    item.key = key++;
    
    item = {};
    this.items.push(item);
    item['support'] = "56.4";
    item['district'] = "Bayless";
    item['questionGroup'] = "1";
    item['category'] = "Questions 16-20";
    item['index'] = "4";
    item.key = key++;
    
    item = {};
    this.items.push(item);
    item['support'] = "55.4";
    item['district'] = "Brentwood";
    item['questionGroup'] = "1";
    item['category'] = "Questions 16-20";
    item['index'] = "4";
    item.key = key++;
    
    item = {};
    this.items.push(item);
    item['support'] = "53.9";
    item['district'] = "Clayton";
    item['questionGroup'] = "1";
    item['category'] = "Questions 16-20";
    item['index'] = "4";
    item.key = key++;
    
    item = {};
    this.items.push(item);
    item['support'] = "52.0";
    item['district'] = "Dallas County R-I";
    item['questionGroup'] = "1";
    item['category'] = "Questions 16-20";
    item['index'] = "4";
    item.key = key++;
    
    item = {};
    this.items.push(item);
    item['support'] = "56.6";
    item['district'] = "Ferguson-Florissant R-II";
    item['questionGroup'] = "1";
    item['category'] = "Questions 16-20";
    item['index'] = "4";
    item.key = key++;
    
    item = {};
    this.items.push(item);
    item['support'] = "56.4";
    item['district'] = "Hancock Place";
    item['questionGroup'] = "1";
    item['category'] = "Questions 16-20";
    item['index'] = "4";
    item.key = key++;
    
    item = {};
    this.items.push(item);
    item['support'] = "55.7";
    item['district'] = "Hazelwood";
    item['questionGroup'] = "1";
    item['category'] = "Questions 16-20";
    item['index'] = "4";
    item.key = key++;
    
    item = {};
    this.items.push(item);
    item['support'] = "56.8";
    item['district'] = "Jennings";
    item['questionGroup'] = "1";
    item['category'] = "Questions 16-20";
    item['index'] = "4";
    item.key = key++;
    
    item = {};
    this.items.push(item);
    item['support'] = "53.4";
    item['district'] = "Kirkwood R-VII";
    item['questionGroup'] = "1";
    item['category'] = "Questions 16-20";
    item['index'] = "4";
    item.key = key++;
    
    item = {};
    this.items.push(item);
    item['support'] = "53.1";
    item['district'] = "Ladue";
    item['questionGroup'] = "1";
    item['category'] = "Questions 16-20";
    item['index'] = "4";
    item.key = key++;
    
    item = {};
    this.items.push(item);
    item['support'] = "54.0";
    item['district'] = "Lindbergh";
    item['questionGroup'] = "1";
    item['category'] = "Questions 16-20";
    item['index'] = "4";
    item.key = key++;
    
    item = {};
    this.items.push(item);
    item['support'] = "57.0";
    item['district'] = "Maplewood-Richmond Heights";
    item['questionGroup'] = "1";
    item['category'] = "Questions 16-20";
    item['index'] = "4";
    item.key = key++;
    
    item = {};
    this.items.push(item);
    item['support'] = "54.3";
    item['district'] = "Mehlville R-IX";
    item['questionGroup'] = "1";
    item['category'] = "Questions 16-20";
    item['index'] = "4";
    item.key = key++;
    
    item = {};
    this.items.push(item);
    item['support'] = "53.5";
    item['district'] = "Parkway C-2";
    item['questionGroup'] = "1";
    item['category'] = "Questions 16-20";
    item['index'] = "4";
    item.key = key++;
    
    item = {};
    this.items.push(item);
    item['support'] = "56.0";
    item['district'] = "Pattonville R-III";
    item['questionGroup'] = "1";
    item['category'] = "Questions 16-20";
    item['index'] = "4";
    item.key = key++;
    
    item = {};
    this.items.push(item);
    item['support'] = "57.3";
    item['district'] = "Ritenour";
    item['questionGroup'] = "1";
    item['category'] = "Questions 16-20";
    item['index'] = "4";
    item.key = key++;
    
    item = {};
    this.items.push(item);
    item['support'] = "53.0";
    item['district'] = "Rockwood R-VI";
    item['questionGroup'] = "1";
    item['category'] = "Questions 16-20";
    item['index'] = "4";
    item.key = key++;
    
    item = {};
    this.items.push(item);
    item['support'] = "57.0";
    item['district'] = "St. Louis City";
    item['questionGroup'] = "1";
    item['category'] = "Questions 16-20";
    item['index'] = "4";
    item.key = key++;
    
    item = {};
    this.items.push(item);
    item['support'] = "75.7";
    item['district'] = "Afton 101";
    item['questionGroup'] = "1";
    item['category'] = "Questions 21-25";
    item['index'] = "5";
    item.key = key++;
    
    item = {};
    this.items.push(item);
    item['support'] = "60.1";
    item['district'] = "Bayless";
    item['questionGroup'] = "1";
    item['category'] = "Questions 21-25";
    item['index'] = "5";
    item.key = key++;
    
    item = {};
    this.items.push(item);
    item['support'] = "59.8";
    item['district'] = "Brentwood";
    item['questionGroup'] = "1";
    item['category'] = "Questions 21-25";
    item['index'] = "5";
    item.key = key++;
    
    item = {};
    this.items.push(item);
    item['support'] = "58.2";
    item['district'] = "Clayton";
    item['questionGroup'] = "1";
    item['category'] = "Questions 21-25";
    item['index'] = "5";
    item.key = key++;
    
    item = {};
    this.items.push(item);
    item['support'] = "60.0";
    item['district'] = "Dallas County R-I";
    item['questionGroup'] = "1";
    item['category'] = "Questions 21-25";
    item['index'] = "5";
    item.key = key++;
    
    item = {};
    this.items.push(item);
    item['support'] = "60.3";
    item['district'] = "Ferguson-Florissant R-II";
    item['questionGroup'] = "1";
    item['category'] = "Questions 21-25";
    item['index'] = "5";
    item.key = key++;
    
    item = {};
    this.items.push(item);
    item['support'] = "60.4";
    item['district'] = "Hancock Place";
    item['questionGroup'] = "1";
    item['category'] = "Questions 21-25";
    item['index'] = "5";
    item.key = key++;
    
    item = {};
    this.items.push(item);
    item['support'] = "59.8";
    item['district'] = "Hazelwood";
    item['questionGroup'] = "1";
    item['category'] = "Questions 21-25";
    item['index'] = "5";
    item.key = key++;
    
    item = {};
    this.items.push(item);
    item['support'] = "60.9";
    item['district'] = "Jennings";
    item['questionGroup'] = "1";
    item['category'] = "Questions 21-25";
    item['index'] = "5";
    item.key = key++;
    
    item = {};
    this.items.push(item);
    item['support'] = "57.9";
    item['district'] = "Kirkwood R-VII";
    item['questionGroup'] = "1";
    item['category'] = "Questions 21-25";
    item['index'] = "5";
    item.key = key++;
    
    item = {};
    this.items.push(item);
    item['support'] = "57.3";
    item['district'] = "Ladue";
    item['questionGroup'] = "1";
    item['category'] = "Questions 21-25";
    item['index'] = "5";
    item.key = key++;
    
    item = {};
    this.items.push(item);
    item['support'] = "58.5";
    item['district'] = "Lindbergh";
    item['questionGroup'] = "1";
    item['category'] = "Questions 21-25";
    item['index'] = "5";
    item.key = key++;
    
    item = {};
    this.items.push(item);
    item['support'] = "60.6";
    item['district'] = "Maplewood-Richmond Heights";
    item['questionGroup'] = "1";
    item['category'] = "Questions 21-25";
    item['index'] = "5";
    item.key = key++;
    
    item = {};
    this.items.push(item);
    item['support'] = "58.7";
    item['district'] = "Mehlville R-IX";
    item['questionGroup'] = "1";
    item['category'] = "Questions 21-25";
    item['index'] = "5";
    item.key = key++;
    
    item = {};
    this.items.push(item);
    item['support'] = "58.0";
    item['district'] = "Parkway C-2";
    item['questionGroup'] = "1";
    item['category'] = "Questions 21-25";
    item['index'] = "5";
    item.key = key++;
    
    item = {};
    this.items.push(item);
    item['support'] = "60.0";
    item['district'] = "Pattonville R-III";
    item['questionGroup'] = "1";
    item['category'] = "Questions 21-25";
    item['index'] = "5";
    item.key = key++;
    
    item = {};
    this.items.push(item);
    item['support'] = "61.2";
    item['district'] = "Ritenour";
    item['questionGroup'] = "1";
    item['category'] = "Questions 21-25";
    item['index'] = "5";
    item.key = key++;
    
    item = {};
    this.items.push(item);
    item['support'] = "57.4";
    item['district'] = "Rockwood R-VI";
    item['questionGroup'] = "1";
    item['category'] = "Questions 21-25";
    item['index'] = "5";
    item.key = key++;
    
    item = {};
    this.items.push(item);
    item['support'] = "61.0";
    item['district'] = "St. Louis City";
    item['questionGroup'] = "1";
    item['category'] = "Questions 21-25";
    item['index'] = "5";
    item.key = key++;
    
    item = {};
    this.items.push(item);
    item['support'] = "72.5";
    item['district'] = "Afton 101";
    item['questionGroup'] = "1";
    item['category'] = "Questions 26-30";
    item['index'] = "6";
    item.key = key++;
    
    item = {};
    this.items.push(item);
    item['support'] = "58.4";
    item['district'] = "Bayless";
    item['questionGroup'] = "1";
    item['category'] = "Questions 26-30";
    item['index'] = "6";
    item.key = key++;
    
    item = {};
    this.items.push(item);
    item['support'] = "58.1";
    item['district'] = "Brentwood";
    item['questionGroup'] = "1";
    item['category'] = "Questions 26-30";
    item['index'] = "6";
    item.key = key++;
    
    item = {};
    this.items.push(item);
    item['support'] = "56.2";
    item['district'] = "Clayton";
    item['questionGroup'] = "1";
    item['category'] = "Questions 26-30";
    item['index'] = "6";
    item.key = key++;
    
    item = {};
    this.items.push(item);
    item['support'] = "60.0";
    item['district'] = "Dallas County R-I";
    item['questionGroup'] = "1";
    item['category'] = "Questions 26-30";
    item['index'] = "6";
    item.key = key++;
    
    item = {};
    this.items.push(item);
    item['support'] = "58.6";
    item['district'] = "Ferguson-Florissant R-II";
    item['questionGroup'] = "1";
    item['category'] = "Questions 26-30";
    item['index'] = "6";
    item.key = key++;
    
    item = {};
    this.items.push(item);
    item['support'] = "58.6";
    item['district'] = "Hancock Place";
    item['questionGroup'] = "1";
    item['category'] = "Questions 26-30";
    item['index'] = "6";
    item.key = key++;
    
    item = {};
    this.items.push(item);
    item['support'] = "58.1";
    item['district'] = "Hazelwood";
    item['questionGroup'] = "1";
    item['category'] = "Questions 26-30";
    item['index'] = "6";
    item.key = key++;
    
    item = {};
    this.items.push(item);
    item['support'] = "59.5";
    item['district'] = "Jennings";
    item['questionGroup'] = "1";
    item['category'] = "Questions 26-30";
    item['index'] = "6";
    item.key = key++;
    
    item = {};
    this.items.push(item);
    item['support'] = "56.2";
    item['district'] = "Kirkwood R-VII";
    item['questionGroup'] = "1";
    item['category'] = "Questions 26-30";
    item['index'] = "6";
    item.key = key++;
    
    item = {};
    this.items.push(item);
    item['support'] = "55.3";
    item['district'] = "Ladue";
    item['questionGroup'] = "1";
    item['category'] = "Questions 26-30";
    item['index'] = "6";
    item.key = key++;
    
    item = {};
    this.items.push(item);
    item['support'] = "57.0";
    item['district'] = "Lindbergh";
    item['questionGroup'] = "1";
    item['category'] = "Questions 26-30";
    item['index'] = "6";
    item.key = key++;
    
    item = {};
    this.items.push(item);
    item['support'] = "59.0";
    item['district'] = "Maplewood-Richmond Heights";
    item['questionGroup'] = "1";
    item['category'] = "Questions 26-30";
    item['index'] = "6";
    item.key = key++;
    
    item = {};
    this.items.push(item);
    item['support'] = "57.0";
    item['district'] = "Mehlville R-IX";
    item['questionGroup'] = "1";
    item['category'] = "Questions 26-30";
    item['index'] = "6";
    item.key = key++;
    
    item = {};
    this.items.push(item);
    item['support'] = "56.2";
    item['district'] = "Parkway C-2";
    item['questionGroup'] = "1";
    item['category'] = "Questions 26-30";
    item['index'] = "6";
    item.key = key++;
    
    item = {};
    this.items.push(item);
    item['support'] = "58.0";
    item['district'] = "Pattonville R-III";
    item['questionGroup'] = "1";
    item['category'] = "Questions 26-30";
    item['index'] = "6";
    item.key = key++;
    
    item = {};
    this.items.push(item);
    item['support'] = "59.7";
    item['district'] = "Ritenour";
    item['questionGroup'] = "1";
    item['category'] = "Questions 26-30";
    item['index'] = "6";
    item.key = key++;
    
    item = {};
    this.items.push(item);
    item['support'] = "55.2";
    item['district'] = "Rockwood R-VI";
    item['questionGroup'] = "1";
    item['category'] = "Questions 26-30";
    item['index'] = "6";
    item.key = key++;
    
    item = {};
    this.items.push(item);
    item['support'] = "59.5";
    item['district'] = "St. Louis City";
    item['questionGroup'] = "1";
    item['category'] = "Questions 26-30";
    item['index'] = "6";
    item.key = key++;
    
    item = {};
    this.items.push(item);
    item['support'] = "52.7";
    item['district'] = "Afton 101";
    item['questionGroup'] = "1";
    item['category'] = "Questions 31-35";
    item['index'] = "7";
    item.key = key++;
    
    item = {};
    this.items.push(item);
    item['support'] = "61.1";
    item['district'] = "Bayless";
    item['questionGroup'] = "1";
    item['category'] = "Questions 31-35";
    item['index'] = "7";
    item.key = key++;
    
    item = {};
    this.items.push(item);
    item['support'] = "62.7";
    item['district'] = "Brentwood";
    item['questionGroup'] = "1";
    item['category'] = "Questions 31-35";
    item['index'] = "7";
    item.key = key++;
    
    item = {};
    this.items.push(item);
    item['support'] = "61.8";
    item['district'] = "Clayton";
    item['questionGroup'] = "1";
    item['category'] = "Questions 31-35";
    item['index'] = "7";
    item.key = key++;
    
    item = {};
    this.items.push(item);
    item['support'] = "64.0";
    item['district'] = "Dallas County R-I";
    item['questionGroup'] = "1";
    item['category'] = "Questions 31-35";
    item['index'] = "7";
    item.key = key++;
    
    item = {};
    this.items.push(item);
    item['support'] = "61.4";
    item['district'] = "Ferguson-Florissant R-II";
    item['questionGroup'] = "1";
    item['category'] = "Questions 31-35";
    item['index'] = "7";
    item.key = key++;
    
    item = {};
    this.items.push(item);
    item['support'] = "61.0";
    item['district'] = "Hancock Place";
    item['questionGroup'] = "1";
    item['category'] = "Questions 31-35";
    item['index'] = "7";
    item.key = key++;
    
    item = {};
    this.items.push(item);
    item['support'] = "61.7";
    item['district'] = "Hazelwood";
    item['questionGroup'] = "1";
    item['category'] = "Questions 31-35";
    item['index'] = "7";
    item.key = key++;
    
    item = {};
    this.items.push(item);
    item['support'] = "61.3";
    item['district'] = "Jennings";
    item['questionGroup'] = "1";
    item['category'] = "Questions 31-35";
    item['index'] = "7";
    item.key = key++;
    
    item = {};
    this.items.push(item);
    item['support'] = "61.9";
    item['district'] = "Kirkwood R-VII";
    item['questionGroup'] = "1";
    item['category'] = "Questions 31-35";
    item['index'] = "7";
    item.key = key++;
    
    item = {};
    this.items.push(item);
    item['support'] = "61.2";
    item['district'] = "Ladue";
    item['questionGroup'] = "1";
    item['category'] = "Questions 31-35";
    item['index'] = "7";
    item.key = key++;
    
    item = {};
    this.items.push(item);
    item['support'] = "62.2";
    item['district'] = "Lindbergh";
    item['questionGroup'] = "1";
    item['category'] = "Questions 31-35";
    item['index'] = "7";
    item.key = key++;
    
    item = {};
    this.items.push(item);
    item['support'] = "61.9";
    item['district'] = "Maplewood-Richmond Heights";
    item['questionGroup'] = "1";
    item['category'] = "Questions 31-35";
    item['index'] = "7";
    item.key = key++;
    
    item = {};
    this.items.push(item);
    item['support'] = "61.6";
    item['district'] = "Mehlville R-IX";
    item['questionGroup'] = "1";
    item['category'] = "Questions 31-35";
    item['index'] = "7";
    item.key = key++;
    
    item = {};
    this.items.push(item);
    item['support'] = "61.9";
    item['district'] = "Parkway C-2";
    item['questionGroup'] = "1";
    item['category'] = "Questions 31-35";
    item['index'] = "7";
    item.key = key++;
    
    item = {};
    this.items.push(item);
    item['support'] = "62.0";
    item['district'] = "Pattonville R-III";
    item['questionGroup'] = "1";
    item['category'] = "Questions 31-35";
    item['index'] = "7";
    item.key = key++;
    
    item = {};
    this.items.push(item);
    item['support'] = "61.6";
    item['district'] = "Ritenour";
    item['questionGroup'] = "1";
    item['category'] = "Questions 31-35";
    item['index'] = "7";
    item.key = key++;
    
    item = {};
    this.items.push(item);
    item['support'] = "61.4";
    item['district'] = "Rockwood R-VI";
    item['questionGroup'] = "1";
    item['category'] = "Questions 31-35";
    item['index'] = "7";
    item.key = key++;
    
    item = {};
    this.items.push(item);
    item['support'] = "62.2";
    item['district'] = "St. Louis City";
    item['questionGroup'] = "1";
    item['category'] = "Questions 31-35";
    item['index'] = "7";
    item.key = key++;
    
    item = {};
    this.items.push(item);
    item['support'] = "72.7";
    item['district'] = "all";
    item['questionGroup'] = "1";
    item['category'] = "Questions 1-5";
    item['index'] = "1";
    item.key = key++;
    
    item = {};
    this.items.push(item);
    item['support'] = "64.1";
    item['district'] = "all";
    item['questionGroup'] = "1";
    item['category'] = "Questions 6-10";
    item['index'] = "2";
    item.key = key++;
    
    item = {};
    this.items.push(item);
    item['support'] = "79.2";
    item['district'] = "all";
    item['questionGroup'] = "1";
    item['category'] = "Questions 11-15";
    item['index'] = "3";
    item.key = key++;
    
    item = {};
    this.items.push(item);
    item['support'] = "81.5";
    item['district'] = "all";
    item['questionGroup'] = "1";
    item['category'] = "Questions 16-20";
    item['index'] = "4";
    item.key = key++;
    
    item = {};
    this.items.push(item);
    item['support'] = "81.5";
    item['district'] = "all";
    item['questionGroup'] = "1";
    item['category'] = "Questions 21-25";
    item['index'] = "5";
    item.key = key++;
    
    item = {};
    this.items.push(item);
    item['support'] = "76.4";
    item['district'] = "all";
    item['questionGroup'] = "1";
    item['category'] = "Questions 26-30";
    item['index'] = "6";
    item.key = key++;
    
    item = {};
    this.items.push(item);
    item['support'] = "73.6";
    item['district'] = "all";
    item['questionGroup'] = "1";
    item['category'] = "Questions 31-35";
    item['index'] = "7";
    item.key = key++;
  }

}
