import React, { Component } from 'react';
import './App.css';
import ScreenContext from './ScreenContext';

export default class QuestionList extends Component {

  static contextType = ScreenContext;

  // Properties used by this component:
  // question, dataSheetRow, totalSupport

  constructor(props) {
    super(props);
    
    this.state = {
    };
  }

  componentDidMount() {
  }

  componentWillUnmount() {
  }

  componentDidUpdate(prevProps, prevState) {
  }

  onClick_elHotspot = async () => {
    let newVal = this.props.dataSheetRow.questionKey;
    this.context.appActions.updateDataSlot('ds_pie', newVal);
  
  
    newVal = this.props.totalSupport;
    this.context.appActions.updateDataSlot('ds_totalSupport', newVal);
  
  }
  
  
  render() {
    const value_text = ((val) => {
      // make sure value is in string format
      if (val instanceof String || typeof val === 'string') return val;
      else {
        try {
          return JSON.stringify(val);
        }
        catch (e) {
          return val.toString();
        }
      }
    })(this.props.question);
    
    const style_elText = {
      color: '#1f1f1f',
      textAlign: 'left',
     };
    
    const style_elHotspot = {
      display: 'block',
      textTransform: 'uppercase',
      backgroundColor: 'transparent',
      cursor: 'pointer',
      pointerEvents: 'auto',
     };
    
    return (
      <div className="QuestionList cardBg">
        <div className="layoutFlow">
          <div className="elText">
            <div className="baseFont" style={style_elText}>
              <div>{value_text}</div>
            </div>
          </div>
        </div>
        
        <div className="foreground">
          <div className="actionFont containerMinHeight elHotspot" style={style_elHotspot} onClick={this.onClick_elHotspot}  />
        </div>
      </div>
    )
  }
  
}
