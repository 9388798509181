import React, { Component } from 'react';
import './App.css';
import ScreenContext from './ScreenContext';
import btn_icon_851318 from './images/btn_icon_851318.png';
import btn_icon_399429 from './images/btn_icon_399429.png';
import btn_icon_676555 from './images/btn_icon_676555.png';

export default class ContentArea extends Component {

  static contextType = ScreenContext;

  // This component doesn't use any properties

  constructor(props) {
    super(props);
    
    this.state = {
      title: (<div>HOME</div>),
      title_plainText: "HOME",
      radiobuttonlabelconnect: (<div>Connect</div>),
      radiobuttonlabelconnect_plainText: "Connect",
      radiobuttonlabelengage: (<div>Engage</div>),
      radiobuttonlabelengage_plainText: "Engage",
      radiobuttonlabelactivate: (<div>Activate</div>),
      radiobuttonlabelactivate_plainText: "Activate",
    };
  }

  componentDidMount() {
  }

  componentWillUnmount() {
  }

  componentDidUpdate(prevProps, prevState) {
  }

  onClick_elHotspotconnect = async () => {
  
  
  
    // Go to screen 'Connect'
    this.context.appActions.goToScreen('connect', this.context.baseScreenId, { transitionId: 'fadeIn' });
  
  }
  
  
  onClick_elHotspotengage = async () => {
  
  
  
  }
  
  
  onClick_elHotspotactivate = async () => {
  
  
  
  }
  
  
  onClick_elGoToActivate = async () => {
    // Go to screen 'Activate'
    this.context.appActions.goToScreen('activate', this.context.baseScreenId, { transitionId: 'fadeIn' });
  
  }
  
  
  onClick_elGoToEngage = async () => {
    // Go to screen 'Engage'
    this.context.appActions.goToScreen('engage', this.context.baseScreenId, { transitionId: 'fadeIn' });
  
  }
  
  
  onClick_elGoToConnect = async () => {
    // Go to screen 'Connect'
    this.context.appActions.goToScreen('connect', this.context.baseScreenId, { transitionId: 'fadeIn' });
  
  }
  
  
  render() {
    const style_elBackground = {
      width: '100%',
      height: '100%',
     };
    const style_elBackground_outer = {
      boxSizing: 'border-box',
      backgroundColor: '#eaeaea',
     };
    const style_elTopbox = {
      width: '100%',
      height: '100%',
     };
    const style_elTopbox_outer = {
      boxSizing: 'border-box',
      backgroundColor: '#fdfdfd',
     };
    const style_elBottombox = {
      width: '100%',
      height: '100%',
     };
    const style_elBottombox_outer = {
      boxSizing: 'border-box',
      backgroundColor: '#fdfdfd',
     };
    const style_elTitle = {
      color: '#1f1f1f',
      textAlign: 'left',
     };
    
    const style_elHotspotconnect = {
      display: 'block',
      textTransform: 'uppercase',
      backgroundColor: 'transparent',
      cursor: 'pointer',
      pointerEvents: 'auto',
     };
    
    const style_elHotspotengage = {
      display: 'block',
      textTransform: 'uppercase',
      backgroundColor: 'transparent',
      cursor: 'pointer',
      pointerEvents: 'auto',
     };
    
    const style_elHotspotactivate = {
      display: 'block',
      textTransform: 'uppercase',
      backgroundColor: 'transparent',
      cursor: 'pointer',
      pointerEvents: 'auto',
     };
    const style_elButtonBgconnect_outer = {
      boxSizing: 'border-box',
      backgroundColor: '#eaeaea',
      filter: 'drop-shadow(0.0px 1.0px 4px rgba(0, 0, 0, 0.3000))',
      overflow: 'visible',
     };
    const style_elButtonBgenagage_outer = {
      boxSizing: 'border-box',
      backgroundColor: '#eaeaea',
      filter: 'drop-shadow(0.0px 1.0px 4px rgba(0, 0, 0, 0.3000))',
      overflow: 'visible',
     };
    const style_elButtonBgactivate_outer = {
      boxSizing: 'border-box',
      backgroundColor: '#eaeaea',
      filter: 'drop-shadow(0.0px 1.0px 4px rgba(0, 0, 0, 0.3000))',
      overflow: 'visible',
     };
    
    const style_elButtonconnect = {
      display: 'block',
      textTransform: 'uppercase',
      backgroundImage: 'url('+btn_icon_851318+')',
      backgroundRepeat: 'no-repeat',
      backgroundSize: '100%',
      backgroundPosition: '50% 0%',
      color: '(null)',
      textAlign: 'left',
      backgroundColor: 'transparent',
     };
    
    const style_elButtonengage = {
      display: 'block',
      textTransform: 'uppercase',
      backgroundImage: 'url('+btn_icon_399429+')',
      backgroundRepeat: 'no-repeat',
      backgroundSize: '100%',
      backgroundPosition: '50% 0%',
      color: '(null)',
      textAlign: 'left',
      backgroundColor: 'transparent',
     };
    
    const style_elButtonactivate = {
      display: 'block',
      textTransform: 'uppercase',
      backgroundImage: 'url('+btn_icon_676555+')',
      backgroundRepeat: 'no-repeat',
      backgroundSize: '100%',
      backgroundPosition: '50% 0%',
      color: '(null)',
      textAlign: 'left',
      backgroundColor: 'transparent',
     };
    const style_elRadioButtonLabelconnect = {
      color: '#1f1f1f',
      textAlign: 'center',
     };
    const style_elRadioButtonLabelengage = {
      color: '#1f1f1f',
      textAlign: 'center',
     };
    const style_elRadioButtonLabelactivate = {
      color: '#1f1f1f',
      textAlign: 'center',
     };
    
    const style_elGoToActivate = {
      display: 'block',
      textTransform: 'uppercase',
      backgroundColor: 'transparent',
      cursor: 'pointer',
      pointerEvents: 'auto',
     };
    
    const style_elGoToEngage = {
      display: 'block',
      textTransform: 'uppercase',
      backgroundColor: 'transparent',
      cursor: 'pointer',
      pointerEvents: 'auto',
     };
    
    const style_elGoToConnect = {
      display: 'block',
      textTransform: 'uppercase',
      backgroundColor: 'transparent',
      cursor: 'pointer',
      pointerEvents: 'auto',
     };
    
    return (
      <div className="ContentArea">
        <div className="background">
          <div className="containerMinHeight elBackground" style={style_elBackground_outer}>
            <div className="cardBg" style={style_elBackground} />
          </div>
          
          <div className="elTopbox" style={style_elTopbox_outer}>
            <div className="appBg" style={style_elTopbox} />
          </div>
          
          <div className="elBottombox" style={style_elBottombox_outer}>
            <div className="appBg" style={style_elBottombox} />
          </div>
        </div>
        
        <div className="layoutFlow">
          <div className="elTitle">
            <div className="headlineFont" style={style_elTitle}>
              <div>{this.state.title}</div>
            </div>
          </div>
          
          <div className="elSpacer">
            <div />
          </div>
          
          <div className="elSpacerCopy">
            <div />
          </div>
          
          <div className="elSpacerCopy2">
            <div />
          </div>
          
          <div className="elSpacerCopy3">
            <div />
          </div>
          
          <div className="elSpacerCopy4">
            <div />
          </div>
          
          <div className="elSpacerCopy5">
            <div />
          </div>
          
          <div className="elSpacerCopy6">
            <div />
          </div>
          
          <div className="flowRow flowRow_ContentArea_elHotspotconnect_648960">
          <div className="elHotspotconnect">
            <div className="actionFont" style={style_elHotspotconnect} onClick={this.onClick_elHotspotconnect}  />
          </div>
          
          <div className="elHotspotengage">
            <div className="actionFont" style={style_elHotspotengage} onClick={this.onClick_elHotspotengage}  />
          </div>
          
          <div className="elHotspotactivate">
            <div className="actionFont" style={style_elHotspotactivate} onClick={this.onClick_elHotspotactivate}  />
          </div>
          
          <div className="elButtonBgconnect" style={style_elButtonBgconnect_outer}>
            <div className="cardBg" />
          </div>
          
          <div className="elButtonBgenagage" style={style_elButtonBgenagage_outer}>
            <div className="cardBg" />
          </div>
          
          <div className="elButtonBgactivate" style={style_elButtonBgactivate_outer}>
            <div className="cardBg" />
          </div>
          
          </div>
          <div className="elSpacerCopy12">
            <div />
          </div>
          
          <div className="elSpacerCopy8">
            <div />
          </div>
          
          <div className="elSpacerCopy11">
            <div />
          </div>
          
          <div className="elSpacerCopy9">
            <div />
          </div>
          
          <div className="elSpacerCopy10">
            <div />
          </div>
          
          <div className="flowRow flowRow_ContentArea_elButtonconnect_851318">
          <div className="elButtonconnect">
            <button className="actionFont" style={style_elButtonconnect} />
          </div>
          
          <div className="elButtonengage">
            <button className="actionFont" style={style_elButtonengage} />
          </div>
          
          </div>
          <div className="elButtonactivate">
            <button className="actionFont" style={style_elButtonactivate} />
          </div>
          
          <div className="flowRow flowRow_ContentArea_elRadioButtonLabelconnect_725227">
          <div className="elRadioButtonLabelconnect">
            <div className="baseFont" style={style_elRadioButtonLabelconnect}>
              <div>{this.state.radiobuttonlabelconnect}</div>
            </div>
          </div>
          
          <div className="elRadioButtonLabelengage">
            <div className="baseFont" style={style_elRadioButtonLabelengage}>
              <div>{this.state.radiobuttonlabelengage}</div>
            </div>
          </div>
          
          </div>
          <div className="elRadioButtonLabelactivate">
            <div className="baseFont" style={style_elRadioButtonLabelactivate}>
              <div>{this.state.radiobuttonlabelactivate}</div>
            </div>
          </div>
          
          <div className="elSpacerCopy7">
            <div />
          </div>
          
          <div className="elSpacer2">
            <div />
          </div>
          
          <div className="elSpacerCopy13">
            <div />
          </div>
        </div>
        
        <div className="foreground">
          <div className="actionFont elGoToActivate" style={style_elGoToActivate} onClick={this.onClick_elGoToActivate}  />
          <div className="actionFont elGoToEngage" style={style_elGoToEngage} onClick={this.onClick_elGoToEngage}  />
          <div className="actionFont elGoToConnect" style={style_elGoToConnect} onClick={this.onClick_elGoToConnect}  />
        </div>
      </div>
    )
  }
  
}
