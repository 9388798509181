// eslint-disable-next-line
import React from 'react';
import DataSheetBase from './DataSheetBase.js';

export default class DataSheet_q6to10 extends DataSheetBase {

  constructor(id, updateCb) {
    super(id, updateCb);
    this.requestedKeyPath = "";  // this value can be specified in the React Studio data sheet UI
  }

  makeDefaultItems() {
    // eslint-disable-next-line no-unused-vars
    let key = 1;
    // eslint-disable-next-line no-unused-vars
    let item;
    
    item = {};
    this.items.push(item);
    item['support'] = "64.1";
    item['question'] = "People like me can’t afford to pay higher taxes, no matter how good the cause.";
    item.key = key++;
    
    item = {};
    this.items.push(item);
    item['support'] = "77.3";
    item['question'] = "We get excellent value from what we pay to support our community college.";
    item.key = key++;
    
    item = {};
    this.items.push(item);
    item['support'] = "43.4";
    item['question'] = "The local community college does not serve the needs of people like me and my family.";
    item.key = key++;
    
    item = {};
    this.items.push(item);
    item['support'] = "63.8";
    item['question'] = "I’m willing to pay more to support the community college’s efforts to help our area economically recover from the COVID-19 pandemic.";
    item.key = key++;
    
    item = {};
    this.items.push(item);
    item['support'] = "75.3";
    item['question'] = "Our community college is an important anchor for our regional economy.";
    item.key = key++;
  }

}
