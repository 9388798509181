// eslint-disable-next-line
import React from 'react';
import DataSheetBase from './DataSheetBase.js';

export default class DataSheet_allQuestions extends DataSheetBase {

  constructor(id, updateCb) {
    super(id, updateCb);
    this.requestedKeyPath = "";  // this value can be specified in the React Studio data sheet UI
  }

  makeDefaultItems() {
    // eslint-disable-next-line no-unused-vars
    let key = 1;
    // eslint-disable-next-line no-unused-vars
    let item;
    
    item = {};
    this.items.push(item);
    item['district'] = "all";
    item['questionKey'] = "1";
    item['support'] = "65.4";
    item['totalSupport'] = "501,053";
    item['question'] = "Thinking about the next two or three months, do you think the economy in your area will . .";
    item['questionGroup'] = "1";
    item.key = key++;
    
    item = {};
    this.items.push(item);
    item['support'] = "79.9";
    item['district'] = "all";
    item['totalSupport'] = "433,988";
    item['question'] = "Students are often given the grades A, B, C, D, and F to evaluate their work. What grade would you give the community college in your area?";
    item['questionGroup'] = "1";
    item['questionKey'] = "2";
    item.key = key++;
    
    item = {};
    this.items.push(item);
    item['support'] = "44.1";
    item['district'] = "all";
    item['totalSupport'] = "560,853";
    item['question'] = "Assume the community college in your area seeks voter approval for an 8-cent increase in property taxes to support improved facilities and programs. If you were voting today, is that something you .";
    item['questionGroup'] = "1";
    item['questionKey'] = "3";
    item.key = key++;
    
    item = {};
    this.items.push(item);
    item['support'] = "26.6";
    item['district'] = "all";
    item['totalSupport'] = "569,750";
    item['question'] = "If you knew this proposal would cost about $1.90 per month for a $150,000 house, is this proposal something you . . .";
    item['questionGroup'] = "1";
    item['questionKey'] = "4";
    item.key = key++;
    
    item = {};
    this.items.push(item);
    item['support'] = "80";
    item['district'] = "all";
    item['totalSupport'] = "647,301";
    item['question'] = "It is essential that our area has a quality community college as a place for adults to be trained and retrained for the good paying jobs of today and tomorrow.";
    item['questionGroup'] = "1";
    item['questionKey'] = "5";
    item.key = key++;
    
    item = {};
    this.items.push(item);
    item['support'] = "60.6";
    item['district'] = "all";
    item['totalSupport'] = "463,573";
    item['question'] = "People like me can't afford to pay higher taxes, no matter how good the cause.";
    item['questionGroup'] = "2";
    item['questionKey'] = "6";
    item.key = key++;
    
    item = {};
    this.items.push(item);
    item['support'] = "75.5";
    item['district'] = "all";
    item['totalSupport'] = "528,133";
    item['question'] = "We get excellent value from what we pay to support our community college.";
    item['questionGroup'] = "2";
    item['questionKey'] = "7";
    item.key = key++;
    
    item = {};
    this.items.push(item);
    item['support'] = "36.7";
    item['district'] = "all";
    item['totalSupport'] = "440,123";
    item['question'] = "The local community college does not serve the needs of people like me and my family.";
    item['questionGroup'] = "2";
    item['questionKey'] = "8";
    item.key = key++;
    
    item = {};
    this.items.push(item);
    item['support'] = "46.9";
    item['district'] = "all";
    item['totalSupport'] = "683,229";
    item['question'] = "I'm willing to pay more to support the community college's efforts to help our area economically recover from the COVID-19 pandemic.";
    item['questionGroup'] = "2";
    item['questionKey'] = "9";
    item.key = key++;
    
    item = {};
    this.items.push(item);
    item['support'] = "71.9";
    item['district'] = "all";
    item['totalSupport'] = "657,999";
    item['question'] = "Our community college is an important anchor for our regional economy.";
    item['questionGroup'] = "2";
    item['questionKey'] = "10";
    item.key = key++;
    
    item = {};
    this.items.push(item);
    item['support'] = "78.4";
    item['district'] = "all";
    item['totalSupport'] = "657,734";
    item['question'] = "Making sure all of the college's instructional areas are equipped with up-to-date instructional technology.";
    item['questionGroup'] = "3";
    item['questionKey'] = "11";
    item.key = key++;
    
    item = {};
    this.items.push(item);
    item['support'] = "60.7";
    item['district'] = "all";
    item['totalSupport'] = "610,857";
    item['question'] = "Replacing buildings that are more than a half-century old, and are expensive and inefficient to operate and maintain.";
    item['questionGroup'] = "3";
    item['questionKey'] = "12";
    item.key = key++;
    
    item = {};
    this.items.push(item);
    item['support'] = "78.7";
    item['district'] = "all";
    item['totalSupport'] = "556,116";
    item['question'] = "Providing learning spaces to accommodate best practices in job training and retraining programs.";
    item['questionGroup'] = "3";
    item['questionKey'] = "13";
    item.key = key++;
    
    item = {};
    this.items.push(item);
    item['support'] = "76.4";
    item['district'] = "all";
    item['totalSupport'] = "547,160";
    item['question'] = "Repairing and replacing aging roofs, doors, windows, plumbing, HVAC, electrical and other infrastructure conditions as needed.";
    item['questionGroup'] = "3";
    item['questionKey'] = "14";
    item.key = key++;
    
    item = {};
    this.items.push(item);
    item['support'] = "80";
    item['district'] = "all";
    item['totalSupport'] = "447,102";
    item['question'] = "Providing job training and retraining facilities and programs for good paying, high-in-demand, jobs in areas like healthcare, information technology (IT) and financial services.";
    item['questionGroup'] = "3";
    item['questionKey'] = "15";
    item.key = key++;
    
    item = {};
    this.items.push(item);
    item['support'] = "80";
    item['district'] = "all";
    item['totalSupport'] = "446,916";
    item['question'] = "Making sure the campus is safe and secure both night and day.";
    item['questionGroup'] = "4";
    item['questionKey'] = "16";
    item.key = key++;
    
    item = {};
    this.items.push(item);
    item['support'] = "80";
    item['district'] = "all";
    item['totalSupport'] = "613,051";
    item['question'] = "Keeping community college programs affordable.";
    item['questionGroup'] = "4";
    item['questionKey'] = "17";
    item.key = key++;
    
    item = {};
    this.items.push(item);
    item['support'] = "79.7";
    item['district'] = "all";
    item['totalSupport'] = "473,335";
    item['question'] = "Improving training programs so those who are underemployed and unemployed can secure jobs that pay a decent salary.";
    item['questionGroup'] = "4";
    item['questionKey'] = "18";
    item.key = key++;
    
    item = {};
    this.items.push(item);
    item['support'] = "75.2";
    item['district'] = "all";
    item['totalSupport'] = "465,264";
    item['question'] = "Making sure student support services such as registration and financial services are centralized and easily available to students.";
    item['questionGroup'] = "4";
    item['questionKey'] = "19";
    item.key = key++;
    
    item = {};
    this.items.push(item);
    item['support'] = "77.1";
    item['district'] = "all";
    item['totalSupport'] = "609,152";
    item['question'] = "Coordinating programs with area economic planners and businesses to make sure well-trained employees are available to help stimulate business growth and relocation in our community.";
    item['questionGroup'] = "4";
    item['questionKey'] = "20";
    item.key = key++;
    
    item = {};
    this.items.push(item);
    item['support'] = "79.5";
    item['district'] = "all";
    item['totalSupport'] = "445,438";
    item['question'] = "Providing real-world learning environments that provide students with the skills they need in the workplace.";
    item['questionGroup'] = "5";
    item['questionKey'] = "21";
    item.key = key++;
    
    item = {};
    this.items.push(item);
    item['support'] = "79.4";
    item['district'] = "all";
    item['totalSupport'] = "695,658";
    item['question'] = "Improving ADA accessibility at all campuses for students with disabilities.";
    item['questionGroup'] = "5";
    item['questionKey'] = "22";
    item.key = key++;
    
    item = {};
    this.items.push(item);
    item['support'] = "80";
    item['district'] = "all";
    item['totalSupport'] = "533,634";
    item['question'] = "We must ensure that our community college has adequate facilities and programs to meet the demand for high tech job training programs.";
    item['questionGroup'] = "5";
    item['questionKey'] = "23";
    item.key = key++;
    
    item = {};
    this.items.push(item);
    item['support'] = "60.2";
    item['district'] = "all";
    item['totalSupport'] = "413,619";
    item['question'] = "If the college needs more money, it should raise student fees, not increase the money it receives from taxes.";
    item['questionGroup'] = "5";
    item['questionKey'] = "24";
    item.key = key++;
    
    item = {};
    this.items.push(item);
    item['support'] = "79.8";
    item['district'] = "all";
    item['totalSupport'] = "455,620";
    item['question'] = "We must protect the investment we've made in our community college by keeping our programming current and our facilities up-to-date in good working condition.";
    item['questionGroup'] = "5";
    item['questionKey'] = "25";
    item.key = key++;
    
    item = {};
    this.items.push(item);
    item['support'] = "70.5";
    item['district'] = "all";
    item['totalSupport'] = "642,871";
    item['question'] = "Our community college is important to our area. But in terms of receiving more financial support, there are other things that are just more important.";
    item['questionGroup'] = "6";
    item['questionKey'] = "26";
    item.key = key++;
    
    item = {};
    this.items.push(item);
    item['support'] = "79.5";
    item['district'] = "all";
    item['totalSupport'] = "652,306";
    item['question'] = "Following more than a year of COVID-19, it is more important than ever to support the career education and training programs at our community college.";
    item['questionGroup'] = "6";
    item['questionKey'] = "27";
    item.key = key++;
    
    item = {};
    this.items.push(item);
    item['support'] = "73.9";
    item['district'] = "all";
    item['totalSupport'] = "617,135";
    item['question'] = "Unless we keep the college's programs and facilities up-to-date, many area workers will not be able to get the training they need to prepare for the good paying jobs of today and tomorrow, and that will hurt the local economy.";
    item['questionGroup'] = "6";
    item['questionKey'] = "28";
    item.key = key++;
    
    item = {};
    this.items.push(item);
    item['support'] = "77.5";
    item['district'] = "all";
    item['totalSupport'] = "696,334";
    item['question'] = "The community college's career education programs help create an educated workforce. That leads to an improved economy, and in the long term, that lowers local taxes. Supporting the community just makes financial sense.";
    item['questionGroup'] = "6";
    item['questionKey'] = "29";
    item.key = key++;
    
    item = {};
    this.items.push(item);
    item['support'] = "62.7";
    item['district'] = "all";
    item['totalSupport'] = "475,576";
    item['question'] = "I just don't trust local leadership with additional public dollars.";
    item['questionGroup'] = "6";
    item['questionKey'] = "30";
    item.key = key++;
    
    item = {};
    this.items.push(item);
    item['support'] = "73.4";
    item['district'] = "all";
    item['totalSupport'] = "475,439";
    item['question'] = "Right now there are thousands of good-paying jobs in our area that remain unfilled because businesses can't find employees with the necessary skills. This proposal helps train local residents for these jobs and keep them in our area.";
    item['questionGroup'] = "7";
    item['questionKey'] = "31";
    item.key = key++;
    
    item = {};
    this.items.push(item);
    item['support'] = "77.7";
    item['district'] = "all";
    item['totalSupport'] = "517,763";
    item['question'] = "Many businesses can't grow, or move to our area, because of the shortage of a well-trained workforce. Our economic future depends on supporting job training and retraining programs at our community college.";
    item['questionGroup'] = "7";
    item['questionKey'] = "32";
    item.key = key++;
    
    item = {};
    this.items.push(item);
    item['support'] = "49.9";
    item['district'] = "all";
    item['totalSupport'] = "548,076";
    item['question'] = "This proposal will enable the college to repurpose out-of-date and underutilized spaces to meet the needs of today's students and for future generations.";
    item['questionGroup'] = "7";
    item['questionKey'] = "33";
    item.key = key++;
    
    item = {};
    this.items.push(item);
    item['support'] = "71.5";
    item['district'] = "all";
    item['totalSupport'] = "676,577";
    item['question'] = "As asked earlier, assume that your local community college is seeking an 8-cent increase in property taxes to support improved facilities and programs. If you were voting today, is that something you would . . .";
    item['questionGroup'] = "7";
    item['questionKey'] = "34";
    item.key = key++;
    
    item = {};
    this.items.push(item);
    item['support'] = "66.4";
    item['district'] = "Afton 101";
    item['totalSupport'] = "6,498";
    item['question'] = "Thinking about the next two or three months, do you think the economy in your area will . .";
    item['questionGroup'] = "1";
    item['questionKey'] = "1";
    item.key = key++;
    
    item = {};
    this.items.push(item);
    item['support'] = "80";
    item['district'] = "Afton 101";
    item['totalSupport'] = "5,223";
    item['question'] = "Students are often given the grades A, B, C, D, and F to evaluate their work. What grade would you give the community college in your area?";
    item['questionGroup'] = "1";
    item['questionKey'] = "2";
    item.key = key++;
    
    item = {};
    this.items.push(item);
    item['support'] = "44.5";
    item['district'] = "Afton 101";
    item['totalSupport'] = "6,216";
    item['question'] = "Assume the community college in your area seeks voter approval for an 8-cent increase in property taxes to support improved facilities and programs. If you were voting today, is that something you .";
    item['questionGroup'] = "1";
    item['questionKey'] = "3";
    item.key = key++;
    
    item = {};
    this.items.push(item);
    item['support'] = "27.9";
    item['district'] = "Afton 101";
    item['totalSupport'] = "9,594";
    item['question'] = "If you knew this proposal would cost about $1.90 per month for a $150,000 house, is this proposal something you . . .";
    item['questionGroup'] = "1";
    item['questionKey'] = "4";
    item.key = key++;
    
    item = {};
    this.items.push(item);
    item['support'] = "80";
    item['district'] = "Afton 101";
    item['totalSupport'] = "9,387";
    item['question'] = "It is essential that our area has a quality community college as a place for adults to be trained and retrained for the good paying jobs of today and tomorrow.";
    item['questionGroup'] = "1";
    item['questionKey'] = "5";
    item.key = key++;
    
    item = {};
    this.items.push(item);
    item['support'] = "59.9";
    item['district'] = "Afton 101";
    item['totalSupport'] = "9,886";
    item['question'] = "People like me can't afford to pay higher taxes, no matter how good the cause.";
    item['questionGroup'] = "2";
    item['questionKey'] = "6";
    item.key = key++;
    
    item = {};
    this.items.push(item);
    item['support'] = "75.9";
    item['district'] = "Afton 101";
    item['totalSupport'] = "4,870";
    item['question'] = "We get excellent value from what we pay to support our community college.";
    item['questionGroup'] = "2";
    item['questionKey'] = "7";
    item.key = key++;
    
    item = {};
    this.items.push(item);
    item['support'] = "35.6";
    item['district'] = "Afton 101";
    item['totalSupport'] = "7,587";
    item['question'] = "The local community college does not serve the needs of people like me and my family.";
    item['questionGroup'] = "2";
    item['questionKey'] = "8";
    item.key = key++;
    
    item = {};
    this.items.push(item);
    item['support'] = "47.7";
    item['district'] = "Afton 101";
    item['totalSupport'] = "8,534";
    item['question'] = "I'm willing to pay more to support the community college's efforts to help our area economically recover from the COVID-19 pandemic.";
    item['questionGroup'] = "2";
    item['questionKey'] = "9";
    item.key = key++;
    
    item = {};
    this.items.push(item);
    item['support'] = "72";
    item['district'] = "Afton 101";
    item['totalSupport'] = "5,834";
    item['question'] = "Our community college is an important anchor for our regional economy.";
    item['questionGroup'] = "2";
    item['questionKey'] = "10";
    item.key = key++;
    
    item = {};
    this.items.push(item);
    item['support'] = "78.7";
    item['district'] = "Afton 101";
    item['totalSupport'] = "6,486";
    item['question'] = "Making sure all of the college's instructional areas are equipped with up-to-date instructional technology.";
    item['questionGroup'] = "3";
    item['questionKey'] = "11";
    item.key = key++;
    
    item = {};
    this.items.push(item);
    item['support'] = "60.4";
    item['district'] = "Afton 101";
    item['totalSupport'] = "5,132";
    item['question'] = "Replacing buildings that are more than a half-century old, and are expensive and inefficient to operate and maintain.";
    item['questionGroup'] = "3";
    item['questionKey'] = "12";
    item.key = key++;
    
    item = {};
    this.items.push(item);
    item['support'] = "78.5";
    item['district'] = "Afton 101";
    item['totalSupport'] = "6,510";
    item['question'] = "Providing learning spaces to accommodate best practices in job training and retraining programs.";
    item['questionGroup'] = "3";
    item['questionKey'] = "13";
    item.key = key++;
    
    item = {};
    this.items.push(item);
    item['support'] = "76";
    item['district'] = "Afton 101";
    item['totalSupport'] = "5,037";
    item['question'] = "Repairing and replacing aging roofs, doors, windows, plumbing, HVAC, electrical and other infrastructure conditions as needed.";
    item['questionGroup'] = "3";
    item['questionKey'] = "14";
    item.key = key++;
    
    item = {};
    this.items.push(item);
    item['support'] = "80";
    item['district'] = "Afton 101";
    item['totalSupport'] = "5,520";
    item['question'] = "Providing job training and retraining facilities and programs for good paying, high-in-demand, jobs in areas like healthcare, information technology (IT) and financial services.";
    item['questionGroup'] = "3";
    item['questionKey'] = "15";
    item.key = key++;
    
    item = {};
    this.items.push(item);
    item['support'] = "80";
    item['district'] = "Afton 101";
    item['totalSupport'] = "5,268";
    item['question'] = "Making sure the campus is safe and secure both night and day.";
    item['questionGroup'] = "4";
    item['questionKey'] = "16";
    item.key = key++;
    
    item = {};
    this.items.push(item);
    item['support'] = "80";
    item['district'] = "Afton 101";
    item['totalSupport'] = "7,114";
    item['question'] = "Keeping community college programs affordable.";
    item['questionGroup'] = "4";
    item['questionKey'] = "17";
    item.key = key++;
    
    item = {};
    this.items.push(item);
    item['support'] = "79.6";
    item['district'] = "Afton 101";
    item['totalSupport'] = "8.488";
    item['question'] = "Improving training programs so those who are underemployed and unemployed can secure jobs that pay a decent salary.";
    item['questionGroup'] = "4";
    item['questionKey'] = "18";
    item.key = key++;
    
    item = {};
    this.items.push(item);
    item['support'] = "75.9";
    item['district'] = "Afton 101";
    item['totalSupport'] = "5,470";
    item['question'] = "Making sure student support services such as registration and financial services are centralized and easily available to students.";
    item['questionGroup'] = "4";
    item['questionKey'] = "19";
    item.key = key++;
    
    item = {};
    this.items.push(item);
    item['support'] = "77.2";
    item['district'] = "Afton 101";
    item['totalSupport'] = "7,020";
    item['question'] = "Coordinating programs with area economic planners and businesses to make sure well-trained employees are available to help stimulate business growth and relocation in our community.";
    item['questionGroup'] = "4";
    item['questionKey'] = "20";
    item.key = key++;
    
    item = {};
    this.items.push(item);
    item['support'] = "79.4";
    item['district'] = "Afton 101";
    item['totalSupport'] = "7,945";
    item['question'] = "Providing real-world learning environments that provide students with the skills they need in the workplace.";
    item['questionGroup'] = "5";
    item['questionKey'] = "21";
    item.key = key++;
    
    item = {};
    this.items.push(item);
    item['support'] = "79.2";
    item['district'] = "Afton 101";
    item['totalSupport'] = "9,038";
    item['question'] = "Improving ADA accessibility at all campuses for students with disabilities.";
    item['questionGroup'] = "5";
    item['questionKey'] = "22";
    item.key = key++;
    
    item = {};
    this.items.push(item);
    item['support'] = "80";
    item['district'] = "Afton 101";
    item['totalSupport'] = "5,102";
    item['question'] = "We must ensure that our community college has adequate facilities and programs to meet the demand for high tech job training programs.";
    item['questionGroup'] = "5";
    item['questionKey'] = "23";
    item.key = key++;
    
    item = {};
    this.items.push(item);
    item['support'] = "60";
    item['district'] = "Afton 101";
    item['totalSupport'] = "8,677";
    item['question'] = "If the college needs more money, it should raise student fees, not increase the money it receives from taxes.";
    item['questionGroup'] = "5";
    item['questionKey'] = "24";
    item.key = key++;
    
    item = {};
    this.items.push(item);
    item['support'] = "79.7";
    item['district'] = "Afton 101";
    item['totalSupport'] = "7,350";
    item['question'] = "We must protect the investment we've made in our community college by keeping our programming current and our facilities up-to-date in good working condition.";
    item['questionGroup'] = "5";
    item['questionKey'] = "25";
    item.key = key++;
    
    item = {};
    this.items.push(item);
    item['support'] = "69.8";
    item['district'] = "Afton 101";
    item['totalSupport'] = "8,910";
    item['question'] = "Our community college is important to our area. But in terms of receiving more financial support, there are other things that are just more important.";
    item['questionGroup'] = "6";
    item['questionKey'] = "26";
    item.key = key++;
    
    item = {};
    this.items.push(item);
    item['support'] = "79.2";
    item['district'] = "Afton 101";
    item['totalSupport'] = "7,129";
    item['question'] = "Following more than a year of COVID-19, it is more important than ever to support the career education and training programs at our community college.";
    item['questionGroup'] = "6";
    item['questionKey'] = "27";
    item.key = key++;
    
    item = {};
    this.items.push(item);
    item['support'] = "74";
    item['district'] = "Afton 101";
    item['totalSupport'] = "4,510";
    item['question'] = "Unless we keep the college's programs and facilities up-to-date, many area workers will not be able to get the training they need to prepare for the good paying jobs of today and tomorrow, and that will hurt the local economy.";
    item['questionGroup'] = "6";
    item['questionKey'] = "28";
    item.key = key++;
    
    item = {};
    this.items.push(item);
    item['support'] = "78.7";
    item['district'] = "Afton 101";
    item['totalSupport'] = "9,119";
    item['question'] = "The community college's career education programs help create an educated workforce. That leads to an improved economy, and in the long term, that lowers local taxes. Supporting the community just makes financial sense.";
    item['questionGroup'] = "6";
    item['questionKey'] = "29";
    item.key = key++;
    
    item = {};
    this.items.push(item);
    item['support'] = "60.9";
    item['district'] = "Afton 101";
    item['totalSupport'] = "8,379";
    item['question'] = "I just don't trust local leadership with additional public dollars.";
    item['questionGroup'] = "6";
    item['questionKey'] = "30";
    item.key = key++;
    
    item = {};
    this.items.push(item);
    item['support'] = "74.5";
    item['district'] = "Afton 101";
    item['totalSupport'] = "4,743";
    item['question'] = "Right now there are thousands of good-paying jobs in our area that remain unfilled because businesses can't find employees with the necessary skills. This proposal helps train local residents for these jobs and keep them in our area.";
    item['questionGroup'] = "7";
    item['questionKey'] = "31";
    item.key = key++;
    
    item = {};
    this.items.push(item);
    item['support'] = "78.5";
    item['district'] = "Afton 101";
    item['totalSupport'] = "6,983";
    item['question'] = "Many businesses can't grow, or move to our area, because of the shortage of a well-trained workforce. Our economic future depends on supporting job training and retraining programs at our community college.";
    item['questionGroup'] = "7";
    item['questionKey'] = "32";
    item.key = key++;
    
    item = {};
    this.items.push(item);
    item['support'] = "50.9";
    item['district'] = "Afton 101";
    item['totalSupport'] = "6,960";
    item['question'] = "This proposal will enable the college to repurpose out-of-date and underutilized spaces to meet the needs of today's students and for future generations.";
    item['questionGroup'] = "7";
    item['questionKey'] = "33";
    item.key = key++;
    
    item = {};
    this.items.push(item);
    item['support'] = "71.7";
    item['district'] = "Afton 101";
    item['totalSupport'] = "8,807";
    item['question'] = "As asked earlier, assume that your local community college is seeking an 8-cent increase in property taxes to support improved facilities and programs. If you were voting today, is that something you would . . .";
    item['questionGroup'] = "7";
    item['questionKey'] = "34";
    item.key = key++;
    
    item = {};
    this.items.push(item);
    item['support'] = "64.8";
    item['district'] = "Bayless";
    item['question'] = "Thinking about the next two or three months, do you think the economy in your area will . .";
    item['questionGroup'] = "1";
    item['questionKey'] = "1";
    item.key = key++;
    
    item = {};
    this.items.push(item);
    item['support'] = "79.8";
    item['district'] = "Bayless";
    item['question'] = "Students are often given the grades A, B, C, D, and F to evaluate their work. What grade would you give the community college in your area?";
    item['questionGroup'] = "1";
    item['questionKey'] = "2";
    item.key = key++;
    
    item = {};
    this.items.push(item);
    item['support'] = "43.8";
    item['district'] = "Bayless";
    item['question'] = "Assume the community college in your area seeks voter approval for an 8-cent increase in property taxes to support improved facilities and programs. If you were voting today, is that something you .";
    item['questionGroup'] = "1";
    item['questionKey'] = "3";
    item.key = key++;
    
    item = {};
    this.items.push(item);
    item['support'] = "27.8";
    item['district'] = "Bayless";
    item['question'] = "If you knew this proposal would cost about $1.90 per month for a $150,000 house, is this proposal something you . . .";
    item['questionGroup'] = "1";
    item['questionKey'] = "4";
    item.key = key++;
    
    item = {};
    this.items.push(item);
    item['support'] = "80";
    item['district'] = "Bayless";
    item['question'] = "It is essential that our area has a quality community college as a place for adults to be trained and retrained for the good paying jobs of today and tomorrow.";
    item['questionGroup'] = "1";
    item['questionKey'] = "5";
    item.key = key++;
    
    item = {};
    this.items.push(item);
    item['support'] = "64.1";
    item['district'] = "Bayless";
    item['question'] = "People like me can't afford to pay higher taxes, no matter how good the cause.";
    item['questionGroup'] = "2";
    item['questionKey'] = "6";
    item.key = key++;
    
    item = {};
    this.items.push(item);
    item['support'] = "74.8";
    item['district'] = "Bayless";
    item['question'] = "We get excellent value from what we pay to support our community college.";
    item['questionGroup'] = "2";
    item['questionKey'] = "7";
    item.key = key++;
    
    item = {};
    this.items.push(item);
    item['support'] = "35.3";
    item['district'] = "Bayless";
    item['question'] = "The local community college does not serve the needs of people like me and my family.";
    item['questionGroup'] = "2";
    item['questionKey'] = "8";
    item.key = key++;
    
    item = {};
    this.items.push(item);
    item['support'] = "46.4";
    item['district'] = "Bayless";
    item['question'] = "I'm willing to pay more to support the community college's efforts to help our area economically recover from the COVID-19 pandemic.";
    item['questionGroup'] = "2";
    item['questionKey'] = "9";
    item.key = key++;
    
    item = {};
    this.items.push(item);
    item['support'] = "71.3";
    item['district'] = "Bayless";
    item['question'] = "Our community college is an important anchor for our regional economy.";
    item['questionGroup'] = "2";
    item['questionKey'] = "10";
    item.key = key++;
    
    item = {};
    this.items.push(item);
    item['support'] = "77.7";
    item['district'] = "Bayless";
    item['question'] = "Making sure all of the college's instructional areas are equipped with up-to-date instructional technology.";
    item['questionGroup'] = "3";
    item['questionKey'] = "11";
    item.key = key++;
    
    item = {};
    this.items.push(item);
    item['support'] = "60.5";
    item['district'] = "Bayless";
    item['question'] = "Replacing buildings that are more than a half-century old, and are expensive and inefficient to operate and maintain.";
    item['questionGroup'] = "3";
    item['questionKey'] = "12";
    item.key = key++;
    
    item = {};
    this.items.push(item);
    item['support'] = "78.5";
    item['district'] = "Bayless";
    item['question'] = "Providing learning spaces to accommodate best practices in job training and retraining programs.";
    item['questionGroup'] = "3";
    item['questionKey'] = "13";
    item.key = key++;
    
    item = {};
    this.items.push(item);
    item['support'] = "75.4";
    item['district'] = "Bayless";
    item['question'] = "Repairing and replacing aging roofs, doors, windows, plumbing, HVAC, electrical and other infrastructure conditions as needed.";
    item['questionGroup'] = "3";
    item['questionKey'] = "14";
    item.key = key++;
    
    item = {};
    this.items.push(item);
    item['support'] = "80";
    item['district'] = "Bayless";
    item['question'] = "Providing job training and retraining facilities and programs for good paying, high-in-demand, jobs in areas like healthcare, information technology (IT) and financial services.";
    item['questionGroup'] = "3";
    item['questionKey'] = "15";
    item.key = key++;
    
    item = {};
    this.items.push(item);
    item['support'] = "80";
    item['district'] = "Bayless";
    item['question'] = "Making sure the campus is safe and secure both night and day.";
    item['questionGroup'] = "4";
    item['questionKey'] = "16";
    item.key = key++;
    
    item = {};
    this.items.push(item);
    item['support'] = "80";
    item['district'] = "Bayless";
    item['question'] = "Keeping community college programs affordable.";
    item['questionGroup'] = "4";
    item['questionKey'] = "17";
    item.key = key++;
    
    item = {};
    this.items.push(item);
    item['support'] = "79.5";
    item['district'] = "Bayless";
    item['question'] = "Improving training programs so those who are underemployed and unemployed can secure jobs that pay a decent salary.";
    item['questionGroup'] = "4";
    item['questionKey'] = "18";
    item.key = key++;
    
    item = {};
    this.items.push(item);
    item['support'] = "76.6";
    item['district'] = "Bayless";
    item['question'] = "Making sure student support services such as registration and financial services are centralized and easily available to students.";
    item['questionGroup'] = "4";
    item['questionKey'] = "19";
    item.key = key++;
    
    item = {};
    this.items.push(item);
    item['support'] = "76.2";
    item['district'] = "Bayless";
    item['question'] = "Coordinating programs with area economic planners and businesses to make sure well-trained employees are available to help stimulate business growth and relocation in our community.";
    item['questionGroup'] = "4";
    item['questionKey'] = "20";
    item.key = key++;
    
    item = {};
    this.items.push(item);
    item['support'] = "79.2";
    item['district'] = "Bayless";
    item['question'] = "Providing real-world learning environments that provide students with the skills they need in the workplace.";
    item['questionGroup'] = "5";
    item['questionKey'] = "21";
    item.key = key++;
    
    item = {};
    this.items.push(item);
    item['support'] = "78.9";
    item['district'] = "Bayless";
    item['question'] = "Improving ADA accessibility at all campuses for students with disabilities.";
    item['questionGroup'] = "5";
    item['questionKey'] = "22";
    item.key = key++;
    
    item = {};
    this.items.push(item);
    item['support'] = "80";
    item['district'] = "Bayless";
    item['question'] = "We must ensure that our community college has adequate facilities and programs to meet the demand for high tech job training programs.";
    item['questionGroup'] = "5";
    item['questionKey'] = "23";
    item.key = key++;
    
    item = {};
    this.items.push(item);
    item['support'] = "59.4";
    item['district'] = "Bayless";
    item['question'] = "If the college needs more money, it should raise student fees, not increase the money it receives from taxes.";
    item['questionGroup'] = "5";
    item['questionKey'] = "24";
    item.key = key++;
    
    item = {};
    this.items.push(item);
    item['support'] = "79.7";
    item['district'] = "Bayless";
    item['question'] = "We must protect the investment we've made in our community college by keeping our programming current and our facilities up-to-date in good working condition.";
    item['questionGroup'] = "5";
    item['questionKey'] = "25";
    item.key = key++;
    
    item = {};
    this.items.push(item);
    item['support'] = "69.9";
    item['district'] = "Bayless";
    item['question'] = "Our community college is important to our area. But in terms of receiving more financial support, there are other things that are just more important.";
    item['questionGroup'] = "6";
    item['questionKey'] = "26";
    item.key = key++;
    
    item = {};
    this.items.push(item);
    item['support'] = "79.2";
    item['district'] = "Bayless";
    item['question'] = "Following more than a year of COVID-19, it is more important than ever to support the career education and training programs at our community college.";
    item['questionGroup'] = "6";
    item['questionKey'] = "27";
    item.key = key++;
    
    item = {};
    this.items.push(item);
    item['support'] = "73";
    item['district'] = "Bayless";
    item['question'] = "Unless we keep the college's programs and facilities up-to-date, many area workers will not be able to get the training they need to prepare for the good paying jobs of today and tomorrow, and that will hurt the local economy.";
    item['questionGroup'] = "6";
    item['questionKey'] = "28";
    item.key = key++;
    
    item = {};
    this.items.push(item);
    item['support'] = "78.5";
    item['district'] = "Bayless";
    item['question'] = "The community college's career education programs help create an educated workforce. That leads to an improved economy, and in the long term, that lowers local taxes. Supporting the community just makes financial sense.";
    item['questionGroup'] = "6";
    item['questionKey'] = "29";
    item.key = key++;
    
    item = {};
    this.items.push(item);
    item['support'] = "61.5";
    item['district'] = "Bayless";
    item['question'] = "I just don't trust local leadership with additional public dollars.";
    item['questionGroup'] = "6";
    item['questionKey'] = "30";
    item.key = key++;
    
    item = {};
    this.items.push(item);
    item['support'] = "73.3";
    item['district'] = "Bayless";
    item['question'] = "Right now there are thousands of good-paying jobs in our area that remain unfilled because businesses can't find employees with the necessary skills. This proposal helps train local residents for these jobs and keep them in our area.";
    item['questionGroup'] = "7";
    item['questionKey'] = "31";
    item.key = key++;
    
    item = {};
    this.items.push(item);
    item['support'] = "77.8";
    item['district'] = "Bayless";
    item['question'] = "Many businesses can't grow, or move to our area, because of the shortage of a well-trained workforce. Our economic future depends on supporting job training and retraining programs at our community college.";
    item['questionGroup'] = "7";
    item['questionKey'] = "32";
    item.key = key++;
    
    item = {};
    this.items.push(item);
    item['support'] = "50.6";
    item['district'] = "Bayless";
    item['question'] = "This proposal will enable the college to repurpose out-of-date and underutilized spaces to meet the needs of today's students and for future generations.";
    item['questionGroup'] = "7";
    item['questionKey'] = "33";
    item.key = key++;
    
    item = {};
    this.items.push(item);
    item['support'] = "70.3";
    item['district'] = "Bayless";
    item['question'] = "As asked earlier, assume that your local community college is seeking an 8-cent increase in property taxes to support improved facilities and programs. If you were voting today, is that something you would . . .";
    item['questionGroup'] = "7";
    item['questionKey'] = "34";
    item.key = key++;
    
    item = {};
    this.items.push(item);
    item['support'] = "67.2";
    item['district'] = "Brentwood";
    item['question'] = "Thinking about the next two or three months, do you think the economy in your area will . .";
    item['questionGroup'] = "1";
    item['questionKey'] = "1";
    item.key = key++;
    
    item = {};
    this.items.push(item);
    item['support'] = "80";
    item['district'] = "Brentwood";
    item['question'] = "Students are often given the grades A, B, C, D, and F to evaluate their work. What grade would you give the community college in your area?";
    item['questionGroup'] = "1";
    item['questionKey'] = "2";
    item.key = key++;
    
    item = {};
    this.items.push(item);
    item['support'] = "45.2";
    item['district'] = "Brentwood";
    item['question'] = "Assume the community college in your area seeks voter approval for an 8-cent increase in property taxes to support improved facilities and programs. If you were voting today, is that something you .";
    item['questionGroup'] = "1";
    item['questionKey'] = "3";
    item.key = key++;
    
    item = {};
    this.items.push(item);
    item['support'] = "26.5";
    item['district'] = "Brentwood";
    item['question'] = "If you knew this proposal would cost about $1.90 per month for a $150,000 house, is this proposal something you . . .";
    item['questionGroup'] = "1";
    item['questionKey'] = "4";
    item.key = key++;
    
    item = {};
    this.items.push(item);
    item['support'] = "80";
    item['district'] = "Brentwood";
    item['question'] = "It is essential that our area has a quality community college as a place for adults to be trained and retrained for the good paying jobs of today and tomorrow.";
    item['questionGroup'] = "1";
    item['questionKey'] = "5";
    item.key = key++;
    
    item = {};
    this.items.push(item);
    item['support'] = "56.4";
    item['district'] = "Brentwood";
    item['question'] = "People like me can't afford to pay higher taxes, no matter how good the cause.";
    item['questionGroup'] = "2";
    item['questionKey'] = "6";
    item.key = key++;
    
    item = {};
    this.items.push(item);
    item['support'] = "76.2";
    item['district'] = "Brentwood";
    item['question'] = "We get excellent value from what we pay to support our community college.";
    item['questionGroup'] = "2";
    item['questionKey'] = "7";
    item.key = key++;
    
    item = {};
    this.items.push(item);
    item['support'] = "38.1";
    item['district'] = "Brentwood";
    item['question'] = "The local community college does not serve the needs of people like me and my family.";
    item['questionGroup'] = "2";
    item['questionKey'] = "8";
    item.key = key++;
    
    item = {};
    this.items.push(item);
    item['support'] = "48.1";
    item['district'] = "Brentwood";
    item['question'] = "I'm willing to pay more to support the community college's efforts to help our area economically recover from the COVID-19 pandemic.";
    item['questionGroup'] = "2";
    item['questionKey'] = "9";
    item.key = key++;
    
    item = {};
    this.items.push(item);
    item['support'] = "71.7";
    item['district'] = "Brentwood";
    item['question'] = "Our community college is an important anchor for our regional economy.";
    item['questionGroup'] = "2";
    item['questionKey'] = "10";
    item.key = key++;
    
    item = {};
    this.items.push(item);
    item['support'] = "79.4";
    item['district'] = "Brentwood";
    item['question'] = "Making sure all of the college's instructional areas are equipped with up-to-date instructional technology.";
    item['questionGroup'] = "3";
    item['questionKey'] = "11";
    item.key = key++;
    
    item = {};
    this.items.push(item);
    item['support'] = "61.8";
    item['district'] = "Brentwood";
    item['question'] = "Replacing buildings that are more than a half-century old, and are expensive and inefficient to operate and maintain.";
    item['questionGroup'] = "3";
    item['questionKey'] = "12";
    item.key = key++;
    
    item = {};
    this.items.push(item);
    item['support'] = "78.8";
    item['district'] = "Brentwood";
    item['question'] = "Providing learning spaces to accommodate best practices in job training and retraining programs.";
    item['questionGroup'] = "3";
    item['questionKey'] = "13";
    item.key = key++;
    
    item = {};
    this.items.push(item);
    item['support'] = "77.4";
    item['district'] = "Brentwood";
    item['question'] = "Repairing and replacing aging roofs, doors, windows, plumbing, HVAC, electrical and other infrastructure conditions as needed.";
    item['questionGroup'] = "3";
    item['questionKey'] = "14";
    item.key = key++;
    
    item = {};
    this.items.push(item);
    item['support'] = "80";
    item['district'] = "Brentwood";
    item['question'] = "Providing job training and retraining facilities and programs for good paying, high-in-demand, jobs in areas like healthcare, information technology (IT) and financial services.";
    item['questionGroup'] = "3";
    item['questionKey'] = "15";
    item.key = key++;
    
    item = {};
    this.items.push(item);
    item['support'] = "80";
    item['district'] = "Brentwood";
    item['question'] = "Making sure the campus is safe and secure both night and day.";
    item['questionGroup'] = "4";
    item['questionKey'] = "16";
    item.key = key++;
    
    item = {};
    this.items.push(item);
    item['support'] = "80";
    item['district'] = "Brentwood";
    item['question'] = "Keeping community college programs affordable.";
    item['questionGroup'] = "4";
    item['questionKey'] = "17";
    item.key = key++;
    
    item = {};
    this.items.push(item);
    item['support'] = "79.9";
    item['district'] = "Brentwood";
    item['question'] = "Improving training programs so those who are underemployed and unemployed can secure jobs that pay a decent salary.";
    item['questionGroup'] = "4";
    item['questionKey'] = "18";
    item.key = key++;
    
    item = {};
    this.items.push(item);
    item['support'] = "74";
    item['district'] = "Brentwood";
    item['question'] = "Making sure student support services such as registration and financial services are centralized and easily available to students.";
    item['questionGroup'] = "4";
    item['questionKey'] = "19";
    item.key = key++;
    
    item = {};
    this.items.push(item);
    item['support'] = "77.9";
    item['district'] = "Brentwood";
    item['question'] = "Coordinating programs with area economic planners and businesses to make sure well-trained employees are available to help stimulate business growth and relocation in our community.";
    item['questionGroup'] = "4";
    item['questionKey'] = "20";
    item.key = key++;
    
    item = {};
    this.items.push(item);
    item['support'] = "79.8";
    item['district'] = "Brentwood";
    item['question'] = "Providing real-world learning environments that provide students with the skills they need in the workplace.";
    item['questionGroup'] = "5";
    item['questionKey'] = "21";
    item.key = key++;
    
    item = {};
    this.items.push(item);
    item['support'] = "79.5";
    item['district'] = "Brentwood";
    item['question'] = "Improving ADA accessibility at all campuses for students with disabilities.";
    item['questionGroup'] = "5";
    item['questionKey'] = "22";
    item.key = key++;
    
    item = {};
    this.items.push(item);
    item['support'] = "80";
    item['district'] = "Brentwood";
    item['question'] = "We must ensure that our community college has adequate facilities and programs to meet the demand for high tech job training programs.";
    item['questionGroup'] = "5";
    item['questionKey'] = "23";
    item.key = key++;
    
    item = {};
    this.items.push(item);
    item['support'] = "61.6";
    item['district'] = "Brentwood";
    item['question'] = "If the college needs more money, it should raise student fees, not increase the money it receives from taxes.";
    item['questionGroup'] = "5";
    item['questionKey'] = "24";
    item.key = key++;
    
    item = {};
    this.items.push(item);
    item['support'] = "79.9";
    item['district'] = "Brentwood";
    item['question'] = "We must protect the investment we've made in our community college by keeping our programming current and our facilities up-to-date in good working condition.";
    item['questionGroup'] = "5";
    item['questionKey'] = "25";
    item.key = key++;
    
    item = {};
    this.items.push(item);
    item['support'] = "71.6";
    item['district'] = "Brentwood";
    item['question'] = "Our community college is important to our area. But in terms of receiving more financial support, there are other things that are just more important.";
    item['questionGroup'] = "6";
    item['questionKey'] = "26";
    item.key = key++;
    
    item = {};
    this.items.push(item);
    item['support'] = "79.1";
    item['district'] = "Brentwood";
    item['question'] = "Following more than a year of COVID-19, it is more important than ever to support the career education and training programs at our community college.";
    item['questionGroup'] = "6";
    item['questionKey'] = "27";
    item.key = key++;
    
    item = {};
    this.items.push(item);
    item['support'] = "75";
    item['district'] = "Brentwood";
    item['question'] = "Unless we keep the college's programs and facilities up-to-date, many area workers will not be able to get the training they need to prepare for the good paying jobs of today and tomorrow, and that will hurt the local economy.";
    item['questionGroup'] = "6";
    item['questionKey'] = "28";
    item.key = key++;
    
    item = {};
    this.items.push(item);
    item['support'] = "77.9";
    item['district'] = "Brentwood";
    item['question'] = "The community college's career education programs help create an educated workforce. That leads to an improved economy, and in the long term, that lowers local taxes. Supporting the community just makes financial sense.";
    item['questionGroup'] = "6";
    item['questionKey'] = "29";
    item.key = key++;
    
    item = {};
    this.items.push(item);
    item['support'] = "62.3";
    item['district'] = "Brentwood";
    item['question'] = "I just don't trust local leadership with additional public dollars.";
    item['questionGroup'] = "6";
    item['questionKey'] = "30";
    item.key = key++;
    
    item = {};
    this.items.push(item);
    item['support'] = "74.6";
    item['district'] = "Brentwood";
    item['question'] = "Right now there are thousands of good-paying jobs in our area that remain unfilled because businesses can't find employees with the necessary skills. This proposal helps train local residents for these jobs and keep them in our area.";
    item['questionGroup'] = "7";
    item['questionKey'] = "31";
    item.key = key++;
    
    item = {};
    this.items.push(item);
    item['support'] = "79.4";
    item['district'] = "Brentwood";
    item['question'] = "Many businesses can't grow, or move to our area, because of the shortage of a well-trained workforce. Our economic future depends on supporting job training and retraining programs at our community college.";
    item['questionGroup'] = "7";
    item['questionKey'] = "32";
    item.key = key++;
    
    item = {};
    this.items.push(item);
    item['support'] = "51";
    item['district'] = "Brentwood";
    item['question'] = "This proposal will enable the college to repurpose out-of-date and underutilized spaces to meet the needs of today's students and for future generations.";
    item['questionGroup'] = "7";
    item['questionKey'] = "33";
    item.key = key++;
    
    item = {};
    this.items.push(item);
    item['support'] = "72.8";
    item['district'] = "Brentwood";
    item['question'] = "As asked earlier, assume that your local community college is seeking an 8-cent increase in property taxes to support improved facilities and programs. If you were voting today, is that something you would . . .";
    item['questionGroup'] = "7";
    item['questionKey'] = "34";
    item.key = key++;
    
    item = {};
    this.items.push(item);
    item['support'] = "64.1";
    item['district'] = "Clayton";
    item['question'] = "Thinking about the next two or three months, do you think the economy in your area will . .";
    item['questionGroup'] = "1";
    item['questionKey'] = "1";
    item.key = key++;
    
    item = {};
    this.items.push(item);
    item['support'] = "80";
    item['district'] = "Clayton";
    item['question'] = "Students are often given the grades A, B, C, D, and F to evaluate their work. What grade would you give the community college in your area?";
    item['questionGroup'] = "1";
    item['questionKey'] = "2";
    item.key = key++;
    
    item = {};
    this.items.push(item);
    item['support'] = "42.7";
    item['district'] = "Clayton";
    item['question'] = "Assume the community college in your area seeks voter approval for an 8-cent increase in property taxes to support improved facilities and programs. If you were voting today, is that something you .";
    item['questionGroup'] = "1";
    item['questionKey'] = "3";
    item.key = key++;
    
    item = {};
    this.items.push(item);
    item['support'] = "23.6";
    item['district'] = "Clayton";
    item['question'] = "If you knew this proposal would cost about $1.90 per month for a $150,000 house, is this proposal something you . . .";
    item['questionGroup'] = "1";
    item['questionKey'] = "4";
    item.key = key++;
    
    item = {};
    this.items.push(item);
    item['support'] = "80";
    item['district'] = "Clayton";
    item['question'] = "It is essential that our area has a quality community college as a place for adults to be trained and retrained for the good paying jobs of today and tomorrow.";
    item['questionGroup'] = "1";
    item['questionKey'] = "5";
    item.key = key++;
    
    item = {};
    this.items.push(item);
    item['support'] = "51.6";
    item['district'] = "Clayton";
    item['question'] = "People like me can't afford to pay higher taxes, no matter how good the cause.";
    item['questionGroup'] = "2";
    item['questionKey'] = "6";
    item.key = key++;
    
    item = {};
    this.items.push(item);
    item['support'] = "75.4";
    item['district'] = "Clayton";
    item['question'] = "We get excellent value from what we pay to support our community college.";
    item['questionGroup'] = "2";
    item['questionKey'] = "7";
    item.key = key++;
    
    item = {};
    this.items.push(item);
    item['support'] = "39.1";
    item['district'] = "Clayton";
    item['question'] = "The local community college does not serve the needs of people like me and my family.";
    item['questionGroup'] = "2";
    item['questionKey'] = "8";
    item.key = key++;
    
    item = {};
    this.items.push(item);
    item['support'] = "44.9";
    item['district'] = "Clayton";
    item['question'] = "I'm willing to pay more to support the community college's efforts to help our area economically recover from the COVID-19 pandemic.";
    item['questionGroup'] = "2";
    item['questionKey'] = "9";
    item.key = key++;
    
    item = {};
    this.items.push(item);
    item['support'] = "69.8";
    item['district'] = "Clayton";
    item['question'] = "Our community college is an important anchor for our regional economy.";
    item['questionGroup'] = "2";
    item['questionKey'] = "10";
    item.key = key++;
    
    item = {};
    this.items.push(item);
    item['support'] = "79.7";
    item['district'] = "Clayton";
    item['question'] = "Making sure all of the college's instructional areas are equipped with up-to-date instructional technology.";
    item['questionGroup'] = "3";
    item['questionKey'] = "11";
    item.key = key++;
    
    item = {};
    this.items.push(item);
    item['support'] = "60.9";
    item['district'] = "Clayton";
    item['question'] = "Replacing buildings that are more than a half-century old, and are expensive and inefficient to operate and maintain.";
    item['questionGroup'] = "3";
    item['questionKey'] = "12";
    item.key = key++;
    
    item = {};
    this.items.push(item);
    item['support'] = "79.3";
    item['district'] = "Clayton";
    item['question'] = "Providing learning spaces to accommodate best practices in job training and retraining programs.";
    item['questionGroup'] = "3";
    item['questionKey'] = "13";
    item.key = key++;
    
    item = {};
    this.items.push(item);
    item['support'] = "78.8";
    item['district'] = "Clayton";
    item['question'] = "Repairing and replacing aging roofs, doors, windows, plumbing, HVAC, electrical and other infrastructure conditions as needed.";
    item['questionGroup'] = "3";
    item['questionKey'] = "14";
    item.key = key++;
    
    item = {};
    this.items.push(item);
    item['support'] = "80";
    item['district'] = "Clayton";
    item['question'] = "Providing job training and retraining facilities and programs for good paying, high-in-demand, jobs in areas like healthcare, information technology (IT) and financial services.";
    item['questionGroup'] = "3";
    item['questionKey'] = "15";
    item.key = key++;
    
    item = {};
    this.items.push(item);
    item['support'] = "80";
    item['district'] = "Clayton";
    item['question'] = "Making sure the campus is safe and secure both night and day.";
    item['questionGroup'] = "4";
    item['questionKey'] = "16";
    item.key = key++;
    
    item = {};
    this.items.push(item);
    item['support'] = "80";
    item['district'] = "Clayton";
    item['question'] = "Keeping community college programs affordable.";
    item['questionGroup'] = "4";
    item['questionKey'] = "17";
    item.key = key++;
    
    item = {};
    this.items.push(item);
    item['support'] = "79.9";
    item['district'] = "Clayton";
    item['question'] = "Improving training programs so those who are underemployed and unemployed can secure jobs that pay a decent salary.";
    item['questionGroup'] = "4";
    item['questionKey'] = "18";
    item.key = key++;
    
    item = {};
    this.items.push(item);
    item['support'] = "71.5";
    item['district'] = "Clayton";
    item['question'] = "Making sure student support services such as registration and financial services are centralized and easily available to students.";
    item['questionGroup'] = "4";
    item['questionKey'] = "19";
    item.key = key++;
    
    item = {};
    this.items.push(item);
    item['support'] = "78.9";
    item['district'] = "Clayton";
    item['question'] = "Coordinating programs with area economic planners and businesses to make sure well-trained employees are available to help stimulate business growth and relocation in our community.";
    item['questionGroup'] = "4";
    item['questionKey'] = "20";
    item.key = key++;
    
    item = {};
    this.items.push(item);
    item['support'] = "79.9";
    item['district'] = "Clayton";
    item['question'] = "Providing real-world learning environments that provide students with the skills they need in the workplace.";
    item['questionGroup'] = "5";
    item['questionKey'] = "21";
    item.key = key++;
    
    item = {};
    this.items.push(item);
    item['support'] = "79.8";
    item['district'] = "Clayton";
    item['question'] = "Improving ADA accessibility at all campuses for students with disabilities.";
    item['questionGroup'] = "5";
    item['questionKey'] = "22";
    item.key = key++;
    
    item = {};
    this.items.push(item);
    item['support'] = "80";
    item['district'] = "Clayton";
    item['question'] = "We must ensure that our community college has adequate facilities and programs to meet the demand for high tech job training programs.";
    item['questionGroup'] = "5";
    item['questionKey'] = "23";
    item.key = key++;
    
    item = {};
    this.items.push(item);
    item['support'] = "61.6";
    item['district'] = "Clayton";
    item['question'] = "If the college needs more money, it should raise student fees, not increase the money it receives from taxes.";
    item['questionGroup'] = "5";
    item['questionKey'] = "24";
    item.key = key++;
    
    item = {};
    this.items.push(item);
    item['support'] = "80";
    item['district'] = "Clayton";
    item['question'] = "We must protect the investment we've made in our community college by keeping our programming current and our facilities up-to-date in good working condition.";
    item['questionGroup'] = "5";
    item['questionKey'] = "25";
    item.key = key++;
    
    item = {};
    this.items.push(item);
    item['support'] = "72";
    item['district'] = "Clayton";
    item['question'] = "Our community college is important to our area. But in terms of receiving more financial support, there are other things that are just more important.";
    item['questionGroup'] = "6";
    item['questionKey'] = "26";
    item.key = key++;
    
    item = {};
    this.items.push(item);
    item['support'] = "79.7";
    item['district'] = "Clayton";
    item['question'] = "Following more than a year of COVID-19, it is more important than ever to support the career education and training programs at our community college.";
    item['questionGroup'] = "6";
    item['questionKey'] = "27";
    item.key = key++;
    
    item = {};
    this.items.push(item);
    item['support'] = "72.7";
    item['district'] = "Clayton";
    item['question'] = "Unless we keep the college's programs and facilities up-to-date, many area workers will not be able to get the training they need to prepare for the good paying jobs of today and tomorrow, and that will hurt the local economy.";
    item['questionGroup'] = "6";
    item['questionKey'] = "28";
    item.key = key++;
    
    item = {};
    this.items.push(item);
    item['support'] = "73.8";
    item['district'] = "Clayton";
    item['question'] = "The community college's career education programs help create an educated workforce. That leads to an improved economy, and in the long term, that lowers local taxes. Supporting the community just makes financial sense.";
    item['questionGroup'] = "6";
    item['questionKey'] = "29";
    item.key = key++;
    
    item = {};
    this.items.push(item);
    item['support'] = "66.6";
    item['district'] = "Clayton";
    item['question'] = "I just don't trust local leadership with additional public dollars.";
    item['questionGroup'] = "6";
    item['questionKey'] = "30";
    item.key = key++;
    
    item = {};
    this.items.push(item);
    item['support'] = "71.1";
    item['district'] = "Clayton";
    item['question'] = "Right now there are thousands of good-paying jobs in our area that remain unfilled because businesses can't find employees with the necessary skills. This proposal helps train local residents for these jobs and keep them in our area.";
    item['questionGroup'] = "7";
    item['questionKey'] = "31";
    item.key = key++;
    
    item = {};
    this.items.push(item);
    item['support'] = "79.6";
    item['district'] = "Clayton";
    item['question'] = "Many businesses can't grow, or move to our area, because of the shortage of a well-trained workforce. Our economic future depends on supporting job training and retraining programs at our community college.";
    item['questionGroup'] = "7";
    item['questionKey'] = "32";
    item.key = key++;
    
    item = {};
    this.items.push(item);
    item['support'] = "45.6";
    item['district'] = "Clayton";
    item['question'] = "This proposal will enable the college to repurpose out-of-date and underutilized spaces to meet the needs of today's students and for future generations.";
    item['questionGroup'] = "7";
    item['questionKey'] = "33";
    item.key = key++;
    
    item = {};
    this.items.push(item);
    item['support'] = "70.6";
    item['district'] = "Clayton";
    item['question'] = "As asked earlier, assume that your local community college is seeking an 8-cent increase in property taxes to support improved facilities and programs. If you were voting today, is that something you would . . .";
    item['questionGroup'] = "7";
    item['questionKey'] = "34";
    item.key = key++;
    
    item = {};
    this.items.push(item);
    item['support'] = "80";
    item['district'] = "Dallas County R-I";
    item['question'] = "Thinking about the next two or three months, do you think the economy in your area will . .";
    item['questionGroup'] = "1";
    item['questionKey'] = "1";
    item.key = key++;
    
    item = {};
    this.items.push(item);
    item['support'] = "80";
    item['district'] = "Dallas County R-I";
    item['question'] = "Students are often given the grades A, B, C, D, and F to evaluate their work. What grade would you give the community college in your area?";
    item['questionGroup'] = "1";
    item['questionKey'] = "2";
    item.key = key++;
    
    item = {};
    this.items.push(item);
    item['support'] = "40";
    item['district'] = "Dallas County R-I";
    item['question'] = "Assume the community college in your area seeks voter approval for an 8-cent increase in property taxes to support improved facilities and programs. If you were voting today, is that something you .";
    item['questionGroup'] = "1";
    item['questionKey'] = "3";
    item.key = key++;
    
    item = {};
    this.items.push(item);
    item['support'] = "20";
    item['district'] = "Dallas County R-I";
    item['question'] = "If you knew this proposal would cost about $1.90 per month for a $150,000 house, is this proposal something you . . .";
    item['questionGroup'] = "1";
    item['questionKey'] = "4";
    item.key = key++;
    
    item = {};
    this.items.push(item);
    item['support'] = "80";
    item['district'] = "Dallas County R-I";
    item['question'] = "It is essential that our area has a quality community college as a place for adults to be trained and retrained for the good paying jobs of today and tomorrow.";
    item['questionGroup'] = "1";
    item['questionKey'] = "5";
    item.key = key++;
    
    item = {};
    this.items.push(item);
    item['support'] = "60";
    item['district'] = "Dallas County R-I";
    item['question'] = "People like me can't afford to pay higher taxes, no matter how good the cause.";
    item['questionGroup'] = "2";
    item['questionKey'] = "6";
    item.key = key++;
    
    item = {};
    this.items.push(item);
    item['support'] = "80";
    item['district'] = "Dallas County R-I";
    item['question'] = "We get excellent value from what we pay to support our community college.";
    item['questionGroup'] = "2";
    item['questionKey'] = "7";
    item.key = key++;
    
    item = {};
    this.items.push(item);
    item['support'] = "20";
    item['district'] = "Dallas County R-I";
    item['question'] = "The local community college does not serve the needs of people like me and my family.";
    item['questionGroup'] = "2";
    item['questionKey'] = "8";
    item.key = key++;
    
    item = {};
    this.items.push(item);
    item['support'] = "60";
    item['district'] = "Dallas County R-I";
    item['question'] = "I'm willing to pay more to support the community college's efforts to help our area economically recover from the COVID-19 pandemic.";
    item['questionGroup'] = "2";
    item['questionKey'] = "9";
    item.key = key++;
    
    item = {};
    this.items.push(item);
    item['support'] = "80";
    item['district'] = "Dallas County R-I";
    item['question'] = "Our community college is an important anchor for our regional economy.";
    item['questionGroup'] = "2";
    item['questionKey'] = "10";
    item.key = key++;
    
    item = {};
    this.items.push(item);
    item['support'] = "80";
    item['district'] = "Dallas County R-I";
    item['question'] = "Making sure all of the college's instructional areas are equipped with up-to-date instructional technology.";
    item['questionGroup'] = "3";
    item['questionKey'] = "11";
    item.key = key++;
    
    item = {};
    this.items.push(item);
    item['support'] = "60";
    item['district'] = "Dallas County R-I";
    item['question'] = "Replacing buildings that are more than a half-century old, and are expensive and inefficient to operate and maintain.";
    item['questionGroup'] = "3";
    item['questionKey'] = "12";
    item.key = key++;
    
    item = {};
    this.items.push(item);
    item['support'] = "80";
    item['district'] = "Dallas County R-I";
    item['question'] = "Providing learning spaces to accommodate best practices in job training and retraining programs.";
    item['questionGroup'] = "3";
    item['questionKey'] = "13";
    item.key = key++;
    
    item = {};
    this.items.push(item);
    item['support'] = "80";
    item['district'] = "Dallas County R-I";
    item['question'] = "Repairing and replacing aging roofs, doors, windows, plumbing, HVAC, electrical and other infrastructure conditions as needed.";
    item['questionGroup'] = "3";
    item['questionKey'] = "14";
    item.key = key++;
    
    item = {};
    this.items.push(item);
    item['support'] = "80";
    item['district'] = "Dallas County R-I";
    item['question'] = "Providing job training and retraining facilities and programs for good paying, high-in-demand, jobs in areas like healthcare, information technology (IT) and financial services.";
    item['questionGroup'] = "3";
    item['questionKey'] = "15";
    item.key = key++;
    
    item = {};
    this.items.push(item);
    item['support'] = "80";
    item['district'] = "Dallas County R-I";
    item['question'] = "Making sure the campus is safe and secure both night and day.";
    item['questionGroup'] = "4";
    item['questionKey'] = "16";
    item.key = key++;
    
    item = {};
    this.items.push(item);
    item['support'] = "80";
    item['district'] = "Dallas County R-I";
    item['question'] = "Keeping community college programs affordable.";
    item['questionGroup'] = "4";
    item['questionKey'] = "17";
    item.key = key++;
    
    item = {};
    this.items.push(item);
    item['support'] = "80";
    item['district'] = "Dallas County R-I";
    item['question'] = "Improving training programs so those who are underemployed and unemployed can secure jobs that pay a decent salary.";
    item['questionGroup'] = "4";
    item['questionKey'] = "18";
    item.key = key++;
    
    item = {};
    this.items.push(item);
    item['support'] = "80";
    item['district'] = "Dallas County R-I";
    item['question'] = "Making sure student support services such as registration and financial services are centralized and easily available to students.";
    item['questionGroup'] = "4";
    item['questionKey'] = "19";
    item.key = key++;
    
    item = {};
    this.items.push(item);
    item['support'] = "80";
    item['district'] = "Dallas County R-I";
    item['question'] = "Coordinating programs with area economic planners and businesses to make sure well-trained employees are available to help stimulate business growth and relocation in our community.";
    item['questionGroup'] = "4";
    item['questionKey'] = "20";
    item.key = key++;
    
    item = {};
    this.items.push(item);
    item['support'] = "80";
    item['district'] = "Dallas County R-I";
    item['question'] = "Providing real-world learning environments that provide students with the skills they need in the workplace.";
    item['questionGroup'] = "5";
    item['questionKey'] = "21";
    item.key = key++;
    
    item = {};
    this.items.push(item);
    item['support'] = "80";
    item['district'] = "Dallas County R-I";
    item['question'] = "Improving ADA accessibility at all campuses for students with disabilities.";
    item['questionGroup'] = "5";
    item['questionKey'] = "22";
    item.key = key++;
    
    item = {};
    this.items.push(item);
    item['support'] = "80";
    item['district'] = "Dallas County R-I";
    item['question'] = "We must ensure that our community college has adequate facilities and programs to meet the demand for high tech job training programs.";
    item['questionGroup'] = "5";
    item['questionKey'] = "23";
    item.key = key++;
    
    item = {};
    this.items.push(item);
    item['support'] = "60";
    item['district'] = "Dallas County R-I";
    item['question'] = "If the college needs more money, it should raise student fees, not increase the money it receives from taxes.";
    item['questionGroup'] = "5";
    item['questionKey'] = "24";
    item.key = key++;
    
    item = {};
    this.items.push(item);
    item['support'] = "80";
    item['district'] = "Dallas County R-I";
    item['question'] = "We must protect the investment we've made in our community college by keeping our programming current and our facilities up-to-date in good working condition.";
    item['questionGroup'] = "5";
    item['questionKey'] = "25";
    item.key = key++;
    
    item = {};
    this.items.push(item);
    item['support'] = "60";
    item['district'] = "Dallas County R-I";
    item['question'] = "Our community college is important to our area. But in terms of receiving more financial support, there are other things that are just more important.";
    item['questionGroup'] = "6";
    item['questionKey'] = "26";
    item.key = key++;
    
    item = {};
    this.items.push(item);
    item['support'] = "80";
    item['district'] = "Dallas County R-I";
    item['question'] = "Following more than a year of COVID-19, it is more important than ever to support the career education and training programs at our community college.";
    item['questionGroup'] = "6";
    item['questionKey'] = "27";
    item.key = key++;
    
    item = {};
    this.items.push(item);
    item['support'] = "80";
    item['district'] = "Dallas County R-I";
    item['question'] = "Unless we keep the college's programs and facilities up-to-date, many area workers will not be able to get the training they need to prepare for the good paying jobs of today and tomorrow, and that will hurt the local economy.";
    item['questionGroup'] = "6";
    item['questionKey'] = "28";
    item.key = key++;
    
    item = {};
    this.items.push(item);
    item['support'] = "80";
    item['district'] = "Dallas County R-I";
    item['question'] = "The community college's career education programs help create an educated workforce. That leads to an improved economy, and in the long term, that lowers local taxes. Supporting the community just makes financial sense.";
    item['questionGroup'] = "6";
    item['questionKey'] = "29";
    item.key = key++;
    
    item = {};
    this.items.push(item);
    item['support'] = "40";
    item['district'] = "Dallas County R-I";
    item['question'] = "I just don't trust local leadership with additional public dollars.";
    item['questionGroup'] = "6";
    item['questionKey'] = "30";
    item.key = key++;
    
    item = {};
    this.items.push(item);
    item['support'] = "80";
    item['district'] = "Dallas County R-I";
    item['question'] = "Right now there are thousands of good-paying jobs in our area that remain unfilled because businesses can't find employees with the necessary skills. This proposal helps train local residents for these jobs and keep them in our area.";
    item['questionGroup'] = "7";
    item['questionKey'] = "31";
    item.key = key++;
    
    item = {};
    this.items.push(item);
    item['support'] = "80";
    item['district'] = "Dallas County R-I";
    item['question'] = "Many businesses can't grow, or move to our area, because of the shortage of a well-trained workforce. Our economic future depends on supporting job training and retraining programs at our community college.";
    item['questionGroup'] = "7";
    item['questionKey'] = "32";
    item.key = key++;
    
    item = {};
    this.items.push(item);
    item['support'] = "60";
    item['district'] = "Dallas County R-I";
    item['question'] = "This proposal will enable the college to repurpose out-of-date and underutilized spaces to meet the needs of today's students and for future generations.";
    item['questionGroup'] = "7";
    item['questionKey'] = "33";
    item.key = key++;
    
    item = {};
    this.items.push(item);
    item['support'] = "80";
    item['district'] = "Dallas County R-I";
    item['question'] = "As asked earlier, assume that your local community college is seeking an 8-cent increase in property taxes to support improved facilities and programs. If you were voting today, is that something you would . . .";
    item['questionGroup'] = "7";
    item['questionKey'] = "34";
    item.key = key++;
    
    item = {};
    this.items.push(item);
    item['support'] = "65";
    item['district'] = "Ferguson-Florissant R-II";
    item['question'] = "Thinking about the next two or three months, do you think the economy in your area will . .";
    item['questionGroup'] = "1";
    item['questionKey'] = "1";
    item.key = key++;
    
    item = {};
    this.items.push(item);
    item['support'] = "79.8";
    item['district'] = "Ferguson-Florissant R-II";
    item['question'] = "Students are often given the grades A, B, C, D, and F to evaluate their work. What grade would you give the community college in your area?";
    item['questionGroup'] = "1";
    item['questionKey'] = "2";
    item.key = key++;
    
    item = {};
    this.items.push(item);
    item['support'] = "43.1";
    item['district'] = "Ferguson-Florissant R-II";
    item['question'] = "Assume the community college in your area seeks voter approval for an 8-cent increase in property taxes to support improved facilities and programs. If you were voting today, is that something you .";
    item['questionGroup'] = "1";
    item['questionKey'] = "3";
    item.key = key++;
    
    item = {};
    this.items.push(item);
    item['support'] = "27.9";
    item['district'] = "Ferguson-Florissant R-II";
    item['question'] = "If you knew this proposal would cost about $1.90 per month for a $150,000 house, is this proposal something you . . .";
    item['questionGroup'] = "1";
    item['questionKey'] = "4";
    item.key = key++;
    
    item = {};
    this.items.push(item);
    item['support'] = "80";
    item['district'] = "Ferguson-Florissant R-II";
    item['question'] = "It is essential that our area has a quality community college as a place for adults to be trained and retrained for the good paying jobs of today and tomorrow.";
    item['questionGroup'] = "1";
    item['questionKey'] = "5";
    item.key = key++;
    
    item = {};
    this.items.push(item);
    item['support'] = "64.4";
    item['district'] = "Ferguson-Florissant R-II";
    item['question'] = "People like me can't afford to pay higher taxes, no matter how good the cause.";
    item['questionGroup'] = "2";
    item['questionKey'] = "6";
    item.key = key++;
    
    item = {};
    this.items.push(item);
    item['support'] = "75.3";
    item['district'] = "Ferguson-Florissant R-II";
    item['question'] = "We get excellent value from what we pay to support our community college.";
    item['questionGroup'] = "2";
    item['questionKey'] = "7";
    item.key = key++;
    
    item = {};
    this.items.push(item);
    item['support'] = "35.5";
    item['district'] = "Ferguson-Florissant R-II";
    item['question'] = "The local community college does not serve the needs of people like me and my family.";
    item['questionGroup'] = "2";
    item['questionKey'] = "8";
    item.key = key++;
    
    item = {};
    this.items.push(item);
    item['support'] = "46.4";
    item['district'] = "Ferguson-Florissant R-II";
    item['question'] = "I'm willing to pay more to support the community college's efforts to help our area economically recover from the COVID-19 pandemic.";
    item['questionGroup'] = "2";
    item['questionKey'] = "9";
    item.key = key++;
    
    item = {};
    this.items.push(item);
    item['support'] = "71.6";
    item['district'] = "Ferguson-Florissant R-II";
    item['question'] = "Our community college is an important anchor for our regional economy.";
    item['questionGroup'] = "2";
    item['questionKey'] = "10";
    item.key = key++;
    
    item = {};
    this.items.push(item);
    item['support'] = "78";
    item['district'] = "Ferguson-Florissant R-II";
    item['question'] = "Making sure all of the college's instructional areas are equipped with up-to-date instructional technology.";
    item['questionGroup'] = "3";
    item['questionKey'] = "11";
    item.key = key++;
    
    item = {};
    this.items.push(item);
    item['support'] = "60.4";
    item['district'] = "Ferguson-Florissant R-II";
    item['question'] = "Replacing buildings that are more than a half-century old, and are expensive and inefficient to operate and maintain.";
    item['questionGroup'] = "3";
    item['questionKey'] = "12";
    item.key = key++;
    
    item = {};
    this.items.push(item);
    item['support'] = "78.3";
    item['district'] = "Ferguson-Florissant R-II";
    item['question'] = "Providing learning spaces to accommodate best practices in job training and retraining programs.";
    item['questionGroup'] = "3";
    item['questionKey'] = "13";
    item.key = key++;
    
    item = {};
    this.items.push(item);
    item['support'] = "75.2";
    item['district'] = "Ferguson-Florissant R-II";
    item['question'] = "Repairing and replacing aging roofs, doors, windows, plumbing, HVAC, electrical and other infrastructure conditions as needed.";
    item['questionGroup'] = "3";
    item['questionKey'] = "14";
    item.key = key++;
    
    item = {};
    this.items.push(item);
    item['support'] = "80";
    item['district'] = "Ferguson-Florissant R-II";
    item['question'] = "Providing job training and retraining facilities and programs for good paying, high-in-demand, jobs in areas like healthcare, information technology (IT) and financial services.";
    item['questionGroup'] = "3";
    item['questionKey'] = "15";
    item.key = key++;
    
    item = {};
    this.items.push(item);
    item['support'] = "80";
    item['district'] = "Ferguson-Florissant R-II";
    item['question'] = "Making sure the campus is safe and secure both night and day.";
    item['questionGroup'] = "4";
    item['questionKey'] = "16";
    item.key = key++;
    
    item = {};
    this.items.push(item);
    item['support'] = "80";
    item['district'] = "Ferguson-Florissant R-II";
    item['question'] = "Keeping community college programs affordable.";
    item['questionGroup'] = "4";
    item['questionKey'] = "17";
    item.key = key++;
    
    item = {};
    this.items.push(item);
    item['support'] = "79.7";
    item['district'] = "Ferguson-Florissant R-II";
    item['question'] = "Improving training programs so those who are underemployed and unemployed can secure jobs that pay a decent salary.";
    item['questionGroup'] = "4";
    item['questionKey'] = "18";
    item.key = key++;
    
    item = {};
    this.items.push(item);
    item['support'] = "77";
    item['district'] = "Ferguson-Florissant R-II";
    item['question'] = "Making sure student support services such as registration and financial services are centralized and easily available to students.";
    item['questionGroup'] = "4";
    item['questionKey'] = "19";
    item.key = key++;
    
    item = {};
    this.items.push(item);
    item['support'] = "76.2";
    item['district'] = "Ferguson-Florissant R-II";
    item['question'] = "Coordinating programs with area economic planners and businesses to make sure well-trained employees are available to help stimulate business growth and relocation in our community.";
    item['questionGroup'] = "4";
    item['questionKey'] = "20";
    item.key = key++;
    
    item = {};
    this.items.push(item);
    item['support'] = "79.4";
    item['district'] = "Ferguson-Florissant R-II";
    item['question'] = "Providing real-world learning environments that provide students with the skills they need in the workplace.";
    item['questionGroup'] = "5";
    item['questionKey'] = "21";
    item.key = key++;
    
    item = {};
    this.items.push(item);
    item['support'] = "79.2";
    item['district'] = "Ferguson-Florissant R-II";
    item['question'] = "Improving ADA accessibility at all campuses for students with disabilities.";
    item['questionGroup'] = "5";
    item['questionKey'] = "22";
    item.key = key++;
    
    item = {};
    this.items.push(item);
    item['support'] = "80";
    item['district'] = "Ferguson-Florissant R-II";
    item['question'] = "We must ensure that our community college has adequate facilities and programs to meet the demand for high tech job training programs.";
    item['questionGroup'] = "5";
    item['questionKey'] = "23";
    item.key = key++;
    
    item = {};
    this.items.push(item);
    item['support'] = "59.4";
    item['district'] = "Ferguson-Florissant R-II";
    item['question'] = "If the college needs more money, it should raise student fees, not increase the money it receives from taxes.";
    item['questionGroup'] = "5";
    item['questionKey'] = "24";
    item.key = key++;
    
    item = {};
    this.items.push(item);
    item['support'] = "79.9";
    item['district'] = "Ferguson-Florissant R-II";
    item['question'] = "We must protect the investment we've made in our community college by keeping our programming current and our facilities up-to-date in good working condition.";
    item['questionGroup'] = "5";
    item['questionKey'] = "25";
    item.key = key++;
    
    item = {};
    this.items.push(item);
    item['support'] = "69.2";
    item['district'] = "Ferguson-Florissant R-II";
    item['question'] = "Our community college is important to our area. But in terms of receiving more financial support, there are other things that are just more important.";
    item['questionGroup'] = "6";
    item['questionKey'] = "26";
    item.key = key++;
    
    item = {};
    this.items.push(item);
    item['support'] = "79.4";
    item['district'] = "Ferguson-Florissant R-II";
    item['question'] = "Following more than a year of COVID-19, it is more important than ever to support the career education and training programs at our community college.";
    item['questionGroup'] = "6";
    item['questionKey'] = "27";
    item.key = key++;
    
    item = {};
    this.items.push(item);
    item['support'] = "73.2";
    item['district'] = "Ferguson-Florissant R-II";
    item['question'] = "Unless we keep the college's programs and facilities up-to-date, many area workers will not be able to get the training they need to prepare for the good paying jobs of today and tomorrow, and that will hurt the local economy.";
    item['questionGroup'] = "6";
    item['questionKey'] = "28";
    item.key = key++;
    
    item = {};
    this.items.push(item);
    item['support'] = "78.9";
    item['district'] = "Ferguson-Florissant R-II";
    item['question'] = "The community college's career education programs help create an educated workforce. That leads to an improved economy, and in the long term, that lowers local taxes. Supporting the community just makes financial sense.";
    item['questionGroup'] = "6";
    item['questionKey'] = "29";
    item.key = key++;
    
    item = {};
    this.items.push(item);
    item['support'] = "61.4";
    item['district'] = "Ferguson-Florissant R-II";
    item['question'] = "I just don't trust local leadership with additional public dollars.";
    item['questionGroup'] = "6";
    item['questionKey'] = "30";
    item.key = key++;
    
    item = {};
    this.items.push(item);
    item['support'] = "73.9";
    item['district'] = "Ferguson-Florissant R-II";
    item['question'] = "Right now there are thousands of good-paying jobs in our area that remain unfilled because businesses can't find employees with the necessary skills. This proposal helps train local residents for these jobs and keep them in our area.";
    item['questionGroup'] = "7";
    item['questionKey'] = "31";
    item.key = key++;
    
    item = {};
    this.items.push(item);
    item['support'] = "77.2";
    item['district'] = "Ferguson-Florissant R-II";
    item['question'] = "Many businesses can't grow, or move to our area, because of the shortage of a well-trained workforce. Our economic future depends on supporting job training and retraining programs at our community college.";
    item['questionGroup'] = "7";
    item['questionKey'] = "32";
    item.key = key++;
    
    item = {};
    this.items.push(item);
    item['support'] = "49.9";
    item['district'] = "Ferguson-Florissant R-II";
    item['question'] = "This proposal will enable the college to repurpose out-of-date and underutilized spaces to meet the needs of today's students and for future generations.";
    item['questionGroup'] = "7";
    item['questionKey'] = "33";
    item.key = key++;
    
    item = {};
    this.items.push(item);
    item['support'] = "70.7";
    item['district'] = "Ferguson-Florissant R-II";
    item['question'] = "As asked earlier, assume that your local community college is seeking an 8-cent increase in property taxes to support improved facilities and programs. If you were voting today, is that something you would . . .";
    item['questionGroup'] = "7";
    item['questionKey'] = "34";
    item.key = key++;
    
    item = {};
    this.items.push(item);
    item['support'] = "63.5";
    item['district'] = "Hancock Place";
    item['question'] = "Thinking about the next two or three months, do you think the economy in your area will . .";
    item['questionGroup'] = "1";
    item['questionKey'] = "1";
    item.key = key++;
    
    item = {};
    this.items.push(item);
    item['support'] = "80";
    item['district'] = "Hancock Place";
    item['question'] = "Students are often given the grades A, B, C, D, and F to evaluate their work. What grade would you give the community college in your area?";
    item['questionGroup'] = "1";
    item['questionKey'] = "2";
    item.key = key++;
    
    item = {};
    this.items.push(item);
    item['support'] = "43.1";
    item['district'] = "Hancock Place";
    item['question'] = "Assume the community college in your area seeks voter approval for an 8-cent increase in property taxes to support improved facilities and programs. If you were voting today, is that something you .";
    item['questionGroup'] = "1";
    item['questionKey'] = "3";
    item.key = key++;
    
    item = {};
    this.items.push(item);
    item['support'] = "26.8";
    item['district'] = "Hancock Place";
    item['question'] = "If you knew this proposal would cost about $1.90 per month for a $150,000 house, is this proposal something you . . .";
    item['questionGroup'] = "1";
    item['questionKey'] = "4";
    item.key = key++;
    
    item = {};
    this.items.push(item);
    item['support'] = "80";
    item['district'] = "Hancock Place";
    item['question'] = "It is essential that our area has a quality community college as a place for adults to be trained and retrained for the good paying jobs of today and tomorrow.";
    item['questionGroup'] = "1";
    item['questionKey'] = "5";
    item.key = key++;
    
    item = {};
    this.items.push(item);
    item['support'] = "65.5";
    item['district'] = "Hancock Place";
    item['question'] = "People like me can't afford to pay higher taxes, no matter how good the cause.";
    item['questionGroup'] = "2";
    item['questionKey'] = "6";
    item.key = key++;
    
    item = {};
    this.items.push(item);
    item['support'] = "74.2";
    item['district'] = "Hancock Place";
    item['question'] = "We get excellent value from what we pay to support our community college.";
    item['questionGroup'] = "2";
    item['questionKey'] = "7";
    item.key = key++;
    
    item = {};
    this.items.push(item);
    item['support'] = "35.6";
    item['district'] = "Hancock Place";
    item['question'] = "The local community college does not serve the needs of people like me and my family.";
    item['questionGroup'] = "2";
    item['questionKey'] = "8";
    item.key = key++;
    
    item = {};
    this.items.push(item);
    item['support'] = "46.6";
    item['district'] = "Hancock Place";
    item['question'] = "I'm willing to pay more to support the community college's efforts to help our area economically recover from the COVID-19 pandemic.";
    item['questionGroup'] = "2";
    item['questionKey'] = "9";
    item.key = key++;
    
    item = {};
    this.items.push(item);
    item['support'] = "71";
    item['district'] = "Hancock Place";
    item['question'] = "Our community college is an important anchor for our regional economy.";
    item['questionGroup'] = "2";
    item['questionKey'] = "10";
    item.key = key++;
    
    item = {};
    this.items.push(item);
    item['support'] = "77.7";
    item['district'] = "Hancock Place";
    item['question'] = "Making sure all of the college's instructional areas are equipped with up-to-date instructional technology.";
    item['questionGroup'] = "3";
    item['questionKey'] = "11";
    item.key = key++;
    
    item = {};
    this.items.push(item);
    item['support'] = "60.2";
    item['district'] = "Hancock Place";
    item['question'] = "Replacing buildings that are more than a half-century old, and are expensive and inefficient to operate and maintain.";
    item['questionGroup'] = "3";
    item['questionKey'] = "12";
    item.key = key++;
    
    item = {};
    this.items.push(item);
    item['support'] = "78.9";
    item['district'] = "Hancock Place";
    item['question'] = "Providing learning spaces to accommodate best practices in job training and retraining programs.";
    item['questionGroup'] = "3";
    item['questionKey'] = "13";
    item.key = key++;
    
    item = {};
    this.items.push(item);
    item['support'] = "75.7";
    item['district'] = "Hancock Place";
    item['question'] = "Repairing and replacing aging roofs, doors, windows, plumbing, HVAC, electrical and other infrastructure conditions as needed.";
    item['questionGroup'] = "3";
    item['questionKey'] = "14";
    item.key = key++;
    
    item = {};
    this.items.push(item);
    item['support'] = "80";
    item['district'] = "Hancock Place";
    item['question'] = "Providing job training and retraining facilities and programs for good paying, high-in-demand, jobs in areas like healthcare, information technology (IT) and financial services.";
    item['questionGroup'] = "3";
    item['questionKey'] = "15";
    item.key = key++;
    
    item = {};
    this.items.push(item);
    item['support'] = "80";
    item['district'] = "Hancock Place";
    item['question'] = "Making sure the campus is safe and secure both night and day.";
    item['questionGroup'] = "4";
    item['questionKey'] = "16";
    item.key = key++;
    
    item = {};
    this.items.push(item);
    item['support'] = "80";
    item['district'] = "Hancock Place";
    item['question'] = "Keeping community college programs affordable.";
    item['questionGroup'] = "4";
    item['questionKey'] = "17";
    item.key = key++;
    
    item = {};
    this.items.push(item);
    item['support'] = "79.7";
    item['district'] = "Hancock Place";
    item['question'] = "Improving training programs so those who are underemployed and unemployed can secure jobs that pay a decent salary.";
    item['questionGroup'] = "4";
    item['questionKey'] = "18";
    item.key = key++;
    
    item = {};
    this.items.push(item);
    item['support'] = "77.3";
    item['district'] = "Hancock Place";
    item['question'] = "Making sure student support services such as registration and financial services are centralized and easily available to students.";
    item['questionGroup'] = "4";
    item['questionKey'] = "19";
    item.key = key++;
    
    item = {};
    this.items.push(item);
    item['support'] = "75.9";
    item['district'] = "Hancock Place";
    item['question'] = "Coordinating programs with area economic planners and businesses to make sure well-trained employees are available to help stimulate business growth and relocation in our community.";
    item['questionGroup'] = "4";
    item['questionKey'] = "20";
    item.key = key++;
    
    item = {};
    this.items.push(item);
    item['support'] = "79.5";
    item['district'] = "Hancock Place";
    item['question'] = "Providing real-world learning environments that provide students with the skills they need in the workplace.";
    item['questionGroup'] = "5";
    item['questionKey'] = "21";
    item.key = key++;
    
    item = {};
    this.items.push(item);
    item['support'] = "79.3";
    item['district'] = "Hancock Place";
    item['question'] = "Improving ADA accessibility at all campuses for students with disabilities.";
    item['questionGroup'] = "5";
    item['questionKey'] = "22";
    item.key = key++;
    
    item = {};
    this.items.push(item);
    item['support'] = "80";
    item['district'] = "Hancock Place";
    item['question'] = "We must ensure that our community college has adequate facilities and programs to meet the demand for high tech job training programs.";
    item['questionGroup'] = "5";
    item['questionKey'] = "23";
    item.key = key++;
    
    item = {};
    this.items.push(item);
    item['support'] = "59.1";
    item['district'] = "Hancock Place";
    item['question'] = "If the college needs more money, it should raise student fees, not increase the money it receives from taxes.";
    item['questionGroup'] = "5";
    item['questionKey'] = "24";
    item.key = key++;
    
    item = {};
    this.items.push(item);
    item['support'] = "79.8";
    item['district'] = "Hancock Place";
    item['question'] = "We must protect the investment we've made in our community college by keeping our programming current and our facilities up-to-date in good working condition.";
    item['questionGroup'] = "5";
    item['questionKey'] = "25";
    item.key = key++;
    
    item = {};
    this.items.push(item);
    item['support'] = "69.6";
    item['district'] = "Hancock Place";
    item['question'] = "Our community college is important to our area. But in terms of receiving more financial support, there are other things that are just more important.";
    item['questionGroup'] = "6";
    item['questionKey'] = "26";
    item.key = key++;
    
    item = {};
    this.items.push(item);
    item['support'] = "79.5";
    item['district'] = "Hancock Place";
    item['question'] = "Following more than a year of COVID-19, it is more important than ever to support the career education and training programs at our community college.";
    item['questionGroup'] = "6";
    item['questionKey'] = "27";
    item.key = key++;
    
    item = {};
    this.items.push(item);
    item['support'] = "72.8";
    item['district'] = "Hancock Place";
    item['question'] = "Unless we keep the college's programs and facilities up-to-date, many area workers will not be able to get the training they need to prepare for the good paying jobs of today and tomorrow, and that will hurt the local economy.";
    item['questionGroup'] = "6";
    item['questionKey'] = "28";
    item.key = key++;
    
    item = {};
    this.items.push(item);
    item['support'] = "79";
    item['district'] = "Hancock Place";
    item['question'] = "The community college's career education programs help create an educated workforce. That leads to an improved economy, and in the long term, that lowers local taxes. Supporting the community just makes financial sense.";
    item['questionGroup'] = "6";
    item['questionKey'] = "29";
    item.key = key++;
    
    item = {};
    this.items.push(item);
    item['support'] = "61.1";
    item['district'] = "Hancock Place";
    item['question'] = "I just don't trust local leadership with additional public dollars.";
    item['questionGroup'] = "6";
    item['questionKey'] = "30";
    item.key = key++;
    
    item = {};
    this.items.push(item);
    item['support'] = "73.2";
    item['district'] = "Hancock Place";
    item['question'] = "Right now there are thousands of good-paying jobs in our area that remain unfilled because businesses can't find employees with the necessary skills. This proposal helps train local residents for these jobs and keep them in our area.";
    item['questionGroup'] = "7";
    item['questionKey'] = "31";
    item.key = key++;
    
    item = {};
    this.items.push(item);
    item['support'] = "77.3";
    item['district'] = "Hancock Place";
    item['question'] = "Many businesses can't grow, or move to our area, because of the shortage of a well-trained workforce. Our economic future depends on supporting job training and retraining programs at our community college.";
    item['questionGroup'] = "7";
    item['questionKey'] = "32";
    item.key = key++;
    
    item = {};
    this.items.push(item);
    item['support'] = "50";
    item['district'] = "Hancock Place";
    item['question'] = "This proposal will enable the college to repurpose out-of-date and underutilized spaces to meet the needs of today's students and for future generations.";
    item['questionGroup'] = "7";
    item['questionKey'] = "33";
    item.key = key++;
    
    item = {};
    this.items.push(item);
    item['support'] = "70.5";
    item['district'] = "Hancock Place";
    item['question'] = "As asked earlier, assume that your local community college is seeking an 8-cent increase in property taxes to support improved facilities and programs. If you were voting today, is that something you would . . .";
    item['questionGroup'] = "7";
    item['questionKey'] = "34";
    item.key = key++;
    
    item = {};
    this.items.push(item);
    item['support'] = "66.3";
    item['district'] = "Hazelwood";
    item['question'] = "Thinking about the next two or three months, do you think the economy in your area will . .";
    item['questionGroup'] = "1";
    item['questionKey'] = "1";
    item.key = key++;
    
    item = {};
    this.items.push(item);
    item['support'] = "79.9";
    item['district'] = "Hazelwood";
    item['question'] = "Students are often given the grades A, B, C, D, and F to evaluate their work. What grade would you give the community college in your area?";
    item['questionGroup'] = "1";
    item['questionKey'] = "2";
    item.key = key++;
    
    item = {};
    this.items.push(item);
    item['support'] = "43.1";
    item['district'] = "Hazelwood";
    item['question'] = "Assume the community college in your area seeks voter approval for an 8-cent increase in property taxes to support improved facilities and programs. If you were voting today, is that something you .";
    item['questionGroup'] = "1";
    item['questionKey'] = "3";
    item.key = key++;
    
    item = {};
    this.items.push(item);
    item['support'] = "27.5";
    item['district'] = "Hazelwood";
    item['question'] = "If you knew this proposal would cost about $1.90 per month for a $150,000 house, is this proposal something you . . .";
    item['questionGroup'] = "1";
    item['questionKey'] = "4";
    item.key = key++;
    
    item = {};
    this.items.push(item);
    item['support'] = "80";
    item['district'] = "Hazelwood";
    item['question'] = "It is essential that our area has a quality community college as a place for adults to be trained and retrained for the good paying jobs of today and tomorrow.";
    item['questionGroup'] = "1";
    item['questionKey'] = "5";
    item.key = key++;
    
    item = {};
    this.items.push(item);
    item['support'] = "60.6";
    item['district'] = "Hazelwood";
    item['question'] = "People like me can't afford to pay higher taxes, no matter how good the cause.";
    item['questionGroup'] = "2";
    item['questionKey'] = "6";
    item.key = key++;
    
    item = {};
    this.items.push(item);
    item['support'] = "75.6";
    item['district'] = "Hazelwood";
    item['question'] = "We get excellent value from what we pay to support our community college.";
    item['questionGroup'] = "2";
    item['questionKey'] = "7";
    item.key = key++;
    
    item = {};
    this.items.push(item);
    item['support'] = "34.6";
    item['district'] = "Hazelwood";
    item['question'] = "The local community college does not serve the needs of people like me and my family.";
    item['questionGroup'] = "2";
    item['questionKey'] = "8";
    item.key = key++;
    
    item = {};
    this.items.push(item);
    item['support'] = "48.1";
    item['district'] = "Hazelwood";
    item['question'] = "I'm willing to pay more to support the community college's efforts to help our area economically recover from the COVID-19 pandemic.";
    item['questionGroup'] = "2";
    item['questionKey'] = "9";
    item.key = key++;
    
    item = {};
    this.items.push(item);
    item['support'] = "71.6";
    item['district'] = "Hazelwood";
    item['question'] = "Our community college is an important anchor for our regional economy.";
    item['questionGroup'] = "2";
    item['questionKey'] = "10";
    item.key = key++;
    
    item = {};
    this.items.push(item);
    item['support'] = "78.7";
    item['district'] = "Hazelwood";
    item['question'] = "Making sure all of the college's instructional areas are equipped with up-to-date instructional technology.";
    item['questionGroup'] = "3";
    item['questionKey'] = "11";
    item.key = key++;
    
    item = {};
    this.items.push(item);
    item['support'] = "60.3";
    item['district'] = "Hazelwood";
    item['question'] = "Replacing buildings that are more than a half-century old, and are expensive and inefficient to operate and maintain.";
    item['questionGroup'] = "3";
    item['questionKey'] = "12";
    item.key = key++;
    
    item = {};
    this.items.push(item);
    item['support'] = "79.2";
    item['district'] = "Hazelwood";
    item['question'] = "Providing learning spaces to accommodate best practices in job training and retraining programs.";
    item['questionGroup'] = "3";
    item['questionKey'] = "13";
    item.key = key++;
    
    item = {};
    this.items.push(item);
    item['support'] = "76";
    item['district'] = "Hazelwood";
    item['question'] = "Repairing and replacing aging roofs, doors, windows, plumbing, HVAC, electrical and other infrastructure conditions as needed.";
    item['questionGroup'] = "3";
    item['questionKey'] = "14";
    item.key = key++;
    
    item = {};
    this.items.push(item);
    item['support'] = "80";
    item['district'] = "Hazelwood";
    item['question'] = "Providing job training and retraining facilities and programs for good paying, high-in-demand, jobs in areas like healthcare, information technology (IT) and financial services.";
    item['questionGroup'] = "3";
    item['questionKey'] = "15";
    item.key = key++;
    
    item = {};
    this.items.push(item);
    item['support'] = "80";
    item['district'] = "Hazelwood";
    item['question'] = "Making sure the campus is safe and secure both night and day.";
    item['questionGroup'] = "4";
    item['questionKey'] = "16";
    item.key = key++;
    
    item = {};
    this.items.push(item);
    item['support'] = "80";
    item['district'] = "Hazelwood";
    item['question'] = "Keeping community college programs affordable.";
    item['questionGroup'] = "4";
    item['questionKey'] = "17";
    item.key = key++;
    
    item = {};
    this.items.push(item);
    item['support'] = "79.8";
    item['district'] = "Hazelwood";
    item['question'] = "Improving training programs so those who are underemployed and unemployed can secure jobs that pay a decent salary.";
    item['questionGroup'] = "4";
    item['questionKey'] = "18";
    item.key = key++;
    
    item = {};
    this.items.push(item);
    item['support'] = "77.1";
    item['district'] = "Hazelwood";
    item['question'] = "Making sure student support services such as registration and financial services are centralized and easily available to students.";
    item['questionGroup'] = "4";
    item['questionKey'] = "19";
    item.key = key++;
    
    item = {};
    this.items.push(item);
    item['support'] = "77.6";
    item['district'] = "Hazelwood";
    item['question'] = "Coordinating programs with area economic planners and businesses to make sure well-trained employees are available to help stimulate business growth and relocation in our community.";
    item['questionGroup'] = "4";
    item['questionKey'] = "20";
    item.key = key++;
    
    item = {};
    this.items.push(item);
    item['support'] = "79.6";
    item['district'] = "Hazelwood";
    item['question'] = "Providing real-world learning environments that provide students with the skills they need in the workplace.";
    item['questionGroup'] = "5";
    item['questionKey'] = "21";
    item.key = key++;
    
    item = {};
    this.items.push(item);
    item['support'] = "79.5";
    item['district'] = "Hazelwood";
    item['question'] = "Improving ADA accessibility at all campuses for students with disabilities.";
    item['questionGroup'] = "5";
    item['questionKey'] = "22";
    item.key = key++;
    
    item = {};
    this.items.push(item);
    item['support'] = "80";
    item['district'] = "Hazelwood";
    item['question'] = "We must ensure that our community college has adequate facilities and programs to meet the demand for high tech job training programs.";
    item['questionGroup'] = "5";
    item['questionKey'] = "23";
    item.key = key++;
    
    item = {};
    this.items.push(item);
    item['support'] = "60.1";
    item['district'] = "Hazelwood";
    item['question'] = "If the college needs more money, it should raise student fees, not increase the money it receives from taxes.";
    item['questionGroup'] = "5";
    item['questionKey'] = "24";
    item.key = key++;
    
    item = {};
    this.items.push(item);
    item['support'] = "79.9";
    item['district'] = "Hazelwood";
    item['question'] = "We must protect the investment we've made in our community college by keeping our programming current and our facilities up-to-date in good working condition.";
    item['questionGroup'] = "5";
    item['questionKey'] = "25";
    item.key = key++;
    
    item = {};
    this.items.push(item);
    item['support'] = "68.6";
    item['district'] = "Hazelwood";
    item['question'] = "Our community college is important to our area. But in terms of receiving more financial support, there are other things that are just more important.";
    item['questionGroup'] = "6";
    item['questionKey'] = "26";
    item.key = key++;
    
    item = {};
    this.items.push(item);
    item['support'] = "79.5";
    item['district'] = "Hazelwood";
    item['question'] = "Following more than a year of COVID-19, it is more important than ever to support the career education and training programs at our community college.";
    item['questionGroup'] = "6";
    item['questionKey'] = "27";
    item.key = key++;
    
    item = {};
    this.items.push(item);
    item['support'] = "73.1";
    item['district'] = "Hazelwood";
    item['question'] = "Unless we keep the college's programs and facilities up-to-date, many area workers will not be able to get the training they need to prepare for the good paying jobs of today and tomorrow, and that will hurt the local economy.";
    item['questionGroup'] = "6";
    item['questionKey'] = "28";
    item.key = key++;
    
    item = {};
    this.items.push(item);
    item['support'] = "79.1";
    item['district'] = "Hazelwood";
    item['question'] = "The community college's career education programs help create an educated workforce. That leads to an improved economy, and in the long term, that lowers local taxes. Supporting the community just makes financial sense.";
    item['questionGroup'] = "6";
    item['questionKey'] = "29";
    item.key = key++;
    
    item = {};
    this.items.push(item);
    item['support'] = "60.2";
    item['district'] = "Hazelwood";
    item['question'] = "I just don't trust local leadership with additional public dollars.";
    item['questionGroup'] = "6";
    item['questionKey'] = "30";
    item.key = key++;
    
    item = {};
    this.items.push(item);
    item['support'] = "74.2";
    item['district'] = "Hazelwood";
    item['question'] = "Right now there are thousands of good-paying jobs in our area that remain unfilled because businesses can't find employees with the necessary skills. This proposal helps train local residents for these jobs and keep them in our area.";
    item['questionGroup'] = "7";
    item['questionKey'] = "31";
    item.key = key++;
    
    item = {};
    this.items.push(item);
    item['support'] = "78.3";
    item['district'] = "Hazelwood";
    item['question'] = "Many businesses can't grow, or move to our area, because of the shortage of a well-trained workforce. Our economic future depends on supporting job training and retraining programs at our community college.";
    item['questionGroup'] = "7";
    item['questionKey'] = "32";
    item.key = key++;
    
    item = {};
    this.items.push(item);
    item['support'] = "50.7";
    item['district'] = "Hazelwood";
    item['question'] = "This proposal will enable the college to repurpose out-of-date and underutilized spaces to meet the needs of today's students and for future generations.";
    item['questionGroup'] = "7";
    item['questionKey'] = "33";
    item.key = key++;
    
    item = {};
    this.items.push(item);
    item['support'] = "70.6";
    item['district'] = "Hazelwood";
    item['question'] = "As asked earlier, assume that your local community college is seeking an 8-cent increase in property taxes to support improved facilities and programs. If you were voting today, is that something you would . . .";
    item['questionGroup'] = "7";
    item['questionKey'] = "34";
    item.key = key++;
    
    item = {};
    this.items.push(item);
    item['support'] = "64.7";
    item['district'] = "Jennings";
    item['question'] = "Thinking about the next two or three months, do you think the economy in your area will . .";
    item['questionGroup'] = "1";
    item['questionKey'] = "1";
    item.key = key++;
    
    item = {};
    this.items.push(item);
    item['support'] = "79.8";
    item['district'] = "Jennings";
    item['question'] = "Students are often given the grades A, B, C, D, and F to evaluate their work. What grade would you give the community college in your area?";
    item['questionGroup'] = "1";
    item['questionKey'] = "2";
    item.key = key++;
    
    item = {};
    this.items.push(item);
    item['support'] = "42.1";
    item['district'] = "Jennings";
    item['question'] = "Assume the community college in your area seeks voter approval for an 8-cent increase in property taxes to support improved facilities and programs. If you were voting today, is that something you .";
    item['questionGroup'] = "1";
    item['questionKey'] = "3";
    item.key = key++;
    
    item = {};
    this.items.push(item);
    item['support'] = "25.9";
    item['district'] = "Jennings";
    item['question'] = "If you knew this proposal would cost about $1.90 per month for a $150,000 house, is this proposal something you . . .";
    item['questionGroup'] = "1";
    item['questionKey'] = "4";
    item.key = key++;
    
    item = {};
    this.items.push(item);
    item['support'] = "80";
    item['district'] = "Jennings";
    item['question'] = "It is essential that our area has a quality community college as a place for adults to be trained and retrained for the good paying jobs of today and tomorrow.";
    item['questionGroup'] = "1";
    item['questionKey'] = "5";
    item.key = key++;
    
    item = {};
    this.items.push(item);
    item['support'] = "68.3";
    item['district'] = "Jennings";
    item['question'] = "People like me can't afford to pay higher taxes, no matter how good the cause.";
    item['questionGroup'] = "2";
    item['questionKey'] = "6";
    item.key = key++;
    
    item = {};
    this.items.push(item);
    item['support'] = "75.4";
    item['district'] = "Jennings";
    item['question'] = "We get excellent value from what we pay to support our community college.";
    item['questionGroup'] = "2";
    item['questionKey'] = "7";
    item.key = key++;
    
    item = {};
    this.items.push(item);
    item['support'] = "34.2";
    item['district'] = "Jennings";
    item['question'] = "The local community college does not serve the needs of people like me and my family.";
    item['questionGroup'] = "2";
    item['questionKey'] = "8";
    item.key = key++;
    
    item = {};
    this.items.push(item);
    item['support'] = "46.7";
    item['district'] = "Jennings";
    item['question'] = "I'm willing to pay more to support the community college's efforts to help our area economically recover from the COVID-19 pandemic.";
    item['questionGroup'] = "2";
    item['questionKey'] = "9";
    item.key = key++;
    
    item = {};
    this.items.push(item);
    item['support'] = "72.9";
    item['district'] = "Jennings";
    item['question'] = "Our community college is an important anchor for our regional economy.";
    item['questionGroup'] = "2";
    item['questionKey'] = "10";
    item.key = key++;
    
    item = {};
    this.items.push(item);
    item['support'] = "77.3";
    item['district'] = "Jennings";
    item['question'] = "Making sure all of the college's instructional areas are equipped with up-to-date instructional technology.";
    item['questionGroup'] = "3";
    item['questionKey'] = "11";
    item.key = key++;
    
    item = {};
    this.items.push(item);
    item['support'] = "60.2";
    item['district'] = "Jennings";
    item['question'] = "Replacing buildings that are more than a half-century old, and are expensive and inefficient to operate and maintain.";
    item['questionGroup'] = "3";
    item['questionKey'] = "12";
    item.key = key++;
    
    item = {};
    this.items.push(item);
    item['support'] = "78.9";
    item['district'] = "Jennings";
    item['question'] = "Providing learning spaces to accommodate best practices in job training and retraining programs.";
    item['questionGroup'] = "3";
    item['questionKey'] = "13";
    item.key = key++;
    
    item = {};
    this.items.push(item);
    item['support'] = "74.9";
    item['district'] = "Jennings";
    item['question'] = "Repairing and replacing aging roofs, doors, windows, plumbing, HVAC, electrical and other infrastructure conditions as needed.";
    item['questionGroup'] = "3";
    item['questionKey'] = "14";
    item.key = key++;
    
    item = {};
    this.items.push(item);
    item['support'] = "80";
    item['district'] = "Jennings";
    item['question'] = "Providing job training and retraining facilities and programs for good paying, high-in-demand, jobs in areas like healthcare, information technology (IT) and financial services.";
    item['questionGroup'] = "3";
    item['questionKey'] = "15";
    item.key = key++;
    
    item = {};
    this.items.push(item);
    item['support'] = "80";
    item['district'] = "Jennings";
    item['question'] = "Making sure the campus is safe and secure both night and day.";
    item['questionGroup'] = "4";
    item['questionKey'] = "16";
    item.key = key++;
    
    item = {};
    this.items.push(item);
    item['support'] = "80";
    item['district'] = "Jennings";
    item['question'] = "Keeping community college programs affordable.";
    item['questionGroup'] = "4";
    item['questionKey'] = "17";
    item.key = key++;
    
    item = {};
    this.items.push(item);
    item['support'] = "79.7";
    item['district'] = "Jennings";
    item['question'] = "Improving training programs so those who are underemployed and unemployed can secure jobs that pay a decent salary.";
    item['questionGroup'] = "4";
    item['questionKey'] = "18";
    item.key = key++;
    
    item = {};
    this.items.push(item);
    item['support'] = "77.9";
    item['district'] = "Jennings";
    item['question'] = "Making sure student support services such as registration and financial services are centralized and easily available to students.";
    item['questionGroup'] = "4";
    item['questionKey'] = "19";
    item.key = key++;
    
    item = {};
    this.items.push(item);
    item['support'] = "75.8";
    item['district'] = "Jennings";
    item['question'] = "Coordinating programs with area economic planners and businesses to make sure well-trained employees are available to help stimulate business growth and relocation in our community.";
    item['questionGroup'] = "4";
    item['questionKey'] = "20";
    item.key = key++;
    
    item = {};
    this.items.push(item);
    item['support'] = "79.1";
    item['district'] = "Jennings";
    item['question'] = "Providing real-world learning environments that provide students with the skills they need in the workplace.";
    item['questionGroup'] = "5";
    item['questionKey'] = "21";
    item.key = key++;
    
    item = {};
    this.items.push(item);
    item['support'] = "79.4";
    item['district'] = "Jennings";
    item['question'] = "Improving ADA accessibility at all campuses for students with disabilities.";
    item['questionGroup'] = "5";
    item['questionKey'] = "22";
    item.key = key++;
    
    item = {};
    this.items.push(item);
    item['support'] = "80";
    item['district'] = "Jennings";
    item['question'] = "We must ensure that our community college has adequate facilities and programs to meet the demand for high tech job training programs.";
    item['questionGroup'] = "5";
    item['questionKey'] = "23";
    item.key = key++;
    
    item = {};
    this.items.push(item);
    item['support'] = "58.4";
    item['district'] = "Jennings";
    item['question'] = "If the college needs more money, it should raise student fees, not increase the money it receives from taxes.";
    item['questionGroup'] = "5";
    item['questionKey'] = "24";
    item.key = key++;
    
    item = {};
    this.items.push(item);
    item['support'] = "79.9";
    item['district'] = "Jennings";
    item['question'] = "We must protect the investment we've made in our community college by keeping our programming current and our facilities up-to-date in good working condition.";
    item['questionGroup'] = "5";
    item['questionKey'] = "25";
    item.key = key++;
    
    item = {};
    this.items.push(item);
    item['support'] = "68.7";
    item['district'] = "Jennings";
    item['question'] = "Our community college is important to our area. But in terms of receiving more financial support, there are other things that are just more important.";
    item['questionGroup'] = "6";
    item['questionKey'] = "26";
    item.key = key++;
    
    item = {};
    this.items.push(item);
    item['support'] = "79.7";
    item['district'] = "Jennings";
    item['question'] = "Following more than a year of COVID-19, it is more important than ever to support the career education and training programs at our community college.";
    item['questionGroup'] = "6";
    item['questionKey'] = "27";
    item.key = key++;
    
    item = {};
    this.items.push(item);
    item['support'] = "73.9";
    item['district'] = "Jennings";
    item['question'] = "Unless we keep the college's programs and facilities up-to-date, many area workers will not be able to get the training they need to prepare for the good paying jobs of today and tomorrow, and that will hurt the local economy.";
    item['questionGroup'] = "6";
    item['questionKey'] = "28";
    item.key = key++;
    
    item = {};
    this.items.push(item);
    item['support'] = "78.8";
    item['district'] = "Jennings";
    item['question'] = "The community college's career education programs help create an educated workforce. That leads to an improved economy, and in the long term, that lowers local taxes. Supporting the community just makes financial sense.";
    item['questionGroup'] = "6";
    item['questionKey'] = "29";
    item.key = key++;
    
    item = {};
    this.items.push(item);
    item['support'] = "61";
    item['district'] = "Jennings";
    item['question'] = "I just don't trust local leadership with additional public dollars.";
    item['questionGroup'] = "6";
    item['questionKey'] = "30";
    item.key = key++;
    
    item = {};
    this.items.push(item);
    item['support'] = "73.7";
    item['district'] = "Jennings";
    item['question'] = "Right now there are thousands of good-paying jobs in our area that remain unfilled because businesses can't find employees with the necessary skills. This proposal helps train local residents for these jobs and keep them in our area.";
    item['questionGroup'] = "7";
    item['questionKey'] = "31";
    item.key = key++;
    
    item = {};
    this.items.push(item);
    item['support'] = "75.4";
    item['district'] = "Jennings";
    item['question'] = "Many businesses can't grow, or move to our area, because of the shortage of a well-trained workforce. Our economic future depends on supporting job training and retraining programs at our community college.";
    item['questionGroup'] = "7";
    item['questionKey'] = "32";
    item.key = key++;
    
    item = {};
    this.items.push(item);
    item['support'] = "49.2";
    item['district'] = "Jennings";
    item['question'] = "This proposal will enable the college to repurpose out-of-date and underutilized spaces to meet the needs of today's students and for future generations.";
    item['questionGroup'] = "7";
    item['questionKey'] = "33";
    item.key = key++;
    
    item = {};
    this.items.push(item);
    item['support'] = "71.4";
    item['district'] = "Jennings";
    item['question'] = "As asked earlier, assume that your local community college is seeking an 8-cent increase in property taxes to support improved facilities and programs. If you were voting today, is that something you would . . .";
    item['questionGroup'] = "7";
    item['questionKey'] = "34";
    item.key = key++;
    
    item = {};
    this.items.push(item);
    item['support'] = "66";
    item['district'] = "Kirkwood R-VII";
    item['question'] = "Thinking about the next two or three months, do you think the economy in your area will . .";
    item['questionGroup'] = "1";
    item['questionKey'] = "1";
    item.key = key++;
    
    item = {};
    this.items.push(item);
    item['support'] = "80";
    item['district'] = "Kirkwood R-VII";
    item['question'] = "Students are often given the grades A, B, C, D, and F to evaluate their work. What grade would you give the community college in your area?";
    item['questionGroup'] = "1";
    item['questionKey'] = "2";
    item.key = key++;
    
    item = {};
    this.items.push(item);
    item['support'] = "41.7";
    item['district'] = "Kirkwood R-VII";
    item['question'] = "Assume the community college in your area seeks voter approval for an 8-cent increase in property taxes to support improved facilities and programs. If you were voting today, is that something you .";
    item['questionGroup'] = "1";
    item['questionKey'] = "3";
    item.key = key++;
    
    item = {};
    this.items.push(item);
    item['support'] = "23.7";
    item['district'] = "Kirkwood R-VII";
    item['question'] = "If you knew this proposal would cost about $1.90 per month for a $150,000 house, is this proposal something you . . .";
    item['questionGroup'] = "1";
    item['questionKey'] = "4";
    item.key = key++;
    
    item = {};
    this.items.push(item);
    item['support'] = "80";
    item['district'] = "Kirkwood R-VII";
    item['question'] = "It is essential that our area has a quality community college as a place for adults to be trained and retrained for the good paying jobs of today and tomorrow.";
    item['questionGroup'] = "1";
    item['questionKey'] = "5";
    item.key = key++;
    
    item = {};
    this.items.push(item);
    item['support'] = "50.7";
    item['district'] = "Kirkwood R-VII";
    item['question'] = "People like me can't afford to pay higher taxes, no matter how good the cause.";
    item['questionGroup'] = "2";
    item['questionKey'] = "6";
    item.key = key++;
    
    item = {};
    this.items.push(item);
    item['support'] = "75.7";
    item['district'] = "Kirkwood R-VII";
    item['question'] = "We get excellent value from what we pay to support our community college.";
    item['questionGroup'] = "2";
    item['questionKey'] = "7";
    item.key = key++;
    
    item = {};
    this.items.push(item);
    item['support'] = "36.7";
    item['district'] = "Kirkwood R-VII";
    item['question'] = "The local community college does not serve the needs of people like me and my family.";
    item['questionGroup'] = "2";
    item['questionKey'] = "8";
    item.key = key++;
    
    item = {};
    this.items.push(item);
    item['support'] = "46.2";
    item['district'] = "Kirkwood R-VII";
    item['question'] = "I'm willing to pay more to support the community college's efforts to help our area economically recover from the COVID-19 pandemic.";
    item['questionGroup'] = "2";
    item['questionKey'] = "9";
    item.key = key++;
    
    item = {};
    this.items.push(item);
    item['support'] = "71.8";
    item['district'] = "Kirkwood R-VII";
    item['question'] = "Our community college is an important anchor for our regional economy.";
    item['questionGroup'] = "2";
    item['questionKey'] = "10";
    item.key = key++;
    
    item = {};
    this.items.push(item);
    item['support'] = "79.4";
    item['district'] = "Kirkwood R-VII";
    item['question'] = "Making sure all of the college's instructional areas are equipped with up-to-date instructional technology.";
    item['questionGroup'] = "3";
    item['questionKey'] = "11";
    item.key = key++;
    
    item = {};
    this.items.push(item);
    item['support'] = "60.2";
    item['district'] = "Kirkwood R-VII";
    item['question'] = "Replacing buildings that are more than a half-century old, and are expensive and inefficient to operate and maintain.";
    item['questionGroup'] = "3";
    item['questionKey'] = "12";
    item.key = key++;
    
    item = {};
    this.items.push(item);
    item['support'] = "79.5";
    item['district'] = "Kirkwood R-VII";
    item['question'] = "Providing learning spaces to accommodate best practices in job training and retraining programs.";
    item['questionGroup'] = "3";
    item['questionKey'] = "13";
    item.key = key++;
    
    item = {};
    this.items.push(item);
    item['support'] = "78.4";
    item['district'] = "Kirkwood R-VII";
    item['question'] = "Repairing and replacing aging roofs, doors, windows, plumbing, HVAC, electrical and other infrastructure conditions as needed.";
    item['questionGroup'] = "3";
    item['questionKey'] = "14";
    item.key = key++;
    
    item = {};
    this.items.push(item);
    item['support'] = "80";
    item['district'] = "Kirkwood R-VII";
    item['question'] = "Providing job training and retraining facilities and programs for good paying, high-in-demand, jobs in areas like healthcare, information technology (IT) and financial services.";
    item['questionGroup'] = "3";
    item['questionKey'] = "15";
    item.key = key++;
    
    item = {};
    this.items.push(item);
    item['support'] = "80";
    item['district'] = "Kirkwood R-VII";
    item['question'] = "Making sure the campus is safe and secure both night and day.";
    item['questionGroup'] = "4";
    item['questionKey'] = "16";
    item.key = key++;
    
    item = {};
    this.items.push(item);
    item['support'] = "80";
    item['district'] = "Kirkwood R-VII";
    item['question'] = "Keeping community college programs affordable.";
    item['questionGroup'] = "4";
    item['questionKey'] = "17";
    item.key = key++;
    
    item = {};
    this.items.push(item);
    item['support'] = "79.8";
    item['district'] = "Kirkwood R-VII";
    item['question'] = "Improving training programs so those who are underemployed and unemployed can secure jobs that pay a decent salary.";
    item['questionGroup'] = "4";
    item['questionKey'] = "18";
    item.key = key++;
    
    item = {};
    this.items.push(item);
    item['support'] = "71.2";
    item['district'] = "Kirkwood R-VII";
    item['question'] = "Making sure student support services such as registration and financial services are centralized and easily available to students.";
    item['questionGroup'] = "4";
    item['questionKey'] = "19";
    item.key = key++;
    
    item = {};
    this.items.push(item);
    item['support'] = "78.8";
    item['district'] = "Kirkwood R-VII";
    item['question'] = "Coordinating programs with area economic planners and businesses to make sure well-trained employees are available to help stimulate business growth and relocation in our community.";
    item['questionGroup'] = "4";
    item['questionKey'] = "20";
    item.key = key++;
    
    item = {};
    this.items.push(item);
    item['support'] = "79.8";
    item['district'] = "Kirkwood R-VII";
    item['question'] = "Providing real-world learning environments that provide students with the skills they need in the workplace.";
    item['questionGroup'] = "5";
    item['questionKey'] = "21";
    item.key = key++;
    
    item = {};
    this.items.push(item);
    item['support'] = "79.7";
    item['district'] = "Kirkwood R-VII";
    item['question'] = "Improving ADA accessibility at all campuses for students with disabilities.";
    item['questionGroup'] = "5";
    item['questionKey'] = "22";
    item.key = key++;
    
    item = {};
    this.items.push(item);
    item['support'] = "80";
    item['district'] = "Kirkwood R-VII";
    item['question'] = "We must ensure that our community college has adequate facilities and programs to meet the demand for high tech job training programs.";
    item['questionGroup'] = "5";
    item['questionKey'] = "23";
    item.key = key++;
    
    item = {};
    this.items.push(item);
    item['support'] = "63";
    item['district'] = "Kirkwood R-VII";
    item['question'] = "If the college needs more money, it should raise student fees, not increase the money it receives from taxes.";
    item['questionGroup'] = "5";
    item['questionKey'] = "24";
    item.key = key++;
    
    item = {};
    this.items.push(item);
    item['support'] = "80";
    item['district'] = "Kirkwood R-VII";
    item['question'] = "We must protect the investment we've made in our community college by keeping our programming current and our facilities up-to-date in good working condition.";
    item['questionGroup'] = "5";
    item['questionKey'] = "25";
    item.key = key++;
    
    item = {};
    this.items.push(item);
    item['support'] = "73.1";
    item['district'] = "Kirkwood R-VII";
    item['question'] = "Our community college is important to our area. But in terms of receiving more financial support, there are other things that are just more important.";
    item['questionGroup'] = "6";
    item['questionKey'] = "26";
    item.key = key++;
    
    item = {};
    this.items.push(item);
    item['support'] = "79.6";
    item['district'] = "Kirkwood R-VII";
    item['question'] = "Following more than a year of COVID-19, it is more important than ever to support the career education and training programs at our community college.";
    item['questionGroup'] = "6";
    item['questionKey'] = "27";
    item.key = key++;
    
    item = {};
    this.items.push(item);
    item['support'] = "73";
    item['district'] = "Kirkwood R-VII";
    item['question'] = "Unless we keep the college's programs and facilities up-to-date, many area workers will not be able to get the training they need to prepare for the good paying jobs of today and tomorrow, and that will hurt the local economy.";
    item['questionGroup'] = "6";
    item['questionKey'] = "28";
    item.key = key++;
    
    item = {};
    this.items.push(item);
    item['support'] = "74.8";
    item['district'] = "Kirkwood R-VII";
    item['question'] = "The community college's career education programs help create an educated workforce. That leads to an improved economy, and in the long term, that lowers local taxes. Supporting the community just makes financial sense.";
    item['questionGroup'] = "6";
    item['questionKey'] = "29";
    item.key = key++;
    
    item = {};
    this.items.push(item);
    item['support'] = "65";
    item['district'] = "Kirkwood R-VII";
    item['question'] = "I just don't trust local leadership with additional public dollars.";
    item['questionGroup'] = "6";
    item['questionKey'] = "30";
    item.key = key++;
    
    item = {};
    this.items.push(item);
    item['support'] = "73";
    item['district'] = "Kirkwood R-VII";
    item['question'] = "Right now there are thousands of good-paying jobs in our area that remain unfilled because businesses can't find employees with the necessary skills. This proposal helps train local residents for these jobs and keep them in our area.";
    item['questionGroup'] = "7";
    item['questionKey'] = "31";
    item.key = key++;
    
    item = {};
    this.items.push(item);
    item['support'] = "79.5";
    item['district'] = "Kirkwood R-VII";
    item['question'] = "Many businesses can't grow, or move to our area, because of the shortage of a well-trained workforce. Our economic future depends on supporting job training and retraining programs at our community college.";
    item['questionGroup'] = "7";
    item['questionKey'] = "32";
    item.key = key++;
    
    item = {};
    this.items.push(item);
    item['support'] = "47.5";
    item['district'] = "Kirkwood R-VII";
    item['question'] = "This proposal will enable the college to repurpose out-of-date and underutilized spaces to meet the needs of today's students and for future generations.";
    item['questionGroup'] = "7";
    item['questionKey'] = "33";
    item.key = key++;
    
    item = {};
    this.items.push(item);
    item['support'] = "72.1";
    item['district'] = "Kirkwood R-VII";
    item['question'] = "As asked earlier, assume that your local community college is seeking an 8-cent increase in property taxes to support improved facilities and programs. If you were voting today, is that something you would . . .";
    item['questionGroup'] = "7";
    item['questionKey'] = "34";
    item.key = key++;
    
    item = {};
    this.items.push(item);
    item['support'] = "63.4";
    item['district'] = "Ladue";
    item['totalSupport'] = "4,043";
    item['question'] = "Thinking about the next two or three months, do you think the economy in your area will . .";
    item['questionGroup'] = "1";
    item['questionKey'] = "1";
    item.key = key++;
    
    item = {};
    this.items.push(item);
    item['support'] = "80";
    item['district'] = "Ladue";
    item['totalSupport'] = "3,713";
    item['question'] = "Students are often given the grades A, B, C, D, and F to evaluate their work. What grade would you give the community college in your area?";
    item['questionGroup'] = "1";
    item['questionKey'] = "2";
    item.key = key++;
    
    item = {};
    this.items.push(item);
    item['support'] = "41.3";
    item['district'] = "Ladue";
    item['totalSupport'] = "3,319";
    item['question'] = "Assume the community college in your area seeks voter approval for an 8-cent increase in property taxes to support improved facilities and programs. If you were voting today, is that something you .";
    item['questionGroup'] = "1";
    item['questionKey'] = "3";
    item.key = key++;
    
    item = {};
    this.items.push(item);
    item['support'] = "22.4";
    item['district'] = "Ladue";
    item['totalSupport'] = "2,833";
    item['question'] = "If you knew this proposal would cost about $1.90 per month for a $150,000 house, is this proposal something you . . .";
    item['questionGroup'] = "1";
    item['questionKey'] = "4";
    item.key = key++;
    
    item = {};
    this.items.push(item);
    item['support'] = "80";
    item['district'] = "Ladue";
    item['totalSupport'] = "4,473";
    item['question'] = "It is essential that our area has a quality community college as a place for adults to be trained and retrained for the good paying jobs of today and tomorrow.";
    item['questionGroup'] = "1";
    item['questionKey'] = "5";
    item.key = key++;
    
    item = {};
    this.items.push(item);
    item['support'] = "50.4";
    item['district'] = "Ladue";
    item['totalSupport'] = "4,177";
    item['question'] = "People like me can't afford to pay higher taxes, no matter how good the cause.";
    item['questionGroup'] = "2";
    item['questionKey'] = "6";
    item.key = key++;
    
    item = {};
    this.items.push(item);
    item['support'] = "74.3";
    item['district'] = "Ladue";
    item['totalSupport'] = "4,335";
    item['question'] = "We get excellent value from what we pay to support our community college.";
    item['questionGroup'] = "2";
    item['questionKey'] = "7";
    item.key = key++;
    
    item = {};
    this.items.push(item);
    item['support'] = "38.3";
    item['district'] = "Ladue";
    item['totalSupport'] = "4,860";
    item['question'] = "The local community college does not serve the needs of people like me and my family.";
    item['questionGroup'] = "2";
    item['questionKey'] = "8";
    item.key = key++;
    
    item = {};
    this.items.push(item);
    item['support'] = "43.7";
    item['district'] = "Ladue";
    item['totalSupport'] = "4,015";
    item['question'] = "I'm willing to pay more to support the community college's efforts to help our area economically recover from the COVID-19 pandemic.";
    item['questionGroup'] = "2";
    item['questionKey'] = "9";
    item.key = key++;
    
    item = {};
    this.items.push(item);
    item['support'] = "69.9";
    item['district'] = "Ladue";
    item['totalSupport'] = "4,102";
    item['question'] = "Our community college is an important anchor for our regional economy.";
    item['questionGroup'] = "2";
    item['questionKey'] = "10";
    item.key = key++;
    
    item = {};
    this.items.push(item);
    item['support'] = "79.7";
    item['district'] = "Ladue";
    item['totalSupport'] = "2,889";
    item['question'] = "Making sure all of the college's instructional areas are equipped with up-to-date instructional technology.";
    item['questionGroup'] = "3";
    item['questionKey'] = "11";
    item.key = key++;
    
    item = {};
    this.items.push(item);
    item['support'] = "60.2";
    item['district'] = "Ladue";
    item['totalSupport'] = "4,846";
    item['question'] = "Replacing buildings that are more than a half-century old, and are expensive and inefficient to operate and maintain.";
    item['questionGroup'] = "3";
    item['questionKey'] = "12";
    item.key = key++;
    
    item = {};
    this.items.push(item);
    item['support'] = "79.6";
    item['district'] = "Ladue";
    item['totalSupport'] = "4,674";
    item['question'] = "Providing learning spaces to accommodate best practices in job training and retraining programs.";
    item['questionGroup'] = "3";
    item['questionKey'] = "13";
    item.key = key++;
    
    item = {};
    this.items.push(item);
    item['support'] = "78.9";
    item['district'] = "Ladue";
    item['totalSupport'] = "2,817";
    item['question'] = "Repairing and replacing aging roofs, doors, windows, plumbing, HVAC, electrical and other infrastructure conditions as needed.";
    item['questionGroup'] = "3";
    item['questionKey'] = "14";
    item.key = key++;
    
    item = {};
    this.items.push(item);
    item['support'] = "80";
    item['district'] = "Ladue";
    item['totalSupport'] = "3,720";
    item['question'] = "Providing job training and retraining facilities and programs for good paying, high-in-demand, jobs in areas like healthcare, information technology (IT) and financial services.";
    item['questionGroup'] = "3";
    item['questionKey'] = "15";
    item.key = key++;
    
    item = {};
    this.items.push(item);
    item['support'] = "80";
    item['district'] = "Ladue";
    item['totalSupport'] = "3,876";
    item['question'] = "Making sure the campus is safe and secure both night and day.";
    item['questionGroup'] = "4";
    item['questionKey'] = "16";
    item.key = key++;
    
    item = {};
    this.items.push(item);
    item['support'] = "80";
    item['district'] = "Ladue";
    item['totalSupport'] = "4,709";
    item['question'] = "Keeping community college programs affordable.";
    item['questionGroup'] = "4";
    item['questionKey'] = "17";
    item.key = key++;
    
    item = {};
    this.items.push(item);
    item['support'] = "79.9";
    item['district'] = "Ladue";
    item['totalSupport'] = "4,393";
    item['question'] = "Improving training programs so those who are underemployed and unemployed can secure jobs that pay a decent salary.";
    item['questionGroup'] = "4";
    item['questionKey'] = "18";
    item.key = key++;
    
    item = {};
    this.items.push(item);
    item['support'] = "70.2";
    item['district'] = "Ladue";
    item['totalSupport'] = "4,944";
    item['question'] = "Making sure student support services such as registration and financial services are centralized and easily available to students.";
    item['questionGroup'] = "4";
    item['questionKey'] = "19";
    item.key = key++;
    
    item = {};
    this.items.push(item);
    item['support'] = "79.3";
    item['district'] = "Ladue";
    item['totalSupport'] = "4,287";
    item['question'] = "Coordinating programs with area economic planners and businesses to make sure well-trained employees are available to help stimulate business growth and relocation in our community.";
    item['questionGroup'] = "4";
    item['questionKey'] = "20";
    item.key = key++;
    
    item = {};
    this.items.push(item);
    item['support'] = "79.9";
    item['district'] = "Ladue";
    item['totalSupport'] = "3,095";
    item['question'] = "Providing real-world learning environments that provide students with the skills they need in the workplace.";
    item['questionGroup'] = "5";
    item['questionKey'] = "21";
    item.key = key++;
    
    item = {};
    this.items.push(item);
    item['support'] = "79.9";
    item['district'] = "Ladue";
    item['totalSupport'] = "4,455";
    item['question'] = "Improving ADA accessibility at all campuses for students with disabilities.";
    item['questionGroup'] = "5";
    item['questionKey'] = "22";
    item.key = key++;
    
    item = {};
    this.items.push(item);
    item['support'] = "80";
    item['district'] = "Ladue";
    item['totalSupport'] = "3,085";
    item['question'] = "We must ensure that our community college has adequate facilities and programs to meet the demand for high tech job training programs.";
    item['questionGroup'] = "5";
    item['questionKey'] = "23";
    item.key = key++;
    
    item = {};
    this.items.push(item);
    item['support'] = "61.4";
    item['district'] = "Ladue";
    item['totalSupport'] = "3,569";
    item['question'] = "If the college needs more money, it should raise student fees, not increase the money it receives from taxes.";
    item['questionGroup'] = "5";
    item['questionKey'] = "24";
    item.key = key++;
    
    item = {};
    this.items.push(item);
    item['support'] = "80";
    item['district'] = "Ladue";
    item['totalSupport'] = "3,711";
    item['question'] = "We must protect the investment we've made in our community college by keeping our programming current and our facilities up-to-date in good working condition.";
    item['questionGroup'] = "5";
    item['questionKey'] = "25";
    item.key = key++;
    
    item = {};
    this.items.push(item);
    item['support'] = "72.3";
    item['district'] = "Ladue";
    item['totalSupport'] = "2,804";
    item['question'] = "Our community college is important to our area. But in terms of receiving more financial support, there are other things that are just more important.";
    item['questionGroup'] = "6";
    item['questionKey'] = "26";
    item.key = key++;
    
    item = {};
    this.items.push(item);
    item['support'] = "79.9";
    item['district'] = "Ladue";
    item['totalSupport'] = "4,005";
    item['question'] = "Following more than a year of COVID-19, it is more important than ever to support the career education and training programs at our community college.";
    item['questionGroup'] = "6";
    item['questionKey'] = "27";
    item.key = key++;
    
    item = {};
    this.items.push(item);
    item['support'] = "70.7";
    item['district'] = "Ladue";
    item['totalSupport'] = "3,989";
    item['question'] = "Unless we keep the college's programs and facilities up-to-date, many area workers will not be able to get the training they need to prepare for the good paying jobs of today and tomorrow, and that will hurt the local economy.";
    item['questionGroup'] = "6";
    item['questionKey'] = "28";
    item.key = key++;
    
    item = {};
    this.items.push(item);
    item['support'] = "72.1";
    item['district'] = "Ladue";
    item['totalSupport'] = "2,837";
    item['question'] = "The community college's career education programs help create an educated workforce. That leads to an improved economy, and in the long term, that lowers local taxes. Supporting the community just makes financial sense.";
    item['questionGroup'] = "6";
    item['questionKey'] = "29";
    item.key = key++;
    
    item = {};
    this.items.push(item);
    item['support'] = "67.9";
    item['district'] = "Ladue";
    item['totalSupport'] = "2,920";
    item['question'] = "I just don't trust local leadership with additional public dollars.";
    item['questionGroup'] = "6";
    item['questionKey'] = "30";
    item.key = key++;
    
    item = {};
    this.items.push(item);
    item['support'] = "70.7";
    item['district'] = "Ladue";
    item['totalSupport'] = "3,612";
    item['question'] = "Right now there are thousands of good-paying jobs in our area that remain unfilled because businesses can't find employees with the necessary skills. This proposal helps train local residents for these jobs and keep them in our area.";
    item['questionGroup'] = "7";
    item['questionKey'] = "31";
    item.key = key++;
    
    item = {};
    this.items.push(item);
    item['support'] = "79.6";
    item['district'] = "Ladue";
    item['totalSupport'] = "4,352";
    item['question'] = "Many businesses can't grow, or move to our area, because of the shortage of a well-trained workforce. Our economic future depends on supporting job training and retraining programs at our community college.";
    item['questionGroup'] = "7";
    item['questionKey'] = "32";
    item.key = key++;
    
    item = {};
    this.items.push(item);
    item['support'] = "44.5";
    item['district'] = "Ladue";
    item['totalSupport'] = "3,567";
    item['question'] = "This proposal will enable the college to repurpose out-of-date and underutilized spaces to meet the needs of today's students and for future generations.";
    item['questionGroup'] = "7";
    item['questionKey'] = "33";
    item.key = key++;
    
    item = {};
    this.items.push(item);
    item['support'] = "70.1";
    item['district'] = "Ladue";
    item['totalSupport'] = "3,642";
    item['question'] = "As asked earlier, assume that your local community college is seeking an 8-cent increase in property taxes to support improved facilities and programs. If you were voting today, is that something you would . . .";
    item['questionGroup'] = "7";
    item['questionKey'] = "34";
    item.key = key++;
    
    item = {};
    this.items.push(item);
    item['support'] = "67.23656169";
    item['district'] = "Lindbergh";
    item['question'] = "Thinking about the next two or three months, do you think the economy in your area will . .";
    item['questionGroup'] = "1";
    item['questionKey'] = "1";
    item.key = key++;
    
    item = {};
    this.items.push(item);
    item['support'] = "79.98579209";
    item['district'] = "Lindbergh";
    item['question'] = "Students are often given the grades A, B, C, D, and F to evaluate their work. What grade would you give the community college in your area?";
    item['questionGroup'] = "1";
    item['questionKey'] = "2";
    item.key = key++;
    
    item = {};
    this.items.push(item);
    item['support'] = "42.24011366";
    item['district'] = "Lindbergh";
    item['question'] = "Assume the community college in your area seeks voter approval for an 8-cent increase in property taxes to support improved facilities and programs. If you were voting today, is that something you .";
    item['questionGroup'] = "1";
    item['questionKey'] = "3";
    item.key = key++;
    
    item = {};
    this.items.push(item);
    item['support'] = "24.94908833";
    item['district'] = "Lindbergh";
    item['question'] = "If you knew this proposal would cost about $1.90 per month for a $150,000 house, is this proposal something you . . .";
    item['questionGroup'] = "1";
    item['questionKey'] = "4";
    item.key = key++;
    
    item = {};
    this.items.push(item);
    item['support'] = "80";
    item['district'] = "Lindbergh";
    item['question'] = "It is essential that our area has a quality community college as a place for adults to be trained and retrained for the good paying jobs of today and tomorrow.";
    item['questionGroup'] = "1";
    item['questionKey'] = "5";
    item.key = key++;
    
    item = {};
    this.items.push(item);
    item['support'] = "53.35069856";
    item['district'] = "Lindbergh";
    item['question'] = "People like me can't afford to pay higher taxes, no matter how good the cause.";
    item['questionGroup'] = "2";
    item['questionKey'] = "6";
    item.key = key++;
    
    item = {};
    this.items.push(item);
    item['support'] = "75.82287473";
    item['district'] = "Lindbergh";
    item['question'] = "We get excellent value from what we pay to support our community college.";
    item['questionGroup'] = "2";
    item['questionKey'] = "7";
    item.key = key++;
    
    item = {};
    this.items.push(item);
    item['support'] = "35.67605967";
    item['district'] = "Lindbergh";
    item['question'] = "The local community college does not serve the needs of people like me and my family.";
    item['questionGroup'] = "2";
    item['questionKey'] = "8";
    item.key = key++;
    
    item = {};
    this.items.push(item);
    item['support'] = "47.66279896";
    item['district'] = "Lindbergh";
    item['question'] = "I'm willing to pay more to support the community college's efforts to help our area economically recover from the COVID-19 pandemic.";
    item['questionGroup'] = "2";
    item['questionKey'] = "9";
    item.key = key++;
    
    item = {};
    this.items.push(item);
    item['support'] = "72.66871892";
    item['district'] = "Lindbergh";
    item['question'] = "Our community college is an important anchor for our regional economy.";
    item['questionGroup'] = "2";
    item['questionKey'] = "10";
    item.key = key++;
    
    item = {};
    this.items.push(item);
    item['support'] = "79.36064409";
    item['district'] = "Lindbergh";
    item['question'] = "Making sure all of the college's instructional areas are equipped with up-to-date instructional technology.";
    item['questionGroup'] = "3";
    item['questionKey'] = "11";
    item.key = key++;
    
    item = {};
    this.items.push(item);
    item['support'] = "60.23206251";
    item['district'] = "Lindbergh";
    item['question'] = "Replacing buildings that are more than a half-century old, and are expensive and inefficient to operate and maintain.";
    item['questionGroup'] = "3";
    item['questionKey'] = "12";
    item.key = key++;
    
    item = {};
    this.items.push(item);
    item['support'] = "79.34170021";
    item['district'] = "Lindbergh";
    item['question'] = "Providing learning spaces to accommodate best practices in job training and retraining programs.";
    item['questionGroup'] = "3";
    item['questionKey'] = "13";
    item.key = key++;
    
    item = {};
    this.items.push(item);
    item['support'] = "77.62254322";
    item['district'] = "Lindbergh";
    item['question'] = "Repairing and replacing aging roofs, doors, windows, plumbing, HVAC, electrical and other infrastructure conditions as needed.";
    item['questionGroup'] = "3";
    item['questionKey'] = "14";
    item.key = key++;
    
    item = {};
    this.items.push(item);
    item['support'] = "80";
    item['district'] = "Lindbergh";
    item['question'] = "Providing job training and retraining facilities and programs for good paying, high-in-demand, jobs in areas like healthcare, information technology (IT) and financial services.";
    item['questionGroup'] = "3";
    item['questionKey'] = "15";
    item.key = key++;
    
    item = {};
    this.items.push(item);
    item['support'] = "80";
    item['district'] = "Lindbergh";
    item['question'] = "Making sure the campus is safe and secure both night and day.";
    item['questionGroup'] = "4";
    item['questionKey'] = "16";
    item.key = key++;
    
    item = {};
    this.items.push(item);
    item['support'] = "80";
    item['district'] = "Lindbergh";
    item['question'] = "Keeping community college programs affordable.";
    item['questionGroup'] = "4";
    item['questionKey'] = "17";
    item.key = key++;
    
    item = {};
    this.items.push(item);
    item['support'] = "79.87686479";
    item['district'] = "Lindbergh";
    item['question'] = "Improving training programs so those who are underemployed and unemployed can secure jobs that pay a decent salary.";
    item['questionGroup'] = "4";
    item['questionKey'] = "18";
    item.key = key++;
    
    item = {};
    this.items.push(item);
    item['support'] = "73.87165522";
    item['district'] = "Lindbergh";
    item['question'] = "Making sure student support services such as registration and financial services are centralized and easily available to students.";
    item['questionGroup'] = "4";
    item['questionKey'] = "19";
    item.key = key++;
    
    item = {};
    this.items.push(item);
    item['support'] = "78.29031494";
    item['district'] = "Lindbergh";
    item['question'] = "Coordinating programs with area economic planners and businesses to make sure well-trained employees are available to help stimulate business growth and relocation in our community.";
    item['questionGroup'] = "4";
    item['questionKey'] = "20";
    item.key = key++;
    
    item = {};
    this.items.push(item);
    item['support'] = "79.82003315";
    item['district'] = "Lindbergh";
    item['question'] = "Providing real-world learning environments that provide students with the skills they need in the workplace.";
    item['questionGroup'] = "5";
    item['questionKey'] = "21";
    item.key = key++;
    
    item = {};
    this.items.push(item);
    item['support'] = "79.49798721";
    item['district'] = "Lindbergh";
    item['question'] = "Improving ADA accessibility at all campuses for students with disabilities.";
    item['questionGroup'] = "5";
    item['questionKey'] = "22";
    item.key = key++;
    
    item = {};
    this.items.push(item);
    item['support'] = "80";
    item['district'] = "Lindbergh";
    item['question'] = "We must ensure that our community college has adequate facilities and programs to meet the demand for high tech job training programs.";
    item['questionGroup'] = "5";
    item['questionKey'] = "23";
    item.key = key++;
    
    item = {};
    this.items.push(item);
    item['support'] = "62.37272081";
    item['district'] = "Lindbergh";
    item['question'] = "If the college needs more money, it should raise student fees, not increase the money it receives from taxes.";
    item['questionGroup'] = "5";
    item['questionKey'] = "24";
    item.key = key++;
    
    item = {};
    this.items.push(item);
    item['support'] = "79.93369642";
    item['district'] = "Lindbergh";
    item['question'] = "We must protect the investment we've made in our community college by keeping our programming current and our facilities up-to-date in good working condition.";
    item['questionGroup'] = "5";
    item['questionKey'] = "25";
    item.key = key++;
    
    item = {};
    this.items.push(item);
    item['support'] = "71.82571632";
    item['district'] = "Lindbergh";
    item['question'] = "Our community college is important to our area. But in terms of receiving more financial support, there are other things that are just more important.";
    item['questionGroup'] = "6";
    item['questionKey'] = "26";
    item.key = key++;
    
    item = {};
    this.items.push(item);
    item['support'] = "79.37958797";
    item['district'] = "Lindbergh";
    item['question'] = "Following more than a year of COVID-19, it is more important than ever to support the career education and training programs at our community college.";
    item['questionGroup'] = "6";
    item['questionKey'] = "27";
    item.key = key++;
    
    item = {};
    this.items.push(item);
    item['support'] = "73.91901492";
    item['district'] = "Lindbergh";
    item['question'] = "Unless we keep the college's programs and facilities up-to-date, many area workers will not be able to get the training they need to prepare for the good paying jobs of today and tomorrow, and that will hurt the local economy.";
    item['questionGroup'] = "6";
    item['questionKey'] = "28";
    item.key = key++;
    
    item = {};
    this.items.push(item);
    item['support'] = "77.39521667";
    item['district'] = "Lindbergh";
    item['question'] = "The community college's career education programs help create an educated workforce. That leads to an improved economy, and in the long term, that lowers local taxes. Supporting the community just makes financial sense.";
    item['questionGroup'] = "6";
    item['questionKey'] = "29";
    item.key = key++;
    
    item = {};
    this.items.push(item);
    item['support'] = "62.13118636";
    item['district'] = "Lindbergh";
    item['question'] = "I just don't trust local leadership with additional public dollars.";
    item['questionGroup'] = "6";
    item['questionKey'] = "30";
    item.key = key++;
    
    item = {};
    this.items.push(item);
    item['support'] = "75.15036704";
    item['district'] = "Lindbergh";
    item['question'] = "Right now there are thousands of good-paying jobs in our area that remain unfilled because businesses can't find employees with the necessary skills. This proposal helps train local residents for these jobs and keep them in our area.";
    item['questionGroup'] = "7";
    item['questionKey'] = "31";
    item.key = key++;
    
    item = {};
    this.items.push(item);
    item['support'] = "79.2659247";
    item['district'] = "Lindbergh";
    item['question'] = "Many businesses can't grow, or move to our area, because of the shortage of a well-trained workforce. Our economic future depends on supporting job training and retraining programs at our community college.";
    item['questionGroup'] = "7";
    item['questionKey'] = "32";
    item.key = key++;
    
    item = {};
    this.items.push(item);
    item['support'] = "49.44825953";
    item['district'] = "Lindbergh";
    item['question'] = "This proposal will enable the college to repurpose out-of-date and underutilized spaces to meet the needs of today's students and for future generations.";
    item['questionGroup'] = "7";
    item['questionKey'] = "33";
    item.key = key++;
    
    item = {};
    this.items.push(item);
    item['support'] = "72.67345489";
    item['district'] = "Lindbergh";
    item['question'] = "As asked earlier, assume that your local community college is seeking an 8-cent increase in property taxes to support improved facilities and programs. If you were voting today, is that something you would . . .";
    item['questionGroup'] = "7";
    item['questionKey'] = "34";
    item.key = key++;
    
    item = {};
    this.items.push(item);
    item['support'] = "66.26231208";
    item['district'] = "Maplewood-Richmond Heights";
    item['question'] = "Thinking about the next two or three months, do you think the economy in your area will . .";
    item['questionGroup'] = "1";
    item['questionKey'] = "1";
    item.key = key++;
    
    item = {};
    this.items.push(item);
    item['support'] = "79.844479";
    item['district'] = "Maplewood-Richmond Heights";
    item['question'] = "Students are often given the grades A, B, C, D, and F to evaluate their work. What grade would you give the community college in your area?";
    item['questionGroup'] = "1";
    item['questionKey'] = "2";
    item.key = key++;
    
    item = {};
    this.items.push(item);
    item['support'] = "45.89942976";
    item['district'] = "Maplewood-Richmond Heights";
    item['question'] = "Assume the community college in your area seeks voter approval for an 8-cent increase in property taxes to support improved facilities and programs. If you were voting today, is that something you .";
    item['questionGroup'] = "1";
    item['questionKey'] = "3";
    item.key = key++;
    
    item = {};
    this.items.push(item);
    item['support'] = "28.29445308";
    item['district'] = "Maplewood-Richmond Heights";
    item['question'] = "If you knew this proposal would cost about $1.90 per month for a $150,000 house, is this proposal something you . . .";
    item['questionGroup'] = "1";
    item['questionKey'] = "4";
    item.key = key++;
    
    item = {};
    this.items.push(item);
    item['support'] = "80";
    item['district'] = "Maplewood-Richmond Heights";
    item['question'] = "It is essential that our area has a quality community college as a place for adults to be trained and retrained for the good paying jobs of today and tomorrow.";
    item['questionGroup'] = "1";
    item['questionKey'] = "5";
    item.key = key++;
    
    item = {};
    this.items.push(item);
    item['support'] = "63.2970451";
    item['district'] = "Maplewood-Richmond Heights";
    item['question'] = "People like me can't afford to pay higher taxes, no matter how good the cause.";
    item['questionGroup'] = "2";
    item['questionKey'] = "6";
    item.key = key++;
    
    item = {};
    this.items.push(item);
    item['support'] = "75.76982893";
    item['district'] = "Maplewood-Richmond Heights";
    item['question'] = "We get excellent value from what we pay to support our community college.";
    item['questionGroup'] = "2";
    item['questionKey'] = "7";
    item.key = key++;
    
    item = {};
    this.items.push(item);
    item['support'] = "37.86417833";
    item['district'] = "Maplewood-Richmond Heights";
    item['question'] = "The local community college does not serve the needs of people like me and my family.";
    item['questionGroup'] = "2";
    item['questionKey'] = "8";
    item.key = key++;
    
    item = {};
    this.items.push(item);
    item['support'] = "46.29341628";
    item['district'] = "Maplewood-Richmond Heights";
    item['question'] = "I'm willing to pay more to support the community college's efforts to help our area economically recover from the COVID-19 pandemic.";
    item['questionGroup'] = "2";
    item['questionKey'] = "9";
    item.key = key++;
    
    item = {};
    this.items.push(item);
    item['support'] = "71.89217211";
    item['district'] = "Maplewood-Richmond Heights";
    item['question'] = "Our community college is an important anchor for our regional economy.";
    item['questionGroup'] = "2";
    item['questionKey'] = "10";
    item.key = key++;
    
    item = {};
    this.items.push(item);
    item['support'] = "77.59460861";
    item['district'] = "Maplewood-Richmond Heights";
    item['question'] = "Making sure all of the college's instructional areas are equipped with up-to-date instructional technology.";
    item['questionGroup'] = "3";
    item['questionKey'] = "11";
    item.key = key++;
    
    item = {};
    this.items.push(item);
    item['support'] = "61.1404873";
    item['district'] = "Maplewood-Richmond Heights";
    item['question'] = "Replacing buildings that are more than a half-century old, and are expensive and inefficient to operate and maintain.";
    item['questionGroup'] = "3";
    item['questionKey'] = "12";
    item.key = key++;
    
    item = {};
    this.items.push(item);
    item['support'] = "77.79160187";
    item['district'] = "Maplewood-Richmond Heights";
    item['question'] = "Providing learning spaces to accommodate best practices in job training and retraining programs.";
    item['questionGroup'] = "3";
    item['questionKey'] = "13";
    item.key = key++;
    
    item = {};
    this.items.push(item);
    item['support'] = "75.24105754";
    item['district'] = "Maplewood-Richmond Heights";
    item['question'] = "Repairing and replacing aging roofs, doors, windows, plumbing, HVAC, electrical and other infrastructure conditions as needed.";
    item['questionGroup'] = "3";
    item['questionKey'] = "14";
    item.key = key++;
    
    item = {};
    this.items.push(item);
    item['support'] = "80";
    item['district'] = "Maplewood-Richmond Heights";
    item['question'] = "Providing job training and retraining facilities and programs for good paying, high-in-demand, jobs in areas like healthcare, information technology (IT) and financial services.";
    item['questionGroup'] = "3";
    item['questionKey'] = "15";
    item.key = key++;
    
    item = {};
    this.items.push(item);
    item['support'] = "80";
    item['district'] = "Maplewood-Richmond Heights";
    item['question'] = "Making sure the campus is safe and secure both night and day.";
    item['questionGroup'] = "4";
    item['questionKey'] = "16";
    item.key = key++;
    
    item = {};
    this.items.push(item);
    item['support'] = "80";
    item['district'] = "Maplewood-Richmond Heights";
    item['question'] = "Keeping community college programs affordable.";
    item['questionGroup'] = "4";
    item['questionKey'] = "17";
    item.key = key++;
    
    item = {};
    this.items.push(item);
    item['support'] = "79.38828409";
    item['district'] = "Maplewood-Richmond Heights";
    item['question'] = "Improving training programs so those who are underemployed and unemployed can secure jobs that pay a decent salary.";
    item['questionGroup'] = "4";
    item['questionKey'] = "18";
    item.key = key++;
    
    item = {};
    this.items.push(item);
    item['support'] = "75.4069466";
    item['district'] = "Maplewood-Richmond Heights";
    item['question'] = "Making sure student support services such as registration and financial services are centralized and easily available to students.";
    item['questionGroup'] = "4";
    item['questionKey'] = "19";
    item.key = key++;
    
    item = {};
    this.items.push(item);
    item['support'] = "76.32970451";
    item['district'] = "Maplewood-Richmond Heights";
    item['question'] = "Coordinating programs with area economic planners and businesses to make sure well-trained employees are available to help stimulate business growth and relocation in our community.";
    item['questionGroup'] = "4";
    item['questionKey'] = "20";
    item.key = key++;
    
    item = {};
    this.items.push(item);
    item['support'] = "79.21202696";
    item['district'] = "Maplewood-Richmond Heights";
    item['question'] = "Providing real-world learning environments that provide students with the skills they need in the workplace.";
    item['questionGroup'] = "5";
    item['questionKey'] = "21";
    item.key = key++;
    
    item = {};
    this.items.push(item);
    item['support'] = "78.96319336";
    item['district'] = "Maplewood-Richmond Heights";
    item['question'] = "Improving ADA accessibility at all campuses for students with disabilities.";
    item['questionGroup'] = "5";
    item['questionKey'] = "22";
    item.key = key++;
    
    item = {};
    this.items.push(item);
    item['support'] = "80";
    item['district'] = "Maplewood-Richmond Heights";
    item['question'] = "We must ensure that our community college has adequate facilities and programs to meet the demand for high tech job training programs.";
    item['questionGroup'] = "5";
    item['questionKey'] = "23";
    item.key = key++;
    
    item = {};
    this.items.push(item);
    item['support'] = "60.16588906";
    item['district'] = "Maplewood-Richmond Heights";
    item['question'] = "If the college needs more money, it should raise student fees, not increase the money it receives from taxes.";
    item['questionGroup'] = "5";
    item['questionKey'] = "24";
    item.key = key++;
    
    item = {};
    this.items.push(item);
    item['support'] = "79.52306895";
    item['district'] = "Maplewood-Richmond Heights";
    item['question'] = "We must protect the investment we've made in our community college by keeping our programming current and our facilities up-to-date in good working condition.";
    item['questionGroup'] = "5";
    item['questionKey'] = "25";
    item.key = key++;
    
    item = {};
    this.items.push(item);
    item['support'] = "70.78278901";
    item['district'] = "Maplewood-Richmond Heights";
    item['question'] = "Our community college is important to our area. But in terms of receiving more financial support, there are other things that are just more important.";
    item['questionGroup'] = "6";
    item['questionKey'] = "26";
    item.key = key++;
    
    item = {};
    this.items.push(item);
    item['support'] = "79.0461379";
    item['district'] = "Maplewood-Richmond Heights";
    item['question'] = "Following more than a year of COVID-19, it is more important than ever to support the career education and training programs at our community college.";
    item['questionGroup'] = "6";
    item['questionKey'] = "27";
    item.key = key++;
    
    item = {};
    this.items.push(item);
    item['support'] = "74.84707102";
    item['district'] = "Maplewood-Richmond Heights";
    item['question'] = "Unless we keep the college's programs and facilities up-to-date, many area workers will not be able to get the training they need to prepare for the good paying jobs of today and tomorrow, and that will hurt the local economy.";
    item['questionGroup'] = "6";
    item['questionKey'] = "28";
    item.key = key++;
    
    item = {};
    this.items.push(item);
    item['support'] = "78.19595645";
    item['district'] = "Maplewood-Richmond Heights";
    item['question'] = "The community college's career education programs help create an educated workforce. That leads to an improved economy, and in the long term, that lowers local taxes. Supporting the community just makes financial sense.";
    item['questionGroup'] = "6";
    item['questionKey'] = "29";
    item.key = key++;
    
    item = {};
    this.items.push(item);
    item['support'] = "62.82011405";
    item['district'] = "Maplewood-Richmond Heights";
    item['question'] = "I just don't trust local leadership with additional public dollars.";
    item['questionGroup'] = "6";
    item['questionKey'] = "30";
    item.key = key++;
    
    item = {};
    this.items.push(item);
    item['support'] = "73.86210472";
    item['district'] = "Maplewood-Richmond Heights";
    item['question'] = "Right now there are thousands of good-paying jobs in our area that remain unfilled because businesses can't find employees with the necessary skills. This proposal helps train local residents for these jobs and keep them in our area.";
    item['questionGroup'] = "7";
    item['questionKey'] = "31";
    item.key = key++;
    
    item = {};
    this.items.push(item);
    item['support'] = "77.26283048";
    item['district'] = "Maplewood-Richmond Heights";
    item['question'] = "Many businesses can't grow, or move to our area, because of the shortage of a well-trained workforce. Our economic future depends on supporting job training and retraining programs at our community college.";
    item['questionGroup'] = "7";
    item['questionKey'] = "32";
    item.key = key++;
    
    item = {};
    this.items.push(item);
    item['support'] = "50.74131674";
    item['district'] = "Maplewood-Richmond Heights";
    item['question'] = "This proposal will enable the college to repurpose out-of-date and underutilized spaces to meet the needs of today's students and for future generations.";
    item['questionGroup'] = "7";
    item['questionKey'] = "33";
    item.key = key++;
    
    item = {};
    this.items.push(item);
    item['support'] = "72.37947123";
    item['district'] = "Maplewood-Richmond Heights";
    item['question'] = "As asked earlier, assume that your local community college is seeking an 8-cent increase in property taxes to support improved facilities and programs. If you were voting today, is that something you would . . .";
    item['questionGroup'] = "7";
    item['questionKey'] = "34";
    item.key = key++;
    
    item = {};
    this.items.push(item);
    item['support'] = "66.00541377";
    item['district'] = "Mehlville R-IX";
    item['question'] = "Thinking about the next two or three months, do you think the economy in your area will . .";
    item['questionGroup'] = "1";
    item['questionKey'] = "1";
    item.key = key++;
    
    item = {};
    this.items.push(item);
    item['support'] = "79.97293117";
    item['district'] = "Mehlville R-IX";
    item['question'] = "Students are often given the grades A, B, C, D, and F to evaluate their work. What grade would you give the community college in your area?";
    item['questionGroup'] = "1";
    item['questionKey'] = "2";
    item.key = key++;
    
    item = {};
    this.items.push(item);
    item['support'] = "42.93890178";
    item['district'] = "Mehlville R-IX";
    item['question'] = "Assume the community college in your area seeks voter approval for an 8-cent increase in property taxes to support improved facilities and programs. If you were voting today, is that something you .";
    item['questionGroup'] = "1";
    item['questionKey'] = "3";
    item.key = key++;
    
    item = {};
    this.items.push(item);
    item['support'] = "25.40216551";
    item['district'] = "Mehlville R-IX";
    item['question'] = "If you knew this proposal would cost about $1.90 per month for a $150,000 house, is this proposal something you . . .";
    item['questionGroup'] = "1";
    item['questionKey'] = "4";
    item.key = key++;
    
    item = {};
    this.items.push(item);
    item['support'] = "80";
    item['district'] = "Mehlville R-IX";
    item['question'] = "It is essential that our area has a quality community college as a place for adults to be trained and retrained for the good paying jobs of today and tomorrow.";
    item['questionGroup'] = "1";
    item['questionKey'] = "5";
    item.key = key++;
    
    item = {};
    this.items.push(item);
    item['support'] = "55.51044084";
    item['district'] = "Mehlville R-IX";
    item['question'] = "People like me can't afford to pay higher taxes, no matter how good the cause.";
    item['questionGroup'] = "2";
    item['questionKey'] = "6";
    item.key = key++;
    
    item = {};
    this.items.push(item);
    item['support'] = "75.27842227";
    item['district'] = "Mehlville R-IX";
    item['question'] = "We get excellent value from what we pay to support our community college.";
    item['questionGroup'] = "2";
    item['questionKey'] = "7";
    item.key = key++;
    
    item = {};
    this.items.push(item);
    item['support'] = "35.30935808";
    item['district'] = "Mehlville R-IX";
    item['question'] = "The local community college does not serve the needs of people like me and my family.";
    item['questionGroup'] = "2";
    item['questionKey'] = "8";
    item.key = key++;
    
    item = {};
    this.items.push(item);
    item['support'] = "47.48259861";
    item['district'] = "Mehlville R-IX";
    item['question'] = "I'm willing to pay more to support the community college's efforts to help our area economically recover from the COVID-19 pandemic.";
    item['questionGroup'] = "2";
    item['questionKey'] = "9";
    item.key = key++;
    
    item = {};
    this.items.push(item);
    item['support'] = "71.2412993";
    item['district'] = "Mehlville R-IX";
    item['question'] = "Our community college is an important anchor for our regional economy.";
    item['questionGroup'] = "2";
    item['questionKey'] = "10";
    item.key = key++;
    
    item = {};
    this.items.push(item);
    item['support'] = "78.87470998";
    item['district'] = "Mehlville R-IX";
    item['question'] = "Making sure all of the college's instructional areas are equipped with up-to-date instructional technology.";
    item['questionGroup'] = "3";
    item['questionKey'] = "11";
    item.key = key++;
    
    item = {};
    this.items.push(item);
    item['support'] = "60.2900232";
    item['district'] = "Mehlville R-IX";
    item['question'] = "Replacing buildings that are more than a half-century old, and are expensive and inefficient to operate and maintain.";
    item['questionGroup'] = "3";
    item['questionKey'] = "12";
    item.key = key++;
    
    item = {};
    this.items.push(item);
    item['support'] = "79.18020108";
    item['district'] = "Mehlville R-IX";
    item['question'] = "Providing learning spaces to accommodate best practices in job training and retraining programs.";
    item['questionGroup'] = "3";
    item['questionKey'] = "13";
    item.key = key++;
    
    item = {};
    this.items.push(item);
    item['support'] = "76.96442382";
    item['district'] = "Mehlville R-IX";
    item['question'] = "Repairing and replacing aging roofs, doors, windows, plumbing, HVAC, electrical and other infrastructure conditions as needed.";
    item['questionGroup'] = "3";
    item['questionKey'] = "14";
    item.key = key++;
    
    item = {};
    this.items.push(item);
    item['support'] = "80";
    item['district'] = "Mehlville R-IX";
    item['question'] = "Providing job training and retraining facilities and programs for good paying, high-in-demand, jobs in areas like healthcare, information technology (IT) and financial services.";
    item['questionGroup'] = "3";
    item['questionKey'] = "15";
    item.key = key++;
    
    item = {};
    this.items.push(item);
    item['support'] = "80";
    item['district'] = "Mehlville R-IX";
    item['question'] = "Making sure the campus is safe and secure both night and day.";
    item['questionGroup'] = "4";
    item['questionKey'] = "16";
    item.key = key++;
    
    item = {};
    this.items.push(item);
    item['support'] = "80";
    item['district'] = "Mehlville R-IX";
    item['question'] = "Keeping community college programs affordable.";
    item['questionGroup'] = "4";
    item['questionKey'] = "17";
    item.key = key++;
    
    item = {};
    this.items.push(item);
    item['support'] = "79.73704563";
    item['district'] = "Mehlville R-IX";
    item['question'] = "Improving training programs so those who are underemployed and unemployed can secure jobs that pay a decent salary.";
    item['questionGroup'] = "4";
    item['questionKey'] = "18";
    item.key = key++;
    
    item = {};
    this.items.push(item);
    item['support'] = "73.80123743";
    item['district'] = "Mehlville R-IX";
    item['question'] = "Making sure student support services such as registration and financial services are centralized and easily available to students.";
    item['questionGroup'] = "4";
    item['questionKey'] = "19";
    item.key = key++;
    
    item = {};
    this.items.push(item);
    item['support'] = "77.73395205";
    item['district'] = "Mehlville R-IX";
    item['question'] = "Coordinating programs with area economic planners and businesses to make sure well-trained employees are available to help stimulate business growth and relocation in our community.";
    item['questionGroup'] = "4";
    item['questionKey'] = "20";
    item.key = key++;
    
    item = {};
    this.items.push(item);
    item['support'] = "79.62103635";
    item['district'] = "Mehlville R-IX";
    item['question'] = "Providing real-world learning environments that provide students with the skills they need in the workplace.";
    item['questionGroup'] = "5";
    item['questionKey'] = "21";
    item.key = key++;
    
    item = {};
    this.items.push(item);
    item['support'] = "79.39288476";
    item['district'] = "Mehlville R-IX";
    item['question'] = "Improving ADA accessibility at all campuses for students with disabilities.";
    item['questionGroup'] = "5";
    item['questionKey'] = "22";
    item.key = key++;
    
    item = {};
    this.items.push(item);
    item['support'] = "80";
    item['district'] = "Mehlville R-IX";
    item['question'] = "We must ensure that our community college has adequate facilities and programs to meet the demand for high tech job training programs.";
    item['questionGroup'] = "5";
    item['questionKey'] = "23";
    item.key = key++;
    
    item = {};
    this.items.push(item);
    item['support'] = "62.03402939";
    item['district'] = "Mehlville R-IX";
    item['question'] = "If the college needs more money, it should raise student fees, not increase the money it receives from taxes.";
    item['questionGroup'] = "5";
    item['questionKey'] = "24";
    item.key = key++;
    
    item = {};
    this.items.push(item);
    item['support'] = "79.89945862";
    item['district'] = "Mehlville R-IX";
    item['question'] = "We must protect the investment we've made in our community college by keeping our programming current and our facilities up-to-date in good working condition.";
    item['questionGroup'] = "5";
    item['questionKey'] = "25";
    item.key = key++;
    
    item = {};
    this.items.push(item);
    item['support'] = "71.4037123";
    item['district'] = "Mehlville R-IX";
    item['question'] = "Our community college is important to our area. But in terms of receiving more financial support, there are other things that are just more important.";
    item['questionGroup'] = "6";
    item['questionKey'] = "26";
    item.key = key++;
    
    item = {};
    this.items.push(item);
    item['support'] = "79.38901779";
    item['district'] = "Mehlville R-IX";
    item['question'] = "Following more than a year of COVID-19, it is more important than ever to support the career education and training programs at our community college.";
    item['questionGroup'] = "6";
    item['questionKey'] = "27";
    item.key = key++;
    
    item = {};
    this.items.push(item);
    item['support'] = "73.13611756";
    item['district'] = "Mehlville R-IX";
    item['question'] = "Unless we keep the college's programs and facilities up-to-date, many area workers will not be able to get the training they need to prepare for the good paying jobs of today and tomorrow, and that will hurt the local economy.";
    item['questionGroup'] = "6";
    item['questionKey'] = "28";
    item.key = key++;
    
    item = {};
    this.items.push(item);
    item['support'] = "77.20804331";
    item['district'] = "Mehlville R-IX";
    item['question'] = "The community college's career education programs help create an educated workforce. That leads to an improved economy, and in the long term, that lowers local taxes. Supporting the community just makes financial sense.";
    item['questionGroup'] = "6";
    item['questionKey'] = "29";
    item.key = key++;
    
    item = {};
    this.items.push(item);
    item['support'] = "62.61020882";
    item['district'] = "Mehlville R-IX";
    item['question'] = "I just don't trust local leadership with additional public dollars.";
    item['questionGroup'] = "6";
    item['questionKey'] = "30";
    item.key = key++;
    
    item = {};
    this.items.push(item);
    item['support'] = "74.24980665";
    item['district'] = "Mehlville R-IX";
    item['question'] = "Right now there are thousands of good-paying jobs in our area that remain unfilled because businesses can't find employees with the necessary skills. This proposal helps train local residents for these jobs and keep them in our area.";
    item['questionGroup'] = "7";
    item['questionKey'] = "31";
    item.key = key++;
    
    item = {};
    this.items.push(item);
    item['support'] = "79.03325599";
    item['district'] = "Mehlville R-IX";
    item['question'] = "Many businesses can't grow, or move to our area, because of the shortage of a well-trained workforce. Our economic future depends on supporting job training and retraining programs at our community college.";
    item['questionGroup'] = "7";
    item['questionKey'] = "32";
    item.key = key++;
    
    item = {};
    this.items.push(item);
    item['support'] = "50.27068832";
    item['district'] = "Mehlville R-IX";
    item['question'] = "This proposal will enable the college to repurpose out-of-date and underutilized spaces to meet the needs of today's students and for future generations.";
    item['questionGroup'] = "7";
    item['questionKey'] = "33";
    item.key = key++;
    
    item = {};
    this.items.push(item);
    item['support'] = "71.50425367";
    item['district'] = "Mehlville R-IX";
    item['question'] = "As asked earlier, assume that your local community college is seeking an 8-cent increase in property taxes to support improved facilities and programs. If you were voting today, is that something you would . . .";
    item['questionGroup'] = "7";
    item['questionKey'] = "34";
    item.key = key++;
    
    item = {};
    this.items.push(item);
    item['support'] = "65.63965314";
    item['district'] = "Parkway C-2";
    item['question'] = "Thinking about the next two or three months, do you think the economy in your area will . .";
    item['questionGroup'] = "1";
    item['questionKey'] = "1";
    item.key = key++;
    
    item = {};
    this.items.push(item);
    item['support'] = "79.98715341";
    item['district'] = "Parkway C-2";
    item['question'] = "Students are often given the grades A, B, C, D, and F to evaluate their work. What grade would you give the community college in your area?";
    item['questionGroup'] = "1";
    item['questionKey'] = "2";
    item.key = key++;
    
    item = {};
    this.items.push(item);
    item['support'] = "42.43014667";
    item['district'] = "Parkway C-2";
    item['question'] = "Assume the community college in your area seeks voter approval for an 8-cent increase in property taxes to support improved facilities and programs. If you were voting today, is that something you .";
    item['questionGroup'] = "1";
    item['questionKey'] = "3";
    item.key = key++;
    
    item = {};
    this.items.push(item);
    item['support'] = "24.07236913";
    item['district'] = "Parkway C-2";
    item['question'] = "If you knew this proposal would cost about $1.90 per month for a $150,000 house, is this proposal something you . . .";
    item['questionGroup'] = "1";
    item['questionKey'] = "4";
    item.key = key++;
    
    item = {};
    this.items.push(item);
    item['support'] = "80";
    item['district'] = "Parkway C-2";
    item['question'] = "It is essential that our area has a quality community college as a place for adults to be trained and retrained for the good paying jobs of today and tomorrow.";
    item['questionGroup'] = "1";
    item['questionKey'] = "5";
    item.key = key++;
    
    item = {};
    this.items.push(item);
    item['support'] = "50.79970025";
    item['district'] = "Parkway C-2";
    item['question'] = "People like me can't afford to pay higher taxes, no matter how good the cause.";
    item['questionGroup'] = "2";
    item['questionKey'] = "6";
    item.key = key++;
    
    item = {};
    this.items.push(item);
    item['support'] = "75.46301253";
    item['district'] = "Parkway C-2";
    item['question'] = "We get excellent value from what we pay to support our community college.";
    item['questionGroup'] = "2";
    item['questionKey'] = "7";
    item.key = key++;
    
    item = {};
    this.items.push(item);
    item['support'] = "37.03671984";
    item['district'] = "Parkway C-2";
    item['question'] = "The local community college does not serve the needs of people like me and my family.";
    item['questionGroup'] = "2";
    item['questionKey'] = "8";
    item.key = key++;
    
    item = {};
    this.items.push(item);
    item['support'] = "46.60528851";
    item['district'] = "Parkway C-2";
    item['question'] = "I'm willing to pay more to support the community college's efforts to help our area economically recover from the COVID-19 pandemic.";
    item['questionGroup'] = "2";
    item['questionKey'] = "9";
    item.key = key++;
    
    item = {};
    this.items.push(item);
    item['support'] = "71.07161974";
    item['district'] = "Parkway C-2";
    item['question'] = "Our community college is an important anchor for our regional economy.";
    item['questionGroup'] = "2";
    item['questionKey'] = "10";
    item.key = key++;
    
    item = {};
    this.items.push(item);
    item['support'] = "79.52253506";
    item['district'] = "Parkway C-2";
    item['question'] = "Making sure all of the college's instructional areas are equipped with up-to-date instructional technology.";
    item['questionGroup'] = "3";
    item['questionKey'] = "11";
    item.key = key++;
    
    item = {};
    this.items.push(item);
    item['support'] = "60.53955679";
    item['district'] = "Parkway C-2";
    item['question'] = "Replacing buildings that are more than a half-century old, and are expensive and inefficient to operate and maintain.";
    item['questionGroup'] = "3";
    item['questionKey'] = "12";
    item.key = key++;
    
    item = {};
    this.items.push(item);
    item['support'] = "79.49684188";
    item['district'] = "Parkway C-2";
    item['question'] = "Providing learning spaces to accommodate best practices in job training and retraining programs.";
    item['questionGroup'] = "3";
    item['questionKey'] = "13";
    item.key = key++;
    
    item = {};
    this.items.push(item);
    item['support'] = "78.29354459";
    item['district'] = "Parkway C-2";
    item['question'] = "Repairing and replacing aging roofs, doors, windows, plumbing, HVAC, electrical and other infrastructure conditions as needed.";
    item['questionGroup'] = "3";
    item['questionKey'] = "14";
    item.key = key++;
    
    item = {};
    this.items.push(item);
    item['support'] = "80";
    item['district'] = "Parkway C-2";
    item['question'] = "Providing job training and retraining facilities and programs for good paying, high-in-demand, jobs in areas like healthcare, information technology (IT) and financial services.";
    item['questionGroup'] = "3";
    item['questionKey'] = "15";
    item.key = key++;
    
    item = {};
    this.items.push(item);
    item['support'] = "80";
    item['district'] = "Parkway C-2";
    item['question'] = "Making sure the campus is safe and secure both night and day.";
    item['questionGroup'] = "4";
    item['questionKey'] = "16";
    item.key = key++;
    
    item = {};
    this.items.push(item);
    item['support'] = "80";
    item['district'] = "Parkway C-2";
    item['question'] = "Keeping community college programs affordable.";
    item['questionGroup'] = "4";
    item['questionKey'] = "17";
    item.key = key++;
    
    item = {};
    this.items.push(item);
    item['support'] = "79.91435606";
    item['district'] = "Parkway C-2";
    item['question'] = "Improving training programs so those who are underemployed and unemployed can secure jobs that pay a decent salary.";
    item['questionGroup'] = "4";
    item['questionKey'] = "18";
    item.key = key++;
    
    item = {};
    this.items.push(item);
    item['support'] = "71.37993791";
    item['district'] = "Parkway C-2";
    item['question'] = "Making sure student support services such as registration and financial services are centralized and easily available to students.";
    item['questionGroup'] = "4";
    item['questionKey'] = "19";
    item.key = key++;
    
    item = {};
    this.items.push(item);
    item['support'] = "78.73889305";
    item['district'] = "Parkway C-2";
    item['question'] = "Coordinating programs with area economic planners and businesses to make sure well-trained employees are available to help stimulate business growth and relocation in our community.";
    item['questionGroup'] = "4";
    item['questionKey'] = "20";
    item.key = key++;
    
    item = {};
    this.items.push(item);
    item['support'] = "79.89722728";
    item['district'] = "Parkway C-2";
    item['question'] = "Providing real-world learning environments that provide students with the skills they need in the workplace.";
    item['questionGroup'] = "5";
    item['questionKey'] = "21";
    item.key = key++;
    
    item = {};
    this.items.push(item);
    item['support'] = "79.74735039";
    item['district'] = "Parkway C-2";
    item['question'] = "Improving ADA accessibility at all campuses for students with disabilities.";
    item['questionGroup'] = "5";
    item['questionKey'] = "22";
    item.key = key++;
    
    item = {};
    this.items.push(item);
    item['support'] = "80";
    item['district'] = "Parkway C-2";
    item['question'] = "We must ensure that our community college has adequate facilities and programs to meet the demand for high tech job training programs.";
    item['questionGroup'] = "5";
    item['questionKey'] = "23";
    item.key = key++;
    
    item = {};
    this.items.push(item);
    item['support'] = "62.63997431";
    item['district'] = "Parkway C-2";
    item['question'] = "If the college needs more money, it should raise student fees, not increase the money it receives from taxes.";
    item['questionGroup'] = "5";
    item['questionKey'] = "24";
    item.key = key++;
    
    item = {};
    this.items.push(item);
    item['support'] = "79.97858902";
    item['district'] = "Parkway C-2";
    item['question'] = "We must protect the investment we've made in our community college by keeping our programming current and our facilities up-to-date in good working condition.";
    item['questionGroup'] = "5";
    item['questionKey'] = "25";
    item.key = key++;
    
    item = {};
    this.items.push(item);
    item['support'] = "72.98790279";
    item['district'] = "Parkway C-2";
    item['question'] = "Our community college is important to our area. But in terms of receiving more financial support, there are other things that are just more important.";
    item['questionGroup'] = "6";
    item['questionKey'] = "26";
    item.key = key++;
    
    item = {};
    this.items.push(item);
    item['support'] = "79.50326518";
    item['district'] = "Parkway C-2";
    item['question'] = "Following more than a year of COVID-19, it is more important than ever to support the career education and training programs at our community college.";
    item['questionGroup'] = "6";
    item['questionKey'] = "27";
    item.key = key++;
    
    item = {};
    this.items.push(item);
    item['support'] = "72.65175035";
    item['district'] = "Parkway C-2";
    item['question'] = "Unless we keep the college's programs and facilities up-to-date, many area workers will not be able to get the training they need to prepare for the good paying jobs of today and tomorrow, and that will hurt the local economy.";
    item['questionGroup'] = "6";
    item['questionKey'] = "28";
    item.key = key++;
    
    item = {};
    this.items.push(item);
    item['support'] = "74.89776255";
    item['district'] = "Parkway C-2";
    item['question'] = "The community college's career education programs help create an educated workforce. That leads to an improved economy, and in the long term, that lowers local taxes. Supporting the community just makes financial sense.";
    item['questionGroup'] = "6";
    item['questionKey'] = "29";
    item.key = key++;
    
    item = {};
    this.items.push(item);
    item['support'] = "65.12150733";
    item['district'] = "Parkway C-2";
    item['question'] = "I just don't trust local leadership with additional public dollars.";
    item['questionGroup'] = "6";
    item['questionKey'] = "30";
    item.key = key++;
    
    item = {};
    this.items.push(item);
    item['support'] = "72.96863291";
    item['district'] = "Parkway C-2";
    item['question'] = "Right now there are thousands of good-paying jobs in our area that remain unfilled because businesses can't find employees with the necessary skills. This proposal helps train local residents for these jobs and keep them in our area.";
    item['questionGroup'] = "7";
    item['questionKey'] = "31";
    item.key = key++;
    
    item = {};
    this.items.push(item);
    item['support'] = "79.6060379";
    item['district'] = "Parkway C-2";
    item['question'] = "Many businesses can't grow, or move to our area, because of the shortage of a well-trained workforce. Our economic future depends on supporting job training and retraining programs at our community college.";
    item['questionGroup'] = "7";
    item['questionKey'] = "32";
    item.key = key++;
    
    item = {};
    this.items.push(item);
    item['support'] = "47.88780644";
    item['district'] = "Parkway C-2";
    item['question'] = "This proposal will enable the college to repurpose out-of-date and underutilized spaces to meet the needs of today's students and for future generations.";
    item['questionGroup'] = "7";
    item['questionKey'] = "33";
    item.key = key++;
    
    item = {};
    this.items.push(item);
    item['support'] = "71.61545873";
    item['district'] = "Parkway C-2";
    item['question'] = "As asked earlier, assume that your local community college is seeking an 8-cent increase in property taxes to support improved facilities and programs. If you were voting today, is that something you would . . .";
    item['questionGroup'] = "7";
    item['questionKey'] = "34";
    item.key = key++;
    
    item = {};
    this.items.push(item);
    item['support'] = "70";
    item['district'] = "Pattonville R-III";
    item['question'] = "Thinking about the next two or three months, do you think the economy in your area will . .";
    item['questionGroup'] = "1";
    item['questionKey'] = "1";
    item.key = key++;
    
    item = {};
    this.items.push(item);
    item['support'] = "80";
    item['district'] = "Pattonville R-III";
    item['question'] = "Students are often given the grades A, B, C, D, and F to evaluate their work. What grade would you give the community college in your area?";
    item['questionGroup'] = "1";
    item['questionKey'] = "2";
    item.key = key++;
    
    item = {};
    this.items.push(item);
    item['support'] = "50";
    item['district'] = "Pattonville R-III";
    item['question'] = "Assume the community college in your area seeks voter approval for an 8-cent increase in property taxes to support improved facilities and programs. If you were voting today, is that something you .";
    item['questionGroup'] = "1";
    item['questionKey'] = "3";
    item.key = key++;
    
    item = {};
    this.items.push(item);
    item['support'] = "30";
    item['district'] = "Pattonville R-III";
    item['question'] = "If you knew this proposal would cost about $1.90 per month for a $150,000 house, is this proposal something you . . .";
    item['questionGroup'] = "1";
    item['questionKey'] = "4";
    item.key = key++;
    
    item = {};
    this.items.push(item);
    item['support'] = "80";
    item['district'] = "Pattonville R-III";
    item['question'] = "It is essential that our area has a quality community college as a place for adults to be trained and retrained for the good paying jobs of today and tomorrow.";
    item['questionGroup'] = "1";
    item['questionKey'] = "5";
    item.key = key++;
    
    item = {};
    this.items.push(item);
    item['support'] = "60";
    item['district'] = "Pattonville R-III";
    item['question'] = "People like me can't afford to pay higher taxes, no matter how good the cause.";
    item['questionGroup'] = "2";
    item['questionKey'] = "6";
    item.key = key++;
    
    item = {};
    this.items.push(item);
    item['support'] = "80";
    item['district'] = "Pattonville R-III";
    item['question'] = "We get excellent value from what we pay to support our community college.";
    item['questionGroup'] = "2";
    item['questionKey'] = "7";
    item.key = key++;
    
    item = {};
    this.items.push(item);
    item['support'] = "30";
    item['district'] = "Pattonville R-III";
    item['question'] = "The local community college does not serve the needs of people like me and my family.";
    item['questionGroup'] = "2";
    item['questionKey'] = "8";
    item.key = key++;
    
    item = {};
    this.items.push(item);
    item['support'] = "50";
    item['district'] = "Pattonville R-III";
    item['question'] = "I'm willing to pay more to support the community college's efforts to help our area economically recover from the COVID-19 pandemic.";
    item['questionGroup'] = "2";
    item['questionKey'] = "9";
    item.key = key++;
    
    item = {};
    this.items.push(item);
    item['support'] = "70";
    item['district'] = "Pattonville R-III";
    item['question'] = "Our community college is an important anchor for our regional economy.";
    item['questionGroup'] = "2";
    item['questionKey'] = "10";
    item.key = key++;
    
    item = {};
    this.items.push(item);
    item['support'] = "80";
    item['district'] = "Pattonville R-III";
    item['question'] = "Making sure all of the college's instructional areas are equipped with up-to-date instructional technology.";
    item['questionGroup'] = "3";
    item['questionKey'] = "11";
    item.key = key++;
    
    item = {};
    this.items.push(item);
    item['support'] = "60";
    item['district'] = "Pattonville R-III";
    item['question'] = "Replacing buildings that are more than a half-century old, and are expensive and inefficient to operate and maintain.";
    item['questionGroup'] = "3";
    item['questionKey'] = "12";
    item.key = key++;
    
    item = {};
    this.items.push(item);
    item['support'] = "80";
    item['district'] = "Pattonville R-III";
    item['question'] = "Providing learning spaces to accommodate best practices in job training and retraining programs.";
    item['questionGroup'] = "3";
    item['questionKey'] = "13";
    item.key = key++;
    
    item = {};
    this.items.push(item);
    item['support'] = "70";
    item['district'] = "Pattonville R-III";
    item['question'] = "Repairing and replacing aging roofs, doors, windows, plumbing, HVAC, electrical and other infrastructure conditions as needed.";
    item['questionGroup'] = "3";
    item['questionKey'] = "14";
    item.key = key++;
    
    item = {};
    this.items.push(item);
    item['support'] = "80";
    item['district'] = "Pattonville R-III";
    item['question'] = "Providing job training and retraining facilities and programs for good paying, high-in-demand, jobs in areas like healthcare, information technology (IT) and financial services.";
    item['questionGroup'] = "3";
    item['questionKey'] = "15";
    item.key = key++;
    
    item = {};
    this.items.push(item);
    item['support'] = "80";
    item['district'] = "Pattonville R-III";
    item['question'] = "Making sure the campus is safe and secure both night and day.";
    item['questionGroup'] = "4";
    item['questionKey'] = "16";
    item.key = key++;
    
    item = {};
    this.items.push(item);
    item['support'] = "80";
    item['district'] = "Pattonville R-III";
    item['question'] = "Keeping community college programs affordable.";
    item['questionGroup'] = "4";
    item['questionKey'] = "17";
    item.key = key++;
    
    item = {};
    this.items.push(item);
    item['support'] = "80";
    item['district'] = "Pattonville R-III";
    item['question'] = "Improving training programs so those who are underemployed and unemployed can secure jobs that pay a decent salary.";
    item['questionGroup'] = "4";
    item['questionKey'] = "18";
    item.key = key++;
    
    item = {};
    this.items.push(item);
    item['support'] = "80";
    item['district'] = "Pattonville R-III";
    item['question'] = "Making sure student support services such as registration and financial services are centralized and easily available to students.";
    item['questionGroup'] = "4";
    item['questionKey'] = "19";
    item.key = key++;
    
    item = {};
    this.items.push(item);
    item['support'] = "80";
    item['district'] = "Pattonville R-III";
    item['question'] = "Coordinating programs with area economic planners and businesses to make sure well-trained employees are available to help stimulate business growth and relocation in our community.";
    item['questionGroup'] = "4";
    item['questionKey'] = "20";
    item.key = key++;
    
    item = {};
    this.items.push(item);
    item['support'] = "80";
    item['district'] = "Pattonville R-III";
    item['question'] = "Providing real-world learning environments that provide students with the skills they need in the workplace.";
    item['questionGroup'] = "5";
    item['questionKey'] = "21";
    item.key = key++;
    
    item = {};
    this.items.push(item);
    item['support'] = "80";
    item['district'] = "Pattonville R-III";
    item['question'] = "Improving ADA accessibility at all campuses for students with disabilities.";
    item['questionGroup'] = "5";
    item['questionKey'] = "22";
    item.key = key++;
    
    item = {};
    this.items.push(item);
    item['support'] = "80";
    item['district'] = "Pattonville R-III";
    item['question'] = "We must ensure that our community college has adequate facilities and programs to meet the demand for high tech job training programs.";
    item['questionGroup'] = "5";
    item['questionKey'] = "23";
    item.key = key++;
    
    item = {};
    this.items.push(item);
    item['support'] = "50";
    item['district'] = "Pattonville R-III";
    item['question'] = "If the college needs more money, it should raise student fees, not increase the money it receives from taxes.";
    item['questionGroup'] = "5";
    item['questionKey'] = "24";
    item.key = key++;
    
    item = {};
    this.items.push(item);
    item['support'] = "80";
    item['district'] = "Pattonville R-III";
    item['question'] = "We must protect the investment we've made in our community college by keeping our programming current and our facilities up-to-date in good working condition.";
    item['questionGroup'] = "5";
    item['questionKey'] = "25";
    item.key = key++;
    
    item = {};
    this.items.push(item);
    item['support'] = "70";
    item['district'] = "Pattonville R-III";
    item['question'] = "Our community college is important to our area. But in terms of receiving more financial support, there are other things that are just more important.";
    item['questionGroup'] = "6";
    item['questionKey'] = "26";
    item.key = key++;
    
    item = {};
    this.items.push(item);
    item['support'] = "80";
    item['district'] = "Pattonville R-III";
    item['question'] = "Following more than a year of COVID-19, it is more important than ever to support the career education and training programs at our community college.";
    item['questionGroup'] = "6";
    item['questionKey'] = "27";
    item.key = key++;
    
    item = {};
    this.items.push(item);
    item['support'] = "80";
    item['district'] = "Pattonville R-III";
    item['question'] = "Unless we keep the college's programs and facilities up-to-date, many area workers will not be able to get the training they need to prepare for the good paying jobs of today and tomorrow, and that will hurt the local economy.";
    item['questionGroup'] = "6";
    item['questionKey'] = "28";
    item.key = key++;
    
    item = {};
    this.items.push(item);
    item['support'] = "80";
    item['district'] = "Pattonville R-III";
    item['question'] = "The community college's career education programs help create an educated workforce. That leads to an improved economy, and in the long term, that lowers local taxes. Supporting the community just makes financial sense.";
    item['questionGroup'] = "6";
    item['questionKey'] = "29";
    item.key = key++;
    
    item = {};
    this.items.push(item);
    item['support'] = "60";
    item['district'] = "Pattonville R-III";
    item['question'] = "I just don't trust local leadership with additional public dollars.";
    item['questionGroup'] = "6";
    item['questionKey'] = "30";
    item.key = key++;
    
    item = {};
    this.items.push(item);
    item['support'] = "80";
    item['district'] = "Pattonville R-III";
    item['question'] = "Right now there are thousands of good-paying jobs in our area that remain unfilled because businesses can't find employees with the necessary skills. This proposal helps train local residents for these jobs and keep them in our area.";
    item['questionGroup'] = "7";
    item['questionKey'] = "31";
    item.key = key++;
    
    item = {};
    this.items.push(item);
    item['support'] = "80";
    item['district'] = "Pattonville R-III";
    item['question'] = "Many businesses can't grow, or move to our area, because of the shortage of a well-trained workforce. Our economic future depends on supporting job training and retraining programs at our community college.";
    item['questionGroup'] = "7";
    item['questionKey'] = "32";
    item.key = key++;
    
    item = {};
    this.items.push(item);
    item['support'] = "50";
    item['district'] = "Pattonville R-III";
    item['question'] = "This proposal will enable the college to repurpose out-of-date and underutilized spaces to meet the needs of today's students and for future generations.";
    item['questionGroup'] = "7";
    item['questionKey'] = "33";
    item.key = key++;
    
    item = {};
    this.items.push(item);
    item['support'] = "80";
    item['district'] = "Pattonville R-III";
    item['question'] = "As asked earlier, assume that your local community college is seeking an 8-cent increase in property taxes to support improved facilities and programs. If you were voting today, is that something you would . . .";
    item['questionGroup'] = "7";
    item['questionKey'] = "34";
    item.key = key++;
    
    item = {};
    this.items.push(item);
    item['support'] = "64.46949153";
    item['district'] = "Ritenour";
    item['question'] = "Thinking about the next two or three months, do you think the economy in your area will . .";
    item['questionGroup'] = "1";
    item['questionKey'] = "1";
    item.key = key++;
    
    item = {};
    this.items.push(item);
    item['support'] = "79.81949153";
    item['district'] = "Ritenour";
    item['question'] = "Students are often given the grades A, B, C, D, and F to evaluate their work. What grade would you give the community college in your area?";
    item['questionGroup'] = "1";
    item['questionKey'] = "2";
    item.key = key++;
    
    item = {};
    this.items.push(item);
    item['support'] = "45.31610169";
    item['district'] = "Ritenour";
    item['question'] = "Assume the community college in your area seeks voter approval for an 8-cent increase in property taxes to support improved facilities and programs. If you were voting today, is that something you .";
    item['questionGroup'] = "1";
    item['questionKey'] = "3";
    item.key = key++;
    
    item = {};
    this.items.push(item);
    item['support'] = "27.82288136";
    item['district'] = "Ritenour";
    item['question'] = "If you knew this proposal would cost about $1.90 per month for a $150,000 house, is this proposal something you . . .";
    item['questionGroup'] = "1";
    item['questionKey'] = "4";
    item.key = key++;
    
    item = {};
    this.items.push(item);
    item['support'] = "80";
    item['district'] = "Ritenour";
    item['question'] = "It is essential that our area has a quality community college as a place for adults to be trained and retrained for the good paying jobs of today and tomorrow.";
    item['questionGroup'] = "1";
    item['questionKey'] = "5";
    item.key = key++;
    
    item = {};
    this.items.push(item);
    item['support'] = "67.6440678";
    item['district'] = "Ritenour";
    item['question'] = "People like me can't afford to pay higher taxes, no matter how good the cause.";
    item['questionGroup'] = "2";
    item['questionKey'] = "6";
    item.key = key++;
    
    item = {};
    this.items.push(item);
    item['support'] = "74.6559322";
    item['district'] = "Ritenour";
    item['question'] = "We get excellent value from what we pay to support our community college.";
    item['questionGroup'] = "2";
    item['questionKey'] = "7";
    item.key = key++;
    
    item = {};
    this.items.push(item);
    item['support'] = "36.62288136";
    item['district'] = "Ritenour";
    item['question'] = "The local community college does not serve the needs of people like me and my family.";
    item['questionGroup'] = "2";
    item['questionKey'] = "8";
    item.key = key++;
    
    item = {};
    this.items.push(item);
    item['support'] = "46.96440678";
    item['district'] = "Ritenour";
    item['question'] = "I'm willing to pay more to support the community college's efforts to help our area economically recover from the COVID-19 pandemic.";
    item['questionGroup'] = "2";
    item['questionKey'] = "9";
    item.key = key++;
    
    item = {};
    this.items.push(item);
    item['support'] = "72.78813559";
    item['district'] = "Ritenour";
    item['question'] = "Our community college is an important anchor for our regional economy.";
    item['questionGroup'] = "2";
    item['questionKey'] = "10";
    item.key = key++;
    
    item = {};
    this.items.push(item);
    item['support'] = "77.13389831";
    item['district'] = "Ritenour";
    item['question'] = "Making sure all of the college's instructional areas are equipped with up-to-date instructional technology.";
    item['questionGroup'] = "3";
    item['questionKey'] = "11";
    item.key = key++;
    
    item = {};
    this.items.push(item);
    item['support'] = "60.90762712";
    item['district'] = "Ritenour";
    item['question'] = "Replacing buildings that are more than a half-century old, and are expensive and inefficient to operate and maintain.";
    item['questionGroup'] = "3";
    item['questionKey'] = "12";
    item.key = key++;
    
    item = {};
    this.items.push(item);
    item['support'] = "78.21779661";
    item['district'] = "Ritenour";
    item['question'] = "Providing learning spaces to accommodate best practices in job training and retraining programs.";
    item['questionGroup'] = "3";
    item['questionKey'] = "13";
    item.key = key++;
    
    item = {};
    this.items.push(item);
    item['support'] = "75.10338983";
    item['district'] = "Ritenour";
    item['question'] = "Repairing and replacing aging roofs, doors, windows, plumbing, HVAC, electrical and other infrastructure conditions as needed.";
    item['questionGroup'] = "3";
    item['questionKey'] = "14";
    item.key = key++;
    
    item = {};
    this.items.push(item);
    item['support'] = "80";
    item['district'] = "Ritenour";
    item['question'] = "Providing job training and retraining facilities and programs for good paying, high-in-demand, jobs in areas like healthcare, information technology (IT) and financial services.";
    item['questionGroup'] = "3";
    item['questionKey'] = "15";
    item.key = key++;
    
    item = {};
    this.items.push(item);
    item['support'] = "80";
    item['district'] = "Ritenour";
    item['question'] = "Making sure the campus is safe and secure both night and day.";
    item['questionGroup'] = "4";
    item['questionKey'] = "16";
    item.key = key++;
    
    item = {};
    this.items.push(item);
    item['support'] = "80";
    item['district'] = "Ritenour";
    item['question'] = "Keeping community college programs affordable.";
    item['questionGroup'] = "4";
    item['questionKey'] = "17";
    item.key = key++;
    
    item = {};
    this.items.push(item);
    item['support'] = "79.49152542";
    item['district'] = "Ritenour";
    item['question'] = "Improving training programs so those who are underemployed and unemployed can secure jobs that pay a decent salary.";
    item['questionGroup'] = "4";
    item['questionKey'] = "18";
    item.key = key++;
    
    item = {};
    this.items.push(item);
    item['support'] = "77.50762712";
    item['district'] = "Ritenour";
    item['question'] = "Making sure student support services such as registration and financial services are centralized and easily available to students.";
    item['questionGroup'] = "4";
    item['questionKey'] = "19";
    item.key = key++;
    
    item = {};
    this.items.push(item);
    item['support'] = "75.96525424";
    item['district'] = "Ritenour";
    item['question'] = "Coordinating programs with area economic planners and businesses to make sure well-trained employees are available to help stimulate business growth and relocation in our community.";
    item['questionGroup'] = "4";
    item['questionKey'] = "20";
    item.key = key++;
    
    item = {};
    this.items.push(item);
    item['support'] = "79.14745763";
    item['district'] = "Ritenour";
    item['question'] = "Providing real-world learning environments that provide students with the skills they need in the workplace.";
    item['questionGroup'] = "5";
    item['questionKey'] = "21";
    item.key = key++;
    
    item = {};
    this.items.push(item);
    item['support'] = "79.10677966";
    item['district'] = "Ritenour";
    item['question'] = "Improving ADA accessibility at all campuses for students with disabilities.";
    item['questionGroup'] = "5";
    item['questionKey'] = "22";
    item.key = key++;
    
    item = {};
    this.items.push(item);
    item['support'] = "80";
    item['district'] = "Ritenour";
    item['question'] = "We must ensure that our community college has adequate facilities and programs to meet the demand for high tech job training programs.";
    item['questionGroup'] = "5";
    item['questionKey'] = "23";
    item.key = key++;
    
    item = {};
    this.items.push(item);
    item['support'] = "58.41101695";
    item['district'] = "Ritenour";
    item['question'] = "If the college needs more money, it should raise student fees, not increase the money it receives from taxes.";
    item['questionGroup'] = "5";
    item['questionKey'] = "24";
    item.key = key++;
    
    item = {};
    this.items.push(item);
    item['support'] = "79.73050847";
    item['district'] = "Ritenour";
    item['question'] = "We must protect the investment we've made in our community college by keeping our programming current and our facilities up-to-date in good working condition.";
    item['questionGroup'] = "5";
    item['questionKey'] = "25";
    item.key = key++;
    
    item = {};
    this.items.push(item);
    item['support'] = "69.48135593";
    item['district'] = "Ritenour";
    item['question'] = "Our community college is important to our area. But in terms of receiving more financial support, there are other things that are just more important.";
    item['questionGroup'] = "6";
    item['questionKey'] = "26";
    item.key = key++;
    
    item = {};
    this.items.push(item);
    item['support'] = "79.49152542";
    item['district'] = "Ritenour";
    item['question'] = "Following more than a year of COVID-19, it is more important than ever to support the career education and training programs at our community college.";
    item['questionGroup'] = "6";
    item['questionKey'] = "27";
    item.key = key++;
    
    item = {};
    this.items.push(item);
    item['support'] = "74.23305085";
    item['district'] = "Ritenour";
    item['question'] = "Unless we keep the college's programs and facilities up-to-date, many area workers will not be able to get the training they need to prepare for the good paying jobs of today and tomorrow, and that will hurt the local economy.";
    item['questionGroup'] = "6";
    item['questionKey'] = "28";
    item.key = key++;
    
    item = {};
    this.items.push(item);
    item['support'] = "78.68813559";
    item['district'] = "Ritenour";
    item['question'] = "The community college's career education programs help create an educated workforce. That leads to an improved economy, and in the long term, that lowers local taxes. Supporting the community just makes financial sense.";
    item['questionGroup'] = "6";
    item['questionKey'] = "29";
    item.key = key++;
    
    item = {};
    this.items.push(item);
    item['support'] = "62.17966102";
    item['district'] = "Ritenour";
    item['question'] = "I just don't trust local leadership with additional public dollars.";
    item['questionGroup'] = "6";
    item['questionKey'] = "30";
    item.key = key++;
    
    item = {};
    this.items.push(item);
    item['support'] = "72.82542373";
    item['district'] = "Ritenour";
    item['question'] = "Right now there are thousands of good-paying jobs in our area that remain unfilled because businesses can't find employees with the necessary skills. This proposal helps train local residents for these jobs and keep them in our area.";
    item['questionGroup'] = "7";
    item['questionKey'] = "31";
    item.key = key++;
    
    item = {};
    this.items.push(item);
    item['support'] = "75.99661017";
    item['district'] = "Ritenour";
    item['question'] = "Many businesses can't grow, or move to our area, because of the shortage of a well-trained workforce. Our economic future depends on supporting job training and retraining programs at our community college.";
    item['questionGroup'] = "7";
    item['questionKey'] = "32";
    item.key = key++;
    
    item = {};
    this.items.push(item);
    item['support'] = "50.91186441";
    item['district'] = "Ritenour";
    item['question'] = "This proposal will enable the college to repurpose out-of-date and underutilized spaces to meet the needs of today's students and for future generations.";
    item['questionGroup'] = "7";
    item['questionKey'] = "33";
    item.key = key++;
    
    item = {};
    this.items.push(item);
    item['support'] = "71.26355932";
    item['district'] = "Ritenour";
    item['question'] = "As asked earlier, assume that your local community college is seeking an 8-cent increase in property taxes to support improved facilities and programs. If you were voting today, is that something you would . . .";
    item['questionGroup'] = "7";
    item['questionKey'] = "34";
    item.key = key++;
    
    item = {};
    this.items.push(item);
    item['support'] = "64.30925222";
    item['district'] = "Rockwood R-VI";
    item['question'] = "Thinking about the next two or three months, do you think the economy in your area will . .";
    item['questionGroup'] = "1";
    item['questionKey'] = "1";
    item.key = key++;
    
    item = {};
    this.items.push(item);
    item['support'] = "79.99366286";
    item['district'] = "Rockwood R-VI";
    item['question'] = "Students are often given the grades A, B, C, D, and F to evaluate their work. What grade would you give the community college in your area?";
    item['questionGroup'] = "1";
    item['questionKey'] = "2";
    item.key = key++;
    
    item = {};
    this.items.push(item);
    item['support'] = "41.56844106";
    item['district'] = "Rockwood R-VI";
    item['question'] = "Assume the community college in your area seeks voter approval for an 8-cent increase in property taxes to support improved facilities and programs. If you were voting today, is that something you .";
    item['questionGroup'] = "1";
    item['questionKey'] = "3";
    item.key = key++;
    
    item = {};
    this.items.push(item);
    item['support'] = "23.27313054";
    item['district'] = "Rockwood R-VI";
    item['question'] = "If you knew this proposal would cost about $1.90 per month for a $150,000 house, is this proposal something you . . .";
    item['questionGroup'] = "1";
    item['questionKey'] = "4";
    item.key = key++;
    
    item = {};
    this.items.push(item);
    item['support'] = "80";
    item['district'] = "Rockwood R-VI";
    item['question'] = "It is essential that our area has a quality community college as a place for adults to be trained and retrained for the good paying jobs of today and tomorrow.";
    item['questionGroup'] = "1";
    item['questionKey'] = "5";
    item.key = key++;
    
    item = {};
    this.items.push(item);
    item['support'] = "48.72940431";
    item['district'] = "Rockwood R-VI";
    item['question'] = "People like me can't afford to pay higher taxes, no matter how good the cause.";
    item['questionGroup'] = "2";
    item['questionKey'] = "6";
    item.key = key++;
    
    item = {};
    this.items.push(item);
    item['support'] = "75.85868188";
    item['district'] = "Rockwood R-VI";
    item['question'] = "We get excellent value from what we pay to support our community college.";
    item['questionGroup'] = "2";
    item['questionKey'] = "7";
    item.key = key++;
    
    item = {};
    this.items.push(item);
    item['support'] = "37.37008872";
    item['district'] = "Rockwood R-VI";
    item['question'] = "The local community college does not serve the needs of people like me and my family.";
    item['questionGroup'] = "2";
    item['questionKey'] = "8";
    item.key = key++;
    
    item = {};
    this.items.push(item);
    item['support'] = "45.08555133";
    item['district'] = "Rockwood R-VI";
    item['question'] = "I'm willing to pay more to support the community college's efforts to help our area economically recover from the COVID-19 pandemic.";
    item['questionGroup'] = "2";
    item['questionKey'] = "9";
    item.key = key++;
    
    item = {};
    this.items.push(item);
    item['support'] = "69.0747782";
    item['district'] = "Rockwood R-VI";
    item['question'] = "Our community college is an important anchor for our regional economy.";
    item['questionGroup'] = "2";
    item['questionKey'] = "10";
    item.key = key++;
    
    item = {};
    this.items.push(item);
    item['support'] = "79.45183777";
    item['district'] = "Rockwood R-VI";
    item['question'] = "Making sure all of the college's instructional areas are equipped with up-to-date instructional technology.";
    item['questionGroup'] = "3";
    item['questionKey'] = "11";
    item.key = key++;
    
    item = {};
    this.items.push(item);
    item['support'] = "60.27883397";
    item['district'] = "Rockwood R-VI";
    item['question'] = "Replacing buildings that are more than a half-century old, and are expensive and inefficient to operate and maintain.";
    item['questionGroup'] = "3";
    item['questionKey'] = "12";
    item.key = key++;
    
    item = {};
    this.items.push(item);
    item['support'] = "79.58808619";
    item['district'] = "Rockwood R-VI";
    item['question'] = "Providing learning spaces to accommodate best practices in job training and retraining programs.";
    item['questionGroup'] = "3";
    item['questionKey'] = "13";
    item.key = key++;
    
    item = {};
    this.items.push(item);
    item['support'] = "78.70722433";
    item['district'] = "Rockwood R-VI";
    item['question'] = "Repairing and replacing aging roofs, doors, windows, plumbing, HVAC, electrical and other infrastructure conditions as needed.";
    item['questionGroup'] = "3";
    item['questionKey'] = "14";
    item.key = key++;
    
    item = {};
    this.items.push(item);
    item['support'] = "80";
    item['district'] = "Rockwood R-VI";
    item['question'] = "Providing job training and retraining facilities and programs for good paying, high-in-demand, jobs in areas like healthcare, information technology (IT) and financial services.";
    item['questionGroup'] = "3";
    item['questionKey'] = "15";
    item.key = key++;
    
    item = {};
    this.items.push(item);
    item['support'] = "80";
    item['district'] = "Rockwood R-VI";
    item['question'] = "Making sure the campus is safe and secure both night and day.";
    item['questionGroup'] = "4";
    item['questionKey'] = "16";
    item.key = key++;
    
    item = {};
    this.items.push(item);
    item['support'] = "80";
    item['district'] = "Rockwood R-VI";
    item['question'] = "Keeping community college programs affordable.";
    item['questionGroup'] = "4";
    item['questionKey'] = "17";
    item.key = key++;
    
    item = {};
    this.items.push(item);
    item['support'] = "79.86375158";
    item['district'] = "Rockwood R-VI";
    item['question'] = "Improving training programs so those who are underemployed and unemployed can secure jobs that pay a decent salary.";
    item['questionGroup'] = "4";
    item['questionKey'] = "18";
    item.key = key++;
    
    item = {};
    this.items.push(item);
    item['support'] = "68.76108999";
    item['district'] = "Rockwood R-VI";
    item['question'] = "Making sure student support services such as registration and financial services are centralized and easily available to students.";
    item['questionGroup'] = "4";
    item['questionKey'] = "19";
    item.key = key++;
    
    item = {};
    this.items.push(item);
    item['support'] = "78.88466413";
    item['district'] = "Rockwood R-VI";
    item['question'] = "Coordinating programs with area economic planners and businesses to make sure well-trained employees are available to help stimulate business growth and relocation in our community.";
    item['questionGroup'] = "4";
    item['questionKey'] = "20";
    item.key = key++;
    
    item = {};
    this.items.push(item);
    item['support'] = "79.84790875";
    item['district'] = "Rockwood R-VI";
    item['question'] = "Providing real-world learning environments that provide students with the skills they need in the workplace.";
    item['questionGroup'] = "5";
    item['questionKey'] = "21";
    item.key = key++;
    
    item = {};
    this.items.push(item);
    item['support'] = "79.74968314";
    item['district'] = "Rockwood R-VI";
    item['question'] = "Improving ADA accessibility at all campuses for students with disabilities.";
    item['questionGroup'] = "5";
    item['questionKey'] = "22";
    item.key = key++;
    
    item = {};
    this.items.push(item);
    item['support'] = "80";
    item['district'] = "Rockwood R-VI";
    item['question'] = "We must ensure that our community college has adequate facilities and programs to meet the demand for high tech job training programs.";
    item['questionGroup'] = "5";
    item['questionKey'] = "23";
    item.key = key++;
    
    item = {};
    this.items.push(item);
    item['support'] = "62.84220532";
    item['district'] = "Rockwood R-VI";
    item['question'] = "If the college needs more money, it should raise student fees, not increase the money it receives from taxes.";
    item['questionGroup'] = "5";
    item['questionKey'] = "24";
    item.key = key++;
    
    item = {};
    this.items.push(item);
    item['support'] = "79.96831432";
    item['district'] = "Rockwood R-VI";
    item['question'] = "We must protect the investment we've made in our community college by keeping our programming current and our facilities up-to-date in good working condition.";
    item['questionGroup'] = "5";
    item['questionKey'] = "25";
    item.key = key++;
    
    item = {};
    this.items.push(item);
    item['support'] = "74.24271229";
    item['district'] = "Rockwood R-VI";
    item['question'] = "Our community college is important to our area. But in terms of receiving more financial support, there are other things that are just more important.";
    item['questionGroup'] = "6";
    item['questionKey'] = "26";
    item.key = key++;
    
    item = {};
    this.items.push(item);
    item['support'] = "79.60709759";
    item['district'] = "Rockwood R-VI";
    item['question'] = "Following more than a year of COVID-19, it is more important than ever to support the career education and training programs at our community college.";
    item['questionGroup'] = "6";
    item['questionKey'] = "27";
    item.key = key++;
    
    item = {};
    this.items.push(item);
    item['support'] = "70.54499366";
    item['district'] = "Rockwood R-VI";
    item['question'] = "Unless we keep the college's programs and facilities up-to-date, many area workers will not be able to get the training they need to prepare for the good paying jobs of today and tomorrow, and that will hurt the local economy.";
    item['questionGroup'] = "6";
    item['questionKey'] = "28";
    item.key = key++;
    
    item = {};
    this.items.push(item);
    item['support'] = "72.17363752";
    item['district'] = "Rockwood R-VI";
    item['question'] = "The community college's career education programs help create an educated workforce. That leads to an improved economy, and in the long term, that lowers local taxes. Supporting the community just makes financial sense.";
    item['questionGroup'] = "6";
    item['questionKey'] = "29";
    item.key = key++;
    
    item = {};
    this.items.push(item);
    item['support'] = "67.95627376";
    item['district'] = "Rockwood R-VI";
    item['question'] = "I just don't trust local leadership with additional public dollars.";
    item['questionGroup'] = "6";
    item['questionKey'] = "30";
    item.key = key++;
    
    item = {};
    this.items.push(item);
    item['support'] = "70.7287706";
    item['district'] = "Rockwood R-VI";
    item['question'] = "Right now there are thousands of good-paying jobs in our area that remain unfilled because businesses can't find employees with the necessary skills. This proposal helps train local residents for these jobs and keep them in our area.";
    item['questionGroup'] = "7";
    item['questionKey'] = "31";
    item.key = key++;
    
    item = {};
    this.items.push(item);
    item['support'] = "79.73067174";
    item['district'] = "Rockwood R-VI";
    item['question'] = "Many businesses can't grow, or move to our area, because of the shortage of a well-trained workforce. Our economic future depends on supporting job training and retraining programs at our community college.";
    item['questionGroup'] = "7";
    item['questionKey'] = "32";
    item.key = key++;
    
    item = {};
    this.items.push(item);
    item['support'] = "46.22306717";
    item['district'] = "Rockwood R-VI";
    item['question'] = "This proposal will enable the college to repurpose out-of-date and underutilized spaces to meet the needs of today's students and for future generations.";
    item['questionGroup'] = "7";
    item['questionKey'] = "33";
    item.key = key++;
    
    item = {};
    this.items.push(item);
    item['support'] = "69.9017744";
    item['district'] = "Rockwood R-VI";
    item['question'] = "As asked earlier, assume that your local community college is seeking an 8-cent increase in property taxes to support improved facilities and programs. If you were voting today, is that something you would . . .";
    item['questionGroup'] = "7";
    item['questionKey'] = "34";
    item.key = key++;
    
    item = {};
    this.items.push(item);
    item['support'] = "65.82624738";
    item['district'] = "St. Louis City";
    item['question'] = "Thinking about the next two or three months, do you think the economy in your area will . .";
    item['questionGroup'] = "1";
    item['questionKey'] = "1";
    item.key = key++;
    
    item = {};
    this.items.push(item);
    item['support'] = "79.8935516";
    item['district'] = "St. Louis City";
    item['question'] = "Students are often given the grades A, B, C, D, and F to evaluate their work. What grade would you give the community college in your area?";
    item['questionGroup'] = "1";
    item['questionKey'] = "2";
    item.key = key++;
    
    item = {};
    this.items.push(item);
    item['support'] = "45.68756759";
    item['district'] = "St. Louis City";
    item['question'] = "Assume the community college in your area seeks voter approval for an 8-cent increase in property taxes to support improved facilities and programs. If you were voting today, is that something you .";
    item['questionGroup'] = "1";
    item['questionKey'] = "3";
    item.key = key++;
    
    item = {};
    this.items.push(item);
    item['support'] = "27.63544604";
    item['district'] = "St. Louis City";
    item['question'] = "If you knew this proposal would cost about $1.90 per month for a $150,000 house, is this proposal something you . . .";
    item['questionGroup'] = "1";
    item['questionKey'] = "4";
    item.key = key++;
    
    item = {};
    this.items.push(item);
    item['support'] = "80";
    item['district'] = "St. Louis City";
    item['question'] = "It is essential that our area has a quality community college as a place for adults to be trained and retrained for the good paying jobs of today and tomorrow.";
    item['questionGroup'] = "1";
    item['questionKey'] = "5";
    item.key = key++;
    
    item = {};
    this.items.push(item);
    item['support'] = "64.48101105";
    item['district'] = "St. Louis City";
    item['question'] = "People like me can't afford to pay higher taxes, no matter how good the cause.";
    item['questionGroup'] = "2";
    item['questionKey'] = "6";
    item.key = key++;
    
    item = {};
    this.items.push(item);
    item['support'] = "75.91933671";
    item['district'] = "St. Louis City";
    item['question'] = "We get excellent value from what we pay to support our community college.";
    item['questionGroup'] = "2";
    item['questionKey'] = "7";
    item.key = key++;
    
    item = {};
    this.items.push(item);
    item['support'] = "37.19247652";
    item['district'] = "St. Louis City";
    item['question'] = "The local community college does not serve the needs of people like me and my family.";
    item['questionGroup'] = "2";
    item['questionKey'] = "8";
    item.key = key++;
    
    item = {};
    this.items.push(item);
    item['support'] = "47.33645752";
    item['district'] = "St. Louis City";
    item['question'] = "I'm willing to pay more to support the community college's efforts to help our area economically recover from the COVID-19 pandemic.";
    item['questionGroup'] = "2";
    item['questionKey'] = "9";
    item.key = key++;
    
    item = {};
    this.items.push(item);
    item['support'] = "72.69280519";
    item['district'] = "St. Louis City";
    item['question'] = "Our community college is an important anchor for our regional economy.";
    item['questionGroup'] = "2";
    item['questionKey'] = "10";
    item.key = key++;
    
    item = {};
    this.items.push(item);
    item['support'] = "77.94991412";
    item['district'] = "St. Louis City";
    item['question'] = "Making sure all of the college's instructional areas are equipped with up-to-date instructional technology.";
    item['questionGroup'] = "3";
    item['questionKey'] = "11";
    item.key = key++;
    
    item = {};
    this.items.push(item);
    item['support'] = "60.99069107";
    item['district'] = "St. Louis City";
    item['question'] = "Replacing buildings that are more than a half-century old, and are expensive and inefficient to operate and maintain.";
    item['questionGroup'] = "3";
    item['questionKey'] = "12";
    item.key = key++;
    
    item = {};
    this.items.push(item);
    item['support'] = "78.12209758";
    item['district'] = "St. Louis City";
    item['question'] = "Providing learning spaces to accommodate best practices in job training and retraining programs.";
    item['questionGroup'] = "3";
    item['questionKey'] = "13";
    item.key = key++;
    
    item = {};
    this.items.push(item);
    item['support'] = "75.61992409";
    item['district'] = "St. Louis City";
    item['question'] = "Repairing and replacing aging roofs, doors, windows, plumbing, HVAC, electrical and other infrastructure conditions as needed.";
    item['questionGroup'] = "3";
    item['questionKey'] = "14";
    item.key = key++;
    
    item = {};
    this.items.push(item);
    item['support'] = "80";
    item['district'] = "St. Louis City";
    item['question'] = "Providing job training and retraining facilities and programs for good paying, high-in-demand, jobs in areas like healthcare, information technology (IT) and financial services.";
    item['questionGroup'] = "3";
    item['questionKey'] = "15";
    item.key = key++;
    
    item = {};
    this.items.push(item);
    item['support'] = "80";
    item['district'] = "St. Louis City";
    item['question'] = "Making sure the campus is safe and secure both night and day.";
    item['questionGroup'] = "4";
    item['questionKey'] = "16";
    item.key = key++;
    
    item = {};
    this.items.push(item);
    item['support'] = "80";
    item['district'] = "St. Louis City";
    item['question'] = "Keeping community college programs affordable.";
    item['questionGroup'] = "4";
    item['questionKey'] = "17";
    item.key = key++;
    
    item = {};
    this.items.push(item);
    item['support'] = "79.58438474";
    item['district'] = "St. Louis City";
    item['question'] = "Improving training programs so those who are underemployed and unemployed can secure jobs that pay a decent salary.";
    item['questionGroup'] = "4";
    item['questionKey'] = "18";
    item.key = key++;
    
    item = {};
    this.items.push(item);
    item['support'] = "76.32180496";
    item['district'] = "St. Louis City";
    item['question'] = "Making sure student support services such as registration and financial services are centralized and easily available to students.";
    item['questionGroup'] = "4";
    item['questionKey'] = "19";
    item.key = key++;
    
    item = {};
    this.items.push(item);
    item['support'] = "76.44903412";
    item['district'] = "St. Louis City";
    item['question'] = "Coordinating programs with area economic planners and businesses to make sure well-trained employees are available to help stimulate business growth and relocation in our community.";
    item['questionGroup'] = "4";
    item['questionKey'] = "20";
    item.key = key++;
    
    item = {};
    this.items.push(item);
    item['support'] = "79.35749274";
    item['district'] = "St. Louis City";
    item['question'] = "Providing real-world learning environments that provide students with the skills they need in the workplace.";
    item['questionGroup'] = "5";
    item['questionKey'] = "21";
    item.key = key++;
    
    item = {};
    this.items.push(item);
    item['support'] = "79.32271677";
    item['district'] = "St. Louis City";
    item['question'] = "Improving ADA accessibility at all campuses for students with disabilities.";
    item['questionGroup'] = "5";
    item['questionKey'] = "22";
    item.key = key++;
    
    item = {};
    this.items.push(item);
    item['support'] = "80";
    item['district'] = "St. Louis City";
    item['question'] = "We must ensure that our community college has adequate facilities and programs to meet the demand for high tech job training programs.";
    item['questionGroup'] = "5";
    item['questionKey'] = "23";
    item.key = key++;
    
    item = {};
    this.items.push(item);
    item['support'] = "59.27309739";
    item['district'] = "St. Louis City";
    item['question'] = "If the college needs more money, it should raise student fees, not increase the money it receives from taxes.";
    item['questionGroup'] = "5";
    item['questionKey'] = "24";
    item.key = key++;
    
    item = {};
    this.items.push(item);
    item['support'] = "79.64545474";
    item['district'] = "St. Louis City";
    item['question'] = "We must protect the investment we've made in our community college by keeping our programming current and our facilities up-to-date in good working condition.";
    item['questionGroup'] = "5";
    item['questionKey'] = "25";
    item.key = key++;
    
    item = {};
    this.items.push(item);
    item['support'] = "69.7041922";
    item['district'] = "St. Louis City";
    item['question'] = "Our community college is important to our area. But in terms of receiving more financial support, there are other things that are just more important.";
    item['questionGroup'] = "6";
    item['questionKey'] = "26";
    item.key = key++;
    
    item = {};
    this.items.push(item);
    item['support'] = "79.43340614";
    item['district'] = "St. Louis City";
    item['question'] = "Following more than a year of COVID-19, it is more important than ever to support the career education and training programs at our community college.";
    item['questionGroup'] = "6";
    item['questionKey'] = "27";
    item.key = key++;
    
    item = {};
    this.items.push(item);
    item['support'] = "75.23696431";
    item['district'] = "St. Louis City";
    item['question'] = "Unless we keep the college's programs and facilities up-to-date, many area workers will not be able to get the training they need to prepare for the good paying jobs of today and tomorrow, and that will hurt the local economy.";
    item['questionGroup'] = "6";
    item['questionKey'] = "28";
    item.key = key++;
    
    item = {};
    this.items.push(item);
    item['support'] = "78.66960707";
    item['district'] = "St. Louis City";
    item['question'] = "The community college's career education programs help create an educated workforce. That leads to an improved economy, and in the long term, that lowers local taxes. Supporting the community just makes financial sense.";
    item['questionGroup'] = "6";
    item['questionKey'] = "29";
    item.key = key++;
    
    item = {};
    this.items.push(item);
    item['support'] = "61.67984902";
    item['district'] = "St. Louis City";
    item['question'] = "I just don't trust local leadership with additional public dollars.";
    item['questionGroup'] = "6";
    item['questionKey'] = "30";
    item.key = key++;
    
    item = {};
    this.items.push(item);
    item['support'] = "73.98927034";
    item['district'] = "St. Louis City";
    item['question'] = "Right now there are thousands of good-paying jobs in our area that remain unfilled because businesses can't find employees with the necessary skills. This proposal helps train local residents for these jobs and keep them in our area.";
    item['questionGroup'] = "7";
    item['questionKey'] = "31";
    item.key = key++;
    
    item = {};
    this.items.push(item);
    item['support'] = "76.66617189";
    item['district'] = "St. Louis City";
    item['question'] = "Many businesses can't grow, or move to our area, because of the shortage of a well-trained workforce. Our economic future depends on supporting job training and retraining programs at our community college.";
    item['questionGroup'] = "7";
    item['questionKey'] = "32";
    item.key = key++;
    
    item = {};
    this.items.push(item);
    item['support'] = "51.20167942";
    item['district'] = "St. Louis City";
    item['question'] = "This proposal will enable the college to repurpose out-of-date and underutilized spaces to meet the needs of today's students and for future generations.";
    item['questionGroup'] = "7";
    item['questionKey'] = "33";
    item.key = key++;
    
    item = {};
    this.items.push(item);
    item['support'] = "72.44173965";
    item['district'] = "St. Louis City";
    item['question'] = "As asked earlier, assume that your local community college is seeking an 8-cent increase in property taxes to support improved facilities and programs. If you were voting today, is that something you would . . .";
    item['questionGroup'] = "7";
    item['questionKey'] = "34";
    item.key = key++;
  }

}
