import React, { Component } from 'react';
import './App.css';
import ScreenContext from './ScreenContext';
import { ResponsiveContainer, BarChart, Bar, XAxis, YAxis, Text, Tooltip} from 'recharts';

export default class ResultsByGroup extends Component {

  static contextType = ScreenContext;

  // This component doesn't use any properties

  constructor(props) {
    super(props);
    
    this.state = {
    };
  }

  componentDidMount() {
  }

  componentWillUnmount() {
  }

  componentDidUpdate(prevProps, prevState) {
  }

  valueChanged_elBarchartresultsByGroup = (value) => {
    this.setState({barchartresultsByGroup: value.index});
    
    this.context.appActions.updateDataSlot("ds_filter", value.index);
  }
  
  render() {
    const style_elTitle = {
      color: '#1f1f1f',
      textAlign: 'center',
     };
    
    
    const sheet_elBarchartresultsByGroup = this.context.appActions.getDataSheet('categoryGraph').items; 
    const slot_elBarchartresultsByGroup = (this.context.appActions.dataSlots ? this.context.appActions.dataSlots['ds_district'] : ''); 
    const slot_elBarchartresultsByGroup2 = (this.context.appActions.dataSlots ? this.context.appActions.dataSlots['ds_QuestionState'] : ''); 
    
    sheet_elBarchartresultsByGroup.forEach(function(list){
      list.support = Number(list.support);
    });
    
    let filteredChart = [];
    let filterStr = slot_elBarchartresultsByGroup; 
    let filterStr2 = slot_elBarchartresultsByGroup2; 
    
    console.log(sheet_elBarchartresultsByGroup);
    sheet_elBarchartresultsByGroup.forEach(item => {
      if (item.district == filterStr && item.questionGroup == filterStr2) {
        filteredChart.push(item);
      }
    });
    
    const style_elBarchartresultsByGroup = {
      pointerEvents: 'auto',
     };
    
    return (
      <div className="ResultsByGroup cardBg">
        <div className="layoutFlow">
          <div className="elSpacer">
            <div />
          </div>
          
          <div className="elTitle">
            <div className="baseFont" style={style_elTitle}>
              <div><div dangerouslySetInnerHTML={{__html: this.context.locStrings.questions_text_814326.replace(/\n/g, '<br>')}}></div></div>
            </div>
          </div>
          
          <div className="elBarchartresultsByGroup">
            <div style={style_elBarchartresultsByGroup}>
              
              <ResponsiveContainer width="100%" height="100%"> 
                  <BarChart data={filteredChart}>
                      <Tooltip labelFormatter={() => 'Percent:'} formatter={(myValue) => [new Intl.NumberFormat('en', {style: 'percent'}).format(myValue/100), ]} />
                      <XAxis dataKey="category" axisLine={true} tickLine={true} interval={0} height={75} tick={ { width: 200, fontSize: 10 }} />
                      <YAxis axisLine = {true} tickLine={true} tickFormatter={(tickItem) => {return String(tickItem).concat("%");}} />
                      <Bar dataKey="support" fill="rgba(146.40787661075592, 146.41141802072525, 146.40951812267303, 1)" 
               onClick={this.valueChanged_elBarchartresultsByGroup}
              />
                  </BarChart>
              </ResponsiveContainer>
            </div>
          </div>
          
          <div className="elSpacer2">
            <div />
          </div>
          
          <div className="elSpacer3">
            <div />
          </div>
        </div>
        
      </div>
    )
  }
  
}
