// eslint-disable-next-line
import React from 'react';
import DataSheetBase from './DataSheetBase.js';

export default class DataSheet_questionSupportBreakdown extends DataSheetBase {

  constructor(id, updateCb) {
    super(id, updateCb);
    this.requestedKeyPath = "";  // this value can be specified in the React Studio data sheet UI
  }

  makeDefaultItems() {
    // eslint-disable-next-line no-unused-vars
    let key = 1;
    // eslint-disable-next-line no-unused-vars
    let item;
    
    item = {};
    this.items.push(item);
    item['value'] = "0.00";
    item['supportRank'] = "1";
    item['questionNumber'] = "1";
    item['name'] = "Strongly Disagree";
    item['questionText'] = "Thinking about the next two or three months, do you think the economy in your area will . .";
    item.key = key++;
    
    item = {};
    this.items.push(item);
    item['value'] = "0.00";
    item['supportRank'] = "1";
    item['questionNumber'] = "2";
    item['name'] = "Strongly Disagree";
    item['questionText'] = "Students are often given the grades A, B, C, D, and F to evaluate their work. What grade would you give the community college in your area?";
    item.key = key++;
    
    item = {};
    this.items.push(item);
    item['value'] = "0.00";
    item['supportRank'] = "1";
    item['questionNumber'] = "3";
    item['name'] = "Strongly Disagree";
    item['questionText'] = "Assume the community college in your area seeks voter approval for an 8-cent increase in property taxes to support improved facilities and programs. If you were voting today, is that something you .";
    item.key = key++;
    
    item = {};
    this.items.push(item);
    item['value'] = "0.00";
    item['supportRank'] = "1";
    item['questionNumber'] = "4";
    item['name'] = "Strongly Disagree";
    item['questionText'] = "If you knew this proposal would cost about $1.90 per month for a $150,000 house, is this proposal something you . . .";
    item.key = key++;
    
    item = {};
    this.items.push(item);
    item['value'] = "0.00";
    item['supportRank'] = "1";
    item['questionNumber'] = "5";
    item['name'] = "Strongly Disagree";
    item['questionText'] = "It is essential that our area has a quality community college as a place for adults to be trained and retrained for the good paying jobs of today and tomorrow.";
    item.key = key++;
    
    item = {};
    this.items.push(item);
    item['value'] = "0.00";
    item['supportRank'] = "1";
    item['questionNumber'] = "6";
    item['name'] = "Strongly Disagree";
    item['questionText'] = "People like me can't afford to pay higher taxes, no matter how good the cause.";
    item.key = key++;
    
    item = {};
    this.items.push(item);
    item['value'] = "0.00";
    item['supportRank'] = "1";
    item['questionNumber'] = "7";
    item['name'] = "Strongly Disagree";
    item['questionText'] = "We get excellent value from what we pay to support our community college.";
    item.key = key++;
    
    item = {};
    this.items.push(item);
    item['value'] = "0.00";
    item['supportRank'] = "1";
    item['questionNumber'] = "8";
    item['name'] = "Strongly Disagree";
    item['questionText'] = "The local community college does not serve the needs of people like me and my family.";
    item.key = key++;
    
    item = {};
    this.items.push(item);
    item['value'] = "0.00";
    item['supportRank'] = "1";
    item['questionNumber'] = "9";
    item['name'] = "Strongly Disagree";
    item['questionText'] = "I'm willing to pay more to support the community college's efforts to help our area economically recover from the COVID-19 pandemic.";
    item.key = key++;
    
    item = {};
    this.items.push(item);
    item['value'] = "0.00";
    item['supportRank'] = "1";
    item['questionNumber'] = "10";
    item['name'] = "Strongly Disagree";
    item['questionText'] = "Our community college is an important anchor for our regional economy.";
    item.key = key++;
    
    item = {};
    this.items.push(item);
    item['value'] = "0.00";
    item['supportRank'] = "1";
    item['questionNumber'] = "11";
    item['name'] = "Strongly Disagree";
    item['questionText'] = "Making sure all of the college's instructional areas are equipped with up-to-date instructional technology.";
    item.key = key++;
    
    item = {};
    this.items.push(item);
    item['value'] = "0.00";
    item['supportRank'] = "1";
    item['questionNumber'] = "12";
    item['name'] = "Strongly Disagree";
    item['questionText'] = "Replacing buildings that are more than a half-century old, and are expensive and inefficient to operate and maintain.";
    item.key = key++;
    
    item = {};
    this.items.push(item);
    item['value'] = "0.00";
    item['supportRank'] = "1";
    item['questionNumber'] = "13";
    item['name'] = "Strongly Disagree";
    item['questionText'] = "Providing learning spaces to accommodate best practices in job training and retraining programs.";
    item.key = key++;
    
    item = {};
    this.items.push(item);
    item['value'] = "0.00";
    item['supportRank'] = "1";
    item['questionNumber'] = "14";
    item['name'] = "Strongly Disagree";
    item['questionText'] = "Repairing and replacing aging roofs, doors, windows, plumbing, HVAC, electrical and other infrastructure conditions as needed.";
    item.key = key++;
    
    item = {};
    this.items.push(item);
    item['value'] = "0.00";
    item['supportRank'] = "1";
    item['questionNumber'] = "15";
    item['name'] = "Strongly Disagree";
    item['questionText'] = "Providing job training and retraining facilities and programs for good paying, high-in-demand, jobs in areas like healthcare, information technology (IT) and financial services.";
    item.key = key++;
    
    item = {};
    this.items.push(item);
    item['value'] = "0.00";
    item['supportRank'] = "1";
    item['questionNumber'] = "16";
    item['name'] = "Strongly Disagree";
    item['questionText'] = "Making sure the campus is safe and secure both night and day.";
    item.key = key++;
    
    item = {};
    this.items.push(item);
    item['value'] = "0.00";
    item['supportRank'] = "1";
    item['questionNumber'] = "17";
    item['name'] = "Strongly Disagree";
    item['questionText'] = "Keeping community college programs affordable.";
    item.key = key++;
    
    item = {};
    this.items.push(item);
    item['value'] = "0.00";
    item['supportRank'] = "1";
    item['questionNumber'] = "18";
    item['name'] = "Strongly Disagree";
    item['questionText'] = "Improving training programs so those who are underemployed and unemployed can secure jobs that pay a decent salary.";
    item.key = key++;
    
    item = {};
    this.items.push(item);
    item['value'] = "0.00";
    item['supportRank'] = "1";
    item['questionNumber'] = "19";
    item['name'] = "Strongly Disagree";
    item['questionText'] = "Making sure student support services such as registration and financial services are centralized and easily available to students.";
    item.key = key++;
    
    item = {};
    this.items.push(item);
    item['value'] = "0.00";
    item['supportRank'] = "1";
    item['questionNumber'] = "20";
    item['name'] = "Strongly Disagree";
    item['questionText'] = "Coordinating programs with area economic planners and businesses to make sure well-trained employees are available to help stimulate business growth and relocation in our community.";
    item.key = key++;
    
    item = {};
    this.items.push(item);
    item['value'] = "0.00";
    item['supportRank'] = "1";
    item['questionNumber'] = "21";
    item['name'] = "Strongly Disagree";
    item['questionText'] = "Providing real-world learning environments that provide students with the skills they need in the workplace.";
    item.key = key++;
    
    item = {};
    this.items.push(item);
    item['value'] = "0.00";
    item['supportRank'] = "1";
    item['questionNumber'] = "22";
    item['name'] = "Strongly Disagree";
    item['questionText'] = "Improving ADA accessibility at all campuses for students with disabilities.";
    item.key = key++;
    
    item = {};
    this.items.push(item);
    item['value'] = "0.00";
    item['supportRank'] = "1";
    item['questionNumber'] = "23";
    item['name'] = "Strongly Disagree";
    item['questionText'] = "We must ensure that our community college has adequate facilities and programs to meet the demand for high tech job training programs.";
    item.key = key++;
    
    item = {};
    this.items.push(item);
    item['value'] = "0.00";
    item['supportRank'] = "1";
    item['questionNumber'] = "24";
    item['name'] = "Strongly Disagree";
    item['questionText'] = "If the college needs more money, it should raise student fees, not increase the money it receives from taxes.";
    item.key = key++;
    
    item = {};
    this.items.push(item);
    item['value'] = "0.00";
    item['supportRank'] = "1";
    item['questionNumber'] = "25";
    item['name'] = "Strongly Disagree";
    item['questionText'] = "We must protect the investment we've made in our community college by keeping our programming current and our facilities up-to-date in good working condition.";
    item.key = key++;
    
    item = {};
    this.items.push(item);
    item['value'] = "0.00";
    item['supportRank'] = "1";
    item['questionNumber'] = "26";
    item['name'] = "Strongly Disagree";
    item['questionText'] = "Our community college is important to our area. But in terms of receiving more financial support, there are other things that are just more important.";
    item.key = key++;
    
    item = {};
    this.items.push(item);
    item['value'] = "0.00";
    item['supportRank'] = "1";
    item['questionNumber'] = "27";
    item['name'] = "Strongly Disagree";
    item['questionText'] = "Following more than a year of COVID-19, it is more important than ever to support the career education and training programs at our community college.";
    item.key = key++;
    
    item = {};
    this.items.push(item);
    item['value'] = "0.00";
    item['supportRank'] = "1";
    item['questionNumber'] = "28";
    item['name'] = "Strongly Disagree";
    item['questionText'] = "Unless we keep the college's programs and facilities up-to-date, many area workers will not be able to get the training they need to prepare for the good paying jobs of today and tomorrow, and that will hurt the local economy.";
    item.key = key++;
    
    item = {};
    this.items.push(item);
    item['value'] = "0.00";
    item['supportRank'] = "1";
    item['questionNumber'] = "29";
    item['name'] = "Strongly Disagree";
    item['questionText'] = "The community college's career education programs help create an educated workforce. That leads to an improved economy, and in the long term, that lowers local taxes. Supporting the community just makes financial sense.";
    item.key = key++;
    
    item = {};
    this.items.push(item);
    item['value'] = "0.00";
    item['supportRank'] = "1";
    item['questionNumber'] = "30";
    item['name'] = "Strongly Disagree";
    item['questionText'] = "I just don't trust local leadership with additional public dollars.";
    item.key = key++;
    
    item = {};
    this.items.push(item);
    item['value'] = "0.00";
    item['supportRank'] = "1";
    item['questionNumber'] = "31";
    item['name'] = "Strongly Disagree";
    item['questionText'] = "Right now there are thousands of good-paying jobs in our area that remain unfilled because businesses can't find employees with the necessary skills. This proposal helps train local residents for these jobs and keep them in our area.";
    item.key = key++;
    
    item = {};
    this.items.push(item);
    item['value'] = "0.00";
    item['supportRank'] = "1";
    item['questionNumber'] = "32";
    item['name'] = "Strongly Disagree";
    item['questionText'] = "Many businesses can't grow, or move to our area, because of the shortage of a well-trained workforce. Our economic future depends on supporting job training and retraining programs at our community college.";
    item.key = key++;
    
    item = {};
    this.items.push(item);
    item['value'] = "0.00";
    item['supportRank'] = "1";
    item['questionNumber'] = "33";
    item['name'] = "Strongly Disagree";
    item['questionText'] = "This proposal will enable the college to repurpose out-of-date and underutilized spaces to meet the needs of today's students and for future generations.";
    item.key = key++;
    
    item = {};
    this.items.push(item);
    item['value'] = "0.00";
    item['supportRank'] = "1";
    item['questionNumber'] = "34";
    item['name'] = "Strongly Disagree";
    item['questionText'] = "As asked earlier, assume that your local community college is seeking an 8-cent increase in property taxes to support improved facilities and programs. If you were voting today, is that something you would . . .";
    item.key = key++;
    
    item = {};
    this.items.push(item);
    item['value'] = "0.00";
    item['supportRank'] = "2";
    item['questionNumber'] = "1";
    item['name'] = "Disagree";
    item['questionText'] = "Thinking about the next two or three months, do you think the economy in your area will . .";
    item.key = key++;
    
    item = {};
    this.items.push(item);
    item['value'] = "0.00";
    item['supportRank'] = "2";
    item['questionNumber'] = "2";
    item['name'] = "Disagree";
    item['questionText'] = "Students are often given the grades A, B, C, D, and F to evaluate their work. What grade would you give the community college in your area?";
    item.key = key++;
    
    item = {};
    this.items.push(item);
    item['value'] = "19.66";
    item['supportRank'] = "2";
    item['questionNumber'] = "3";
    item['name'] = "Disagree";
    item['questionText'] = "Assume the community college in your area seeks voter approval for an 8-cent increase in property taxes to support improved facilities and programs. If you were voting today, is that something you .";
    item.key = key++;
    
    item = {};
    this.items.push(item);
    item['value'] = "85.78";
    item['supportRank'] = "2";
    item['questionNumber'] = "4";
    item['name'] = "Disagree";
    item['questionText'] = "If you knew this proposal would cost about $1.90 per month for a $150,000 house, is this proposal something you . . .";
    item.key = key++;
    
    item = {};
    this.items.push(item);
    item['value'] = "0.00";
    item['supportRank'] = "2";
    item['questionNumber'] = "5";
    item['name'] = "Disagree";
    item['questionText'] = "It is essential that our area has a quality community college as a place for adults to be trained and retrained for the good paying jobs of today and tomorrow.";
    item.key = key++;
    
    item = {};
    this.items.push(item);
    item['value'] = "0.00";
    item['supportRank'] = "2";
    item['questionNumber'] = "6";
    item['name'] = "Disagree";
    item['questionText'] = "People like me can't afford to pay higher taxes, no matter how good the cause.";
    item.key = key++;
    
    item = {};
    this.items.push(item);
    item['value'] = "0.02";
    item['supportRank'] = "2";
    item['questionNumber'] = "7";
    item['name'] = "Disagree";
    item['questionText'] = "We get excellent value from what we pay to support our community college.";
    item.key = key++;
    
    item = {};
    this.items.push(item);
    item['value'] = "47.49";
    item['supportRank'] = "2";
    item['questionNumber'] = "8";
    item['name'] = "Disagree";
    item['questionText'] = "The local community college does not serve the needs of people like me and my family.";
    item.key = key++;
    
    item = {};
    this.items.push(item);
    item['value'] = "9.82";
    item['supportRank'] = "2";
    item['questionNumber'] = "9";
    item['name'] = "Disagree";
    item['questionText'] = "I'm willing to pay more to support the community college's efforts to help our area economically recover from the COVID-19 pandemic.";
    item.key = key++;
    
    item = {};
    this.items.push(item);
    item['value'] = "0.00";
    item['supportRank'] = "2";
    item['questionNumber'] = "10";
    item['name'] = "Disagree";
    item['questionText'] = "Our community college is an important anchor for our regional economy.";
    item.key = key++;
    
    item = {};
    this.items.push(item);
    item['value'] = "0.00";
    item['supportRank'] = "2";
    item['questionNumber'] = "11";
    item['name'] = "Disagree";
    item['questionText'] = "Making sure all of the college's instructional areas are equipped with up-to-date instructional technology.";
    item.key = key++;
    
    item = {};
    this.items.push(item);
    item['value'] = "0.00";
    item['supportRank'] = "2";
    item['questionNumber'] = "12";
    item['name'] = "Disagree";
    item['questionText'] = "Replacing buildings that are more than a half-century old, and are expensive and inefficient to operate and maintain.";
    item.key = key++;
    
    item = {};
    this.items.push(item);
    item['value'] = "0.00";
    item['supportRank'] = "2";
    item['questionNumber'] = "13";
    item['name'] = "Disagree";
    item['questionText'] = "Providing learning spaces to accommodate best practices in job training and retraining programs.";
    item.key = key++;
    
    item = {};
    this.items.push(item);
    item['value'] = "0.00";
    item['supportRank'] = "2";
    item['questionNumber'] = "14";
    item['name'] = "Disagree";
    item['questionText'] = "Repairing and replacing aging roofs, doors, windows, plumbing, HVAC, electrical and other infrastructure conditions as needed.";
    item.key = key++;
    
    item = {};
    this.items.push(item);
    item['value'] = "0.00";
    item['supportRank'] = "2";
    item['questionNumber'] = "15";
    item['name'] = "Disagree";
    item['questionText'] = "Providing job training and retraining facilities and programs for good paying, high-in-demand, jobs in areas like healthcare, information technology (IT) and financial services.";
    item.key = key++;
    
    item = {};
    this.items.push(item);
    item['value'] = "0.00";
    item['supportRank'] = "2";
    item['questionNumber'] = "16";
    item['name'] = "Disagree";
    item['questionText'] = "Making sure the campus is safe and secure both night and day.";
    item.key = key++;
    
    item = {};
    this.items.push(item);
    item['value'] = "0.00";
    item['supportRank'] = "2";
    item['questionNumber'] = "17";
    item['name'] = "Disagree";
    item['questionText'] = "Keeping community college programs affordable.";
    item.key = key++;
    
    item = {};
    this.items.push(item);
    item['value'] = "0.00";
    item['supportRank'] = "2";
    item['questionNumber'] = "18";
    item['name'] = "Disagree";
    item['questionText'] = "Improving training programs so those who are underemployed and unemployed can secure jobs that pay a decent salary.";
    item.key = key++;
    
    item = {};
    this.items.push(item);
    item['value'] = "0.00";
    item['supportRank'] = "2";
    item['questionNumber'] = "19";
    item['name'] = "Disagree";
    item['questionText'] = "Making sure student support services such as registration and financial services are centralized and easily available to students.";
    item.key = key++;
    
    item = {};
    this.items.push(item);
    item['value'] = "0.00";
    item['supportRank'] = "2";
    item['questionNumber'] = "20";
    item['name'] = "Disagree";
    item['questionText'] = "Coordinating programs with area economic planners and businesses to make sure well-trained employees are available to help stimulate business growth and relocation in our community.";
    item.key = key++;
    
    item = {};
    this.items.push(item);
    item['value'] = "0.00";
    item['supportRank'] = "2";
    item['questionNumber'] = "21";
    item['name'] = "Disagree";
    item['questionText'] = "Providing real-world learning environments that provide students with the skills they need in the workplace.";
    item.key = key++;
    
    item = {};
    this.items.push(item);
    item['value'] = "0.00";
    item['supportRank'] = "2";
    item['questionNumber'] = "22";
    item['name'] = "Disagree";
    item['questionText'] = "Improving ADA accessibility at all campuses for students with disabilities.";
    item.key = key++;
    
    item = {};
    this.items.push(item);
    item['value'] = "0.00";
    item['supportRank'] = "2";
    item['questionNumber'] = "23";
    item['name'] = "Disagree";
    item['questionText'] = "We must ensure that our community college has adequate facilities and programs to meet the demand for high tech job training programs.";
    item.key = key++;
    
    item = {};
    this.items.push(item);
    item['value'] = "1.18";
    item['supportRank'] = "2";
    item['questionNumber'] = "24";
    item['name'] = "Disagree";
    item['questionText'] = "If the college needs more money, it should raise student fees, not increase the money it receives from taxes.";
    item.key = key++;
    
    item = {};
    this.items.push(item);
    item['value'] = "0.00";
    item['supportRank'] = "2";
    item['questionNumber'] = "25";
    item['name'] = "Disagree";
    item['questionText'] = "We must protect the investment we've made in our community college by keeping our programming current and our facilities up-to-date in good working condition.";
    item.key = key++;
    
    item = {};
    this.items.push(item);
    item['value'] = "0.00";
    item['supportRank'] = "2";
    item['questionNumber'] = "26";
    item['name'] = "Disagree";
    item['questionText'] = "Our community college is important to our area. But in terms of receiving more financial support, there are other things that are just more important.";
    item.key = key++;
    
    item = {};
    this.items.push(item);
    item['value'] = "0.00";
    item['supportRank'] = "2";
    item['questionNumber'] = "27";
    item['name'] = "Disagree";
    item['questionText'] = "Following more than a year of COVID-19, it is more important than ever to support the career education and training programs at our community college.";
    item.key = key++;
    
    item = {};
    this.items.push(item);
    item['value'] = "0.00";
    item['supportRank'] = "2";
    item['questionNumber'] = "28";
    item['name'] = "Disagree";
    item['questionText'] = "Unless we keep the college's programs and facilities up-to-date, many area workers will not be able to get the training they need to prepare for the good paying jobs of today and tomorrow, and that will hurt the local economy.";
    item.key = key++;
    
    item = {};
    this.items.push(item);
    item['value'] = "0.00";
    item['supportRank'] = "2";
    item['questionNumber'] = "29";
    item['name'] = "Disagree";
    item['questionText'] = "The community college's career education programs help create an educated workforce. That leads to an improved economy, and in the long term, that lowers local taxes. Supporting the community just makes financial sense.";
    item.key = key++;
    
    item = {};
    this.items.push(item);
    item['value'] = "0.00";
    item['supportRank'] = "2";
    item['questionNumber'] = "30";
    item['name'] = "Disagree";
    item['questionText'] = "I just don't trust local leadership with additional public dollars.";
    item.key = key++;
    
    item = {};
    this.items.push(item);
    item['value'] = "0.00";
    item['supportRank'] = "2";
    item['questionNumber'] = "31";
    item['name'] = "Disagree";
    item['questionText'] = "Right now there are thousands of good-paying jobs in our area that remain unfilled because businesses can't find employees with the necessary skills. This proposal helps train local residents for these jobs and keep them in our area.";
    item.key = key++;
    
    item = {};
    this.items.push(item);
    item['value'] = "0.00";
    item['supportRank'] = "2";
    item['questionNumber'] = "32";
    item['name'] = "Disagree";
    item['questionText'] = "Many businesses can't grow, or move to our area, because of the shortage of a well-trained workforce. Our economic future depends on supporting job training and retraining programs at our community college.";
    item.key = key++;
    
    item = {};
    this.items.push(item);
    item['value'] = "0.00";
    item['supportRank'] = "2";
    item['questionNumber'] = "33";
    item['name'] = "Disagree";
    item['questionText'] = "This proposal will enable the college to repurpose out-of-date and underutilized spaces to meet the needs of today's students and for future generations.";
    item.key = key++;
    
    item = {};
    this.items.push(item);
    item['value'] = "4.32";
    item['supportRank'] = "2";
    item['questionNumber'] = "34";
    item['name'] = "Disagree";
    item['questionText'] = "As asked earlier, assume that your local community college is seeking an 8-cent increase in property taxes to support improved facilities and programs. If you were voting today, is that something you would . . .";
    item.key = key++;
    
    item = {};
    this.items.push(item);
    item['value'] = "61.55";
    item['supportRank'] = "3";
    item['questionNumber'] = "1";
    item['name'] = "Neutral";
    item['questionText'] = "Thinking about the next two or three months, do you think the economy in your area will . .";
    item.key = key++;
    
    item = {};
    this.items.push(item);
    item['value'] = "0.46";
    item['supportRank'] = "3";
    item['questionNumber'] = "2";
    item['name'] = "Neutral";
    item['questionText'] = "Students are often given the grades A, B, C, D, and F to evaluate their work. What grade would you give the community college in your area?";
    item.key = key++;
    
    item = {};
    this.items.push(item);
    item['value'] = "76.77";
    item['supportRank'] = "3";
    item['questionNumber'] = "3";
    item['name'] = "Neutral";
    item['questionText'] = "Assume the community college in your area seeks voter approval for an 8-cent increase in property taxes to support improved facilities and programs. If you were voting today, is that something you .";
    item.key = key++;
    
    item = {};
    this.items.push(item);
    item['value'] = "14.22";
    item['supportRank'] = "3";
    item['questionNumber'] = "4";
    item['name'] = "Neutral";
    item['questionText'] = "If you knew this proposal would cost about $1.90 per month for a $150,000 house, is this proposal something you . . .";
    item.key = key++;
    
    item = {};
    this.items.push(item);
    item['value'] = "0.00";
    item['supportRank'] = "3";
    item['questionNumber'] = "5";
    item['name'] = "Neutral";
    item['questionText'] = "It is essential that our area has a quality community college as a place for adults to be trained and retrained for the good paying jobs of today and tomorrow.";
    item.key = key++;
    
    item = {};
    this.items.push(item);
    item['value'] = "67.72";
    item['supportRank'] = "3";
    item['questionNumber'] = "6";
    item['name'] = "Neutral";
    item['questionText'] = "People like me can't afford to pay higher taxes, no matter how good the cause.";
    item.key = key++;
    
    item = {};
    this.items.push(item);
    item['value'] = "17.01";
    item['supportRank'] = "3";
    item['questionNumber'] = "7";
    item['name'] = "Neutral";
    item['questionText'] = "We get excellent value from what we pay to support our community college.";
    item.key = key++;
    
    item = {};
    this.items.push(item);
    item['value'] = "51.54";
    item['supportRank'] = "3";
    item['questionNumber'] = "8";
    item['name'] = "Neutral";
    item['questionText'] = "The local community college does not serve the needs of people like me and my family.";
    item.key = key++;
    
    item = {};
    this.items.push(item);
    item['value'] = "83.60";
    item['supportRank'] = "3";
    item['questionNumber'] = "9";
    item['name'] = "Neutral";
    item['questionText'] = "I'm willing to pay more to support the community college's efforts to help our area economically recover from the COVID-19 pandemic.";
    item.key = key++;
    
    item = {};
    this.items.push(item);
    item['value'] = "26.46";
    item['supportRank'] = "3";
    item['questionNumber'] = "10";
    item['name'] = "Neutral";
    item['questionText'] = "Our community college is an important anchor for our regional economy.";
    item.key = key++;
    
    item = {};
    this.items.push(item);
    item['value'] = "1.55";
    item['supportRank'] = "3";
    item['questionNumber'] = "11";
    item['name'] = "Neutral";
    item['questionText'] = "Making sure all of the college's instructional areas are equipped with up-to-date instructional technology.";
    item.key = key++;
    
    item = {};
    this.items.push(item);
    item['value'] = "92.62";
    item['supportRank'] = "3";
    item['questionNumber'] = "12";
    item['name'] = "Neutral";
    item['questionText'] = "Replacing buildings that are more than a half-century old, and are expensive and inefficient to operate and maintain.";
    item.key = key++;
    
    item = {};
    this.items.push(item);
    item['value'] = "1.08";
    item['supportRank'] = "3";
    item['questionNumber'] = "13";
    item['name'] = "Neutral";
    item['questionText'] = "Providing learning spaces to accommodate best practices in job training and retraining programs.";
    item.key = key++;
    
    item = {};
    this.items.push(item);
    item['value'] = "3.01";
    item['supportRank'] = "3";
    item['questionNumber'] = "14";
    item['name'] = "Neutral";
    item['questionText'] = "Repairing and replacing aging roofs, doors, windows, plumbing, HVAC, electrical and other infrastructure conditions as needed.";
    item.key = key++;
    
    item = {};
    this.items.push(item);
    item['value'] = "0.00";
    item['supportRank'] = "3";
    item['questionNumber'] = "15";
    item['name'] = "Neutral";
    item['questionText'] = "Providing job training and retraining facilities and programs for good paying, high-in-demand, jobs in areas like healthcare, information technology (IT) and financial services.";
    item.key = key++;
    
    item = {};
    this.items.push(item);
    item['value'] = "0.00";
    item['supportRank'] = "3";
    item['questionNumber'] = "16";
    item['name'] = "Neutral";
    item['questionText'] = "Making sure the campus is safe and secure both night and day.";
    item.key = key++;
    
    item = {};
    this.items.push(item);
    item['value'] = "0.00";
    item['supportRank'] = "3";
    item['questionNumber'] = "17";
    item['name'] = "Neutral";
    item['questionText'] = "Keeping community college programs affordable.";
    item.key = key++;
    
    item = {};
    this.items.push(item);
    item['value'] = "1.55";
    item['supportRank'] = "3";
    item['questionNumber'] = "18";
    item['name'] = "Neutral";
    item['questionText'] = "Improving training programs so those who are underemployed and unemployed can secure jobs that pay a decent salary.";
    item.key = key++;
    
    item = {};
    this.items.push(item);
    item['value'] = "13.62";
    item['supportRank'] = "3";
    item['questionNumber'] = "19";
    item['name'] = "Neutral";
    item['questionText'] = "Making sure student support services such as registration and financial services are centralized and easily available to students.";
    item.key = key++;
    
    item = {};
    this.items.push(item);
    item['value'] = "5.46";
    item['supportRank'] = "3";
    item['questionNumber'] = "20";
    item['name'] = "Neutral";
    item['questionText'] = "Coordinating programs with area economic planners and businesses to make sure well-trained employees are available to help stimulate business growth and relocation in our community.";
    item.key = key++;
    
    item = {};
    this.items.push(item);
    item['value'] = "0.00";
    item['supportRank'] = "3";
    item['questionNumber'] = "21";
    item['name'] = "Neutral";
    item['questionText'] = "Providing real-world learning environments that provide students with the skills they need in the workplace.";
    item.key = key++;
    
    item = {};
    this.items.push(item);
    item['value'] = "1.55";
    item['supportRank'] = "3";
    item['questionNumber'] = "22";
    item['name'] = "Neutral";
    item['questionText'] = "Improving ADA accessibility at all campuses for students with disabilities.";
    item.key = key++;
    
    item = {};
    this.items.push(item);
    item['value'] = "0.00";
    item['supportRank'] = "3";
    item['questionNumber'] = "23";
    item['name'] = "Neutral";
    item['questionText'] = "We must ensure that our community college has adequate facilities and programs to meet the demand for high tech job training programs.";
    item.key = key++;
    
    item = {};
    this.items.push(item);
    item['value'] = "77.20";
    item['supportRank'] = "3";
    item['questionNumber'] = "24";
    item['name'] = "Neutral";
    item['questionText'] = "If the college needs more money, it should raise student fees, not increase the money it receives from taxes.";
    item.key = key++;
    
    item = {};
    this.items.push(item);
    item['value'] = "0.00";
    item['supportRank'] = "3";
    item['questionNumber'] = "25";
    item['name'] = "Neutral";
    item['questionText'] = "We must protect the investment we've made in our community college by keeping our programming current and our facilities up-to-date in good working condition.";
    item.key = key++;
    
    item = {};
    this.items.push(item);
    item['value'] = "33.64";
    item['supportRank'] = "3";
    item['questionNumber'] = "26";
    item['name'] = "Neutral";
    item['questionText'] = "Our community college is important to our area. But in terms of receiving more financial support, there are other things that are just more important.";
    item.key = key++;
    
    item = {};
    this.items.push(item);
    item['value'] = "10.20";
    item['supportRank'] = "3";
    item['questionNumber'] = "27";
    item['name'] = "Neutral";
    item['questionText'] = "Following more than a year of COVID-19, it is more important than ever to support the career education and training programs at our community college.";
    item.key = key++;
    
    item = {};
    this.items.push(item);
    item['value'] = "12.50";
    item['supportRank'] = "3";
    item['questionNumber'] = "28";
    item['name'] = "Neutral";
    item['questionText'] = "Unless we keep the college's programs and facilities up-to-date, many area workers will not be able to get the training they need to prepare for the good paying jobs of today and tomorrow, and that will hurt the local economy.";
    item.key = key++;
    
    item = {};
    this.items.push(item);
    item['value'] = "0.60";
    item['supportRank'] = "3";
    item['questionNumber'] = "29";
    item['name'] = "Neutral";
    item['questionText'] = "The community college's career education programs help create an educated workforce. That leads to an improved economy, and in the long term, that lowers local taxes. Supporting the community just makes financial sense.";
    item.key = key++;
    
    item = {};
    this.items.push(item);
    item['value'] = "62.44";
    item['supportRank'] = "3";
    item['questionNumber'] = "30";
    item['name'] = "Neutral";
    item['questionText'] = "I just don't trust local leadership with additional public dollars.";
    item.key = key++;
    
    item = {};
    this.items.push(item);
    item['value'] = "19.33";
    item['supportRank'] = "3";
    item['questionNumber'] = "31";
    item['name'] = "Neutral";
    item['questionText'] = "Right now there are thousands of good-paying jobs in our area that remain unfilled because businesses can't find employees with the necessary skills. This proposal helps train local residents for these jobs and keep them in our area.";
    item.key = key++;
    
    item = {};
    this.items.push(item);
    item['value'] = "60.35";
    item['supportRank'] = "3";
    item['questionNumber'] = "32";
    item['name'] = "Neutral";
    item['questionText'] = "Many businesses can't grow, or move to our area, because of the shortage of a well-trained workforce. Our economic future depends on supporting job training and retraining programs at our community college.";
    item.key = key++;
    
    item = {};
    this.items.push(item);
    item['value'] = "24.94";
    item['supportRank'] = "3";
    item['questionNumber'] = "33";
    item['name'] = "Neutral";
    item['questionText'] = "This proposal will enable the college to repurpose out-of-date and underutilized spaces to meet the needs of today's students and for future generations.";
    item.key = key++;
    
    item = {};
    this.items.push(item);
    item['value'] = "87.16";
    item['supportRank'] = "3";
    item['questionNumber'] = "34";
    item['name'] = "Neutral";
    item['questionText'] = "As asked earlier, assume that your local community college is seeking an 8-cent increase in property taxes to support improved facilities and programs. If you were voting today, is that something you would . . .";
    item.key = key++;
    
    item = {};
    this.items.push(item);
    item['value'] = "38.45";
    item['supportRank'] = "4";
    item['questionNumber'] = "1";
    item['name'] = "Agree";
    item['questionText'] = "Thinking about the next two or three months, do you think the economy in your area will . .";
    item.key = key++;
    
    item = {};
    this.items.push(item);
    item['value'] = "96.16";
    item['supportRank'] = "4";
    item['questionNumber'] = "2";
    item['name'] = "Agree";
    item['questionText'] = "Students are often given the grades A, B, C, D, and F to evaluate their work. What grade would you give the community college in your area?";
    item.key = key++;
    
    item = {};
    this.items.push(item);
    item['value'] = "3.58";
    item['supportRank'] = "4";
    item['questionNumber'] = "3";
    item['name'] = "Agree";
    item['questionText'] = "Assume the community college in your area seeks voter approval for an 8-cent increase in property taxes to support improved facilities and programs. If you were voting today, is that something you .";
    item.key = key++;
    
    item = {};
    this.items.push(item);
    item['value'] = "0.00";
    item['supportRank'] = "4";
    item['questionNumber'] = "4";
    item['name'] = "Agree";
    item['questionText'] = "If you knew this proposal would cost about $1.90 per month for a $150,000 house, is this proposal something you . . .";
    item.key = key++;
    
    item = {};
    this.items.push(item);
    item['value'] = "39.99";
    item['supportRank'] = "4";
    item['questionNumber'] = "5";
    item['name'] = "Agree";
    item['questionText'] = "It is essential that our area has a quality community college as a place for adults to be trained and retrained for the good paying jobs of today and tomorrow.";
    item.key = key++;
    
    item = {};
    this.items.push(item);
    item['value'] = "32.28";
    item['supportRank'] = "4";
    item['questionNumber'] = "6";
    item['name'] = "Agree";
    item['questionText'] = "People like me can't afford to pay higher taxes, no matter how good the cause.";
    item.key = key++;
    
    item = {};
    this.items.push(item);
    item['value'] = "82.97";
    item['supportRank'] = "4";
    item['questionNumber'] = "7";
    item['name'] = "Agree";
    item['questionText'] = "We get excellent value from what we pay to support our community college.";
    item.key = key++;
    
    item = {};
    this.items.push(item);
    item['value'] = "0.97";
    item['supportRank'] = "4";
    item['questionNumber'] = "8";
    item['name'] = "Agree";
    item['questionText'] = "The local community college does not serve the needs of people like me and my family.";
    item.key = key++;
    
    item = {};
    this.items.push(item);
    item['value'] = "6.58";
    item['supportRank'] = "4";
    item['questionNumber'] = "9";
    item['name'] = "Agree";
    item['questionText'] = "I'm willing to pay more to support the community college's efforts to help our area economically recover from the COVID-19 pandemic.";
    item.key = key++;
    
    item = {};
    this.items.push(item);
    item['value'] = "72.99";
    item['supportRank'] = "4";
    item['questionNumber'] = "10";
    item['name'] = "Agree";
    item['questionText'] = "Our community college is an important anchor for our regional economy.";
    item.key = key++;
    
    item = {};
    this.items.push(item);
    item['value'] = "97.23";
    item['supportRank'] = "4";
    item['questionNumber'] = "11";
    item['name'] = "Agree";
    item['questionText'] = "Making sure all of the college's instructional areas are equipped with up-to-date instructional technology.";
    item.key = key++;
    
    item = {};
    this.items.push(item);
    item['value'] = "7.38";
    item['supportRank'] = "4";
    item['questionNumber'] = "12";
    item['name'] = "Agree";
    item['questionText'] = "Replacing buildings that are more than a half-century old, and are expensive and inefficient to operate and maintain.";
    item.key = key++;
    
    item = {};
    this.items.push(item);
    item['value'] = "98.77";
    item['supportRank'] = "4";
    item['questionNumber'] = "13";
    item['name'] = "Agree";
    item['questionText'] = "Providing learning spaces to accommodate best practices in job training and retraining programs.";
    item.key = key++;
    
    item = {};
    this.items.push(item);
    item['value'] = "96.99";
    item['supportRank'] = "4";
    item['questionNumber'] = "14";
    item['name'] = "Agree";
    item['questionText'] = "Repairing and replacing aging roofs, doors, windows, plumbing, HVAC, electrical and other infrastructure conditions as needed.";
    item.key = key++;
    
    item = {};
    this.items.push(item);
    item['value'] = "88.13";
    item['supportRank'] = "4";
    item['questionNumber'] = "15";
    item['name'] = "Agree";
    item['questionText'] = "Providing job training and retraining facilities and programs for good paying, high-in-demand, jobs in areas like healthcare, information technology (IT) and financial services.";
    item.key = key++;
    
    item = {};
    this.items.push(item);
    item['value'] = "87.97";
    item['supportRank'] = "4";
    item['questionNumber'] = "16";
    item['name'] = "Agree";
    item['questionText'] = "Making sure the campus is safe and secure both night and day.";
    item.key = key++;
    
    item = {};
    this.items.push(item);
    item['value'] = "89.76";
    item['supportRank'] = "4";
    item['questionNumber'] = "17";
    item['name'] = "Agree";
    item['questionText'] = "Keeping community college programs affordable.";
    item.key = key++;
    
    item = {};
    this.items.push(item);
    item['value'] = "97.44";
    item['supportRank'] = "4";
    item['questionNumber'] = "18";
    item['name'] = "Agree";
    item['questionText'] = "Improving training programs so those who are underemployed and unemployed can secure jobs that pay a decent salary.";
    item.key = key++;
    
    item = {};
    this.items.push(item);
    item['value'] = "86.03";
    item['supportRank'] = "4";
    item['questionNumber'] = "19";
    item['name'] = "Agree";
    item['questionText'] = "Making sure student support services such as registration and financial services are centralized and easily available to students.";
    item.key = key++;
    
    item = {};
    this.items.push(item);
    item['value'] = "94.19";
    item['supportRank'] = "4";
    item['questionNumber'] = "20";
    item['name'] = "Agree";
    item['questionText'] = "Coordinating programs with area economic planners and businesses to make sure well-trained employees are available to help stimulate business growth and relocation in our community.";
    item.key = key++;
    
    item = {};
    this.items.push(item);
    item['value'] = "94.88";
    item['supportRank'] = "4";
    item['questionNumber'] = "21";
    item['name'] = "Agree";
    item['questionText'] = "Providing real-world learning environments that provide students with the skills they need in the workplace.";
    item.key = key++;
    
    item = {};
    this.items.push(item);
    item['value'] = "98.45";
    item['supportRank'] = "4";
    item['questionNumber'] = "22";
    item['name'] = "Agree";
    item['questionText'] = "Improving ADA accessibility at all campuses for students with disabilities.";
    item.key = key++;
    
    item = {};
    this.items.push(item);
    item['value'] = "72.53";
    item['supportRank'] = "4";
    item['questionNumber'] = "23";
    item['name'] = "Agree";
    item['questionText'] = "We must ensure that our community college has adequate facilities and programs to meet the demand for high tech job training programs.";
    item.key = key++;
    
    item = {};
    this.items.push(item);
    item['value'] = "21.62";
    item['supportRank'] = "4";
    item['questionNumber'] = "24";
    item['name'] = "Agree";
    item['questionText'] = "If the college needs more money, it should raise student fees, not increase the money it receives from taxes.";
    item.key = key++;
    
    item = {};
    this.items.push(item);
    item['value'] = "89.95";
    item['supportRank'] = "4";
    item['questionNumber'] = "25";
    item['name'] = "Agree";
    item['questionText'] = "We must protect the investment we've made in our community college by keeping our programming current and our facilities up-to-date in good working condition.";
    item.key = key++;
    
    item = {};
    this.items.push(item);
    item['value'] = "66.36";
    item['supportRank'] = "4";
    item['questionNumber'] = "26";
    item['name'] = "Agree";
    item['questionText'] = "Our community college is important to our area. But in terms of receiving more financial support, there are other things that are just more important.";
    item.key = key++;
    
    item = {};
    this.items.push(item);
    item['value'] = "89.45";
    item['supportRank'] = "4";
    item['questionNumber'] = "27";
    item['name'] = "Agree";
    item['questionText'] = "Following more than a year of COVID-19, it is more important than ever to support the career education and training programs at our community college.";
    item.key = key++;
    
    item = {};
    this.items.push(item);
    item['value'] = "87.15";
    item['supportRank'] = "4";
    item['questionNumber'] = "28";
    item['name'] = "Agree";
    item['questionText'] = "Unless we keep the college's programs and facilities up-to-date, many area workers will not be able to get the training they need to prepare for the good paying jobs of today and tomorrow, and that will hurt the local economy.";
    item.key = key++;
    
    item = {};
    this.items.push(item);
    item['value'] = "99.04";
    item['supportRank'] = "4";
    item['questionNumber'] = "29";
    item['name'] = "Agree";
    item['questionText'] = "The community college's career education programs help create an educated workforce. That leads to an improved economy, and in the long term, that lowers local taxes. Supporting the community just makes financial sense.";
    item.key = key++;
    
    item = {};
    this.items.push(item);
    item['value'] = "37.56";
    item['supportRank'] = "4";
    item['questionNumber'] = "30";
    item['name'] = "Agree";
    item['questionText'] = "I just don't trust local leadership with additional public dollars.";
    item.key = key++;
    
    item = {};
    this.items.push(item);
    item['value'] = "80.67";
    item['supportRank'] = "4";
    item['questionNumber'] = "31";
    item['name'] = "Agree";
    item['questionText'] = "Right now there are thousands of good-paying jobs in our area that remain unfilled because businesses can't find employees with the necessary skills. This proposal helps train local residents for these jobs and keep them in our area.";
    item.key = key++;
    
    item = {};
    this.items.push(item);
    item['value'] = "39.65";
    item['supportRank'] = "4";
    item['questionNumber'] = "32";
    item['name'] = "Agree";
    item['questionText'] = "Many businesses can't grow, or move to our area, because of the shortage of a well-trained workforce. Our economic future depends on supporting job training and retraining programs at our community college.";
    item.key = key++;
    
    item = {};
    this.items.push(item);
    item['value'] = "75.06";
    item['supportRank'] = "4";
    item['questionNumber'] = "33";
    item['name'] = "Agree";
    item['questionText'] = "This proposal will enable the college to repurpose out-of-date and underutilized spaces to meet the needs of today's students and for future generations.";
    item.key = key++;
    
    item = {};
    this.items.push(item);
    item['value'] = "8.52";
    item['supportRank'] = "4";
    item['questionNumber'] = "34";
    item['name'] = "Agree";
    item['questionText'] = "As asked earlier, assume that your local community college is seeking an 8-cent increase in property taxes to support improved facilities and programs. If you were voting today, is that something you would . . .";
    item.key = key++;
    
    item = {};
    this.items.push(item);
    item['value'] = "0.00";
    item['supportRank'] = "5";
    item['questionNumber'] = "0";
    item['name'] = "Strongly Agree";
    item['questionText'] = "Thinking about the next two or three months, do you think the economy in your area will . .";
    item.key = key++;
    
    item = {};
    this.items.push(item);
    item['value'] = "3.38";
    item['supportRank'] = "5";
    item['questionNumber'] = "2";
    item['name'] = "Strongly Agree";
    item['questionText'] = "Students are often given the grades A, B, C, D, and F to evaluate their work. What grade would you give the community college in your area?";
    item.key = key++;
    
    item = {};
    this.items.push(item);
    item['value'] = "0.00";
    item['supportRank'] = "5";
    item['questionNumber'] = "3";
    item['name'] = "Strongly Agree";
    item['questionText'] = "Assume the community college in your area seeks voter approval for an 8-cent increase in property taxes to support improved facilities and programs. If you were voting today, is that something you .";
    item.key = key++;
    
    item = {};
    this.items.push(item);
    item['value'] = "0.00";
    item['supportRank'] = "5";
    item['questionNumber'] = "4";
    item['name'] = "Strongly Agree";
    item['questionText'] = "If you knew this proposal would cost about $1.90 per month for a $150,000 house, is this proposal something you . . .";
    item.key = key++;
    
    item = {};
    this.items.push(item);
    item['value'] = "60.01";
    item['supportRank'] = "5";
    item['questionNumber'] = "5";
    item['name'] = "Strongly Agree";
    item['questionText'] = "It is essential that our area has a quality community college as a place for adults to be trained and retrained for the good paying jobs of today and tomorrow.";
    item.key = key++;
    
    item = {};
    this.items.push(item);
    item['value'] = "0.00";
    item['supportRank'] = "5";
    item['questionNumber'] = "6";
    item['name'] = "Strongly Agree";
    item['questionText'] = "People like me can't afford to pay higher taxes, no matter how good the cause.";
    item.key = key++;
    
    item = {};
    this.items.push(item);
    item['value'] = "0.00";
    item['supportRank'] = "5";
    item['questionNumber'] = "7";
    item['name'] = "Strongly Agree";
    item['questionText'] = "We get excellent value from what we pay to support our community college.";
    item.key = key++;
    
    item = {};
    this.items.push(item);
    item['value'] = "0.00";
    item['supportRank'] = "5";
    item['questionNumber'] = "8";
    item['name'] = "Strongly Agree";
    item['questionText'] = "The local community college does not serve the needs of people like me and my family.";
    item.key = key++;
    
    item = {};
    this.items.push(item);
    item['value'] = "0.00";
    item['supportRank'] = "5";
    item['questionNumber'] = "9";
    item['name'] = "Strongly Agree";
    item['questionText'] = "I'm willing to pay more to support the community college's efforts to help our area economically recover from the COVID-19 pandemic.";
    item.key = key++;
    
    item = {};
    this.items.push(item);
    item['value'] = "0.55";
    item['supportRank'] = "5";
    item['questionNumber'] = "10";
    item['name'] = "Strongly Agree";
    item['questionText'] = "Our community college is an important anchor for our regional economy.";
    item.key = key++;
    
    item = {};
    this.items.push(item);
    item['value'] = "1.22";
    item['supportRank'] = "5";
    item['questionNumber'] = "11";
    item['name'] = "Strongly Agree";
    item['questionText'] = "Making sure all of the college's instructional areas are equipped with up-to-date instructional technology.";
    item.key = key++;
    
    item = {};
    this.items.push(item);
    item['value'] = "0.00";
    item['supportRank'] = "5";
    item['questionNumber'] = "12";
    item['name'] = "Strongly Agree";
    item['questionText'] = "Replacing buildings that are more than a half-century old, and are expensive and inefficient to operate and maintain.";
    item.key = key++;
    
    item = {};
    this.items.push(item);
    item['value'] = "0.14";
    item['supportRank'] = "5";
    item['questionNumber'] = "13";
    item['name'] = "Strongly Agree";
    item['questionText'] = "Providing learning spaces to accommodate best practices in job training and retraining programs.";
    item.key = key++;
    
    item = {};
    this.items.push(item);
    item['value'] = "0.00";
    item['supportRank'] = "5";
    item['questionNumber'] = "14";
    item['name'] = "Strongly Agree";
    item['questionText'] = "Repairing and replacing aging roofs, doors, windows, plumbing, HVAC, electrical and other infrastructure conditions as needed.";
    item.key = key++;
    
    item = {};
    this.items.push(item);
    item['value'] = "11.87";
    item['supportRank'] = "5";
    item['questionNumber'] = "15";
    item['name'] = "Strongly Agree";
    item['questionText'] = "Providing job training and retraining facilities and programs for good paying, high-in-demand, jobs in areas like healthcare, information technology (IT) and financial services.";
    item.key = key++;
    
    item = {};
    this.items.push(item);
    item['value'] = "12.03";
    item['supportRank'] = "5";
    item['questionNumber'] = "16";
    item['name'] = "Strongly Agree";
    item['questionText'] = "Making sure the campus is safe and secure both night and day.";
    item.key = key++;
    
    item = {};
    this.items.push(item);
    item['value'] = "10.24";
    item['supportRank'] = "5";
    item['questionNumber'] = "17";
    item['name'] = "Strongly Agree";
    item['questionText'] = "Keeping community college programs affordable.";
    item.key = key++;
    
    item = {};
    this.items.push(item);
    item['value'] = "1.02";
    item['supportRank'] = "5";
    item['questionNumber'] = "18";
    item['name'] = "Strongly Agree";
    item['questionText'] = "Improving training programs so those who are underemployed and unemployed can secure jobs that pay a decent salary.";
    item.key = key++;
    
    item = {};
    this.items.push(item);
    item['value'] = "0.35";
    item['supportRank'] = "5";
    item['questionNumber'] = "19";
    item['name'] = "Strongly Agree";
    item['questionText'] = "Making sure student support services such as registration and financial services are centralized and easily available to students.";
    item.key = key++;
    
    item = {};
    this.items.push(item);
    item['value'] = "0.35";
    item['supportRank'] = "5";
    item['questionNumber'] = "20";
    item['name'] = "Strongly Agree";
    item['questionText'] = "Coordinating programs with area economic planners and businesses to make sure well-trained employees are available to help stimulate business growth and relocation in our community.";
    item.key = key++;
    
    item = {};
    this.items.push(item);
    item['value'] = "5.12";
    item['supportRank'] = "5";
    item['questionNumber'] = "21";
    item['name'] = "Strongly Agree";
    item['questionText'] = "Providing real-world learning environments that provide students with the skills they need in the workplace.";
    item.key = key++;
    
    item = {};
    this.items.push(item);
    item['value'] = "0.00";
    item['supportRank'] = "5";
    item['questionNumber'] = "22";
    item['name'] = "Strongly Agree";
    item['questionText'] = "Improving ADA accessibility at all campuses for students with disabilities.";
    item.key = key++;
    
    item = {};
    this.items.push(item);
    item['value'] = "27.47";
    item['supportRank'] = "5";
    item['questionNumber'] = "23";
    item['name'] = "Strongly Agree";
    item['questionText'] = "We must ensure that our community college has adequate facilities and programs to meet the demand for high tech job training programs.";
    item.key = key++;
    
    item = {};
    this.items.push(item);
    item['value'] = "0.00";
    item['supportRank'] = "5";
    item['questionNumber'] = "24";
    item['name'] = "Strongly Agree";
    item['questionText'] = "If the college needs more money, it should raise student fees, not increase the money it receives from taxes.";
    item.key = key++;
    
    item = {};
    this.items.push(item);
    item['value'] = "10.05";
    item['supportRank'] = "5";
    item['questionNumber'] = "25";
    item['name'] = "Strongly Agree";
    item['questionText'] = "We must protect the investment we've made in our community college by keeping our programming current and our facilities up-to-date in good working condition.";
    item.key = key++;
    
    item = {};
    this.items.push(item);
    item['value'] = "0.00";
    item['supportRank'] = "5";
    item['questionNumber'] = "26";
    item['name'] = "Strongly Agree";
    item['questionText'] = "Our community college is important to our area. But in terms of receiving more financial support, there are other things that are just more important.";
    item.key = key++;
    
    item = {};
    this.items.push(item);
    item['value'] = "0.35";
    item['supportRank'] = "5";
    item['questionNumber'] = "27";
    item['name'] = "Strongly Agree";
    item['questionText'] = "Following more than a year of COVID-19, it is more important than ever to support the career education and training programs at our community college.";
    item.key = key++;
    
    item = {};
    this.items.push(item);
    item['value'] = "0.35";
    item['supportRank'] = "5";
    item['questionNumber'] = "28";
    item['name'] = "Strongly Agree";
    item['questionText'] = "Unless we keep the college's programs and facilities up-to-date, many area workers will not be able to get the training they need to prepare for the good paying jobs of today and tomorrow, and that will hurt the local economy.";
    item.key = key++;
    
    item = {};
    this.items.push(item);
    item['value'] = "0.35";
    item['supportRank'] = "5";
    item['questionNumber'] = "29";
    item['name'] = "Strongly Agree";
    item['questionText'] = "The community college's career education programs help create an educated workforce. That leads to an improved economy, and in the long term, that lowers local taxes. Supporting the community just makes financial sense.";
    item.key = key++;
    
    item = {};
    this.items.push(item);
    item['value'] = "0.00";
    item['supportRank'] = "5";
    item['questionNumber'] = "30";
    item['name'] = "Strongly Agree";
    item['questionText'] = "I just don't trust local leadership with additional public dollars.";
    item.key = key++;
    
    item = {};
    this.items.push(item);
    item['value'] = "0.00";
    item['supportRank'] = "5";
    item['questionNumber'] = "31";
    item['name'] = "Strongly Agree";
    item['questionText'] = "Right now there are thousands of good-paying jobs in our area that remain unfilled because businesses can't find employees with the necessary skills. This proposal helps train local residents for these jobs and keep them in our area.";
    item.key = key++;
    
    item = {};
    this.items.push(item);
    item['value'] = "0.00";
    item['supportRank'] = "5";
    item['questionNumber'] = "32";
    item['name'] = "Strongly Agree";
    item['questionText'] = "Many businesses can't grow, or move to our area, because of the shortage of a well-trained workforce. Our economic future depends on supporting job training and retraining programs at our community college.";
    item.key = key++;
    
    item = {};
    this.items.push(item);
    item['value'] = "0.00";
    item['supportRank'] = "5";
    item['questionNumber'] = "33";
    item['name'] = "Strongly Agree";
    item['questionText'] = "This proposal will enable the college to repurpose out-of-date and underutilized spaces to meet the needs of today's students and for future generations.";
    item.key = key++;
    
    item = {};
    this.items.push(item);
    item['value'] = "0.00";
    item['supportRank'] = "5";
    item['questionNumber'] = "34";
    item['name'] = "Strongly Agree";
    item['questionText'] = "As asked earlier, assume that your local community college is seeking an 8-cent increase in property taxes to support improved facilities and programs. If you were voting today, is that something you would . . .";
    item.key = key++;
  }

}
