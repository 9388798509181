// eslint-disable-next-line
import React from 'react';
import DataSheetBase from './DataSheetBase.js';

export default class DataSheet_q11to15 extends DataSheetBase {

  constructor(id, updateCb) {
    super(id, updateCb);
    this.requestedKeyPath = "";  // this value can be specified in the React Studio data sheet UI
  }

  makeDefaultItems() {
    // eslint-disable-next-line no-unused-vars
    let key = 1;
    // eslint-disable-next-line no-unused-vars
    let item;
    
    item = {};
    this.items.push(item);
    item['support'] = "79.2";
    item['question'] = "Making sure all of the college's instructional areas are equipped with up-to-date instructional technology.";
    item.key = key++;
    
    item = {};
    this.items.push(item);
    item['support'] = "66.8";
    item['question'] = "Replacing buildings that are more than a half-century old, and are expensive and inefficient to operate and maintain.";
    item.key = key++;
    
    item = {};
    this.items.push(item);
    item['support'] = "79.2";
    item['question'] = "Providing learning spaces to accommodate best practices in job training and retraining programs.";
    item.key = key++;
    
    item = {};
    this.items.push(item);
    item['support'] = "79.6";
    item['question'] = "Repairing and replacing aging roofs, doors, windows, plumbing, HVAC, electrical and other infrastructure conditions as needed.";
    item.key = key++;
    
    item = {};
    this.items.push(item);
    item['support'] = "84.5";
    item['question'] = "Providing job training and retraining facilities and programs for good paying, high-in-demand, jobs in areas like healthcare, information technology (IT) and financial services.";
    item.key = key++;
  }

}
