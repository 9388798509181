import React, { Component } from 'react';
import './App.css';
import ScreenContext from './ScreenContext';
import Logo from './Logo';
import btn_icon_914563 from './images/btn_icon_914563.png';
import btn_icon_223693 from './images/btn_icon_223693.png';
import btn_icon_915655 from './images/btn_icon_915655.png';
import ResultsByGroup from './ResultsByGroup';
import ResultsByQuestion from './ResultsByQuestion';
import QuestionList from './QuestionList';
import { ResponsiveContainer, PieChart, Pie, Cell, Legend } from 'recharts';

// UI framework component imports
import Select from 'muicss/lib/react/select';
import Option from 'muicss/lib/react/option';

export default class ActivateScreen extends Component {

  static contextType = ScreenContext;


  constructor(props) {
    super(props);
    
    this.state = {
    };
  }

  componentDidMount() {
  }

  componentWillUnmount() {
  }

  componentDidUpdate(prevProps, prevState) {
  }

  
  getValue_elPicker = () => {
    return this.state.picker !== undefined ? this.state.picker : ((this.context.appActions.dataSlots ? this.context.appActions.dataSlots['ds_district'] : ''));
  }
  
  pickerValueChanged_elPicker = (event) => {
    this.setState({picker: event.target.value});
    
    this.context.appActions.updateDataSlot("ds_district", event.target.value);
  }
  
  onClick_elLogo = async () => {
    // Go to screen 'Home'
    this.context.appActions.goToScreen('home', this.context.baseScreenId, { transitionId: 'fadeIn' });
  
  }
  
  
  onClick_elButtonconnect = async () => {
    // Go to screen 'Connect'
    this.context.appActions.goToScreen('connect', this.context.baseScreenId, { transitionId: 'fadeIn' });
  
  }
  
  
  onClick_elButtonengage = async () => {
    // Go to screen 'Engage'
    this.context.appActions.goToScreen('engage', this.context.baseScreenId, { transitionId: 'fadeIn' });
  
  }
  
  
  onClick_elButtonLabelengage = async () => {
    // Go to screen 'Engage'
    this.context.appActions.goToScreen('engage', this.context.baseScreenId, { transitionId: 'fadeIn' });
  
  }
  
  
  onClick_elButtonactivate = async () => {
    // Go to screen 'Activate'
    this.context.appActions.goToScreen('activate', this.context.baseScreenId, { transitionId: 'fadeIn' });
  
  }
  
  
  
  render() {
    let layoutFlowStyle = {};
    let baseStyle = {};
    if (this.context.transitionId && this.context.transitionId.length > 0 && this.context.atTopOfScreenStack && this.context.transitionForward) {
      baseStyle.animation = '0.25s ease-in-out '+this.context.transitionId;
    }
    if ( !this.context.atTopOfScreenStack) {
      layoutFlowStyle.height = '100vh';
      layoutFlowStyle.overflow = 'hidden';
    }
    
    // eslint-disable-next-line
    const dataSheet_allQuestions = this.context.dataSheets['allQuestions'];
    const style_elBackground = {
      width: '100%',
      height: '100%',
     };
    const style_elBackground_outer = {
      boxSizing: 'border-box',
      backgroundColor: '#fdfdfd',
     };
    const style_elText = {
      color: '#1f1f1f',
      textAlign: 'center',
     };
    
    let selection_picker = this.getValue_elPicker();
    // Source datasheet and selected data column for picker element 'picker'
    const dataSource_picker = this.context.appActions.getDataSheet('districts');
    const valueColumnName_picker = 'district';
    
    const style_elPicker = {
      pointerEvents: 'auto',
     };
    const style_elLogo = {
      cursor: 'pointer',
      pointerEvents: 'auto',
     };
    
    const style_elButtonconnect = {
      display: 'block',
      textTransform: 'uppercase',
      backgroundImage: 'url('+btn_icon_914563+')',
      backgroundRepeat: 'no-repeat',
      backgroundSize: '75.784%',
      backgroundPosition: '50% 0%',
      color: '(null)',
      textAlign: 'center',
      backgroundColor: 'transparent',
      cursor: 'pointer',
      pointerEvents: 'auto',
     };
    const style_elButtonLabelconnect = {
      color: '#1f1f1f',
      textAlign: 'center',
     };
    
    const style_elButtonengage = {
      display: 'block',
      textTransform: 'uppercase',
      backgroundImage: 'url('+btn_icon_223693+')',
      backgroundRepeat: 'no-repeat',
      backgroundSize: '75.784%',
      backgroundPosition: '50% 0%',
      color: '(null)',
      textAlign: 'center',
      backgroundColor: 'transparent',
      cursor: 'pointer',
      pointerEvents: 'auto',
     };
    const style_elButtonLabelengage = {
      color: '#1f1f1f',
      textAlign: 'center',
      cursor: 'pointer',
      pointerEvents: 'auto',
     };
    
    const style_elButtonactivate = {
      display: 'block',
      textTransform: 'uppercase',
      backgroundImage: 'url('+btn_icon_915655+')',
      backgroundRepeat: 'no-repeat',
      backgroundSize: '75.784%',
      backgroundPosition: '50% 0%',
      color: '(null)',
      textAlign: 'center',
      backgroundColor: 'transparent',
      cursor: 'pointer',
      pointerEvents: 'auto',
     };
    const style_elButtonLabelactivate = {
      color: '#1f1f1f',
      textAlign: 'center',
     };
    const style_elLabelresultsByQuestion = {
      color: '#1f1f1f',
      textAlign: 'left',
     };
    const style_elText3 = {
      color: '#1f1f1f',
      textAlign: 'center',
     };
    
    const value_text2 = ((val) => {
      // make sure value is in string format
      if (val instanceof String || typeof val === 'string') return val;
      else {
        try {
          return JSON.stringify(val);
        }
        catch (e) {
          return val.toString();
        }
      }
    })((this.context.appActions.dataSlots ? this.context.appActions.dataSlots['ds_totalSupport'] : ''));
    
    const style_elText2 = {
      fontSize: 30.0,
      color: '#a1a1a1',
      textAlign: 'center',
     };
    
    // Source items and any special components used for list/grid element 'list-resultsByQuestion'.
    let items_listresultsByQuestion = [];
    let listComps_listresultsByQuestion = {};
    let filterItems_listresultsByQuestion = items => {
      // This function filters items for the List / Grid element.
      // There is a variable named ‘items’ that provides item values.
      let filteredItems = [];
      let filterStr = this.context.appActions.dataSlots['ds_filter']; // Data slot value
      // Loop through all items and add item to new array if item includes filter string
      items.forEach(item => {
        if (item['questionGroup'].toLowerCase().includes(filterStr) && item['district'].toLowerCase().includes('all')) {
          filteredItems.push(item);
        }
      });
      items=filteredItems; 
      return items;
    
    }
    items_listresultsByQuestion = items_listresultsByQuestion.concat(filterItems_listresultsByQuestion(this.context.appActions.getDataSheet('allQuestions').items));
    this._elListresultsByQuestion_items = [];
    
    const style_elListresultsByQuestion = {
      height: 'auto',  // This element is in scroll flow
     };
    
    
    var sheet_elPiechartquestionBySupport = this.context.appActions.getDataSheet('questionSupportBreakdown').items;
    var slot_elPiechartquestionBySupport = (this.context.appActions.dataSlots ? this.context.appActions.dataSlots['ds_pie'] : '');
    
    sheet_elPiechartquestionBySupport.forEach(function(list){
      list.value = Number(list.value);
    });
    
    var pieChart = sheet_elPiechartquestionBySupport.filter(filterList);
    
    function filterList(list){
     return Number(list.questionNumber) === Number(slot_elPiechartquestionBySupport) && Number(list.value) > 1;
    }
    
    const COLORS = ["#d6d6d6", "#c0c0c0", "#929292", "#797979"];
    // const COLORS = ["#797979", "#929292", "#c0c0c0", "#d6d6d6"];
    
    const RADIAN = Math.PI / 180;
    const renderCustomizedLabel = ({
      cx,
      cy,
      midAngle,
      innerRadius,
      outerRadius,
      percent,
      index
    }: any) => {
      const radius = innerRadius + (outerRadius - innerRadius) * 0.5;
      const x = cx + radius * Math.cos(-midAngle * RADIAN);
      const y = cy + radius * Math.sin(-midAngle * RADIAN);
    
      return (
        <text
          x={x}
          y={y}
          fill="black"
          fontSize="12"
          textAnchor={x > cx ? "start" : "end"}
          dominantBaseline="central"
        >
        {`${(percent * 100).toFixed(0)}%`}  
        </text>
      );
    };
    const style_elPiechartquestionBySupport = {
      pointerEvents: 'auto',
     };
    
    return (
      <div className="AppScreen ActivateScreen" style={baseStyle}>
        <div className="background">
          <div className="containerMinHeight elBackground" style={style_elBackground_outer}>
            <div className="appBg" style={style_elBackground} />
          </div>
        </div>
        
        <div className="layoutFlow" style={layoutFlowStyle}>
          <div className="flowRow flowRow_ActivateScreen_elText_435343">
          <div className="elText">
            <div className="headlineFont" style={style_elText}>
              <div>{this.context.locStrings.connect2_text_435343}</div>
            </div>
          </div>
          
          <div className="elPicker">
            <Select className="baseFont" style={style_elPicker}  onChange={this.pickerValueChanged_elPicker} value={selection_picker} >
              {dataSource_picker.items.every(item => {
                return item[valueColumnName_picker] !== selection_picker;
              }) ? <Option value=''/> : null}
              {dataSource_picker.items.map(item => {
                const colValue = item[valueColumnName_picker];
                const labelColValue = item[valueColumnName_picker];
                return <Option key={item.key} value={colValue} label={labelColValue} />
              })}
            </Select>
          </div>
          
          </div>
          <div className="hasNestedComps elLogo">
            <div style={style_elLogo} onClick={this.onClick_elLogo} >
              <Logo ref={(el)=> this._elLogo = el} />
            </div>
          </div>
          
          <div className="elButtonconnect">
            <button className="actionFont" style={style_elButtonconnect} onClick={this.onClick_elButtonconnect}  />
          </div>
          
          <div className="elButtonLabelconnect">
            <div className="baseFont" style={style_elButtonLabelconnect}>
              <div>{this.context.locStrings.connect2_buttonlabelconnect_744725}</div>
            </div>
          </div>
          
          <div className="elButtonengage">
            <button className="actionFont" style={style_elButtonengage} onClick={this.onClick_elButtonengage}  />
          </div>
          
          <div className="elButtonLabelengage">
            <div className="baseFont" style={style_elButtonLabelengage} onClick={this.onClick_elButtonLabelengage} >
              <div>{this.context.locStrings.connect2_buttonlabelengage_818067}</div>
            </div>
          </div>
          
          <div className="elButtonactivate">
            <button className="actionFont" style={style_elButtonactivate} onClick={this.onClick_elButtonactivate}  />
          </div>
          
          <div className="elButtonLabelactivate">
            <div className="baseFont" style={style_elButtonLabelactivate}>
              <div>{this.context.locStrings.connect2_buttonlabelactivate_318750}</div>
            </div>
          </div>
          
          <div className="hasNestedComps elComponentresultsByQuestionGroup">
            <div>
              <ResultsByGroup ref={(el)=> this._elComponentresultsByQuestionGroup = el} />
            </div>
          </div>
          
          <div className="elSpacer3">
            <div />
          </div>
          
          <div className="elSpacer2">
            <div />
          </div>
          
          <div className="hasNestedComps elComponentresultsByQuestion">
            <div>
              <ResultsByQuestion visualStateIndex={(this.context.appActions.dataSlots ? this.context.appActions.dataSlots['ds_filter'] : '')} ref={(el)=> this._elComponentresultsByQuestion = el} />
            </div>
          </div>
          
          <div className="elLabelresultsByQuestion">
            <div className="headlineFont" style={style_elLabelresultsByQuestion}>
              <div>{this.context.locStrings.connect2_labelresultsbyquestion_272124}</div>
            </div>
          </div>
          
          <div className="elText3">
            <div className="headlineFont" style={style_elText3}>
              <div>{this.context.locStrings.activate_text3_889378}</div>
            </div>
          </div>
          
          <div className="elText2">
            <div className="systemFontRegular" style={style_elText2}>
              <div>{value_text2}</div>
            </div>
          </div>
          
          <div className="flowRow flowRow_ActivateScreen_elListresultsByQuestion_241079">
          <div className="hasNestedComps elListresultsByQuestion">
            <ul style={style_elListresultsByQuestion}>
              {items_listresultsByQuestion.map((row, index) => {
                let itemComp = (row._componentId)
                    ? listComps_listresultsByQuestion[row._componentId]
                    : <QuestionList dataSheetId={'allQuestions'} dataSheetRow={row} {...{ 'question': row['question'], 'totalSupport': row['totalSupport'], }} ref={(el) => {if (el) this._elListresultsByQuestion_items.push(el)}} />;
                return (<li key={row.key || index}>
                    {itemComp}
                  </li>);
              })}
              <div className="marker" ref={(el)=> this._elListresultsByQuestion_endMarker = el} />
            </ul>
          </div>
          
          </div>
          <div className="elPiechartquestionBySupport">
            <div style={style_elPiechartquestionBySupport}>
              
              <ResponsiveContainer width="100%" height="100%"> 
                   <PieChart>
                       <Pie
                          data={pieChart} // sheet_elRechartsPie
                          labelLine={false}
                          label={renderCustomizedLabel}
                          dataKey="value"
                      >
                      {sheet_elPiechartquestionBySupport.map((entry, index) => (<Cell key={ Cell-index } fill={COLORS[index % COLORS.length]} />))}
                    </Pie>
                    <Legend layout="horizontal" verticalAlign="top"/>
                  </PieChart>
              </ResponsiveContainer>
            </div>
          </div>
          
          <div className="elSpacer">
            <div />
          </div>
        </div>
        
      </div>
    )
  }
  
}
