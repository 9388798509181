// eslint-disable-next-line
import React from 'react';
import DataSheetBase from './DataSheetBase.js';

export default class DataSheet_personicxDist extends DataSheetBase {

  constructor(id, updateCb) {
    super(id, updateCb);
    this.requestedKeyPath = "";  // this value can be specified in the React Studio data sheet UI
  }

  makeDefaultItems() {
    // eslint-disable-next-line no-unused-vars
    let key = 1;
    // eslint-disable-next-line no-unused-vars
    let item;
    
    item = {};
    this.items.push(item);
    item['support'] = "3.55";
    item['category'] = "Beginnings";
    item.key = key++;
    
    item = {};
    this.items.push(item);
    item['support'] = "4.14";
    item['category'] = "Taking Hold";
    item.key = key++;
    
    item = {};
    this.items.push(item);
    item['support'] = "0.69";
    item['category'] = "Transition Time";
    item.key = key++;
    
    item = {};
    this.items.push(item);
    item['support'] = "3.72";
    item['category'] = "Flying Solo";
    item.key = key++;
    
    item = {};
    this.items.push(item);
    item['support'] = "2.98";
    item['category'] = "Family Focused";
    item.key = key++;
    
    item = {};
    this.items.push(item);
    item['support'] = "0.96";
    item['category'] = "Mixed Singles";
    item.key = key++;
    
    item = {};
    this.items.push(item);
    item['support'] = "4.38";
    item['category'] = "Cash & Careers";
    item.key = key++;
    
    item = {};
    this.items.push(item);
    item['support'] = "2.15";
    item['category'] = "Jumbo Families";
    item.key = key++;
    
    item = {};
    this.items.push(item);
    item['support'] = "1.23";
    item['category'] = "Middling Singles";
    item.key = key++;
    
    item = {};
    this.items.push(item);
    item['support'] = "6.11";
    item['category'] = "Mixed Middlers";
    item.key = key++;
    
    item = {};
    this.items.push(item);
    item['support'] = "13.38";
    item['category'] = "Fortunes & Families";
    item.key = key++;
    
    item = {};
    this.items.push(item);
    item['support'] = "10.00";
    item['category'] = "Flush Families";
    item.key = key++;
    
    item = {};
    this.items.push(item);
    item['support'] = "4.15";
    item['category'] = "True Blues";
    item.key = key++;
    
    item = {};
    this.items.push(item);
    item['support'] = "4.34";
    item['category'] = "Our Turn";
    item.key = key++;
    
    item = {};
    this.items.push(item);
    item['support'] = "5.37";
    item['category'] = "Mature Wealth";
    item.key = key++;
    
    item = {};
    this.items.push(item);
    item['support'] = "3.06";
    item['category'] = "Aging Upscale";
    item.key = key++;
    
    item = {};
    this.items.push(item);
    item['support'] = "6.89";
    item['category'] = "Modest Means";
    item.key = key++;
    
    item = {};
    this.items.push(item);
    item['support'] = "0.04";
    item['category'] = "Mature Rustics";
    item.key = key++;
    
    item = {};
    this.items.push(item);
    item['support'] = "5.30";
    item['category'] = "Golden Years";
    item.key = key++;
    
    item = {};
    this.items.push(item);
    item['support'] = "11.48";
    item['category'] = "Active Elders";
    item.key = key++;
    
    item = {};
    this.items.push(item);
    item['support'] = "6.09";
    item['category'] = "Leisure Buffs";
    item.key = key++;
  }

}
