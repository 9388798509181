// eslint-disable-next-line
import React from 'react';
import DataSheetBase from './DataSheetBase.js';

export default class DataSheet_q21to25 extends DataSheetBase {

  constructor(id, updateCb) {
    super(id, updateCb);
    this.requestedKeyPath = "";  // this value can be specified in the React Studio data sheet UI
  }

  makeDefaultItems() {
    // eslint-disable-next-line no-unused-vars
    let key = 1;
    // eslint-disable-next-line no-unused-vars
    let item;
    
    item = {};
    this.items.push(item);
    item['support'] = "81.5";
    item['question'] = "Providing real-world learning environments that provide students with the skills they need in the workplace.";
    item.key = key++;
    
    item = {};
    this.items.push(item);
    item['support'] = "78.1";
    item['question'] = "Improving ADA accessibility at all campuses for students with disabilities.";
    item.key = key++;
    
    item = {};
    this.items.push(item);
    item['support'] = "88.6";
    item['question'] = "We must ensure that our community college has adequate facilities and programs to meet the demand for high tech job training programs.";
    item.key = key++;
    
    item = {};
    this.items.push(item);
    item['support'] = "64.3";
    item['question'] = "If the college needs more money, it should raise student fees, not increase the money it receives from taxes.";
    item.key = key++;
    
    item = {};
    this.items.push(item);
    item['support'] = "84.3";
    item['question'] = "We must protect the investment we've made in our community college by keeping our programming current and our facilities up-to-date in good working condition.";
    item.key = key++;
  }

}
