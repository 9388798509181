// eslint-disable-next-line
import React from 'react';
import DataSheetBase from './DataSheetBase.js';

export default class DataSheet_q1to5 extends DataSheetBase {

  constructor(id, updateCb) {
    super(id, updateCb);
    this.requestedKeyPath = "";  // this value can be specified in the React Studio data sheet UI
  }

  makeDefaultItems() {
    // eslint-disable-next-line no-unused-vars
    let key = 1;
    // eslint-disable-next-line no-unused-vars
    let item;
    
    item = {};
    this.items.push(item);
    item['support'] = "72.7";
    item['question'] = "Thinking about the next two or three \nmonths, do you think the economy in your \narea will . .";
    item.key = key++;
    
    item = {};
    this.items.push(item);
    item['support'] = "80";
    item['question'] = "Students are often given the grades A, B, C, D, and F to evaluate their work. What grade would you give the community college in your area?";
    item.key = key++;
    
    item = {};
    this.items.push(item);
    item['support'] = "58.8";
    item['question'] = "Assume the community college in your area seeks voter approval for an 8-cent increase in property taxes to support improved facilities and programs. If you were voting today, is that something you .";
    item.key = key++;
    
    item = {};
    this.items.push(item);
    item['support'] = "49.2";
    item['question'] = "If you knew this proposal would cost about $1.90 per month for a $150,000 house, is this proposal something you . . .";
    item.key = key++;
    
    item = {};
    this.items.push(item);
    item['support'] = "89.2";
    item['question'] = "It is essential that our area has a quality community college as a place for adults to be trained and retrained for the good paying jobs of today and tomorrow.";
    item.key = key++;
  }

}
