// eslint-disable-next-line
import React from 'react';
import DataSheetBase from './DataSheetBase.js';

export default class DataSheet_es extends DataSheetBase {

  constructor(id, updateCb) {
    super(id, updateCb);
    this.requestedKeyPath = "";  // this value can be specified in the React Studio data sheet UI
  }

  makeDefaultItems() {
    // eslint-disable-next-line no-unused-vars
    let key = 1;
    // eslint-disable-next-line no-unused-vars
    let item;
    
    item = {};
    this.items.push(item);
    item['name'] = "BARRETTS ELEM";
    item['type'] = "es";
    item['district'] = "Parkway C-2 School District";
    item.key = key++;
    
    item = {};
    this.items.push(item);
    item['name'] = "SORRENTO SPRINGS ELEM";
    item['type'] = "es";
    item['district'] = "Parkway C-2 School District";
    item.key = key++;
    
    item = {};
    this.items.push(item);
    item['name'] = "GREEN TRAILS ELEM";
    item['type'] = "es";
    item['district'] = "Parkway C-2 School District";
    item.key = key++;
    
    item = {};
    this.items.push(item);
    item['name'] = "MASON RIDGE ELEM";
    item['type'] = "es";
    item['district'] = "Parkway C-2 School District";
    item.key = key++;
    
    item = {};
    this.items.push(item);
    item['name'] = "WREN HOLLOW ELEM";
    item['type'] = "es";
    item['district'] = "Parkway C-2 School District";
    item.key = key++;
    
    item = {};
    this.items.push(item);
    item['name'] = "PIERREMONT ELEM";
    item['type'] = "es";
    item['district'] = "Parkway C-2 School District";
    item.key = key++;
    
    item = {};
    this.items.push(item);
    item['name'] = "BELLERIVE ELEM";
    item['type'] = "es";
    item['district'] = "Parkway C-2 School District";
    item.key = key++;
    
    item = {};
    this.items.push(item);
    item['name'] = "CRAIG ELEM";
    item['type'] = "es";
    item['district'] = "Parkway C-2 School District";
    item.key = key++;
    
    item = {};
    this.items.push(item);
    item['name'] = "RIVER BEND ELEM";
    item['type'] = "es";
    item['district'] = "Parkway C-2 School District";
    item.key = key++;
    
    item = {};
    this.items.push(item);
    item['name'] = "CARMAN TRAILS ELEM";
    item['type'] = "es";
    item['district'] = "Parkway C-2 School District";
    item.key = key++;
    
    item = {};
    this.items.push(item);
    item['name'] = "CLAYMONT ELEM";
    item['type'] = "es";
    item['district'] = "Parkway C-2 School District";
    item.key = key++;
    
    item = {};
    this.items.push(item);
    item['name'] = "HENRY ELEM";
    item['type'] = "es";
    item['district'] = "Parkway C-2 School District";
    item.key = key++;
    
    item = {};
    this.items.push(item);
    item['name'] = "ROSS ELEM";
    item['type'] = "es";
    item['district'] = "Parkway C-2 School District";
    item.key = key++;
    
    item = {};
    this.items.push(item);
    item['name'] = "HANNA WOODS ELEM";
    item['type'] = "es";
    item['district'] = "Parkway C-2 School District";
    item.key = key++;
    
    item = {};
    this.items.push(item);
    item['name'] = "SHENANDOAH VALLEY ELEM";
    item['type'] = "es";
    item['district'] = "Parkway C-2 School District";
    item.key = key++;
    
    item = {};
    this.items.push(item);
    item['name'] = "OAK BROOK ELEM";
    item['type'] = "es";
    item['district'] = "Parkway C-2 School District";
    item.key = key++;
    
    item = {};
    this.items.push(item);
    item['name'] = "MCKELVEY ELEM";
    item['type'] = "es";
    item['district'] = "Parkway C-2 School District";
    item.key = key++;
    
    item = {};
    this.items.push(item);
    item['name'] = "HIGHCROFT RIDGE ELEM";
    item['type'] = "es";
    item['district'] = "Parkway C-2 School District";
    item.key = key++;
  }

}
