// eslint-disable-next-line
import React from 'react';
import DataSheetBase from './DataSheetBase.js';

export default class DataSheet_q16to20 extends DataSheetBase {

  constructor(id, updateCb) {
    super(id, updateCb);
    this.requestedKeyPath = "";  // this value can be specified in the React Studio data sheet UI
  }

  makeDefaultItems() {
    // eslint-disable-next-line no-unused-vars
    let key = 1;
    // eslint-disable-next-line no-unused-vars
    let item;
    
    item = {};
    this.items.push(item);
    item['support'] = "83.8";
    item['question'] = "Making sure the campus is safe and secure both night and day.";
    item.key = key++;
    
    item = {};
    this.items.push(item);
    item['support'] = "83.4";
    item['question'] = "Keeping community college programs affordable.";
    item.key = key++;
    
    item = {};
    this.items.push(item);
    item['support'] = "81.5";
    item['question'] = "Improving training programs so those who are underemployed and unemployed can secure jobs that pay a decent salary.";
    item.key = key++;
    
    item = {};
    this.items.push(item);
    item['support'] = "77";
    item['question'] = "Making sure student support services such as registration and financial services are centralized and easily available to students.";
    item.key = key++;
    
    item = {};
    this.items.push(item);
    item['support'] = "79.6";
    item['question'] = "Coordinating programs with area economic planners and businesses to make sure well-trained employees are available to help stimulate business growth and relocation in our community.";
    item.key = key++;
  }

}
